/* eslint-disable no-use-before-define */

import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import services from '../../services/apiService';
import { ToggleButton } from './ToggleButton';

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
            {
                padding: 2
            },
        '& .MuiInputBase-input': {
            color: (props) => (props.originalValue === null ? 'black' : 'gray')
        }
    },
    root: {
        '& .MuiInputBase-sizeSmall': {
            paddingTop: '7.5px !important',
            paddingLeft: '8px !important',
            paddingBottom: '7.5px !important'
        },
        marginBottom: '10px'
    },
    margin: {
        margin: theme.spacing(1),
        width: '100%'
    },
    labelStyle: {
        fontWeight: 600,
        fontSize: 'large',
        color: 'black'
    },
    error: {
        borderRadius: 4,
        border: '1px solid red',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    }
}));

// TODO: Fix renderOption issue in which renderOption worked in @material-ui/lab/Autocomplete,
// but no longer works in @mui/material/Autocomplete
export const LiveAutocomplete = (props) => {
    const classes = useStyles(props);

    const {
        label,
        name,
        nameField = 'label',
        handleChange,
        defaultValue,
        showToggle,
        enableToggle,
        originalValue,
        handleToggleChange,
        error,
        localization,
        serviceName,
        required
    } = props;

    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(null);

    useEffect(() => {
        let value = defaultValue;
        if (options && defaultValue) {
            value = options.find((v) => v.id === defaultValue) || null;
        }
        setValue(value);
    }, [options, defaultValue]);

    useEffect(() => {
        // clean up controller
        let isSubscribed = true;

        services.fetchAllData(serviceName).then((data) => {
            isSubscribed && setOptions(data);
        });

        // cancel subscription to useEffect
        return () => (isSubscribed = false);
    }, []);

    return (
        <>
            <Grid
                container
                justifyContent='space-between'
                alignItems='flex-end'
            >
                {showToggle && (
                    <>
                        <Grid item xs={9}>
                            <InputLabel
                                className={classes.labelStyle}
                                shrink
                                htmlFor={name}
                            >
                                {label}{' '}
                                {required && (
                                    <span className='text-danger'>*</span>
                                )}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={3} style={{ flexBasis: '5%' }}>
                            <ToggleButton
                                showToggle={
                                    showToggle &&
                                    (defaultValue != null ||
                                        defaultValue !== undefined) &&
                                    !_.isEqual(defaultValue, originalValue)
                                }
                                name={name}
                                originalValue={
                                    originalValue
                                        ? originalValue[nameField]
                                        : ''
                                }
                                enableToggle={enableToggle}
                                handleToggleChange={handleToggleChange}
                            />
                        </Grid>
                    </>
                )}
                {!showToggle && (
                    <>
                        <Grid item xs={12}>
                            <InputLabel
                                className={classes.labelStyle}
                                shrink
                                htmlFor={name}
                            >
                                {label}{' '}
                                {required && (
                                    <span className='text-danger'>*</span>
                                )}
                            </InputLabel>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    name={name}
                    id={name}
                    classes={classes}
                    size='small'
                    limitTags={2}
                    onChange={(e, newValue) => {
                        handleChange(e, newValue?.id, name);
                    }}
                    options={options}
                    getOptionLabel={(option) => {
                        return option && nameField ? option[nameField] : '';
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='outlined'
                            placeholder={
                                originalValue && nameField
                                    ? originalValue[nameField]
                                    : localization.select
                            }
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={`${name}-${option.id}`}>
                                {option.name}
                            </li>
                        );
                    }}
                    value={value}
                    {...(!!error && { className: classes.error })}
                    noOptionsText={`No available ${serviceName}`}
                />
            </Grid>
        </>
    );
};
