import React, { useState, useEffect } from 'react';
import { AccessAlarm, HighlightOff } from '@mui/icons-material';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Calendar as DatePicker } from '../shared/Calendar';
import { date as dateUtils } from '../../utils/date';
import { isAdmin } from '../../reducer/userReducer';
import { useSelector } from 'react-redux';
import { Input } from '../shared/Input';
import { Textarea } from '../shared/Textarea';
import productsService from '../../services/productsService';
import store from '../../redux/store';
import {
    ABOUT_INFO,
    COOKIE_NAME,
    DEFAULT_COOKIE,
    ENABLED_ENVIRONMENTS,
    NON_PROD_RESPONSE
} from './constants';

export const getFormattedDate = (dateString) => {
    if (Object.prototype.toString.call(dateString) === '[object String]') {
        dateString = new Date(dateString);
    }
    let formattedDate = '';
    try {
        formattedDate = new Intl.DateTimeFormat('default', {
            dateStyle: 'short',
            timeStyle: 'short',
            hour12: true
        }).format(dateString);
    } catch (error) {
        console.error(error);
    }

    return formattedDate;
};

export const getDuration = (start, end) => {
    let seconds = 0;
    let minutes = 0;
    let hours = 0;

    try {
        seconds = Math.abs((end.getTime() - start.getTime()) / 1000);
        minutes = Math.floor(seconds / 60);

        if (minutes > 60) {
            hours = Math.floor(minutes / 60);
            minutes = minutes % 60;
        }
    } catch (e) {
        console.error(
            `getDuration call failed when trying to get a times for ${start} and ${end}`
        );
    }

    const hourStr = `hour${hours > 1 ? 's' : ''}`;
    const minuteStr = `minute${minutes > 1 ? 's' : ''}`;

    if (hours === 0 && minutes === 0) {
        return `INVALID DURATION`;
    }

    if (hours > 0) {
        return `${hours} ${hourStr}${
            minutes > 0 ? ` and ${minutes} ${minuteStr}` : ''
        }`;
    }

    return `${minutes} ${minuteStr}`;
};

export const readConfig = (isLoaded, stateObject, envSetter, loadedSetter) => {
    if (!isLoaded && stateObject?.config?.domains.serviceApiDomain) {
        envSetter(
            stateObject?.config.environment === ''
                ? 'local'
                : stateObject.config.environment
        );
        loadedSetter(true);
    }
};

export const getCookie = () => {
    return JSON.parse(localStorage.getItem(COOKIE_NAME) || DEFAULT_COOKIE);
};

export const updateCookie = (currentCookie, environment, enabled) => {
    return {
        ...currentCookie,
        release_notifications_enabled: {
            ...currentCookie.release_notifications_enabled,
            [environment]: enabled
        }
    };
};

export const saveCookie = (cookieName, cookieValue) => {
    localStorage.setItem(cookieName, JSON.stringify(cookieValue));
};

const ReleaseNotification = () => {
    const useStyles = makeStyles((theme) => ({
        cancelEditButton: {
            backgroundColor: 'transparent',
            color: 'black',
            cursor: 'pointer',
            float: 'right'
        },
        editButton: {
            color: 'white',
            float: 'right',
            margin: '10px 0 0 0',
            '&:disabled': {
                color: 'white',
                backgroundColor: 'gray'
            }
        },
        componentContainer: {
            position: 'relative'
        },
        notificationContainer: {
            cursor: 'pointer'
        },
        notificationHeadline: {
            display: 'block',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            whiteSpace: 'nowrap'
        },
        notificationEditContainer: {
            backgroundColor: 'white',
            border: '2px solid gray',
            borderRadius: '25px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, 20%)',
            padding: '20px',
            position: 'absolute',
            zIndex: 999,
            minWidth: '460px',
            boxShadow:
                'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px'
        },
        notificationHeadlineOffset: {
            margin: '0 0 0 6px'
        }
    }));

    const classes = useStyles();
    const isAdminUser = useSelector(isAdmin);

    const [editActive, setEditActive] = useState(false);
    const [editEnabled, setEditEnabled] = useState(false);
    const [enableSave, setEnableSave] = useState(false);
    const [env, setEnv] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [messagePending, setMessagePending] = useState(false);
    const [notification, setNotification] = useState(null);
    const [maintenanceStartDate, setMaintenanceStartDate] = useState(null);
    const [maintenanceEndDate, setMaintenanceEndDate] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState(null);
    const [notificationTitle, setNotificationTitle] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [rawNotificationMessage, setRawNotificationMessage] = useState(null);

    const state = store.getState();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    };

    React.useEffect(() => {
        readConfig(loaded, state, setEnv, setLoaded);
    }, [state.config]);

    React.useEffect(async () => {
        if (isAdminUser !== null && loaded && env) {
            if (isAdminUser) {
                window.let = {
                    ...window.let,
                    editRelease,
                    enableNotificiations,
                    info: () => {
                        console.info(ABOUT_INFO);
                    }
                };
            } else {
                window.let = {
                    ...window.let
                };
            }

            if (isAdminUser) {
                window.let.info();
            }

            setNotification(await loadNotification());
        }
    }, [isAdminUser]);

    React.useEffect(async () => {
        if (isAdminUser) {
            window.let = {
                ...window.let,
                editRelease: editRelease
            };
        }
    }, [editEnabled]);

    const editRelease = () => {
        if (isAdminUser) {
            if (editEnabled) {
                setEditActive(true);
            } else {
                console.info(
                    `Editing is disabled in ${env}. To enable it, please call the let.enableNotifications command.`
                );
            }
        }
    };
    const enableNotificiations = (enable = false) => {
        const currentCookie = getCookie();
        const updatedCookie = updateCookie(currentCookie, env, enable);
        saveCookie(COOKIE_NAME, updatedCookie);

        console.info(
            `Release notifications have been ${
                enable ? 'enabled' : 'disabled'
            } for ${env}. ${
                enable
                    ? 'Re-load the page to view the current release notification'
                    : ''
            }`
        );
        window.let = {
            ...window.let,
            editRelease
        };
        setEditEnabled(enable);
    };
    const handleDateChange = (key, value) => {
        if (key === 'date_start') {
            setMaintenanceStartDate(value);
            setEnableSave(true);
        } else if (key === 'date_end') {
            setMaintenanceEndDate(value);
            setEnableSave(true);
        }
    };
    const handleMessageChange = (event) => {
        setRawNotificationMessage(event.target.value);
        setNotificationMessage(event.target.value);
        setEnableSave(true);
    };
    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
    };
    const handleNotificationSave = async (event) => {
        const updatedNotification = {
            date_start: dateUtils.estToIso(maintenanceStartDate),
            date_end: dateUtils.estToIso(maintenanceEndDate),
            title: notificationTitle,
            message: rawNotificationMessage
        };
        // Disable the save button:
        setEnableSave(false);
        // Save:
        const result = await productsService.updateReleaseNotifications(
            updatedNotification
        );
        if (result.error) {
            console.error(result.error);
        }
        // Update the notification locally:
        setNotification({ ...updatedNotification });
        // Re-load the notification with the cache busted:
        await loadNotification(true);
        // Close the edit component:
        setEditActive(false);
    };
    const handleTitleChange = (event) => {
        setNotificationTitle(event.target.value);
        setEnableSave(true);
    };
    const loadSavedValue = () => {
        const cookie = getCookie();
        return cookie?.release_notifications_enabled[env] || false;
    };
    const loadNotification = async (override = false) => {
        let response;

        try {
            let higherEnvironment = ENABLED_ENVIRONMENTS.test(env);
            let lowerEnvironmentOverride = loadSavedValue();
            let enabled = higherEnvironment
                ? true
                : isAdminUser
                ? Boolean(lowerEnvironmentOverride)
                : false;

            setEditEnabled(enabled);

            if (isAdminUser) {
                if (higherEnvironment) {
                    console.info(`\n** Release Notifications enabled`);
                } else if (lowerEnvironmentOverride) {
                    console.info(
                        `\n** Release Notifications override enabled for ${env}`
                    );
                } else {
                    console.info(`\n** Release Notifications Not Enabled`);
                }
            }

            response = enabled
                ? await productsService.getReleaseNotifications(override)
                : (response = NON_PROD_RESPONSE);

            const { date_end, date_start, title, message } = response;

            const start = dateUtils.isoToEst(date_start);
            const end = dateUtils.isoToEst(date_end);
            const now = new Date();

            if (now.getTime() > new Date(end).getTime()) {
                // Notification is in the past; don't show it:
                setMessagePending(false);
                return;
            }

            setMaintenanceStartDate(start);
            setMaintenanceEndDate(end);
            setRawNotificationMessage(message);

            const durationRegEx = /%%DURATION%%/g;

            if (durationRegEx.test(message)) {
                setNotificationMessage(
                    message.replace(
                        durationRegEx,
                        getDuration(new Date(date_start), new Date(date_end))
                    )
                );
            } else {
                setNotificationMessage(message);
            }

            setNotificationTitle(title);
            setMessagePending(true);
        } catch (e) {
            console.error(
                `Error parsing notification response:\n\n${JSON.stringify(
                    response,
                    null,
                    2
                )}\n\nEND ERROR`
            );
        } finally {
            setNotification(response);
        }
    };

    return (
        <div className={classes.componentContainer} id={'ReleaseNotification'}>
            {editActive && (
                <Grid
                    container
                    className={classes.notificationEditContainer}
                    justifyContent='flex-start'
                >
                    <Grid item xs={12}>
                        <HighlightOff
                            className={classes.cancelEditButton}
                            onClick={() => {
                                setEditActive(false);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DatePicker
                            name='date_start'
                            label='Maintenance Start (EST):'
                            defaultValue={maintenanceStartDate}
                            handleChange={handleDateChange}
                            showToggle={false}
                            enableToggle={false}
                            originalValue={maintenanceStartDate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DatePicker
                            name='date_end'
                            label='Maintenance End (EST):'
                            defaultValue={maintenanceEndDate}
                            handleChange={handleDateChange}
                            showToggle={false}
                            enableToggle={false}
                            originalValue={maintenanceEndDate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            label='Notification Title:'
                            name='notification_title'
                            value={notificationTitle}
                            handleChange={handleTitleChange}
                            enableToggle={false}
                            originalValue={handleTitleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Textarea
                            label='Message:'
                            name='notitication_message'
                            defaultValue={rawNotificationMessage}
                            originalValue={rawNotificationMessage}
                            handleChange={handleMessageChange}
                        />
                    </Grid>
                    <Grid item xs={12} justifySelf={'flex-end'}>
                        <Button
                            className={classes.editButton}
                            disabled={!enableSave}
                            onClick={handleNotificationSave}
                        >
                            Save Changes
                        </Button>
                    </Grid>
                </Grid>
            )}
            {messagePending && (
                <Grid
                    container
                    spacing={2}
                    className={classes.notificationContainer}
                    alignItems='center'
                    title={notificationMessage}
                    onClick={handleModalOpen}
                >
                    <Grid item xs={1}>
                        <AccessAlarm />
                    </Grid>
                    <Grid item xs={11} className={classes.notificationHeadline}>
                        <span className={classes.notificationHeadlineOffset}>
                            {notificationTitle}{' '}
                            {getFormattedDate(maintenanceStartDate)} EST
                        </span>
                    </Grid>
                </Grid>
            )}

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                onClick={handleModalClose}
            >
                <Box sx={style}>
                    <Typography
                        id='modal-modal-title'
                        variant='h6'
                        component='h2'
                    >
                        {notificationTitle}
                    </Typography>
                    <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                        {notificationMessage}
                    </Typography>
                    <br />
                    <small>Click Anywhere to Close</small>
                </Box>
            </Modal>
        </div>
    );
};

export default ReleaseNotification;
