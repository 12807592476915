import React, { useEffect, useState, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import { ArrowForwardOutlined, RemoveOutlined } from '@mui/icons-material';
import services from '../../../services/apiService';
import { AutocompleteBox } from '../../shared/AutocompleteBox';
import { Buttons } from '../../shared/Buttons';
import { CheckBox } from '../../shared/Checkbox';
import { Container, Grid } from '@mui/material';
import { countries } from '../../../data';
import { getRoute } from '../../shared/AdvancedSearch';
import { Input } from '../../shared/Input';
import { LiveAutocomplete } from '../../shared/LiveAutocomplete';
import { Loader } from '../../shared/Loader';
import { makeStyles } from '@mui/styles';
import { MultiSelectBox } from '../../shared/MultiSelectBox';
import { selectTenant } from '../../../reducer/tenantSlice';
import { Textarea } from '../../shared/Textarea';
import { useForm } from '../../hooks/UseForm';
// Notifications:'
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        }
    },
    buttonright: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

export const Info = ({
    id,
    competitor,
    setRefresh,
    competitorSchema,
    handleUpdate,
    handleForward
}) => {

    const dispatch = useDispatch();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [sports, setSports] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [conferences, setConferences] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const requiredFields = competitorSchema.required;

    const {
        item,
        showToggle,
        enableToggle,
        isInProgress,
        error,
        valueChanged,
        handleChange,
        handleFieldChange,
        handleToggleChange,
        handleMultipleSelectChange,
        onFormSubmit,
        form
    } = useForm({
        service: 'competitors',
        initialState: competitor,
        id,
        requiredFields,
        setRefresh
    });

    const {
        name,
        alias,
        abbreviation,
        description,
        short_name,
        vocal_name,
        market,
        vocal_market,
        mascot_name,
        synonyms,
        venue_id,
        territories_available,
        hidden,
        has_media,
        let_override: {
            name: override_name,
            alias: override_alias,
            abbreviation: override_abbreviation,
            description: override_description,
            short_name: override_short_name,
            vocal_name: override_vocal_name,
            market: override_market,
            vocal_market: override_vocal_market,
            mascot_name: override_mascot_name,
            synonyms: override_synonyms,
            venue_id: override_venue_id,
            territories_available: override_territories_available,
            hidden: override_hidden,
            has_media: override_has_media
        } = {}
    } = item;

    const currentTenant = useSelector(selectTenant);

    useEffect(() => {
        handleUpdate(valueChanged);
    }, [valueChanged]);

    useEffect(() => {
        setLoading(true);
        services
            .fetchAllData('sports')
            .then((data) => {
                setSports(data);
            })
            .catch((err) => {
                setLeagues([]);
                dispatch(notifyError(`${err.name}:  ${err.message}`));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const fetchLeagues = useCallback(() => {
        const sportId = form.sport_id || undefined;
        if (sportId !== undefined) {
            setLoading(true);
            let route = getRoute('leagues', { sport_id: sportId });
            services
                .fetchAllData(route)
                .then((data) => {
                    setLeagues(data);
                })
                .catch((err) => {
                    setLeagues([]);
                    dispatch(notifyError(`${err.name}:  ${err.message}`));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLeagues([]);
        }
        setConferences([]);
        setDivisions([]);
    }, [form.sport_id]);

    useEffect(() => {
        fetchLeagues();
    }, [fetchLeagues]);

    const fetchConferences = useCallback(() => {
        const leagueId = form.league_id || undefined;

        if (leagueId !== undefined) {
            setLoading(true);
            let route = getRoute('conferences', { league_id: leagueId });
            services
                .fetchAllData(route)
                .then((data) => {
                    setConferences(data);
                })
                .catch((err) => {
                    setConferences([]);
                    dispatch(notifyError(`${err.name}:  ${err.message}`));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setConferences([]);
        }

        setDivisions([]);
    }, [form.league_id]);

    useEffect(() => {
        fetchConferences();
    }, [fetchConferences]);

    const fetchDivisions = useCallback(() => {
        const conferenceId = form.conference_id || undefined;
        if (conferenceId !== undefined) {
            setLoading(true);
            let route = getRoute('divisions', { conference_id: conferenceId });
            services
                .fetchAllData(route)
                .then((data) => {
                    setDivisions(data);
                })
                .catch((err) => {
                    dispatch(notifyError(`${err.name}:  ${err.message}`));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setDivisions([]);
        }
    }, [form.conference_id]);

    useEffect(() => {
        fetchDivisions();
    }, [fetchDivisions]);

    const nextPage = `/competitors/${id}/appearance`;

    const localization = useSelector(strings);

    return (
        <div className={classes.root}>
            <Container maxWidth='lg'>
                <Loader isInProgress={isInProgress || loading} />
                <form noValidate autoComplete='off' onSubmit={onFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Input
                                label={`${localization.competitor_name}:`}
                                name='name'
                                value={override_name || name || form.name || ''}
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={competitor && competitor.name}
                                handleToggleChange={handleToggleChange}
                                error={error.name}
                                showCopy={true}
                                required={requiredFields.includes('name')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Textarea
                                label={`${localization.description}:`}
                                name='description'
                                defaultValue={
                                    override_description ||
                                    description ||
                                    form.description ||
                                    ''
                                }
                                originalValue={
                                    competitor && competitor.description
                                }
                                handleChange={handleChange}
                                required={requiredFields.includes(
                                    'description'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.abbreviation}:`}
                                name='abbreviation'
                                value={
                                    override_abbreviation ||
                                    abbreviation ||
                                    form.abbreviation ||
                                    ''
                                }
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    competitor && competitor.abbreviation
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes(
                                    'abbreviation'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.forms_alias}:`}
                                name='alias'
                                value={
                                    override_alias || alias || form.alias || ''
                                }
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={competitor && competitor.alias}
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('alias')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.forms_short_name}:`}
                                name='short_name'
                                value={
                                    override_short_name ||
                                    short_name ||
                                    form.short_name ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    competitor && competitor.short_name
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('short_name')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.forms_vocal_name}:`}
                                name='vocal_name'
                                value={
                                    override_vocal_name ||
                                    vocal_name ||
                                    form.vocal_name ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    competitor && competitor.vocal_name
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('vocal_name')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.forms_market}:`}
                                name='market'
                                value={
                                    override_market ||
                                    market ||
                                    form.market ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={competitor && competitor.market}
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('market')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.forms_vocal_market}:`}
                                name='vocal_market'
                                value={
                                    override_vocal_market ||
                                    vocal_market ||
                                    form.vocal_market ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    competitor && competitor.vocal_market
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes(
                                    'vocal_market'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.forms_mascot}:`}
                                name='mascot_name'
                                value={
                                    override_mascot_name ||
                                    mascot_name ||
                                    form.mascot_name ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    competitor && competitor.mascot_name
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes(
                                    'mascot_name'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <AutocompleteBox
                                label={`${localization.forms_sport}:`}
                                options={sports}
                                name='sport_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={form.sport_id || null}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    (competitor && competitor.sport_id) || null
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.sport_id}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes('sport_id')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <AutocompleteBox
                                label={`${localization.league}:`}
                                options={leagues}
                                name='league_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={form.league_id || null}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    (competitor && competitor.league_id) || null
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.league_id}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes('league_id')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <AutocompleteBox
                                label={`${localization.conference}:`}
                                options={conferences}
                                name='conference_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={form.conference_id || null}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    (competitor && competitor.conference_id) ||
                                    null
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.conference_id}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes(
                                    'conference_id'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <AutocompleteBox
                                label={`${localization.division}:`}
                                options={divisions}
                                name='division_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={form.division_id || null}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    (competitor && competitor.division_id) ||
                                    null
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.division_id}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes(
                                    'division_id'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <LiveAutocomplete
                                label={`${localization.venue}:`}
                                id='venue_id'
                                name='venue_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={
                                    override_venue_id ||
                                    venue_id ||
                                    form.venue_id ||
                                    null
                                }
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    override_venue_id || venue_id || null
                                }
                                handleToggleChange={handleToggleChange}
                                localization={localization}
                                serviceName='venues'
                                required={requiredFields.includes('venue_id')}
                            />
                        </Grid>
                        {currentTenant.isBR && (
                            <Grid item md={6} xs={12}>
                                <MultiSelectBox
                                    label={`${localization.competitor_in_territory}:`}
                                    options={countries}
                                    name='territories_available'
                                    handleMultipleSelectChange={
                                        handleMultipleSelectChange
                                    }
                                    defaultValue={
                                        override_territories_available ||
                                        territories_available ||
                                        form.territories_available ||
                                        []
                                    }
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={
                                        competitor &&
                                        competitor.territories_available
                                    }
                                    handleToggleChange={handleToggleChange}
                                    localizedPlaceholder={localization.choose}
                                    required={requiredFields.includes(
                                        'territories_available'
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.synonyms}:`}
                                name='synonyms'
                                value={
                                    override_synonyms ||
                                    synonyms ||
                                    form.synonyms ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    competitor && competitor.synonyms
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('synonyms')}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item md={6} xs={12}>
                                <CheckBox
                                    label={`${localization.hidden}`}
                                    name='hidden'
                                    checked={
                                        override_hidden ||
                                        hidden ||
                                        form.hidden ||
                                        false
                                    }
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <CheckBox
                                    label={`${localization.has_media}`}
                                    name='has_media'
                                    checked={
                                        override_has_media ||
                                        has_media ||
                                        form.has_media ||
                                        false
                                    }
                                    handleChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                justifyContent='flex-end'
                                spacing={3}
                            >
                                <div className={classes.buttonright}>
                                    <Buttons
                                        type='submit'
                                        label={localization.save}
                                        showForWriteAccessUsers
                                    />
                                    <IconButton
                                        aria-label='next-page'
                                        style={{ color: 'black' }}
                                        onClick={(e) =>
                                            handleForward(e, nextPage)
                                        }
                                    >
                                        <RemoveOutlined
                                            fontSize='large'
                                            style={{ marginRight: '-16px' }}
                                        />
                                        <ArrowForwardOutlined fontSize='large' />
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};
