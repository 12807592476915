var moment = require('moment');
var momentTz = require('moment-timezone');

export const date = {
    estTimezone: 'America/New_York',
    gmtTimezone: 'GMT',
    // Replaces current timezone with ET, returns utc iso string
    estToIso: function (date) {
        const est = momentTz(date).format('YYYY-MM-DD HH:mm:ss');
        const gmt = momentTz.tz(est, this.estTimezone);

        return gmt.toISOString();
    },

    isoToEst: function (iso) {
        const date = new Date(iso);
        if (!this.isValidDate(date)) return;
        else return this.toEst(date);
    },

    toEst: function (date) {
        let result;

        if (!date || date === '') {
            return '';
        }

        try {
            /*
             * 1. Create utc date
             * 2. Update to ET
             * 3. Remove timezone, so date doesn't adjust to local
             */
            const est = momentTz
                .utc(date)
                .tz(this.estTimezone)
                .format('YYYY-MM-DD HH:mm:ss');

            // Create local date, where time is reflective of ET
            const local = momentTz(est);

            result = local.toDate();
        } catch (error) {
            result = '';
        }
        return result;
    },

    /**
     * Given an iso sring, converts it to a new timezone
     * @param {string} iso iso string
     * @param {boolean} keepZone determines whether to show new zone
     * @returns {string} date converted to iso string
     */
    toTimeZoneIso: function (iso, timezone, keepZone = false) {
        const date = momentTz.utc(iso).tz(timezone);

        if (!keepZone) {
            return `${date.format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
        }

        return date.toISOString(true);
    },

    format: function (date, format = 'YYYY/MM/DD hh:mmA') {
        return momentTz(date).format(format);
    },

    compareDates: function (dateOne, dateTwo, compareType) {
        if (compareType === 'before') {
            return moment(dateOne).isBefore(dateTwo);
        } else if (compareType === 'after') {
            return moment(dateOne).isAfter(dateTwo);
        } else if (compareType === 'equal' || compareType === 'same') {
            return moment(dateOne).isSame(dateTwo);
        }
    },

    isValidDate: (d) => {
        const parsedDate = Date.parse(d);
        return isNaN(parsedDate) ? false : true;
    },

    isValidateTime: (time) => {
        if (!time || time.toString() === 'Invalid Date') {
            return false;
        }

        return true;
    },

    defaultStartDateTime: () => {
        const today = new Date();

        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);

        return yesterday.toISOString();
    }
};
