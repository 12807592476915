/* eslint-disable no-use-before-define */
import React, { useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { ToggleButton } from './ToggleButton';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiInputBase-sizeSmall': {
            paddingTop: '7.5px !important',
            paddingLeft: '8px !important',
            paddingBottom: '7.5px !important'
        },
        marginBottom: '10px'
    },
    labelStyle: {
        fontWeight: 600,
        fontSize: 'large',
        color: 'black'
    },
    error: {
        borderRadius: 4,
        border: '1px solid red',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    }
}));

// TODO: Fix renderOption issue in which renderOption worked in @material-ui/lab/Autocomplete,
// but no longer works in @mui/material/Autocomplete
export const AutocompleteBox = (props) => {
    const classes = useStyles(props);

    const {
        options,
        label,
        name,
        nameField = 'label',
        handleChange,
        defaultValue,
        showToggle,
        enableToggle,
        originalValue,
        handleToggleChange,
        error,
        localizedPlaceholder,
        required
    } = props;

    const value = useMemo(() => {
        return (
            (defaultValue != null &&
                options.find((v) => v.id === defaultValue)) ||
            null
        );
    }, [defaultValue, options]);

    const placeholder = useMemo(() => {
        if (originalValue === null) return localizedPlaceholder;

        if (options.length === 0) return localizedPlaceholder;

        return originalValue && nameField
            ? originalValue[nameField]
            : localizedPlaceholder;
    }, [options, originalValue]);

    const sortedOptions = useMemo(() => {
        return options.sort((a, b) => {
            a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1;
        });
    }, [options]);

    return (
        <>
            <Grid
                container
                justifyContent='space-between'
                alignItems='flex-end'
            >
                {showToggle && (
                    <>
                        <Grid item xs={9}>
                            <InputLabel
                                className={classes.labelStyle}
                                shrink
                                htmlFor={name}
                            >
                                {label}{' '}
                                {required && (
                                    <span className='text-danger'>*</span>
                                )}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={3} style={{ flexBasis: '5%' }}>
                            <ToggleButton
                                showToggle={
                                    showToggle &&
                                    (defaultValue != null ||
                                        defaultValue !== undefined) &&
                                    !_.isEqual(defaultValue, originalValue)
                                }
                                name={name}
                                originalValue={originalValue}
                                enableToggle={enableToggle}
                                handleToggleChange={handleToggleChange}
                            />
                        </Grid>
                    </>
                )}
                {!showToggle && (
                    <>
                        <Grid item xs={12}>
                            <InputLabel
                                className={classes.labelStyle}
                                shrink
                                htmlFor={name}
                            >
                                {label}{' '}
                                {required && (
                                    <span className='text-danger'>*</span>
                                )}
                            </InputLabel>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    className={classes.root}
                    name={name}
                    id={name}
                    classes={classes}
                    size='small'
                    limitTags={2}
                    onChange={(e, newValue) =>
                        handleChange(
                            e,
                            newValue ? newValue.id : undefined,
                            name
                        )
                    }
                    options={sortedOptions}
                    getOptionLabel={(option) =>
                        option && nameField ? option[nameField] : ''
                    }
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='outlined'
                            placeholder={placeholder}
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={`${name}-${option.id}`}>
                                {option[nameField]}
                            </li>
                        );
                    }}
                    value={value}
                    {...(!!error && { className: classes.error })}
                />
            </Grid>
        </>
    );
};
