import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import DataList from '../datalist';
import services from '../../services/apiService';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import { Confirm } from '../shared/Confirm';
import { date as dateUtil } from '../../utils/date';
import { Edit, Delete } from '@mui/icons-material';
import { eventsProcessingExportFiles } from './eventsProcessingExportFiles';
import { exportFile } from '../../utils/export';
import { getRoute } from '../shared/AdvancedSearch';
import { hasUserEditPermission } from '../../reducer/userReducer';
import { Link } from 'react-router-dom';
import { useReset } from '../hooks/UseReset';
import { useSelector } from 'react-redux';
import { strings } from '../../reducer/localizationSlice';

const AdvancedSearch = lazy(() => import('../shared/AdvancedSearch'));

const Events = () => {
    const [asOpen, setAsOpen] = useState(false);
    const [content, setContent] = useState('');
    const [newSearchParms, setNewSearchParms] = useState({});
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [path, setPath] = useState('');
    const [searchParms, setSearchParms] = useState({});
    const [tableHeaders, setTableHeaders] = useState([]);

    const createNew = `/events/new/event-info`;
    const dataListRef = useRef();
    const userCanEdit = useSelector(hasUserEditPermission);

    const eventObject = (event) => {
        return {
            id: event.id,
            legacy_id: event.legacy_id,
            name: event.name,
            scheduled_utc: event.scheduled_utc,
            scheduled_utc_time: event.scheduled_utc,
            is_live: event.is_live,
            free_preview: event.free_preview,
            free_event: event.free_event,
            hidden: event.hidden,
            has_media: event.has_media,
            season: event.season,
            division_id: event.division_id,
            created_at: event.created_at,
            updated_at: event.updated_at,
            home_competitor_id: event.home_competitor_id,
            away_competitor_id: event.away_competitor_id,
            actions: '/events/' + event.id + '/event-info'
        };
    };

    const { searchParams, newSearchParams, openStatus } = useReset(
        searchParms,
        newSearchParms,
        asOpen
    );

    useEffect(() => {
        setSearchParms(searchParams);
        setNewSearchParms(newSearchParams);
        setAsOpen(openStatus);
    }, [searchParams, newSearchParams, openStatus]);

    const handleAsSearch = () => {
        setAsOpen(true);
    };

    const handleExport = (fileType) => {
        const route = getRoute('events', searchParms);
        return exportFile(
            fileType,
            services,
            route,
            setOpen,
            eventsProcessingExportFiles
        );
    };

    const handleDelete = (id, title) => {
        setContent(`Are you sure you want to delete '${title}'?`);
        setOpenConfirm(true);
        setPath(`events/${id}`);
    };

    const handleClose = (success) => {
        setOpenConfirm(false);
        if (success) dataListRef.current.reload();
    };

    const handleSearch = (params) => {
        setSearchParms(params);
        setAsOpen(false);
    };

    const handleAsClose = () => {
        setAsOpen(false);
    };

    useEffect(() => {
        setTableHeaders([
            {
                field: 'id',
                headerName: localization.headers_id,
                width: 230,
                headerAlign: 'center',
                cellClassName: `super-app-theme-id-cell`,
                renderCell: (params) => (
                    <Typography
                        component={Link}
                        to={`/events/${params.value}/event-info`}
                        variant='subtitle2'
                        gutterBottom
                    >
                        {params.value}
                    </Typography>
                )
            },
            {
                field: 'legacy_id',
                headerName: localization.headers_legacy_id,
                width: 130,
                headerAlign: 'center'
            },
            {
                field: 'name',
                headerName: localization.headers_name,
                width: 230,
                headerAlign: 'center'
            },
            {
                field: 'scheduled_utc',
                headerName: localization.headers_date,
                width: 130,
                headerAlign: 'center',
                valueFormatter: (params) =>
                    params.value
                        ? dateUtil.format(
                              dateUtil.toEst(params.value),
                              'YYYY/MM/DD'
                          )
                        : ''
            },
            {
                field: 'scheduled_utc_time',
                headerName: localization.headers_time,
                width: 130,
                headerAlign: 'center',
                valueFormatter: (params) =>
                    params.value
                        ? dateUtil.format(
                              dateUtil.toEst(params.value),
                              'hh:mm A'
                          )
                        : ''
            },
            {
                field: 'is_live',
                headerName: localization.headers_live,
                width: 130,
                headerAlign: 'center',
                valueFormatter: (params) =>
                    params.value ? localization.yes : localization.no
            },
            {
                field: 'hidden',
                headerName: localization.headers_visibility,
                width: 130,
                headerAlign: 'center',
                valueFormatter: (params) =>
                    params.value ? localization.no : localization.yes
            },
            {
                field: 'has_media',
                headerName: localization.headers_media,
                width: 130,
                headerAlign: 'center',
                valueFormatter: (params) =>
                    params.value ? localization.yes : localization.no
            },
            {
                field: 'created_at',
                headerName: localization.headers_created,
                width: 180,
                headerAlign: 'center',
                valueFormatter: (params) =>
                    params.value
                        ? dateUtil.format(
                              dateUtil.toEst(params.value),
                              'YYYY/MM/DD hh:mm A'
                          )
                        : ''
            },
            {
                field: 'updated_at',
                headerName: localization.headers_updated,
                width: 180,
                headerAlign: 'center',
                valueFormatter: (params) =>
                    params.value
                        ? dateUtil.format(
                              dateUtil.toEst(params.value),
                              'YYYY/MM/DD hh:mm A'
                          )
                        : ''
            },
            {
                field: 'actions',
                headerName: localization.headers_actions,
                width: 130,
                headerAlign: 'center',
                renderCell: (params) => {
                    let DeleteButton = '';
                    if (userCanEdit) {
                        DeleteButton = (
                            <strong>
                                <IconButton
                                    aria-label='delete'
                                    onClick={() =>
                                        handleDelete(
                                            params.id,
                                            params?.row?.name
                                        )
                                    }
                                >
                                    <Delete />
                                </IconButton>
                            </strong>
                        );
                    }
                    return (
                        <div>
                            <strong>
                                <IconButton
                                    component={Link}
                                    to={params.value}
                                    aria-label='edit'
                                    style={{ marginLeft: 16 }}
                                >
                                    <Edit />
                                </IconButton>
                            </strong>

                            {DeleteButton}
                        </div>
                    );
                }
            }
        ]);
    }, [searchParms, services.getTenant(), userCanEdit]);

    const defaultFilter = ``;

    const localization = useSelector(strings);

    return (
        <div>
            <Confirm
                path={path}
                open={openConfirm}
                onClose={handleClose}
                onYes={handleClose}
                title='Confirmation'
                content={content}
                isDelete={true}
            />
            <DataList
                ref={dataListRef}
                tableHeaders={tableHeaders}
                onSearch={handleAsSearch}
                searchParms={searchParms}
                handleExport={handleExport}
                open={open}
                endPoint='events'
                dataObject={eventObject}
                createNew={createNew}
                defaultFilter={defaultFilter}
            />
            <Suspense fallback={<CircularProgress color='inherit' />}>
                <AdvancedSearch
                    handleSearch={handleSearch}
                    handleClose={handleAsClose}
                    open={asOpen}
                    name
                    sport
                    league
                    tournament
                    series
                    division
                    conference
                    hasMedia
                    isLive
                    freePreview
                    freeEvent
                    editorialOverride
                    homeTeam
                    awayTeam
                    seasonMin
                    seasonMax
                    startTime
                    endTime
                />
            </Suspense>
        </div>
    );
};

export default Events;
