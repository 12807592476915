import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: 'black'
    }
}));

export const Loader = (props) => {
    const classes = useStyles();

    return (
        <div>
            <Backdrop
                className={classes.backdrop}
                open={props.isInProgress}
                data-testid='circle-loading'
            >
                <CircularProgress color='inherit' />
            </Backdrop>
        </div>
    );
};
