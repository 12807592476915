import React from 'react';
import { Route, Redirect } from "react-router-dom";

const GuardedRoute = ({ component: Component, auth, ...rest }) => (
    <Route {...rest} render={(props) => {
        rest.auth = auth
        if (rest && rest.tenants && rest.tenants !== "") {
            const tenant = localStorage.getItem("tenant");
            const allowedTenants = rest.tenants.split(",");
            if (!allowedTenants.includes(tenant)) {
                return <Redirect to='/' />
            }
        }
        return( auth === true
                ? <Component {...props} {...rest} />
                : <Redirect to='/' />
        )}} />
)

export default GuardedRoute;
