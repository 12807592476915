import { createSlice } from '@reduxjs/toolkit';

export const ENCODER_BUTTON_ACTIONS = Object.freeze({
    SAVE: 'save',
    START: 'start',
    STOP: 'stop'
});

export const ENCODER_BUTTON_TEXT = Object.freeze({
    INITIALIZING: 'encoder_initializing',
    RETRY: 'encoder_retry',
    SAVE: 'encoder_save_config',
    SAVING: 'encoder_saving',
    START: 'encoder_start',
    STARTING: 'encoder_starting',
    STOP: 'encoder_stop',
    STOPPING: 'encoder_stopping',
    UNAVAILABLE: 'encoder_unavailable'
});

export const ENCODER_STATES = Object.freeze({
    CREATED: 'CREATED',
    DEGRADED: 'DEGRADED',
    FAILED: 'FAILED',
    INITIALIZING: 'INITIALIZING',
    LOADING: '[LOADING]',
    PROVISIONING: 'PROVISIONING',
    RUNNING: 'RUNNING',
    STARTING: 'STARTING',
    STOPPED: 'STOPPED',
    STOPPING: 'STOPPING',
    UNAVAILABLE: 'UNAVAILABLE',
    WARNING: 'WARNING'
});

export const INITIAL_STATE = Object.freeze({
    athenaId: null,
    buttonAction: null,
    buttonDisabled: true,
    buttonText: ENCODER_BUTTON_TEXT.INITIALIZING,
    cdnConfig: null,
    createArchive: false,
    cdnConfigDisabled: true,
    dvrWindow: null,
    encoderBusy: false,
    encoderStatus: ENCODER_STATES.LOADING,
    source: null,
    urls_asset_id_live: null,
    userMod: null
});

export const getButtonStatus = (encoderStatus) => {
    let buttonAction = null;
    let buttonDisabled = true;
    let buttonText = ENCODER_BUTTON_TEXT.INITIALIZING;
    let cdnConfigDisabled = true;
    let encoderBusy = false;

    switch (encoderStatus) {
        case ENCODER_STATES.DEGRADED:
        case ENCODER_STATES.RUNNING:
        case ENCODER_STATES.WARNING:
            buttonDisabled = false;
            encoderBusy = true;
            buttonText = ENCODER_BUTTON_TEXT.STOP;
            buttonAction = ENCODER_BUTTON_ACTIONS.STOP;
            cdnConfigDisabled = true;
            break;
        case ENCODER_STATES.FAILED:
            buttonDisabled = false;
            buttonText = ENCODER_BUTTON_TEXT.RETRY;
            buttonAction = ENCODER_BUTTON_ACTIONS.START;
            cdnConfigDisabled = false;
            break;
        case ENCODER_STATES.INITIALIZING:
        case ENCODER_STATES.PROVISIONING:
        case ENCODER_STATES.STARTING:
            buttonDisabled = true;
            buttonText = ENCODER_BUTTON_TEXT.STARTING;
            buttonAction = ENCODER_BUTTON_ACTIONS.STOP;
            cdnConfigDisabled = true;
            encoderBusy = true;
            break;
        case ENCODER_STATES.SAVE_CONFIG:
            buttonAction = ENCODER_BUTTON_ACTIONS.SAVE;
            buttonDisabled = false;
            buttonText = ENCODER_BUTTON_TEXT.SAVE_CONFIG;
            cdnConfigDisabled = false;
            break;
        case ENCODER_STATES.CREATED:
        case ENCODER_STATES.STOPPED:
            buttonDisabled = false;
            buttonText = ENCODER_BUTTON_TEXT.START;
            buttonAction = ENCODER_BUTTON_ACTIONS.START;
            cdnConfigDisabled = false;
            break;
        case ENCODER_STATES.STOPPING:
            buttonDisabled = true;
            buttonText = ENCODER_BUTTON_TEXT.STOPPING;
            cdnConfigDisabled = true;
            encoderBusy = true;
            break;
        case ENCODER_STATES.UNAVAILABLE: // This means the encoder is unavailable, possible because athena id is invalid.
            buttonDisabled = true;
            buttonText = ENCODER_BUTTON_TEXT.UNAVAILABLE;
            break;
        default:
            console.warn(`Unknown encoder status: ${encoderStatus}`);
    }
    return {
        buttonAction,
        buttonDisabled,
        buttonText,
        cdnConfigDisabled,
        encoderBusy
    };
};

export const encoderStatusReducer = createSlice({
    name: 'encoderStatus',
    initialState: INITIAL_STATE,
    reducers: {
        enableConfigSave: (state, action) => {
            const { cdnConfig, createArchive, dvrWindow, source } =
                action.payload;
            state.buttonAction = ENCODER_BUTTON_ACTIONS.SAVE;
            state.buttonDisabled = false;
            state.buttonText = ENCODER_BUTTON_TEXT.SAVE;
            state.cdnConfig = cdnConfig;
            state.createArchive = createArchive;
            state.cdnConfigDisabled = false;
            state.dvrWindow = dvrWindow;
            state.source = source;
        },
        encoderStop: (state, action) => {
            state.buttonText = ENCODER_BUTTON_TEXT.STOPPING;
            state.buttonDisabled = true;
            state.cdnConfigDisabled = true;
        },
        encoderStart: (state, action) => {
            state.buttonText = ENCODER_BUTTON_TEXT.STARTING;
            state.buttonDisabled = true;
            state.cdnConfigDisabled = true;
        },
        resetEncoderState: (state, payload) => {
            Object.keys(INITIAL_STATE).forEach((key, index) => {
                state[key] = INITIAL_STATE[key];
            });
        },
        resetOnError: (state, payload) => {
            state.buttonDisabled = false;
            state.buttonText = ENCODER_BUTTON_TEXT.RETRY;
            state.cdnConfigDisabled = false;
            state.encoderBusy = false;
            state.encoderStatus = ENCODER_STATES.FAILED;
        },
        saveComplete: (state) => {
            const {
                buttonAction,
                buttonDisabled,
                buttonText,
                cdnConfigDisabled,
                encoderBusy
            } = getButtonStatus(state.encoderStatus);
            state.buttonAction = buttonAction;
            state.buttonDisabled = buttonDisabled;
            state.buttonText = buttonText;
            state.cdnConfigDisabled = cdnConfigDisabled;
            state.encoderBusy = encoderBusy;
        },
        savingCdnConfig: (state) => {
            state.buttonDisabled = true;
            state.buttonText = ENCODER_BUTTON_TEXT.SAVING;
            state.cdnConfigDisabled = true;
        },
        setAthenaId: (state, action) => {
            state.athenaId = action.payload;
        },
        setEncoderConfig: (state, action) => {
            const {
                cdnConfig,
                createArchive,
                dvrWindow,
                source,
                urls_asset_id_live
            } = action.payload;
            state.cdnConfig = cdnConfig;
            state.createArchive = createArchive;
            state.dvrWindow = dvrWindow;
            state.source = source;
            state.urls_asset_id_live = urls_asset_id_live;
        },
        setEncoderUnavailable: (state) => {
            state.buttonDisabled = true;
            state.cdnConfigDisabled = true;
            state.buttonText = ENCODER_BUTTON_TEXT.UNAVAILABLE;
            state.encoderStatus = ENCODER_STATES.UNAVAILABLE;
        },
        setEncoderProps: (state, action) => {
            Object.keys(action.payload).forEach((key, index) => {
                state[key] = action.payload[key];
            });
        },
        updateAsset: (state, action) => {
            const {
                athenaId,
                encoderStatus,
                cdnConfig,
                createArchive,
                dvrWindow,
                source,
                urls_asset_id_live
            } = action.payload;

            const {
                buttonAction,
                buttonDisabled,
                buttonText,
                cdnConfigDisabled,
                encoderBusy
            } = getButtonStatus(encoderStatus);

            state.athenaId = athenaId;
            state.buttonAction = buttonAction;
            state.buttonDisabled = buttonDisabled;
            state.buttonText = buttonText;
            state.cdnConfig = cdnConfig;
            state.createArchive = createArchive;
            state.dvrWindow = dvrWindow;
            state.cdnConfigDisabled = cdnConfigDisabled;
            state.dvrWindow = dvrWindow;
            state.encoderBusy = encoderBusy;
            state.encoderStatus = encoderStatus;
            state.source = source;
            state.urls_asset_id_live = urls_asset_id_live;
        },
        updateEncoderStatus: (state, action) => {
            const newState = action.payload;

            const {
                buttonAction,
                buttonDisabled,
                buttonText,
                cdnConfigDisabled,
                encoderBusy
            } = getButtonStatus(newState);

            state.buttonAction = buttonAction;
            state.buttonDisabled = buttonDisabled;
            state.buttonText = buttonText;
            state.cdnConfigDisabled = cdnConfigDisabled;
            state.encoderBusy = encoderBusy;
            state.encoderStatus = newState;
        }
    }
});

export const {
    enableConfigSave,
    encoderStop,
    encoderStart,
    dvrWindow,
    resetEncoderState,
    resetOnError,
    saveComplete,
    savingCdnConfig,
    setAthenaId,
    setEncoderConfig,
    setEncoderUnavailable,
    setEncoderProps,
    updateAsset,
    updateEncoderStatus
} = encoderStatusReducer.actions;
export const getAthenaState = (state) => state.encoderStatus.encoderStatus;
export const getButtonText = (state) => state.encoderStatus.buttonText;
export const getCdnButtonDisabled = (state) =>
    state.encoderStatus.buttonDisabled;
export const getCdnConfig = (state) => state.encoderStatus.cdnConfig;
export const getCreateArchive = (state) => state.encoderStatus.createArchive;
export const getDisableCdnConfig = (state) =>
    state.encoderStatus.cdnConfigDisabled;
export const getDvrWindow = (state) => state.encoderStatus.dvrWindow;
export const getEncoderBusy = (state) => state.encoderStatus.encoderBusy;
export const getSource = (state) => state.encoderStatus.source;
export default encoderStatusReducer.reducer;
