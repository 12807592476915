import React from 'react';
import { Grid } from '@mui/material';
import { MultiSelectBox } from '../../../shared/MultiSelectBox';

const { COUNTRIES_WORLDWIDE } = require('../../../../data/countriesList');

export const agnosticSort = (array, property) => {
    if(!array || !Array.isArray(array)){
        return [];
    }
    const collator = new Intl.Collator(); // <-- the trick to sorting things like Åland Islands easily.    
    return property
        ? array.sort((a, b) => collator.compare(a[property], b[property]))
        : array.sort((a, b) => collator.compare(a, b));
};

export const COUNTRY_OPTIONS = (() => {
    return agnosticSort(
        COUNTRIES_WORLDWIDE.map((obj) => {
            return {
                id: obj.isoCode,
                label: `${obj.country}`
            };
        }),
        'label'
    );
})();

const CountriesAvailable = (props) => {
    const {
        name,
        handleMultipleSelectChange,
        localization,
        originalValue,
        selectTitle,
        value
    } = props;

    return (
        <Grid item container xs={12}>
            <Grid item xs={6}>
                <MultiSelectBox
                    defaultValue={value}
                    enableToggle={false}
                    handleMultipleSelectChange={handleMultipleSelectChange}
                    id={'AllowedCountries'}
                    label={`${selectTitle}:`}
                    localization={localization}
                    localizedPlaceholder={
                        originalValue && originalValue.length
                            ? ''
                            : localization.choose
                    }
                    name={name}
                    options={COUNTRY_OPTIONS}
                    originalValue={originalValue}
                    required={false}
                    showToggle={false}
                />
            </Grid>
        </Grid>
    );
};

export default CountriesAvailable;
