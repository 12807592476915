import React, { lazy, Suspense } from 'react';
import Menu from './menu';
import { CircularProgress, Grid } from '@mui/material';
import { CreateEditCompetitors } from './competitors/competitors-details/CreateEditCompetitors';
import { CreateEditConferences } from './conferences/conferences-details/CreateEditConferences';
import { CreateEditDivisions } from './divisions/divisions-details/CreateEditDivisions';
import { CreateEditEvent } from './events/event-details/CreateEditEvent';
import { CreateEditLeague } from './leagues/league-details/CreateEditLeague';
import { CreateEditSport } from './sports/sport-details/CreateEditSport';
import { CreateEditVenues } from './venues/venues-details/CreateEditVenues';
import { CreateEditSeries } from './series/series-details/CreateEditSeries';
import { CreateEditTournaments } from './tournaments/tournaments-details/CreateEditTournaments';
import { makeStyles } from '@mui/styles';
import { Redirect } from 'react-router-dom';

const Competitors = lazy(() => import('./competitors/competitors'));
const Conferences = lazy(() => import('./conferences/conferences'));
const Divisions = lazy(() => import('./divisions/divisions'));
// const Events = lazy(() => import('./events/events'));
import Events from './events/events';
const Leagues = lazy(() => import('./leagues/leagues'));
const Series = lazy(() => import('./series/series'));
const Sports = lazy(() => import('./sports/sports'));
const Tournaments = lazy(() => import('./tournaments/tournaments'));
const Venues = lazy(() => import('./venues/venues'));
const CalendarPage = lazy(() => import('./calendar/CalendarPage'));

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

function Results(props) {
    switch (props.resultType) {
        case 'events':
            return <Events />;
        case 'competitors':
            return (
                <Suspense fallback={<CircularProgress />}>
                    <Competitors />
                </Suspense>
            );
        /*
        case 'conferences':
            return (
                <Suspense fallback={<CircularProgress />}>
                    <Conferences />
                </Suspense>
            );
        case 'divisions':
            return (
                <Suspense fallback={<CircularProgress />}>
                    <Divisions />
                </Suspense>
            );
        */
        case 'leagues':
            return (
                <Suspense fallback={<CircularProgress />}>
                    <Leagues />
                </Suspense>
            );
        case 'series':
            return (
                <Suspense fallback={<CircularProgress />}>
                    <Series />
                </Suspense>
            );
        case 'sports':
            return (
                <Suspense fallback={<CircularProgress />}>
                    <Sports />
                </Suspense>
            );
        case 'tournaments':
            return (
                <Suspense fallback={<CircularProgress />}>
                    <Tournaments />
                </Suspense>
            );
        case 'venues':
            return (
                <Suspense fallback={<CircularProgress />}>
                    <Venues />
                </Suspense>
            );
        case 'tournament-info':
        case 'tournament-appearance':
            return <CreateEditTournaments config={props.config} />;
        case 'venue-info':
        case 'venue-appearance':
            return <CreateEditVenues config={props.config} />;
        case 'sport-info':
        case 'sport-appearance':
            return <CreateEditSport config={props.config} />;
        case 'league-info':
        case 'league-appearance':
        case 'league-record-rank-management':
            return <CreateEditLeague config={props.config} />;
        case 'competitor-info':
        case 'competitor-appearance':
        case 'competitor-record-rank-management':
            return <CreateEditCompetitors config={props.config} />;
        case 'conference-info':
            return <CreateEditConferences />;
        case 'division-info':
            return <CreateEditDivisions />;
        case 'series-info':
            return <CreateEditSeries />;
        case 'event-info':
        case 'event-appearance':
        case 'live-admin':
            return <CreateEditEvent config={props.config} />;
        case 'calendar':
            return (
                <Suspense fallback={<CircularProgress />}>
                    <CalendarPage config={props.config} />
                </Suspense>
            );
        default:
            return (
                <Suspense fallback={<CircularProgress />}>
                    <Events />
                </Suspense>
            );
    }
}

function FromMainMenu(props) {
    return (
        <div>
            <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
            >
                <Grid item xs={12} sm={2}>
                    <Menu />
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Results
                        resultType={props.resultType}
                        config={props.config}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default function Home(props) {
    if (props.auth && ['/', '/login'].includes(props.location.pathname))
        return <Redirect to='/events' />;

    return (
        <FromMainMenu
            resultType={props.resultType}
            config={props.config}
            auth={props.auth}
        />
    );
}
