import React from 'react';
import { SelectBox } from '../../shared/SelectBox';

export const CustomFields = (props) => {
    const { customFields, defaultValue, handleChange, localization } = props;
    const isEmptyObject = (obj) => {
        return obj && Object.keys(obj).length === 0;
    };

    if (!isEmptyObject(customFields)) {
        return (
            <SelectBox
                label={customFields.label}
                options={customFields.choices.map((element) => {
                    return { id: element.value, name: element.label };
                })}
                defaultValue={defaultValue}
                handleChange={handleChange}
                name='custom-fields'
                localization={localization}
            />
        );
    }
    return '';
};
