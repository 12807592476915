import { createSlice } from '@reduxjs/toolkit';
import {
    allLanguages,
    fullLanguageArrayFromIsoCodes,
    orderLanguages
} from './includes/multilanguage';
export const INITIAL_STATE = Object.freeze({
    allLanguages,
    currentIndex: 0,
    currentLanguage: null,
    eventId: null,
    isMultilanguage: false,
    languages: [],
    showEdit: false
});

export const multilanguageSlice = createSlice({
    name: 'multilanguage',
    initialState: INITIAL_STATE,
    reducers: {
        reset: (state, action) => {
            return {
                ...state,
                ...INITIAL_STATE
            };
        },
        setIndex: (state, action) => {
            let index = action.payload;

            if (index >= state.languages.length) {
                index = 0;
            } else if (index < 0) {
                index = state.languages.length - 1;
            }

            return {
                ...state,
                currentIndex: index,
                currentLanguage: state.languages[index]
            };
        },
        setLanguage: (state, action) => {
            const currentIndex = state.languages.findIndex(
                (lang) => lang.isoCode === action.payload
            );
            return {
                ...state,
                currentIndex,
                currentLanguage: state.languages[currentIndex]
            };
        },
        setEventData: (state, action) => {

            const event = action?.payload || {};

            if(Object.keys(event).length === 0){
                return {
                    ...state,
                    currentIndex: 0,
                    currentLanguage: null,
                    isMultilanguage: false,
                    languages: []
                };
            };
            
            const metadata = {
                ...(event?.metadata || {}),
                ...(event?.let_override?.metadata || {})
            }

            let currentIndex = 0;
            let currentLanguage = null;
            let eventId = event?.id;
            let languages = orderLanguages(
                fullLanguageArrayFromIsoCodes(metadata?.languages || [])
            );
            let isMultilanguage = languages.length > 1;
            if (isMultilanguage) {
                currentLanguage = languages[currentIndex] || null;
            }
            
            return {
                ...state,
                currentIndex,
                currentLanguage,
                eventId,
                isMultilanguage,
                languages
            };
        },
        setShowEdit: (state, action) => {
            return {
                ...state,
                showEdit: action.payload
            };
        }
    }
});

export const {
    reset,
    setEventData,
    setIndex,
    setLanguage,
    setShowEdit
} = multilanguageSlice.actions;

export const getLanguages = (state) => state.multilanguage.languages;
export const getCurrentIndex = (state) => state.multilanguage.currentIndex;
export const getCurrentLanguage = (state) =>
    state.multilanguage.currentLanguage;
export const getCurrentLanguageCode = (state) =>
    state.multilanguage?.currentLanguage?.isoCode;
export const getCurrentLanguageName = (state) =>
    state.multilanguage?.currentLanguage?.languageName;
export const getEventId = (state) => state.multilanguage.eventId;
export const getIsMultiLanguage = (state) =>
    state.multilanguage.isMultilanguage;
export const getShowEdit = (state) => state.multilanguage.showEdit;

export default multilanguageSlice.reducer;
