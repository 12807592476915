import React, { useState } from 'react';
import awsStorage from '../../services/awsStorage';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';
import { FormControl, Grid, InputLabel } from '@mui/material';
import { Input } from '../shared/Input';
import { isAdmin, isOperator } from '../../reducer/userReducer';
import { Loader } from './Loader';
import { makeStyles, withStyles } from '@mui/styles';
import {
    PanoramaOutlined,
    PlayCircleOutlineOutlined
} from '@mui/icons-material';
import { selectTenant } from '../../reducer/tenantSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { encodeFilename, utils } from '../../utils/utils';
// Notifications:
import { notifyError } from '../../reducer/notificationReducer';
import { Confirm } from './Confirm';
import { strings } from '../../reducer/localizationSlice';
import { getFileFilter } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            //margin: theme.spacing(1),
        },
        display: 'flex',
        flexDirection: 'row',
        '& .MuiButton-containedPrimary': {
            color: '#fff',
            backgroundColor: '#000'
        }
    },
    buttonWarning: {
        margin: '0 0 0 5px',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'darkred'
        }
    },
    dataContainer: {
        fontSize: '0.8rem',
        marginLeft: '-8px',
        padding: '10px'
    },
    input: {
        display: 'none'
    },
    upload: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 8px 0 0'
    },
    buttonLabel: {
        alignself: 'center',
        marginTop: 33
    },
    imgPreview: {
        display: 'flex',
        minHeight: '9.5rem',
        padding: '.75rem',
        position: 'relative',
        background: '#d8d8d8',
        color: '#c3c3c3',
        marginBottom: '2rem',
        marginLeft: 8,
        height: '9.5rem',
        width: '93%'
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        width: '100%'
    },
    icon: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)'
    },
    margin: {
        margin: theme.spacing(1),
        width: '100%'
    },
    labelStyle: {
        fontWeight: 600,
        fontSize: 'large',
        color: 'black',
        marginLeft: '-12px'
    }
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            boxShadow: `${alpha(
                theme.palette.primary.main,
                0.25
            )} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main
        }
    }
}))(InputBase);

export const UploadMaxPreview = (props) => {
    const isAdminUser = useSelector(isAdmin);
    const isOperatorUser = useSelector(isOperator);
    const tenant = useSelector(selectTenant);

    const dispatch = useDispatch();

    const {
        bucket,
        config,
        data,
        enableDelete,
        fileName,
        handleDeleteValues,
        handleFileChange,
        handleTextChange,
        hideBrowseButton,
        imageId,
        item,
        label,
        metadata,
        name,
        overrideUrlVal,
        required,
        type,
        allowedFormat
    } = props;

    const { aspectRatio, format, kind, notes, size } = data;

    const overrideUrlFieldName = `${name}_override`;
    const metaDataFieldName = `${name}_metadata`;
    const classes = useStyles();

    const [confirmContent, setConfirmContent] = useState(null);
    const [confirmTitle, setConfirmTitle] = useState('Confirmation');
    const [metaDataState, setMetaDataState] = useState({
        name: metaDataFieldName,
        val: metadata,
        updated: false
    });
    const [showConfirm, setShowConfirm] = useState(false);
    const [state, setState] = useState({
        [name]: { file: '', fileName: fileName }
    });
    const [tenantName, setTenantName] = useState();
    const [urlOverride, setUrlOverride] = useState({
        name: overrideUrlFieldName,
        val: overrideUrlVal,
        updated: false
    });

    const acceptFilter = getFileFilter(type, allowedFormat);

    useEffect(() => {
        setTenantName(tenant.tenantName);
    }, [tenant]);

    useEffect(() => {
        if (!urlOverride.updated) {
            setUrlOverride({
                val: overrideUrlVal,
                name: overrideUrlFieldName,
                updated: false
            });
        }
    }, [overrideUrlVal]);

    useEffect(() => {
        if (!metaDataState.updated) {
            setMetaDataState({
                name: metaDataFieldName,
                val: metadata,
                updated: false
            });
        }
    }, [metadata]);

    const handleConfirmNo = () => {
        setShowConfirm(false);
    };

    const handleConfirmYes = () => {
        try {
            setMetaDataState({
                ...metaDataState,
                val: '',
                updated: true
            });
            setUrlOverride({
                val: '',
                updated: true
            });
            setState({
                [name]: {
                    ...state[name],
                    file: '',
                    fileName: '',
                    updated: true
                }
            });
            handleDeleteValues([name, `${name}_metadata`, `${name}_override`]);
        } catch (e) {
            console.error(e);
        } finally {
            setShowConfirm(false);
        }
    };

    const handleShowConfirm = () => {
        setConfirmTitle(`Confirm Delete`);

        let file = 'NOT_FOUND';
        try {
            file = state[name].fileName.split('/').pop();
        } catch (e) {
            console.error(e);
        }

        setConfirmContent(
            `Are you sure you want to delete the image "${file}"? This cannot be un-done.`
        );
        setShowConfirm(true);
    };

    const handleOverrideUrlUpdate = (e) => {
        setUrlOverride({
            val: e.target.value,
            name: e.target.name,
            updated: true
        });
        handleTextChange(e);
    };

    const handleMetadataUpdate = (e) => {
        setMetaDataState({
            val: e.target.value,
            name: e.target.name,
            updated: true
        });
        handleTextChange(e);
    };

    let [isInProgress, setIsInProgress] = useState(false);

    useEffect(() => {
        fileName &&
            setState({
                [name]: {
                    ...state[name],
                    fileName: fileName
                }
            });
    }, [fileName]);

    useEffect(() => handleFileChange(name, state[name].fileName), [state]);

    useEffect(() => triggerMetadataChangeEvent(), [metaDataState]);

    const getImageFileMeta = (file) => {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.readAsDataURL(file);
            fr.onload = () => {
                // when file has loaded
                const img = new Image();
                img.src = fr.result.toString();
                img.onload = (event) => {
                    resolve({
                        height: img.naturalHeight,
                        width: img.naturalWidth
                    });
                };
            };
        });
    };

    const renderDelete = () => {
        const file = state && state[name]?.fileName;
        return (
            (isAdminUser || isOperatorUser) &&
            enableDelete &&
            file &&
            file !== ''
        );
    };

    const triggerMetadataChangeEvent = () => {
        const event = {
            target: {
                type: 'textfield',
                name: metaDataFieldName,
                value: metaDataState.val
            }
        };
        handleTextChange(event);
    };

    const handleImageChange = async (event) => {
        event.preventDefault();
        setIsInProgress(true);
        try {
            const reader = new FileReader();
            const file = event.target.files[0];
            const imageMetadata = await getImageFileMeta(file);

            if (aspectRatio) {
                imageMetadata.aspect_ratio = aspectRatio; // Adds the aspect ratio from the external config file.
            }

            if (imageId) {
                imageMetadata.image_id = imageId; // Adds the image id from the external config file.
            }

            const filePath = utils.getFilePath(
                tenantName,
                bucket,
                encodeFilename(file.name)
            );

            const uploadResult = await awsStorage.upload({
                folder: '',
                name: filePath,
                file: file
            });

            setState({
                [name]: {
                    ...state[name],
                    file: file,
                    imagePreviewUrl: reader.result,
                    fileName: uploadResult.key
                }
            });
            const s3Download = await awsStorage.download(uploadResult.key);
            const ETAG = s3Download?.ETag;
            imageMetadata['etag'] = ETAG;
            const updatedMetaData = JSON.stringify(imageMetadata);
            setMetaDataState({
                val: updatedMetaData,
                name: metaDataFieldName,
                updated: true
            });
        } catch (err) {
            console.log(err);
            dispatch(notifyError(`${err.name}:  ${err.message}`));
        } finally {
            setIsInProgress(false);
        }
    };

    const fullFilePath = utils.getFullFilePath(
        config.imageBaseUrl,
        tenantName,
        bucket,
        state[name].fileName
    );

    const localization = useSelector(strings);

    return (
        <div className={classes.root}>
            <Loader isInProgress={isInProgress} />
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <Grid item xs={12}>
                        <div className={classes.upload}>
                            <FormControl className={classes.margin}>
                                <InputLabel
                                    className={classes.labelStyle}
                                    shrink
                                    htmlFor={name}
                                >
                                    {label}{' '}
                                    {required && (
                                        <span className='text-danger'>*</span>
                                    )}
                                </InputLabel>
                                <BootstrapInput
                                    name={name}
                                    label={label}
                                    type='text'
                                    id={name}
                                    value={
                                        (fullFilePath !== '' && fullFilePath) ||
                                        ''
                                    }
                                />
                            </FormControl>
                            <input
                                name={name}
                                accept={acceptFilter}
                                className={classes.input}
                                id={item}
                                type='file'
                                onChange={handleImageChange}
                            />
                            {(isAdminUser || isOperatorUser) &&
                                !hideBrowseButton && (
                                    <label
                                        htmlFor={item}
                                        className={classes.buttonLabel}
                                    >
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            component='span'
                                        >
                                            {localization.browse}...
                                        </Button>
                                    </label>
                                )}
                            {renderDelete() && (
                                <label className={classes.buttonLabel}>
                                    <Button
                                        className={classes.buttonWarning}
                                        variant='contained'
                                        color='warning'
                                        component='span'
                                        onClick={handleShowConfirm}
                                    >
                                        Delete
                                    </Button>
                                </label>
                            )}
                        </div>
                        <Grid
                            container
                            spacing={1}
                            className={classes.dataContainer}
                        >
                            <Grid item xs={12} lg={6}>
                                <strong>Target Aspect Ratio: </strong>{' '}
                                <span data-testid='aspect-ratio'>
                                    {aspectRatio}
                                </span>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <strong>Kind: </strong>{' '}
                                <span data-testid='kind'>{kind}</span>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <strong>Target Size: </strong>{' '}
                                <span data-testid='size'>{size}</span>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <strong>Anticipated Format: </strong>{' '}
                                <span data-testid='format'>{format}</span>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <strong>Image ID: </strong>{' '}
                                <span data-testid='imageId'>{imageId}</span>
                            </Grid>
                            <Grid item xs={12}>
                                <strong>Notes: </strong>{' '}
                                <span data-testid='notes'>{notes}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.imgPreview}>
                            {type === 'image' && !fullFilePath && (
                                <div className={classes.icon}>
                                    <PanoramaOutlined
                                        style={{ fontSize: 80 }}
                                    />
                                </div>
                            )}
                            {type === 'image' && fullFilePath && (
                                <img
                                    className={classes.img}
                                    src={fullFilePath}
                                    alt=''
                                />
                            )}
                            {type === 'video' && (
                                <div className={classes.icon}>
                                    <PlayCircleOutlineOutlined
                                        style={{ fontSize: 80 }}
                                    />
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid item xs={12}>
                        <Input
                            label={'Override URL - ' + label}
                            name={overrideUrlFieldName}
                            value={urlOverride.val}
                            handleChange={handleOverrideUrlUpdate}
                            showCopy={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            label={'Metadata - ' + label}
                            name={metaDataFieldName}
                            value={metaDataState.val}
                            disabled={true}
                            handleChange={handleMetadataUpdate}
                            showCopy={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Confirm
                open={showConfirm}
                onClose={handleConfirmNo}
                onYes={handleConfirmYes}
                title={confirmTitle}
                content={confirmContent}
            />
        </div>
    );
};
