import httpService from './httpService';

export const productsService = {
    httpInstanceProtected: null,
    httpInstance: null,
    httpCalendarInstance: null,

    letProductInstance: function () {
        if (!this.httpInstance) {
            this.httpInstance = httpService.getProductAPIHttpInstance();
        }
        return this.httpInstance;
    },

    letProductInstanceProtected: function () {
        if (!this.httpInstanceProtected) {
            this.httpInstanceProtected =
                httpService.getProductAPIHttpInstanceProtected();
        }
        return this.httpInstanceProtected;
    },

    letEventCalendarInstance: function () {
        if (!this.httpCalendarInstance) {
            this.httpCalendarInstance =
                httpService.getEventCalendarAPIHttpInstance();
        }
        return this.httpCalendarInstance;
    },

    letReleaseInstance: function () {
        if (!this.httpReleaseInstance) {
            this.httpReleaseInstance =
                httpService.getLetReleaseAPIHttpInstanceProtected();
        }
        return this.httpReleaseInstance;
    },

    getProfiles: async function () {
        try {
            let profiles = await this.letProductInstanceProtected().get(
                'media/profiles'
            );
            return profiles.data;
        } catch (err) {
            throw err;
        }
    },

    getDescriptors: async function (profileId, descriptor) {
        try {
            const response = await this.letProductInstanceProtected().get(
                `media/profiles/${profileId}/${descriptor}`
            );
            return response.data;
        } catch (err) {
            throw err;
        }
    },

    getCustomFields: async function (profileId) {
        try {
            const response = await this.letProductInstanceProtected().get(
                `media/profiles/${profileId}/custom`
            );
            return response.data;
        } catch (err) {
            throw err;
        }
    },

    getAllDescriptors: async function (profileId) {
        try {
            const response = await this.letProductInstanceProtected().get(
                `media/profiles/${profileId}/all_descriptors`
            );
            return response.data;
        } catch (err) {
            throw err;
        }
    },

    getAsset: async function (assetId) {
        try {
            const response = await this.letProductInstanceProtected().get(
                `media/assets/${assetId}`
            );
            return response.data;
        } catch (err) {
            throw err;
        }
    },

    createAsset: async function (asset) {
        try {
            return await this.letProductInstanceProtected().post(
                'media/assets',
                asset
            );
        } catch (err) {
            throw err;
        }
    },

    updateAsset: async function (assetId, asset) {
        try {
            return await this.letProductInstanceProtected().put(
                `media/assets/${assetId}`,
                asset
            );
        } catch (err) {
            throw err;
        }
    },

    getProducts: async function () {
        try {
            let apiData = await this.letProductInstanceProtected().get(
                'products'
            );
            return apiData.data;
        } catch (err) {
            throw err;
        }
    },

    getEntitlements: async function (mediaId) {
        try {
            let apiData = await this.letProductInstanceProtected().get(
                `get-registered-products/${mediaId}`
            );
            return apiData.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    addFlight: async function (payload) {
        try {
            return await this.letProductInstanceProtected().post(
                'product/addFlight',
                payload
            );
        } catch (err) {
            throw err;
        }
    },

    actionEncoder: async function (assetId, action) {
        try {
            return await this.letProductInstanceProtected().put(
                `media/assets/${assetId}/encoder/${action}`,
                null
            );
        } catch (err) {
            throw err;
        }
    },

    generateToken: async function (eventId, params) {
        try {
            return await this.letProductInstanceProtected().get(
                `media/event/${eventId}/token`,
                {
                    params
                }
            );
        } catch (err) {
            throw err;
        }
    },

    getAllTenants: async function () {
        let tenantData = await this.letProductInstance().get('tenants');
        let tenantList = [...(tenantData?.data?.tenants || [])].sort((a, b) => {
            return a.name > b.name ? 1 : -1;
        });
        return tenantList;
    },

    getEventCalendar: async function (queryParams = {}) {
        const queryString = new URLSearchParams(queryParams).toString();
        const eventUrl = `eventCalendar?verbose=true${
            queryString === '' ? '' : `&${queryString}`
        }`;
        let eventData = await this.letEventCalendarInstance().get(eventUrl);
        return eventData;
    },

    getReleaseNotifications: async function (override = false) {
        try {
            const response = await this.letReleaseInstance().get(
                `releaseNotifications${override ? '?reset=1' : ''}`
            );
            return response.data;
        } catch (err) {
            throw err;
        }
    },

    updateReleaseNotifications: async function (payload) {
        try {
            const response = await this.letReleaseInstance().put(
                'releaseNotifications',
                payload
            );
            return response.data;
        } catch (err) {
            throw err;
        }
    }
};

export default productsService;
