import React, { useEffect } from 'react';
// Notifications:
import { notifyWarning } from '../reducer/notificationReducer';
import { useDispatch } from 'react-redux';

export const SessionExpired = (props) => {
    const dispatch = useDispatch();
    const { message } = props;
    function componentDidMount() {
        if (props.notify) {
            dispatch(notifyWarning(message));
        }
    }

    useEffect(componentDidMount, [props.notify]);

    return <span />;
};
