import { Storage } from 'aws-amplify';

const awsStorage = {
  getPublicPrefix: function (bucket) {
    let publicPrefix = `${bucket}/`;

    // Videos do not need a source directory (no resizing)
    if (bucket && bucket.indexOf('videos/') !== 0) {
      publicPrefix += 'source/';
    }

    return publicPrefix;
  },
  upload: async function ({ folder, name, file }) {
    return await Storage.put(name, file, {
      contentType: file.type,
      customPrefix: {
        public: ''
      }
    })
  },
  get: async function ({folder, name}) {
    return await Storage.get(name, {
      customPrefix: {
        public: this.getPublicPrefix(folder)
      }
    })
  },
  download: async function (name) {
    return await Storage.get(name, {
      download: true,
      customPrefix: {
        public: ''
      }
    })
  }
}

export default awsStorage;
