import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CopyAll from '@mui/icons-material/CopyAll';
import { AppBar, Button, IconButton, Toolbar } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetSearchParams } from '../reducer/advancedSearchReducer';
import { notifyInfo } from '../reducer/notificationReducer';
import { allTenants, changeTenant } from '../reducer/tenantSlice';
import { userTenants } from '../reducer/userReducer';
import { utils } from '../utils/utils';
import ReleaseNotification from './releaseNotification/ReleaseNotification';
// Localization
import Select from '@mui/material/Select';
import localization, { LANGUAGE_OPTIONS } from '../l10n/localization';
import {
    currentLanguage,
    setLanguage,
    strings
} from '../reducer/localizationSlice';

export const copyLink = (url, tenant) => {
    const directLink = /\?/.test(url)
        ? `${url}&tenant=${tenant}`
        : `${url}?tenant=${tenant}`;
    navigator.clipboard.writeText(directLink);
    console.info(`${directLink} copied to clipboard`);
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    userProfileMenu: {
        '& .MuiPaper-root': {
            position: 'absolute',
            top: '55px !important',
            width: '190px'
        }
    },

    tenantMenu: {
        '& .MuiPaper-root': {
            position: 'absolute',
            top: '55px !important',
            width: '190px'
        }
    },

    header: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    header_info: {
        display: 'flex'
    }
}));

export default function TopBar(props) {
    const { login, logout, userData } = props;

    const language = useSelector(currentLanguage);
    const l10n = useSelector(strings);

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const currentTenant = localStorage.getItem('tenant');
    const allTenantList = useSelector(allTenants);
    const userTenantList = useSelector(userTenants);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentTenantName, setCurrentTenantName] = useState('');
    const [tenantList, setTenantList] = useState([]);
    const [userName, setUserName] = useState('');
    const [userProfileOpen, setUserProfileOpen] = useState(null);

    const handleChange = (event) => {
        dispatch(resetSearchParams());
        resetTenant(event.value);
        setCurrentTenantName(getTenantName(event.name));
        setAnchorEl(null);

        const targetPath = `/${
            window.location.pathname.split('/').filter((e) => e != '')[0]
        }`;

        window.location.href = targetPath;
    };

    const handleClose = () => {
        setAnchorEl(null);
        setUserProfileOpen(null);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleUserProfile = (event) => {
        setUserProfileOpen(event.currentTarget);
    };

    const resetTenant = (tenantName) => {
        dispatch(changeTenant(tenantName));
        localStorage.setItem('tenant', tenantName);
    };

    const getTenantName = (name) =>
        utils.isDomestic(name) ? 'Max Domestic' : name;

    useEffect(() => {
        if (language && language.length) {
            setUserName(`${l10n.loading}...`);
        }
    }, [language]);

    useEffect(() => {
        if (allTenantList?.length && userTenantList?.length) {
            const currentTenantData = allTenantList.find(
                (tenant) => tenant.key === currentTenant
            );

            setCurrentTenantName(getTenantName(currentTenantData?.name));

            // If User tenants are found, create an array for the dropdown:
            const tenants = userTenantList
                .map((tenant, index) => {
                    const tenantKeyValue = allTenantList.find(
                        (element) => element.key === tenant
                    );
                    const name = getTenantName(
                        tenantKeyValue?.name || 'Undefined'
                    );
                    return {
                        id: index,
                        name,
                        value: tenant
                    };
                })
                .sort((a, b) => (a.name > b.name ? 1 : -1));
            setTenantList(tenants);
        }
    }, [allTenantList, userTenantList]);

    useEffect(() => {
        if (userData.isAuthenticated) {
            setUserName(`${userData.firstName} ${userData?.lastName}`);
        } else {
            setUserName(`${l10n.loading} ...`);
        }
    }, [userData]);

    const handleLanguageChange = (event) => {
        dispatch(setLanguage(event.target.value));
        localization.setSavedLocalizedLanguage(event.target.value);
        // Trigger a page refresh:
        setTimeout(() => {
            window.location.reload(false);
        }, 100);
    };

    const authMenu = userData?.isAuthenticated ? (
        <div className={classes.userMenu}>
            <IconButton
                size='medium'
                aria-label='User Profile Menu'
                aria-controls='menu-user-profile'
                aria-haspopup='true'
                onClick={handleUserProfile}
                color='inherit'
                id='CurrentUserButton'
            >
                <AccountCircleIcon />
                &nbsp;&nbsp;
                <span id='CurrentUser'>{userName}</span>
            </IconButton>
            <Menu
                id='user-profile-menu'
                anchorEl={userProfileOpen}
                className={classes.userProfileMenu}
                open={Boolean(userProfileOpen)}
                onClose={handleClose}
            >
                <MenuItem>
                    <Select
                        data-testid={'languageSelect'}
                        id={'LanguageSelect'}
                        name='language'
                        value={language}
                        onChange={handleLanguageChange}
                    >
                        {LANGUAGE_OPTIONS.map((lang, index) => {
                            return (
                                <MenuItem
                                    key={`lang-${index}`}
                                    value={lang.id}
                                    data-testid={`language_${index}`}
                                >
                                    {lang.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </MenuItem>
                <MenuItem id='LogOutButton' onClick={logout}>
                    {l10n.top_bar_logout}
                </MenuItem>
            </Menu>
        </div>
    ) : (
        <Button id='LoginButton' color='inherit' onClick={login}>
            {l10n.log_in}
        </Button>
    );

    const tenantMenu = userData?.isAuthenticated ? (
        <div>
            <IconButton
                aria-label='Change Tenant'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                color='inherit'
                data-testid='changeTenant'
                onClick={handleMenu}
                size='medium'
            >
                <span id='CurrentTenant'>{currentTenantName}</span>
            </IconButton>
            <Menu
                id='TenantMenu'
                className={classes.tenantMenu}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {tenantList.map((tenant, index) => (
                    <MenuItem
                        key={`tenant_${index}`}
                        onClick={() => handleChange(tenant)}
                    >
                        <span data-testid={`tenant_${index}`}>
                            {tenant.name}
                        </span>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    ) : (
        ''
    );

    const copyURL = userData?.isAuthenticated ? (
        <Button
            title={l10n.copy_link}
            id={'CopyURL'}
            data-testid={'copy-url'}
            onClick={() => {
                copyLink(window.location.href, currentTenant);
                dispatch(notifyInfo(l10n.url_copied));
            }}
        >
            <CopyAll />
        </Button>
    ) : (
        ''
    );

    return (
        <div className={classes.root} id={'TopBar'}>
            <AppBar position='static'>
                <Toolbar className={classes.header}>
                    <div>{l10n.top_bar_dashboard || ''}</div>
                    <ReleaseNotification />
                    <div className={classes.header_info}>
                        {tenantMenu}
                        {authMenu}
                        {copyURL}
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}
