import axios from 'axios';
import constants from '../constants';
import store from '../redux/store';

export const httpService = {
    isServiceInitialised: false,
    apiKey: null,
    globalConfigs: null,
    currentTenant: null,

    init: function () {
        const state = store.getState();
        this.globalConfigs = state.config;
        this.currentTenant = state.tenant.tenantName;
        this.apiKey = this.globalConfigs.apiKey;
        this.isServiceInitialised = true;
    },

    getHBOcatalogInstance: function () {
        if (!this.isServiceInitialised)
            throw 'HBO catalog api not initialised!';
        return axios.create({
            baseURL: this.globalConfigs.domains.hboCatalogDomain,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });
    },

    getMediaDistilleryInstance: function () {
        if (!this.isServiceInitialised)
            throw 'HBO catalog api not initialised!';
        return axios.create({
            baseURL: this.globalConfigs.domains.hboDistilleryDomain,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    },

    getLetAPIHttpInstanceProtected: function () {
        if (!this.isServiceInitialised)
            throw 'LET API service not initialised!';
        return axios.create({
            baseURL:
                this.globalConfigs.domains.apiDomain + constants.letVersion,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                tenant: this.currentTenant,
                'x-api-key': this.apiKey
            }
        });
    },

    getProductAPIHttpInstanceProtected: function () {
        if (!this.isServiceInitialised)
            throw 'Product service not initialised!';
        return axios.create({
            baseURL:
                this.globalConfigs.domains.serviceApiDomain +
                constants.letVersion,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                tenant: this.currentTenant,
                'x-api-key': this.apiKey
            }
        });
    },

    getLetAPIHttpInstance: function () {
        const state = store.getState();
        let configs = state.config;
        let currentTenant = state.tenant.tenantName;
        return axios.create({
            baseURL: configs.domains.apiDomain + constants.letVersion,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                tenant: currentTenant
            }
        });
    },

    // TODO: Fix this call so we can make use of it to pull data instead of
    // using anchor link.
    getProductAPIHttpInstance: function () {
        const state = store.getState();
        let configs = state.config;
        let currentTenant = state.tenant.tenantName;
        return axios.create({
            baseURL: configs.domains.serviceApiDomain + constants.letVersion,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                tenant: currentTenant
            }
        });
    },

    getEventCalendarAPIHttpInstance: function () {
        const state = store.getState();
        let configs = state.config;
        let currentTenant = state.tenant.tenantName;
        return axios.create({
            baseURL: configs.domains.serviceApiDomain + constants.apiVersion,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });
    },

    getLetReleaseAPIHttpInstanceProtected: function () {
        if (!this.isServiceInitialised)
            throw 'LET Release API service not initialised!';
        return axios.create({
            baseURL: `${this.globalConfigs.domains.serviceApiDomain}${constants.apiVersion}let`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                tenant: this.currentTenant,
                'x-api-key': this.apiKey
            }
        });
    }
};

export default httpService;
