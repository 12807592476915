export const ABOUT_INFO = `+----------------------------+
| LET Administrative Console |
+----------------------------+

About
-----
The LET Adminstrative Console is available for Administrators. 

Release notifications are enabled for all users by default in both Stage and Production. Administrators may optionally enable them in lower environments (for themselves only) via the enableNotifications call detailed below.

To use the console, simply type a command (with an argument when required) and click Enter.

The Commands
------------
window.let.info()                                 // Display this message.
window.let.editRelease()                          // Opens the modal for editing the Release Notification.
window.let.enableNotificiations(true|1|false|0)   // Enables the loading and editing of the Release Notification in the current lower environment.
`;
export const ENABLED_ENVIRONMENTS = /stage|prod/i;
export const COOKIE_NAME = 'let.info';
export const DEFAULT_COOKIE = JSON.stringify({
    release_notifications_enabled: {}
});
export const NON_PROD_RESPONSE = {
    date_end: '2023-01-01T00:15:00Z',
    date_start: '2023-01-01T00:00:00Z',
    title: 'Non-Prod',
    message: ''
};
