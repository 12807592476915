import { Box, Container, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectTenant } from '../../reducer/tenantSlice';
import services from '../../services/apiService';
import { date as dateUtil } from '../../utils/date';
import { handleOnlyNumber4 } from '../../utils/utils';
import { TeamContext } from '../context/TeamContext';
import { AutocompleteBox } from './AutocompleteBox';
import { Calendar } from './Calendar';
import { ClearButton } from './ClearButton';
import { Input } from './Input';
import { SelectBox } from './SelectBox';
// Notifications:
import {
    advancedSearchParams,
    setSearchParams
} from '../../reducer/advancedSearchReducer';
import { currentLanguage, strings } from '../../reducer/localizationSlice';
import { notifyError } from '../../reducer/notificationReducer';

export const yesno = [
    { name: 'Yes', id: 'true' },
    { name: 'No', id: 'false' }
];

const SEASON_YEAR_MIN = 1970;
const SEASON_YEAR_MAX = 2099;

export const useStyles = makeStyles((theme) => ({
    searchGrid: {
        marginTop: '50px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#000'
    },
    paper: {
        position: 'absolute',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),

        '& .scroll': {
            minHeight: '80vh',
            maxHeight: '80vh',
            overflowY: 'scroll'
        }
    },
    scroll: {
        minHeight: '80vh',
        maxHeight: '80vh',
        overflowY: 'scroll'
    }
}));

export function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
        overflow: 'scroll',
        maxHeight: '90vh',
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

const cleanSearchParams = (params) => {
    if (!!params && typeof params === 'object') {
        Object.keys(params).forEach((key, value) => {
            if (params[key] === undefined) {
                delete params[key];
            }
        });
    }
    return params;
};

export const getRoute = (componentType, searchParms) => {
    let route = '';
    let parms = Object.keys(searchParms);
    parms.forEach((element) => {
        let value = searchParms[element];
        route +=
            value && value !== ''
                ? element + '=' + searchParms[element] + '&'
                : '';
    });
    // Now, take the final & off the end of the string.
    route =
        componentType +
        (route !== '' ? '?' + route.substring(0, route.length - 1) : '');
    return route;
};

const AdvancedSearch = (props) => {
    // Competitors: Sport, League, HasMedia, Hidden, Sport Radar ID >>> Added
    // Conferences: Sport, League >>> Added
    // Divisions: Sport, League, Conference
    // Events: Name, Sport. League, Tournament, Series, Division, Conference, HasMedia, Is Live, Free Preview, Free Event, Editorial Override, Home Team, Away Team, Session Min, Session Max, Start Time, End Time
    // Leagues: Sport, Followable, HasMedia
    // Series: Sport, League, Tournament, HasMedia,
    // Sports: HasMedia
    // Tournaments: Sport, League, HasMedia
    // Venues: City, State, Country, Zip, TimeZone
    const dispatch = useDispatch();
    const location = useLocation();
    const route = location?.pathname.replace('/', '');
    const searchedParams = useSelector(advancedSearchParams);
    const tenant = useSelector(selectTenant);

    const {
        open,
        handleSearch,
        handleClose,
        name,
        sport,
        league,
        tournament,
        series,
        division,
        conference,
        hasMedia,
        isLive,
        freePreview,
        freeEvent,
        editorialOverride,
        homeTeam,
        awayTeam,
        seasonMax,
        seasonMin,
        season,
        startTime,
        endTime,
        hidden,
        sportRadarId,
        followable,
        city,
        state,
        country,
        zip,
        timezone
    } = props;
    const classes = useStyles();
    const [sports, setSports] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [tournaments, setTournaments] = useState([]);
    const [conferences, setConferences] = useState([]);
    const [allSeries, setSeries] = useState([]);
    const [divisions, setDivisions] = useState([]);

    const [modalStyle] = useState(getModalStyle);
    const teams = useContext(TeamContext);
    const [newSearchParms, setNewSearchParms] = useState({});

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [zips, setZips] = useState([]);
    const [timezones, setTimezones] = useState([]);

    const [errorSeasonMin, setErrorSeasonMin] = useState(false);
    const [errorSeasonMax, setErrorSeasonMax] = useState(false);
    const [errorSeason, setErrorSeason] = useState(false);

    const [yesNoOptions, setYesNoOptions] = useState(yesno);

    useEffect(() => {
        services
            .fetchAllData('sports')
            .then((data) => {
                setSports(data);
            })
            .catch((err) => {
                setLeagues([]);
                dispatch(notifyError(`${err.name}:  ${err.message}`));
            });
    }, []);

    useEffect(() => {
        if (searchedParams[route]) {
            setNewSearchParms(searchedParams[route]);
        }
    }, [open]);

    const handleEventNameChange = (event) => {
        const target = event.target;
        const value = target.value;
        setNewSearchParms({ ...newSearchParms, name: value });
    };

    const handleSportChange = (event, sport_id) => {
        setNewSearchParms({
            ...newSearchParms,
            sport_id,
            league_id: undefined,
            tournament_id: undefined,
            conference_id: undefined,
            series_id: undefined,
            division_id: undefined
        });
    };

    const handleLeagueChange = (event, league_id) => {
        setNewSearchParms({
            ...newSearchParms,
            league_id,
            tournament_id: undefined,
            conference_id: undefined,
            series_id: undefined,
            division_id: undefined
        });
    };

    const handleConferenceChange = (event, conference_id) => {
        setNewSearchParms({
            ...newSearchParms,
            conference_id,
            division_id: undefined
        });
    };

    const handleTournamentChange = (event, tournament_id) => {
        setNewSearchParms({
            ...newSearchParms,
            tournament_id,
            series_id: undefined
        });
    };

    const handleSeriesChange = (event, series_id) => {
        setNewSearchParms({ ...newSearchParms, series_id });
    };

    const handleDivisionChange = (event, division_id) => {
        setNewSearchParms({ ...newSearchParms, division_id });
    };

    const fetchLeagues = useCallback(() => {
        let sportId = newSearchParms.sport_id;

        if (sportId !== undefined) {
            let route = getRoute('leagues', { sport_id: sportId });
            const data = services
                .fetchAllData(route)
                .then((data) => {
                    setLeagues(data);
                })
                .catch((err) => {
                    dispatch(notifyError(`${err.name}:  ${err.message}`));
                });
        } else {
            setLeagues([]);
            const params = { ...newSearchParms };
            delete params['sport_id'];
            delete params['league_id'];

            setNewSearchParms(params);
        }
    }, [newSearchParms.sport_id]);

    useEffect(() => {
        fetchLeagues();
    }, [fetchLeagues]);

    const fetchConferences = useCallback(() => {
        let leagueId = newSearchParms.league_id;

        if (leagueId !== undefined) {
            services
                .getAttribute('conferences', 'league_id', leagueId)
                .then((res) => setConferences(res))
                .catch((err) => {
                    dispatch(notifyError(`${err.name}:  ${err.message}`));
                });

            services
                .getAttribute('tournaments', 'league_id', leagueId)
                .then((res) => setTournaments(res))
                .catch((err) => {
                    dispatch(notifyError(`${err.name}:  ${err.message}`));
                });
        } else {
            setConferences([]);
            setTournaments([]);

            const params = { ...newSearchParms };
            delete params['league_id'];
            delete params['conference_id'];
            delete params['tournament_id'];

            setNewSearchParms(params);
        }
    }, [newSearchParms.league_id]);

    useEffect(() => {
        fetchConferences();
    }, [fetchConferences]);

    const fetchSeries = useCallback(() => {
        let tournamentId = newSearchParms.tournament_id;

        if (tournamentId !== undefined) {
            services
                .getAttribute('series', 'tournament_id', tournamentId)
                .then((res) => setSeries(res))
                .catch((err) => {
                    dispatch(notifyError(`${err.name}:  ${err.message}`));
                });
        } else {
            setSeries([]);
            const params = { ...newSearchParms };
            delete params['tournament_id'];
            delete params['series_id'];

            setNewSearchParms(params);
        }
    }, [newSearchParms.tournament_id]);

    useEffect(() => {
        fetchSeries();
    }, [fetchSeries]);

    const fetchDivision = useCallback(() => {
        const conferenceId = newSearchParms.conference_id;

        if (conferenceId !== undefined) {
            services
                .getAttribute('divisions', 'conference_id', conferenceId)
                .then((res) => setDivisions(res))
                .catch((err) => {
                    dispatch(notifyError(`${err.name}:  ${err.message}`));
                });
        } else {
            setDivisions([]);

            const params = { ...newSearchParms };
            delete params['conference_id'];
            delete params['division_id'];
            setNewSearchParms(params);
        }
    }, [newSearchParms.conference_id]);

    useEffect(() => {
        fetchDivision();
    }, [fetchDivision]);

    useEffect(() => {
        startTime &&
            setNewSearchParms({
                ...newSearchParms,
                start_date: dateUtil.defaultStartDateTime()
            });
    }, [startTime]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewSearchParms({ ...newSearchParms, [name]: value });
    };

    const handleCityChange = (event, city) => {
        setNewSearchParms({ ...newSearchParms, city });
    };

    const handleStateChange = (event, state) => {
        setNewSearchParms({ ...newSearchParms, state });
    };

    const handleCountryChange = (event, country) => {
        setNewSearchParms({ ...newSearchParms, country });
    };

    const handleZipChange = (event, zip) => {
        setNewSearchParms({ ...newSearchParms, zip });
    };
    const handleTimeZoneChange = (event, time_zone) => {
        setNewSearchParms({ ...newSearchParms, time_zone });
    };

    const getVenueInfo = (unique) => {
        const route = getRoute('venues', { unique });
        const emptyFilter = (item) =>
            item.id && item.id.length && item.name && item.name.length;

        services
            .fetchAllData(route)
            .then((data) => {
                const result = data
                    .map((item) => {
                        return { id: item[unique], name: item[unique] };
                    })
                    .filter(emptyFilter)
                    .sort((a, b) => {
                        return a.name > b.name ? 1 : -1;
                    });

                switch (unique) {
                    case 'country':
                        setCountries(result);
                        break;
                    case 'state':
                        setStates(result);
                        break;
                    case 'city':
                        setCities(result);
                        break;
                    case 'zip':
                        setZips(result);
                        break;
                    case 'time_zone':
                        setTimezones(result);
                        break;
                }
            })
            .catch((err) => {
                dispatch(notifyError(`${err.name}:  ${err.message}`));
            });
    };

    const fetchVenues = useCallback(() => {
        country && getVenueInfo('country');
        state && getVenueInfo('state');
        city && getVenueInfo('city');
        zip && getVenueInfo('zip');
        timezone && getVenueInfo('time_zone');
    }, [country, state, city, zip, timezone]);

    useEffect(() => {
        fetchVenues();
    }, [fetchVenues]);

    const handleDateChange = (parm1, parm2) => {
        let searchParms = { ...newSearchParms };
        parm2 ? (searchParms[parm1] = parm2) : delete searchParms[parm1];
        setNewSearchParms(searchParms);
    };

    const handleClear = () => {
        const params = {};
        setNewSearchParms(params);
        handleSearch();
        dispatch(
            setSearchParams({
                params,
                route
            })
        );
        /*
        const initialParam = startTime ? { start_date: dateUtil.defaultStartDateTime() } : {}
        setNewSearchParms(initialParam);
        handleSearch();
        dispatch(
            setSearchParams({
                params: initialParam,
                route
            })
        );
        */
    };

    const handleSearchParam = () => {
        if (seasonMin && errorSeasonMin) return;
        if (seasonMax && errorSeasonMax) return;
        if (season && errorSeason) return;
        const params = cleanSearchParams(newSearchParms);
        handleSearch(params);

        dispatch(
            setSearchParams({
                params,
                route
            })
        );
    };

    // validation season_min
    useEffect(() => {
        if (seasonMin) {
            let _error = false;
            if (newSearchParms.season_min) {
                const min_value = parseInt(newSearchParms.season_min);
                if (min_value < SEASON_YEAR_MIN || min_value > SEASON_YEAR_MAX)
                    _error = true;
            }
            setErrorSeasonMin(_error);
        }
    }, [newSearchParms.season_min, seasonMin]);

    // validation season_max
    useEffect(() => {
        if (seasonMax) {
            let _error = false;
            if (newSearchParms.season_max) {
                const max_value = parseInt(newSearchParms.season_max);
                if (max_value > SEASON_YEAR_MAX || max_value < SEASON_YEAR_MIN)
                    _error = true;
                else if (seasonMin && newSearchParms.season_min) {
                    const min_value = parseInt(newSearchParms.season_min);
                    if (max_value < min_value) _error = true;
                }
            }
            setErrorSeasonMax(_error);
        }
    }, [
        newSearchParms.season_min,
        newSearchParms.season_max,
        seasonMax,
        seasonMin
    ]);

    const handleChangeSeason = (e) => {
        handleChange(e);
        const seasonValue = parseInt(e.target.value);
        setErrorSeason(
            !seasonValue ||
                seasonValue < SEASON_YEAR_MIN ||
                seasonValue > SEASON_YEAR_MAX
        );
    };

    const localization = useSelector(strings);
    const language = useSelector(currentLanguage);

    useEffect(() => {
        if (language) {
            setYesNoOptions([
                { name: localization.yes, id: 'true' },
                { name: localization.no, id: 'false' }
            ]);
        }
    }, [language]);

    // Optional Conference display:
    const showConference = false;
    const conferenceSelect =
        conference && showConference ? (
            <Grid item xs={6}>
                <AutocompleteBox
                    label={`${localization.advanced_search_conference}:`}
                    options={conferences}
                    nameField='name'
                    name='conference_id'
                    handleChange={handleConferenceChange}
                    showToggle={false}
                    enableToggle={false}
                    defaultValue={newSearchParms.conference_id || null}
                    localizedPlaceholder={localization.advanced_search_select}
                />
            </Grid>
        ) : (
            ''
        );
    // Optional Division display:
    const showDivision = false;
    const divisionSelect =
        division && showDivision ? (
            <Grid item xs={6}>
                <AutocompleteBox
                    label={`${localization.advanced_search_division}:`}
                    options={divisions}
                    nameField='name'
                    name='division_id'
                    handleChange={handleDivisionChange}
                    showToggle={false}
                    enableToggle={false}
                    defaultValue={newSearchParms.division_id || null}
                    localizedPlaceholder={localization.advanced_search_select}
                />
            </Grid>
        ) : (
            ''
        );

    return (
        <Modal
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
            open={open}
            onClose={handleClose}
            style={{ overflow: 'scroll' }}
            disableEnforceFocus
        >
            <div style={modalStyle} className={classes.paper}>
                <Container maxWidth='md'>
                    <h3>{localization.advanced_search_title}</h3>
                    <Grid container spacing={2}>
                        {name && (
                            <Grid item xs={6}>
                                <Input
                                    label={`${localization.advanced_search_event_name}:`}
                                    name='name'
                                    value={newSearchParms.name || ''}
                                    handleChange={handleEventNameChange}
                                    enableToggle={false}
                                />
                            </Grid>
                        )}
                        {sport && (
                            <Grid item xs={6}>
                                <AutocompleteBox
                                    label={`${localization.advanced_search_sport}:`}
                                    options={sports}
                                    name='sport_id'
                                    nameField='name'
                                    handleChange={handleSportChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                    defaultValue={
                                        newSearchParms.sport_id || null
                                    }
                                />
                            </Grid>
                        )}
                        {league && (
                            <Grid item xs={6}>
                                <AutocompleteBox
                                    label={`${localization.advanced_search_league}:`}
                                    options={leagues}
                                    name='league_id'
                                    nameField='name'
                                    handleChange={handleLeagueChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                    defaultValue={
                                        newSearchParms.league_id || null
                                    }
                                />
                            </Grid>
                        )}
                        {tournament && (
                            <Grid item xs={6}>
                                <AutocompleteBox
                                    label={`${localization.advanced_search_tournament}:`}
                                    options={tournaments}
                                    nameField='name'
                                    handleChange={handleTournamentChange}
                                    name='tournament_id'
                                    showToggle={false}
                                    enableToggle={false}
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                    defaultValue={
                                        newSearchParms.tournament_id || null
                                    }
                                />
                            </Grid>
                        )}
                        {series && (
                            <Grid item xs={6}>
                                <AutocompleteBox
                                    label={`${localization.advanced_search_series}:`}
                                    options={allSeries}
                                    nameField='name'
                                    name='series_id'
                                    handleChange={handleSeriesChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    defaultValue={
                                        newSearchParms.series_id || null
                                    }
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                />
                            </Grid>
                        )}
                        {conferenceSelect}
                        {divisionSelect}
                        {followable && (
                            <Grid item xs={6}>
                                <SelectBox
                                    label='Followable:'
                                    name='followable'
                                    options={yesNoOptions}
                                    defaultValue={
                                        newSearchParms.followable || ''
                                    }
                                    handleChange={handleChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    localization={localization}
                                />
                            </Grid>
                        )}
                        {hasMedia && (
                            <Grid item xs={6}>
                                <SelectBox
                                    label={`${localization.advanced_search_has_media}:`}
                                    name='has_media'
                                    options={yesNoOptions}
                                    defaultValue={
                                        newSearchParms.has_media || ''
                                    }
                                    handleChange={handleChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    localization={localization}
                                />
                            </Grid>
                        )}
                        {isLive && (
                            <Grid item xs={6}>
                                <SelectBox
                                    label={`${localization.advanced_search_is_live}:`}
                                    name='is_live'
                                    options={yesNoOptions}
                                    defaultValue={newSearchParms.is_live || ''}
                                    handleChange={handleChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    localization={localization}
                                />
                            </Grid>
                        )}
                        {freePreview && (
                            <Grid item xs={6}>
                                <SelectBox
                                    label={`${localization.advanced_search_free_preview}:`}
                                    name='free_preview'
                                    options={yesNoOptions}
                                    defaultValue={
                                        newSearchParms.free_preview || ''
                                    }
                                    handleChange={handleChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    localization={localization}
                                />
                            </Grid>
                        )}
                        {freeEvent && (
                            <Grid item xs={6}>
                                <SelectBox
                                    label={`${localization.advanced_search_free_event}:`}
                                    name='free_event'
                                    options={yesNoOptions}
                                    defaultValue={
                                        newSearchParms.free_event || ''
                                    }
                                    handleChange={handleChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    localization={localization}
                                />
                            </Grid>
                        )}

                        {homeTeam && (
                            <Grid item xs={6}>
                                <SelectBox
                                    label={`${localization.advanced_search_home_team}:`}
                                    name='home_competitor_id'
                                    options={teams}
                                    defaultValue={
                                        newSearchParms.home_competitor_id || ''
                                    }
                                    handleChange={handleChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    localization={localization}
                                />
                            </Grid>
                        )}
                        {awayTeam && (
                            <Grid item xs={6}>
                                <SelectBox
                                    label={`${localization.advanced_search_away_team}:`}
                                    name='away_competitor_id'
                                    options={teams}
                                    defaultValue={
                                        newSearchParms.away_competitor_id || ''
                                    }
                                    handleChange={handleChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    localization={localization}
                                />
                            </Grid>
                        )}
                        {season && (
                            <Grid item xs={6}>
                                <Input
                                    label={`${localization.advanced_search_season}:`}
                                    name='season'
                                    value={newSearchParms.season || ''}
                                    handleChange={handleChangeSeason}
                                    enableToggle={false}
                                    onKeyDown={(e) => handleOnlyNumber4(e)}
                                    error={errorSeason}
                                />
                            </Grid>
                        )}
                        {seasonMin && (
                            <Grid item xs={6}>
                                <Input
                                    label={`${localization.advanced_search_season_min} (${SEASON_YEAR_MIN}-${SEASON_YEAR_MAX}):`}
                                    name='season_min'
                                    value={newSearchParms.season_min || ''}
                                    handleChange={handleChange}
                                    enableToggle={false}
                                    onKeyDown={(e) => handleOnlyNumber4(e)}
                                    error={errorSeasonMin}
                                />
                            </Grid>
                        )}
                        {seasonMin && (
                            <Grid item xs={6}>
                                <Input
                                    label={`${
                                        localization.advanced_search_season_max
                                    } (${
                                        newSearchParms.season_min ||
                                        SEASON_YEAR_MIN
                                    }-${SEASON_YEAR_MAX} ):`}
                                    name='season_max'
                                    value={newSearchParms.season_max || ''}
                                    handleChange={handleChange}
                                    enableToggle={false}
                                    onKeyDown={(e) => handleOnlyNumber4(e)}
                                    error={errorSeasonMax}
                                />
                            </Grid>
                        )}
                        {hidden && (
                            <Grid item xs={6}>
                                <SelectBox
                                    label={`${localization.advanced_search_hidden}:`}
                                    name='hidden'
                                    options={yesNoOptions}
                                    defaultValue={newSearchParms.hidden || ''}
                                    handleChange={handleChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    localization={localization}
                                />
                            </Grid>
                        )}
                        {sportRadarId && (
                            <Grid item xs={6}>
                                <Input
                                    label='SportRadar ID:'
                                    name='sportradar_id'
                                    value={newSearchParms.sportradar_id || ''}
                                    handleChange={handleChange}
                                    enableToggle={false}
                                />
                            </Grid>
                        )}
                        {city && (
                            <Grid item xs={6}>
                                <AutocompleteBox
                                    label={`${localization.advanced_search_city}:`}
                                    options={cities}
                                    nameField='name'
                                    name='city'
                                    handleChange={handleCityChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    defaultValue={newSearchParms.city || null}
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                />
                            </Grid>
                        )}
                        {state && (
                            <Grid item xs={6}>
                                <AutocompleteBox
                                    label={`${localization.advanced_search_state}:`}
                                    options={states}
                                    nameField='name'
                                    name='state'
                                    handleChange={handleStateChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    defaultValue={newSearchParms.state || null}
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                />
                            </Grid>
                        )}
                        {country && (
                            <Grid item xs={6}>
                                <AutocompleteBox
                                    label={`${localization.advanced_search_country}:`}
                                    options={countries}
                                    nameField='name'
                                    name='country'
                                    handleChange={handleCountryChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    defaultValue={
                                        newSearchParms.country || null
                                    }
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                />
                            </Grid>
                        )}
                        {zip && (
                            <Grid item xs={6}>
                                <AutocompleteBox
                                    label={`${localization.headers_zip_code}:`}
                                    options={zips}
                                    nameField='name'
                                    name='zip'
                                    handleChange={handleZipChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    defaultValue={newSearchParms.zip || null}
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                />
                            </Grid>
                        )}
                        {timezone && (
                            <Grid item xs={6}>
                                <AutocompleteBox
                                    label={`${localization.advanced_search_timezone}:`}
                                    options={timezones}
                                    nameField='name'
                                    name='time_zone'
                                    handleChange={handleTimeZoneChange}
                                    showToggle={false}
                                    enableToggle={false}
                                    defaultValue={
                                        newSearchParms.time_zone || null
                                    }
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                    {(startTime || endTime) && (
                        <Grid container spacing={2}>
                            {startTime && (
                                <Grid item xs={6}>
                                    <Calendar
                                        name='start_date'
                                        label={
                                            localization.advanced_search_start_time
                                        }
                                        defaultValue={
                                            newSearchParms.start_date || ''
                                        }
                                        handleChange={handleDateChange}
                                        showToggle={false}
                                        enableToggle={false}
                                    />
                                </Grid>
                            )}
                            {endTime && (
                                <Grid item xs={6}>
                                    <Calendar
                                        name='end_date'
                                        label={
                                            localization.advanced_search_end_time
                                        }
                                        defaultValue={
                                            newSearchParms.end_date || ''
                                        }
                                        handleChange={handleDateChange}
                                        showToggle={false}
                                        enableToggle={false}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}
                    <Box
                        display='flex'
                        justifyContent='flex-end'
                        gap='20px'
                        sx={{ mt: 2 }}
                    >
                        <ClearButton
                            cta={localization.advanced_search_clear}
                            onClick={handleClear}
                        />
                        <Button onClick={handleSearchParam} variant='contained'>
                            {localization.advanced_search_search}
                        </Button>
                    </Box>
                </Container>
            </div>
        </Modal>
    );
};

export default AdvancedSearch;
