import React, { useEffect, useMemo, useState } from 'react';
import { Details } from '../../Details';
import { makeStyles } from '@mui/styles';
import { utils } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectTenant } from '../../../reducer/tenantSlice';
import { strings } from '../../../reducer/localizationSlice';
import { getLanguages, setShowEdit } from '../../../reducer/multilanguageSlice';
import { isAdmin } from '../../../reducer/userReducer';

const useStyles = makeStyles((theme) => ({
    styling: {
        fontWeight: 'bold',
        '& .MuiContainer-root': {
            padding: 0,
            marginTop: '10px'
        }
    }
}));

export const Detail = ({
    path,
    id,
    event,
    athenaState = undefined,
    cdn = undefined
}) => {
    const classes = useStyles();
    const languages = useSelector(getLanguages);
    const tenant = useSelector(selectTenant);
    const userIsAdmin = useSelector(isAdmin);
    const [tenantName, setTenantName] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        setTenantName(tenant.tenantName);
    }, [tenant]);

    const localization = useSelector(strings);

    const showEdit = (bool) => {
        dispatch(setShowEdit(true));
    };

    const details = useMemo(() => {
        let summary = [];

        if (path == 'live-admin') {
            summary = [
                {
                    label: localization.event_info_event_name,
                    value: event.let_override?.name || event.name
                },
                { label: localization.event_info_event_id, value: event.id },
                {
                    label: localization.event_info_start_time,
                    value: utils.setDate(
                        event.let_override?.scheduled_utc || event.scheduled_utc
                    )
                },
                {
                    label: localization.event_info_profile_id,
                    value:
                        event.let_override?.profile_id ||
                        event.profile_id ||
                        '---'
                },
                {
                    label: localization.event_info_athena_id,
                    value: event.let_override?.athena_id || event.athena_id
                }
            ];

            athenaState !== undefined &&
                summary.push({
                    label: localization.event_info_athena_state,
                    value: athenaState
                });

            event.id &&
                !event.let_override?.is_medium &&
                summary.push({
                    label: localization.event_info_viewable,
                    value:
                        event.let_override?.viewable_id ||
                        event.viewable_id ||
                        '---'
                });

            cdn !== undefined &&
                summary.push({ label: 'CDN', value: cdn || '---' });

            tenantName &&
                tenantName.startsWith('estadio') &&
                summary.push({
                    label: localization.event_info_status,
                    value: utils.capitalize(event.derived_status)
                });
        } else {
            summary = utils.serializeSummary(
                localization.event_info_event_id,
                event,
                localization,
                tenant,
                languages,
                userIsAdmin,
                showEdit
            );
        }

        return summary;
    });

    return (
        <div style={{ width: '100%', margin: '16px 0 0 0' }}>
            {id !== 'new' && Object.keys(event).length > 0 && (
                <Details details={details} />
            )}
        </div>
    );
};
