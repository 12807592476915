import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import services from '../../services/apiService';
import { CircularProgress } from '@mui/material';
import { UploadPreview } from './UploadPreview';
import { alpha } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative'
    },
    loader: {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: alpha('#FFFFFF', 0.5)
    }
}));

export const CompetitorLogo = (props) => {
    const classes = useStyles();
    const competitorId = useRef(false);
    const [fileName, setFileName] = useState('');
    const [loading, setLoading] = useState(false);

    const { item, label, leagueBucket, config } = props;

    useEffect(() => {
        if (props.competitorId && competitorId.current !== props.competitorId) {
            setLoading(true);
            competitorId.current = props.competitorId;
            services
                .getItem('competitors', props.competitorId)
                .then((response) => {
                    setFileName(
                        response?.let_override?.[props.logoName] ||
                            response?.[props.logoName]
                    );
                    setLoading(false);
                });
        }
    }, [props]);

    return (
        <div className={classes.container}>
            <UploadPreview
                item={item}
                label={label}
                bucket={leagueBucket}
                type='image'
                config={config}
                fileName={fileName}
                handleChange={() => {}}
                hideBrowseButton={true}
            />
            {loading && (
                <div className={classes.loader}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};
