import axios from 'axios';

export default function Config(letApiUrl) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  const target =
    process &&
      process.env &&
      process.env.NODE_ENV &&
      (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local')
      ? process.env.REACT_APP_LET_API_URL + 'config'
      : '/config';

  return axios
    .get(target, { headers: headers })
    .then((response) => response.data)
    .then((response) => {
      return JSON.parse(JSON.stringify(response));
    });
}
