import React from 'react';
import { getEtag, indexSort } from '../../../utils/maxImageUtils';
import { Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import MultilanguageImageUpload from '../../shared/MultilanguageImageUpload';
import store from '../../../redux/store';

export const getFilename = (imageItem) => {
    return imageItem?.let_url || imageItem?.image || imageItem?.fileName || '';
};

const MultiLanguageImageInput = (props) => {
    const {
        bucket,
        config,
        currentlanguage,
        handleChange,
        name,
        originalValue,
        overrideValue
    } = props;

    const [value, setValue] = useState(null);

    const handleUploadChange = (e) => {
        // Nothing yet.
    };

    useMemo(() => {
        setValue({
            ...(originalValue || {}),
            ...(overrideValue || {})
        });
    }, [originalValue, overrideValue]);

    const broadcastChange = (images) => {
        handleChange({
            target: {
                name: 'images_ml',
                value: images
            }
        });
    };

    const onHandleStateChange = (e) => {
        if (!value) {
            return;
        }

        // Gather the current version of the state
        const state = store.getState();

        Object.keys(e).forEach((key) => {
            // Isolate the language & key:
            const [language, imageKey] = key.split(':');
            // Grab the new file name:
            const { fileName } = e[key];

            let oldRecord, newRecord;

            if (fileName === null) {
                // Deleting the image;
                oldRecord = value[language]
                    .filter((img) => img.image_id === imageKey)
                    .pop();
                newRecord = {
                    ...oldRecord,
                    image: '',
                    let_url: '',
                    path: state.config.imageBaseUrl
                };
            } else {
                // Find the image that's being manipulated:
                oldRecord = value[language]
                    .filter((img) => img.image_id === imageKey)
                    .pop();
                // Examine the currently assigned name:
                const imageFileName = getFilename(oldRecord);
                // Only act if the current name has changed:
                if (!imageFileName || imageFileName !== fileName) {
                    // Re-assign values to the image:
                    const let_url = fileName;
                    const pathSuffix = fileName.substring(
                        0,
                        fileName.lastIndexOf('/') + 1
                    );
                    const image = fileName.slice(pathSuffix.length);

                    newRecord = {
                        ...oldRecord,
                        image,
                        let_url,
                        cdnBasePath: state.config.gspCdnBasePath,
                        path: `${state.config.imageBaseUrl}${pathSuffix}`
                    };
                }else{
                    newRecord = {
                        ...oldRecord
                    }
                }
            }
            // Create a new array of images minus the one we're modifying:
            const updatedImageList = [...value[language]].filter(
                (img) => img.image_id !== newRecord.image_id
            );
            // Push the modified image onto the stack:
            updatedImageList.push(newRecord);
            // Broadcase the change with a sorted list of updated images
            // for the current language:
            broadcastChange({
                ...value,
                [language]: updatedImageList.sort(indexSort)
            });
        });
    };

    const onHandleTextChange = (e) => {
        if (!value) {
            return;
        }

        const { language, name } = e.target;
        const metadata = e.target?.value;

        if (metadata) {
            broadcastChange({
                ...value,
                [language]: value[language].map((img) => {
                    if (img.image_id === name) {
                        return {
                            ...img,
                            etag: getEtag(metadata),
                            metadata
                        };
                    } else {
                        return img;
                    }
                })
            });
        }
    };

    return (
        <Grid
            item
            container
            xs={12}
            spacing={2}
            data-testid={'MultilanguageImageInput'}
        >
            {value &&
                Object.keys(value).map((key, index) => {
                    const style = {
                        display:
                            currentlanguage && currentlanguage !== key
                                ? 'none'
                                : 'block'
                    };

                    const data = value[currentlanguage];

                    return (
                        <Grid
                            item
                            xs={12}
                            key={`lang_${index}`}
                            style={style}
                            data-testid={`MultilanguageImageInput:${name}:${key}`}
                        >
                            {data &&
                                data.map((imageItem) => {
                                    return (
                                        <Grid
                                            item
                                            key={`MultiLanguageImage-${currentlanguage}-${imageItem.image_id}`}
                                            order={imageItem.index}
                                            xs={12}
                                        >
                                            <MultilanguageImageUpload
                                                bucket={bucket}
                                                config={config}
                                                data={imageItem}
                                                enableDelete={true}
                                                fileName={getFilename(imageItem)} // Old version used .fileName
                                                handleFileChange={
                                                    handleUploadChange
                                                }
                                                handleStateChange={
                                                    onHandleStateChange
                                                }
                                                handleTextChange={
                                                    onHandleTextChange
                                                }
                                                hideBrowseButton={false}
                                                imageId={imageItem.image_id}
                                                item={`images_ml_${currentlanguage}_${imageItem.image_id}`}
                                                label={imageItem.label}
                                                language={currentlanguage}
                                                metadata={imageItem?.metadata || ''}
                                                name={`${currentlanguage}:${imageItem.image_id}`}
                                                overrideUrlVal={
                                                    imageItem.overrideUrlVal
                                                }
                                                type='image'
                                                allowedFormat={imageItem?.format}
                                            />
                                        </Grid>
                                    )
                                })}
                        </Grid>
                    );
                })}
        </Grid>
    );
};

export default MultiLanguageImageInput;
