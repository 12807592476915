import React, { useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { Buttons } from '../../shared/Buttons';
import { MultiLanguageSelector } from '../../shared/MultilanguageSelector';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentIndex, getLanguages, setIndex, getCurrentLanguage, getCurrentLanguageCode } from '../../../reducer/multilanguageSlice';

const MultiLanguageContainer = (props) => {

    const dispatch = useDispatch();

    const [headline,setHeadline] = useState('');
    
    const languages = useSelector(getLanguages);
    const currentIndex = useSelector(getCurrentIndex);
    const currentLanguage = useSelector(getCurrentLanguage);

    let { l10n, offset } = props;

    const nextLanguage = () => {
        dispatch(setIndex(currentIndex + 1));
    };

    const previousLanguage = () => {
        dispatch(setIndex(currentIndex - 1));
    };

    const containerStyle = {
        border: '2px solid rgba(150,150,150, 0.4)',
        justifyContent: "center",
        margin: `0 0 20px ${offset ? 0 : '18px'}`,
        padding: '8px'
    };

    useMemo(()=>{
        if(currentLanguage && l10n && languages && languages.length && currentIndex > -1){
            
            const tokenizedString = l10n.language_x_of_y;    
            const {isoCode, languageName} = currentLanguage;
    
            setHeadline(tokenizedString
                .replace(/%%X%%/, currentIndex + 1)
                .replace(/%%Y%%/, languages.length)
                .replace(/%%LANG%%/, languageName)
                .replace(/%%CODE%%/, isoCode));

        }
    }, [l10n, languages, currentIndex]);
    
    return (
        <Grid
            item
            container
            data-testid={`MultiLanguageContainer`}
            style={containerStyle}
        >
            <Grid
                container
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '2px solid rgba(150,150,150, 0.4)',
                    margin: '0 0 10px 0'
                }}
            >
                <Buttons
                    data-testid={'previous'}
                    disabled={languages.length === 1}
                    flex={1}
                    label={l10n && l10n.previous_language}
                    onClick={previousLanguage}
                />

                <h4 data-testid='ml-container-headline' flex={1}>
                    {headline}
                </h4>

                <Buttons
                    data-testid={'next'}
                    disabled={languages.length === 1}
                    label={l10n && l10n.next_language}
                    onClick={nextLanguage}
                />
            </Grid>
            <MultiLanguageSelector />
            {React.Children.map(props.children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        currentlanguage:
                            currentLanguage?.isoCode
                    });
                }
                return child;
            })}
            <Grid container item xs={12} style={{ padding: '10px 0 0' }}>
                <hr
                    style={{
                        borderTop: `1px solid rgba(125, 125, 125, 0.4)`,
                        width: '100%'
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default MultiLanguageContainer;
