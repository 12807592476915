import React from 'react';
import { brChannels, channels } from '../../../data';
import { Grid } from '@mui/material';
import { SelectBox } from '../../shared/SelectBox';

export const CHANNEL_OPTIONS = (() => {
    return Object.freeze(
        channels
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((channel, index) => {
                return {
                    ...channel,
                    key: `channel_${index}`
                };
            })
    );
})();

export const BR_CHANNEL_OPTIONS = (() => {
    return Object.freeze(
        brChannels
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((channel, index) => {
                return {
                    ...channel,
                    key: `channel_${index}`
                };
            })
    );
})();

const Channels = (props) => {
    const {
        handleChange,
        localization,
        originalValue,
        selectTitle,
        tenant,
        value
    } = props;

    const channelOptions = tenant && tenant.isBR2 ? BR_CHANNEL_OPTIONS : CHANNEL_OPTIONS;

    return (
        <Grid item container xs={6}>
            <SelectBox
                label={`${selectTitle}:`}
                name='channel'
                id='channels'
                options={channelOptions}
                defaultValue={value || ''}
                handleChange={handleChange}
                showToggle={false}
                enableToggle={false}
                originalValue={originalValue}
                localization={localization}
                required={false}
            />
        </Grid>
    );
};

export default Channels;
