import React, { useEffect, useState } from 'react';
import { Confirm } from './Confirm';
import { Grid, InputLabel } from '@mui/material';
import { Buttons } from './Buttons';
import { Input } from './Input';
import { SelectBox } from './SelectBox';
import { CheckBox } from './Checkbox';
import { MultiSelectBox } from './MultiSelectBox';
import { notifySuccess } from '../../reducer/notificationReducer';
import { useDispatch } from 'react-redux';
import Offer from './Offer';
import { Calendar } from './Calendar';
import { COUNTRIES_WORLDWIDE } from '../../data/countriesList';

const countries = COUNTRIES_WORLDWIDE.map((c, i) => {
    const { country, isoCode } = c;
    return {
        id: i,
        label: country,
        value: isoCode
    };
}).sort((a, b) => (a.label > b.label ? 1 : -1));

export const failOverGenerateUUID = () => {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 =
        (typeof performance !== 'undefined' &&
            performance.now &&
            performance.now() * 1000) ||
        0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            var r = Math.random() * 16; //random number between 0 and 16
            if (d > 0) {
                //Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                //Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        }
    );
};

const Offers = (props) => {
    const {
        availableDestinations,
        label,
        localization,
        name,
        id,
        handleChange,
        value
    } = props;

    const dispatch = useDispatch();

    const [addingOffer, setAddingOffer] = useState(false);
    const [addButtonActive, setAddButtonActive] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [destination, setDestination] = useState(null);
    const [editingOffer, setEditingOffer] = useState(false);
    const [isTVE, setIsTVE] = useState(false);
    const [freePreview, setFreePreview] = useState(false);
    const [isBrandOriginal, setIsBrandOriginal] = useState(false);
    const [offerId, setOfferId] = useState(null);
    const [territories, setTerritories] = useState(null);

    const [dateError, setDateError] = useState(null);
    const [defaultCountryOptions, setDefaultCountryOptions] = useState([]);
    const [destinationName, setDestinationName] = useState('');
    const [destinationOptions, setDestinationOptions] = useState([]);
    const [hasOffers, setHasOffers] = useState(false);
    const [offerStep, setOfferStep] = useState(0);
    const [disableUpdate, setDisableUpdate] = useState(false);

    const errorStyle = {
        color: 'red',
        fontSize: '0.8em',
        textAlign: 'center',
        textTransform: 'uppercase'
    };

    useEffect(() => {
        setHasOffers(Object.keys(value).length !== 0);
    }, [value]);

    useEffect(() => {
        setDefaultCountryOptions(
            countries.filter((country) => country.value === 'US')
        );
    }, [countries]);

    useEffect(() => {
        setDestinationOptions(
            availableDestinations.map((destination, index) => ({
                id: destination,
                key: `dest_${index}`,
                name: destination.toUpperCase()
            }))
        );
    }, [availableDestinations]);

    useEffect(() => {
        setAddButtonActive(
            addingOffer &&
                !(availableDestinations && availableDestinations.length === 0)
        );
    }, [availableDestinations, addingOffer]);

    useEffect(() => {
        if (!destination) {
            setDestinationName('');
            return;
        }
        try {
            setDestinationName(
                destinationOptions?.find((aDest) => aDest.id === destination)
                    .name
            );
        } catch (e) {
            console.log(
                `Error trying to find ${destination} in ${JSON.stringify(
                    destinationOptions
                )}`
            );
            setDestinationName('n/a');
        }
    }, [destination]);

    const handleDeleteOffer = () => {
        const update = {};
        Object.keys(value).forEach((aDestination) => {
            const validOffers = Object.keys(value[aDestination]).filter(
                (oid) => oid !== offerId
            );
            if (validOffers && validOffers.length > 0) {
                update[aDestination] = {};
                validOffers.forEach((validOffer) => {
                    update[aDestination][validOffer] =
                        value[aDestination][validOffer];
                });
            }
        });

        handleChange({
            target: {
                name: 'offers',
                value: update
            }
        });

        reset();

        dispatch(notifySuccess(`Remember to save your changes.`));
    };

    const handleDeleteCancel = () => {
        setOfferId(null);
        setDeleteConfirm(false);
        dispatch({ type: 'testing', payload: 'cancelled' });
    };

    const editOffer = (offerDestination, offerId) => {
        // Find the destination:
        setDestination(offerDestination);
        const offer = value[offerDestination][offerId];
        const {
            isBrandOriginal,
            isFreePreview,
            isTVE,
            territories
        } = offer;
        const selectedTerritories = [];

        territories.forEach((territory) => {
            const match = countries.find((country) => {
                return country.value === territory;
            });
            selectedTerritories.push(match);
        });

        setIsBrandOriginal(isBrandOriginal);
        setFreePreview(isFreePreview);
        setIsTVE(isTVE);
        setOfferId(offerId);
        setTerritories(selectedTerritories);

        setOfferStep(1);
        setEditingOffer(true);
    };

    const getUUID = () => {
        try {
            return crypto?.randomUUID();
        } catch (e) {
            console.log(
                `Error tapping into the crypto library. Are you running tests?`
            );
            return failOverGenerateUUID();
        }
    };

    const getDestinationName = (key) => {
        let destinationName = '';
        try {
            destinationName = destinationOptions.find(
                (destination) => destination.id === key
            ).name;
        } catch (e) {
            destinationName = `Unknown Destination "${key}"`;
        }
        return destinationName;
    };

    const removeOffer = (id) => {
        setOfferId(id);
        setDeleteMessage(
            localization.offers_delete_confirm.replace(
                /%%OFFER_ID%%/,
                id.substr(-5)
            )
        );
        setDeleteConfirm(true);
    };

    const renderOffers = () => {
        if (!value || Object.keys(value).length === 0) {
            return '';
        }

        return (
            <Grid container item sm={12}>
                {Object.keys(value)
                    .sort()
                    .map((key, index) => {
                        const offers = value[key];
                        return (
                            <Grid
                                item
                                container
                                sm={12}
                                key={`destination_${index}`}
                                data-testid={`destination_${key}`}
                            >
                                <Grid
                                    item
                                    container
                                    sm={12}
                                    style={{
                                        borderBottom: '1px solid #cccccc',
                                        margin: '0 0 10px 0',
                                        padding: '0 0 10px 0'
                                    }}
                                >
                                    <strong data-testid={`destination_name`}>
                                        {getDestinationName(key)}:
                                    </strong>
                                </Grid>

                                {Object.keys(offers).map(
                                    (offerId, idIndex) => {
                                        const currentOffer =
                                            value[key][offerId];
                                        
                                        const offerProps = {
                                            currentOffer,
                                            destination: key,
                                            editOffer,
                                            index,
                                            idIndex,
                                            localization,
                                            offerId,
                                            removeOffer
                                        };

                                        return (
                                            <Offer
                                                {...offerProps}
                                                key={`offer_${index}_${idIndex}`}
                                            />
                                        );
                                    }
                                )}
                            </Grid>
                        );
                    })}
            </Grid>
        );
    };

    const onAddOffer = () => {
        handleChange({
            target: {
                name: 'offers',
                value: {
                    ...value,
                    [destination]: {
                        ...value[destination],
                        [offerId]: {
                            isTVE: isTVE,
                            isBrandOriginal: isBrandOriginal,
                            isFreePreview: freePreview,
                            territories: (
                                territories || defaultCountryOptions
                            ).map((territory) => territory.value)
                        }
                    }
                }
            }
        });
        reset();
        dispatch(notifySuccess(`Remember to save your changes.`));
    };

    const onCreateOffer = () => {
        // There's only one destionaion available, so skip the choice step:
        if (destinationOptions.length === 1) {
            onUpdateOffer({
                target: {
                    name: 'destination',
                    value: destinationOptions[0].id
                }
            });
        }
        setAddingOffer(true);
    };

    const onUpdateOffer = (e, obj, arr) => {
        const key = arr || e?.target?.name || e;
        switch (key) {
            case 'destination':
                setDestination(e.target.value);
                setOfferId(getUUID());
                setOfferStep(offerStep + 1);
                break;
            case 'isTVE':
                setIsTVE(e.target.checked);
                if (e.target.checked) {
                    setFreePreview(true);
                }
                break;
            case 'isFreePreview':
                setFreePreview(e.target.checked);
                break;
            case 'isBrandOriginal':
                setIsBrandOriginal(e.target.checked);
                break;
            case 'territories':
                setTerritories(obj);
                break;
        }
    };

    const reset = () => {
        setDeleteConfirm(false);
        setDestination(null);
        setEditingOffer(false);
        setIsTVE(false);
        setFreePreview(false);
        setIsBrandOriginal(false);
        setTerritories(null);
        setOfferId(null);
        setOfferStep(0);
        setAddingOffer(false);
    };

    return (
        <Grid container item sm={12}>
            <Grid item sm={12}>
                <InputLabel
                    data-testid='offers_title'
                    style={{
                        fontWeight: 600,
                        fontSize: 'large',
                        color: 'black'
                    }}
                    shrink
                    htmlFor={name}
                >
                    {`${label}:`}
                </InputLabel>
            </Grid>

            {renderOffers()}

            {!addingOffer && !hasOffers && (
                <Grid item sm={12}>
                    <span data-testid='oops_no_offers'>
                        {localization.offers_no_offers}
                    </span>
                </Grid>
            )}

            {(addingOffer || editingOffer) && (
                <Grid
                    item
                    sm={12}
                    style={{
                        margin: '20px 0',
                        border: '1px solid gray',
                        padding: '10px'
                    }}
                >
                    {offerStep === 0 && (
                        <Grid item container spacing={2} sm={12}>
                            <Grid item sm={6}>
                                <SelectBox
                                    name='destination'
                                    label={
                                        localization.offers_select_destination
                                    }
                                    localizedPlaceholder={
                                        localization.offers_select_destination_placeholder
                                    }
                                    options={destinationOptions}
                                    handleChange={onUpdateOffer}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <Input
                                    name={'offer_id'}
                                    label={localization.offers_id}
                                    disabled={true}
                                    value={offerId}
                                />
                            </Grid>
                        </Grid>
                    )}

                    {offerStep === 1 && (
                        <Grid item container spacing={2} sm={12}>
                            <Grid item sm={6}>
                                <Input
                                    name={'destination_name_display'}
                                    label={localization.offers_destination}
                                    disabled={true}
                                    value={destinationName}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <Input
                                    name={'offer_id_display'}
                                    label={localization.offers_id}
                                    disabled={true}
                                    value={offerId}
                                />
                            </Grid>
                        </Grid>
                    )}

                    <Grid item container spacing={2} sm={12}>
                        <Grid item sm={6}>
                            <CheckBox
                                checked={isTVE}
                                disabled={offerStep < 1}
                                handleChange={onUpdateOffer}
                                label={localization.offers_is_tve}
                                name='isTVE'
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <CheckBox
                                checked={freePreview}
                                disabled={offerStep < 1}
                                handleChange={onUpdateOffer}
                                label={localization.offers_free_preview}
                                name='isFreePreview'
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <CheckBox
                                checked={isBrandOriginal}
                                disabled={offerStep < 1}
                                handleChange={onUpdateOffer}
                                label={localization.offers_is_brand_original}
                                name='isBrandOriginal'
                            />
                        </Grid>

                        <Grid item sm={12}>
                            <Grid item md={6} xs={12}>
                                <MultiSelectBox
                                    disabled={offerStep < 1}
                                    label={localization.offers_territories}
                                    options={countries}
                                    name='territories'
                                    handleMultipleSelectChange={
                                        onUpdateOffer
                                    }
                                    defaultValue={
                                        territories || defaultCountryOptions
                                    }
                                    localizedPlaceholder={localization.choose}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            sm={12}
                            justifyContent={'flex-end'}
                        >
                            <Grid item style={{ margin: '0 10px 0 0' }}>
                                <Buttons
                                    data-testid='offers-cancel-button'
                                    label={localization.offers_cancel}
                                    onClick={(e) => reset()}
                                    type='button'
                                />
                            </Grid>
                            <Grid item>
                                <Buttons
                                    data-testid='offers-add-button'
                                    disabled={disableUpdate || offerStep < 1}
                                    label={
                                        addingOffer
                                            ? localization.offers_add_offer
                                            : localization.offers_update_offer
                                    }
                                    onClick={(e) => onAddOffer()}
                                    type='button'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <Grid item sm={12} display='flex' justifyContent='flex-end'>
                <Buttons
                    data-testid='offers-create-new-button'
                    disabled={addButtonActive}
                    label={localization.offers_create_new_offer}
                    onClick={(e) => onCreateOffer()}
                    type='button'
                />
            </Grid>
            <Confirm
                content={deleteMessage}
                open={deleteConfirm}
                onYes={handleDeleteOffer}
                onNo={handleDeleteCancel}
            />
        </Grid>
    );
};

export default Offers;