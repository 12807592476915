import { hboImages, maxImages } from '../data';

export const ALL_IMAGES = Object.freeze([...hboImages, ...maxImages]);

// These are used in UseForm for validation instead of
// hardcoding the image, metadata, and override values.
export const HBO_AND_MAX_IMAGE_DATA_FIELDS = (() => {
    let imageDataFields = [];
    ALL_IMAGES.forEach((img) => {
        imageDataFields = [
            ...imageDataFields,
            `${img.name}`,
            `${img.name}_metadata`,
            `${img.name}_override`
        ];
    });
    return Object.freeze(imageDataFields);
})();

export const getImage = (imageName) => {
    const filtered = ALL_IMAGES.filter((image) => image.name === imageName);
    return filtered && filtered.length === 1 ? filtered.shift() : null;
};

export const getImageProperty = (imageName, property) => {
    const filtered = ALL_IMAGES.filter((image) => image.name === imageName);
    const image = filtered && filtered.length === 1 ? filtered.shift() : null;
    return image[property];
};

export const getImages = (tenant) => {
    if (!tenant) {
        throw new Error(
            'hboImageUtils.getImages called with no tenant provided'
        );
    }
    return !tenant.isMax && !tenant.isDomestic
        ? hboImages.map((img) => img.name)
        : [];
};
