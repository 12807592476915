import React, { useEffect } from 'react';
import {
    NOTIFICATION_LEVELS,
    getNotification,
    resetNotification
} from '../reducer/notificationReducer';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

function Notifications() {
    const notification = useSelector(getNotification);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        const { level, body } = notification;
        switch (level) {
            case NOTIFICATION_LEVELS.ERROR:
            case NOTIFICATION_LEVELS.INFO:
            case NOTIFICATION_LEVELS.SUCCESS:
            case NOTIFICATION_LEVELS.WARNING:
                enqueueSnackbar(body, {
                    variant: level,
                    autoHideDuration: 3000,
                    action: (key) => (
                        <IconButton
                            onClick={() => {
                                closeSnackbar(key);
                            }}
                            style={{
                                cursor: 'pointer',
                                color: 'white',
                                backgroundColor: 'black',
                                height: 20,
                                width: 20
                            }}
                        >
                            <Close style={{ height: 15, width: 'auto' }} />
                        </IconButton>
                    )
                });
                // Afterwards, re-set the notification state.
                dispatch(resetNotification());
                break;
            default:
            //Nothing.
        }
    }, [notification]);
    return <span data-testid='notifications' />;
}

export default Notifications;
