// Segments:
import { advanced_search } from './advancedSearch.json';
import { competitors } from './competitors.json';
import { conferences } from './conferences.json';
import { divisions } from './divisions.json';
import { events } from './events.json';
import { forms } from './forms.json';
import { globals } from './globals.json';
import { headers } from './headers.json';
import { leagues } from './leagues.json';
import { main_menu } from './mainMenu.json';
import { series } from './series.json';
import { sports } from './sports.json';
import { tournaments } from './tournaments.json';
import { venues } from './venues.json';
import { calendar } from './calendar.json';
import { offers } from './offers.json';

let instance;

const sections = [
    advanced_search,
    calendar,
    competitors,
    conferences,
    divisions,
    events,
    forms,
    globals,
    headers,
    leagues,
    main_menu,
    offers,
    series,
    sports,
    tournaments,
    venues
];

export const LANGUAGE_OPTIONS = Object.freeze([
    { name: 'English', id: 'en' },
    { name: 'Español', id: 'es' }
]);

export const DEFAULT_LOCALIZATION_COOKIE = Object.freeze({ language: 'en' });

export const LOCALIZATION_COOKIE_NAME = 'let_localization';

export const getCombinedScript = (acts, languages) => {
    const script = {};
    languages.forEach((language) => {
        script[language] = {};
        acts.forEach((act) => {
            script[language] = {
                ...script[language],
                ...act[language]
            };
        });
    });
    return script;
};

export const LET_SCRIPT = Object.freeze(
    getCombinedScript(
        sections,
        LANGUAGE_OPTIONS.map((obj) => obj.id)
    )
);

export const ENGLISH_SCRIPT = {
    ...LET_SCRIPT.en
};

class Localization {
    constructor() {
        if (instance) {
            throw new Error('New Localization instance cannot be created!!');
        }

        instance = this;
    }

    getCurrentLocalizedLanguage() {
        let localizationCookie = DEFAULT_LOCALIZATION_COOKIE;

        const cookie = localStorage.getItem(LOCALIZATION_COOKIE_NAME);

        if (cookie) {
            localizationCookie = JSON.parse(cookie);
        }

        const { language } = localizationCookie;

        return language;
    }

    setSavedLocalizedLanguage = (language) => {
        const cookie = JSON.stringify({
            language: language
        });
        localStorage.setItem(LOCALIZATION_COOKIE_NAME, cookie);
    };
}

let localizationInstance = Object.freeze(new Localization());

export default localizationInstance;
