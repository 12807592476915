import React, { useEffect, useMemo, useState } from 'react';
import { Grid, InputLabel } from '@mui/material';
import { CheckBox } from './Checkbox';

export const Destinations = (props) => {
    const { label, name, id, handleChange, value } = props;
    const [propertyName, setPropertyName] = useState('');
    const [selectedDestinations, setSelectedDestinations] = useState([]);
    const options = [
        {
            value: 'br',
            label: 'BR',
            enabled: true
        },
        {
            value: 'max',
            label: 'Max',
            enabled: false
        }
    ];

    useMemo(() => {
        setPropertyName(name);
    }, [name]);

    useMemo(() => {
        setSelectedDestinations(value);
    }, [value]);

    const onCheckboxToggled = (e) => {
        e.preventDefault();
        const destinationSelected = e.target.name;
        const needsAdding =
            selectedDestinations.indexOf(destinationSelected) === -1;
        const updatedValue = needsAdding
            ? [...selectedDestinations, destinationSelected]
            : selectedDestinations.filter(
                  (destination) => destination !== destinationSelected
              );

        const target = {
            name: propertyName,
            value: updatedValue
        };

        handleChange({
            target
        });
    };

    return (
        <>
            <InputLabel
                data-testid='destinations_title'
                style={{ fontWeight: 600, fontSize: 'large', color: 'black' }}
                shrink
                htmlFor={name}
            >
                {`${label}:`}
            </InputLabel>

            {options.map((option, index) => {
                return (
                    <Grid
                        item
                        sm={12}
                        key={`destination_${index}`}
                        data-testid={`check-${index}`}
                    >
                        <CheckBox
                            checked={value.indexOf(option.value) >= 0}
                            disabled={option.value !== 'br'}
                            name={option.value}
                            handleChange={onCheckboxToggled}
                            label={option.label}
                        />
                    </Grid>
                );
            })}
        </>
    );
};
