export const oxfordComa = (arr)=>{
    if(arr.length === 1){
        return arr[0];
    }else if(arr.length === 2){
        return `${arr[0]} & ${arr[1]}`;
    }else{
        let str = '';
        for(var i = 0; i < arr.length - 1; i++){
            str += `${arr[i]}, `;
        }
        str += `& ${arr[arr.length - 1]}`;
        return str;
    }
}