import React from 'react'
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme)=>({
  styling: {
    fontWeight: 700,
    margin: '3rem 0 1rem 0'
  }
}));

export const Heading = (props) => {
  const classes = useStyles();

  return (
    <Typography className={classes.styling} align="center" variant="h4">{props.heading}</Typography>
  )
}
