import React, {
    forwardRef,
    useEffect,
    useState,
    useImperativeHandle,
    useMemo
} from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import services from '../services/apiService';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridOverlay
} from '@mui/x-data-grid';
import {
    TextField,
    LinearProgress,
    Container,
    Chip,
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Tooltip,
    Typography,
    CircularProgress
} from '@mui/material';

import { Done, Clear, Bookmark } from '@mui/icons-material';

import { TableToolBar } from './shared/TableToolBar';
import { utils } from '../utils/utils';
import debounce from 'lodash.debounce';
import { loadState, saveState } from './../utils/utils';
import {
    setSearchParams,
    advancedSearchParams
} from '../reducer/advancedSearchReducer';
import { selectTenant } from '../reducer/tenantSlice';
import constants from '../constants';
// Notifications:
import { notifySuccess, notifyError } from '../reducer/notificationReducer';
import { strings, dataGridLocaleText } from '../reducer/localizationSlice';

const FILTER_TYPES = {
    MONGO_ID: 'mongo id',
    INTEGER: 'integer',
    STRING: 'string'
};

const getFilterType = (queryString) => {
    // regex to check for 12-byte hex value
    const mongoIdPattern = /^[a-f\d]{24}$/i;
    // regex to check for integer
    const integerPattern = /^\d+$/;

    return mongoIdPattern.test(queryString)
        ? FILTER_TYPES.MONGO_ID
        : integerPattern.test(queryString)
        ? FILTER_TYPES.INTEGER
        : FILTER_TYPES.STRING;
};

const keywordTypeState = {};

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div
                style={{ position: 'absolute', top: 0, width: '100%' }}
                data-testid='data-loading'
            >
                <LinearProgress color='error' />
            </div>
        </GridOverlay>
    );
}

const CustomToolbar = ({ classes, onChange, filter }) => {
    const localization = useSelector(strings);
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <div className={classes.simpleSearch}>
                <TextField
                    id='outlined-search'
                    label={localization.listing_search_field}
                    type='search'
                    variant='outlined'
                    inputProps={{ margin: '20px' }}
                    size='small'
                    onChange={onChange}
                    defaultValue={filter}
                />
            </div>
        </GridToolbarContainer>
    );
};

const useStyles = makeStyles({
    root: {
        '& .super-app-theme-id-cell a': {
            color: 'red',
            fontWeight: 'bold',
            textDecoration: 'none'
        },
        '& .MuiDataGrid-root': {
            border: 0
        },
        '& .MuiInputBase-root': {
            width: 'auto'
        },
        '& .MuiDataGrid-root .MuiDataGrid-toolbar': {
            justifyContent: 'space-between'
        },
        '& .MuiDataGrid-main': {
            borderTop: '2px solid black'
        },
        '& .MuiDataGrid-columnsContainer': {
            borderBottom: '2px solid black'
        },
        '& .MuiDataGrid-root .MuiDataGrid-cellLeft': {
            textAlign: 'center'
        },
        '& .MuiButton-textPrimary': {
            color: 'black',
            background: 'none'
        }
    },
    simpleSearch: {
        margin: '30px'
    },
    card: {
        marginTop: '10px',
        width: '50%',

        '& .MuiChip-root': {
            marginRight: '10px',
            marginBottom: '10px'
        }
    },
    loader: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        minHeight: '500px',
        width: '100%'
    }
});

const FilterInfo = (props) => {
    const { field, value } = props;
    let displayValue = value;
    if (value === 'true') displayValue = props.yes;
    else if (value === 'false') displayValue = props.no;
    return field !== 'tenant' && displayValue ? (
        <Chip
            size='small'
            label={`${utils.renameFromKey(field)}: ${displayValue}`}
            icon={<Done />}
        />
    ) : (
        ''
    );
};

const DataList = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchedParams = useSelector(advancedSearchParams);
    const tenant = useSelector(selectTenant);

    const classes = useStyles();
    let {
        onSearch,
        open,
        handleExport,
        tableHeaders,
        createNew,
        endPoint,
        dataObject,
        defaultFilter
    } = props;

    const [rowCount, setRowCount] = useState(0);
    const key = 'paging_' + endPoint;
    const [rowsState, setRowsState] = useState(
        loadState(key) || {
            page: 0,
            pageSize: 10,
            rows: [],
            loading: false,
            sortModel: []
        }
    );
    const [showResults, setShowResults] = useState(false);

    const defaultSort = [];

    const [filter, setFilter] = useState();

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    const onChangeFilter = (event) => {
        const value = event.target.value;
        const typeFilter = getFilterType(value);
        if (typeFilter === FILTER_TYPES.STRING) {
            let filters = Object.keys(searchedParams).includes(endPoint)
                ? { ...searchedParams[endPoint] }
                : {};
            dispatch(
                setSearchParams({
                    params: { ...filters, name: value },
                    route: endPoint
                })
            );
        }
        setFilter(value);
    };

    useImperativeHandle(ref, () => ({
        reload() {
            fetchData(filter, searchedParams, rowsState);
        }
    }));

    const changeRowsInfo = (newStateFunction) => {
        setRowsState(newStateFunction);
        saveState(key, rowsState);
    };

    const path = location.pathname.split('/').filter((el) => !!el)[0];

    const getConfigColumnFromLocalStorage = () => {
        let columnVisable = localStorage.getItem(
            constants.COLUMN_VISIBILITY_KEY
        );
        if (columnVisable) columnVisable = JSON.parse(columnVisable) || {};
        return columnVisable || {};
    };

    const setConfigColumnToLocalStorage = (data) => {
        let columnVisable = getConfigColumnFromLocalStorage();
        columnVisable[path] = data;
        localStorage.setItem(
            constants.COLUMN_VISIBILITY_KEY,
            JSON.stringify(columnVisable)
        );
    };

    const fetchData = async (filter, searchedParams, rowsState) => {
        let filters = Object.keys(searchedParams).includes(endPoint)
            ? { ...searchedParams[endPoint] }
            : {};

        let requestType = keywordTypeState[filter];
        if (filter) {
            if (!requestType) {
                const typeFilter = getFilterType(filter);
                if (typeFilter === FILTER_TYPES.MONGO_ID) {
                    requestType = 'BY_ID';
                    keywordTypeState[filter] = requestType;
                } else if (typeFilter === FILTER_TYPES.STRING) {
                    filters.name = filter;
                    keywordTypeState[filter] = 'NAME';
                } else {
                    requestType = 'NAME_OR_LEGACY';
                    // will determin by API
                    // requestType = "NAME"
                    // requestType = "LEGACY"
                }
            }
        }
        changeRowsInfo((prev) => ({ ...prev, loading: true }));

        switch (requestType) {
            case 'BY_ID':
                let rows = [];
                const res = await services.getItem(endPoint, filter, true);
                const item = { ...res, ...res?.let_override };
                delete item.let_override;
                if (item.id) {
                    setRowCount(1);
                    rows = [dataObject(item)];
                } else {
                    setRowCount(0);
                }
                changeRowsInfo((prev) => ({
                    ...prev,
                    loading: false,
                    rows
                }));
                break;

            case 'NAME_OR_LEGACY':
            // Idea: first search by `name`, and then by `legacy_id`
            default:
                // NAME or undefined..
                const sortModel =
                    rowsState.sortModel.length == 0
                        ? defaultSort
                        : rowsState.sortModel;
                const query = utils.getSortFilters(sortModel);
                if (requestType === 'LEGACY') {
                    filters.legacy_id = filter;
                } else {
                    if (filter || filter === '') filters.name = filter;
                }
                const advancedQuery = utils.getAdvancedFilters(filters);
                const rangeQuery = `brlive=${
                    rowsState.page * rowsState.pageSize
                }-${(rowsState.page + 1) * rowsState.pageSize - 1}`;

                const url = `${endPoint}?${
                    defaultFilter || ''
                }${advancedQuery}${query}`;
                services
                    .fetchData(url, rangeQuery)
                    .then((res) => {
                        const { headers, data } = res;
                        const rowCount = utils.getContentLength(headers);

                        setRowCount(rowCount);
                        let renderData = [];
                        if (requestType === 'NAME_OR_LEGACY') {
                            if (data.length) {
                                keywordTypeState[filter] = 'NAME';
                                data.forEach((item) =>
                                    renderData.push(dataObject(item))
                                );
                                changeRowsInfo((prev) => ({
                                    ...prev,
                                    loading: false,
                                    rows: renderData
                                }));
                            } else {
                                filters = Object.keys(searchedParams).includes(
                                    endPoint
                                )
                                    ? { ...searchedParams[endPoint] }
                                    : {};
                                filters.legacy_id = filter;
                                const advancedQuery =
                                    utils.getAdvancedFilters(filters);
                                const url = `${endPoint}?${
                                    defaultFilter || ''
                                }${advancedQuery}${query}`;

                                services
                                    .fetchData(url, rangeQuery)
                                    .then((res1) => {
                                        if (res1.data.length)
                                            keywordTypeState[filter] = 'LEGACY';
                                        const rowCount = utils.getContentLength(
                                            res1.headers
                                        );
                                        setRowCount(rowCount);
                                        res1.data.forEach((item) =>
                                            renderData.push(dataObject(item))
                                        );
                                        changeRowsInfo((prev) => ({
                                            ...prev,
                                            loading: false,
                                            rows: renderData
                                        }));
                                    });
                            }
                        } else {
                            data.forEach((item) =>
                                renderData.push(dataObject(item))
                            );
                            changeRowsInfo((prev) => ({
                                ...prev,
                                loading: false,
                                rows: renderData
                            }));
                        }
                        setShowResults(true);
                    })
                    .catch((error) => {
                        dispatch(
                            notifyError(
                                error.name && error.message
                                    ? `${error.name}:  ${error.message}`
                                    : error
                            )
                        );
                    });
                break;
        }
    };

    const debounceFetch = React.useRef(
        debounce(async (filter, searchedParams, rowsState) => {
            await fetchData(filter, searchedParams, rowsState);
        }, 300)
    ).current;

    const handleColumnVisibilityModelChange = (e) => {
        setColumnVisibilityModel(e);
        setConfigColumnToLocalStorage(e);
    };
    useEffect(() => {
        const savedConfig = getConfigColumnFromLocalStorage();
        setColumnVisibilityModel(savedConfig[path] || {});
        return () => {
            setShowResults(false);
            debounceFetch.cancel();
        };
    }, []);

    useEffect(() => {
        debounceFetch(filter, searchedParams, rowsState);
    }, [
        rowsState.page,
        rowsState.pageSize,
        rowsState.sortModel,
        filter,
        searchedParams
    ]);

    const clearAdvancedSearchParams = () => {
        dispatch(
            setSearchParams({
                params: {},
                route: endPoint
            })
        );
    };

    const copyUrl = () => {
        const { pathname } = location;
        const params = searchedParams[endPoint] || {};
        const advancedFilterUrl = utils.getAdvancedFilters(params);
        navigator.clipboard.writeText(
            `${utils.getHostUrl()}${pathname}?${advancedFilterUrl}tenant=${
                tenant?.tenantName
            }`
        );
        dispatch(notifySuccess('Url Copied!'));
    };

    const hasSearchParams = useMemo(() => {
        const params = searchedParams[endPoint] || {};
        return Object.values(params).filter((vv) => !!vv).length > 0;
    }, [searchedParams[endPoint]]);

    const localization = useSelector(strings);
    const localeText = useSelector(dataGridLocaleText);

    return (
        <Container maxWidth={false}>
            <TableToolBar
                onSearch={onSearch}
                handleExport={handleExport}
                open={open}
                createNew={createNew}
            />
            {hasSearchParams && (
                <div className={classes.card}>
                    <Card>
                        <CardHeader
                            title={localization.advanced_search_title}
                            action={
                                <>
                                    <Tooltip
                                        title='Copy url'
                                        aria-label='copy-url'
                                    >
                                        <IconButton
                                            aria-label='copy-url'
                                            onClick={copyUrl}
                                        >
                                            <Bookmark />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Close' aria-label='close'>
                                        <IconButton
                                            aria-label='clear'
                                            onClick={clearAdvancedSearchParams}
                                        >
                                            <Clear />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                        />
                        <CardContent>
                            {Object.keys(searchedParams[endPoint]).map(
                                (key) => (
                                    <FilterInfo
                                        key={`filter-${key}`}
                                        field={key}
                                        no={localization.no}
                                        value={searchedParams[endPoint][key]}
                                        yes={localization.yes}
                                    />
                                )
                            )}
                        </CardContent>
                    </Card>
                </div>
            )}
            <div style={{ width: '100%' }} className={classes.root}>
                {showResults && (
                    <DataGrid
                        className='MuiDataGrid-cellCenter'
                        autoHeight
                        columns={tableHeaders}
                        components={{
                            Toolbar: CustomToolbar,
                            LoadingOverlay: CustomLoadingOverlay,
                            NoRowsOverlay: () => (
                                <Typography
                                    align='center'
                                    style={{ marginTop: '20px' }}
                                >
                                    Not Found
                                </Typography>
                            )
                        }}
                        disableColumnMenu={true}
                        disableSelectionOnClick
                        pagination
                        paginationMode='server'
                        rowCount={rowCount}
                        rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
                        sortingMode='server'
                        columnVisibilityModel={columnVisibilityModel}
                        localeText={localeText}
                        onPageChange={(page) =>
                            changeRowsInfo((prev) => ({ ...prev, page }))
                        }
                        onPageSizeChange={(pageSize) =>
                            changeRowsInfo((prev) => ({ ...prev, pageSize }))
                        }
                        onSortModelChange={(sortModel) =>
                            changeRowsInfo((prev) => ({ ...prev, sortModel }))
                        }
                        onColumnVisibilityModelChange={(e) =>
                            handleColumnVisibilityModelChange(e)
                        }
                        {...rowsState}
                        disableVirtualization
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage:
                                    localization.listing_rows_per_page,
                                labelDisplayedRows: ({ from, to, count }) =>
                                    `${from}–${to} ${localization.listing_of} ${
                                        count !== -1
                                            ? count
                                            : `${localization.listing_more_than} ${to}`
                                    }`
                            },
                            toolbar: {
                                classes,
                                onChange: onChangeFilter,
                                filter
                            }
                        }}
                    />
                )}

                {!showResults && (
                    <div className={classes.loader}>
                        <CircularProgress color='inherit' />
                    </div>
                )}
            </div>
        </Container>
    );
});

export default DataList;
