import React, { useEffect } from 'react';
import { Container, Grid, Box } from '@mui/material';
import { ArrowForwardOutlined, RemoveOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';

import { Input } from '../../shared/Input';
import { Buttons } from '../../shared/Buttons';
import { Loader } from '../../shared/Loader';
import { useForm } from '../../hooks/UseForm';
import { useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        }
    }
}));

export const Info = ({
    id,
    venues,
    setRefresh,
    venueSchema,
    handleUpdate,
    handleForward,
    showForWriteAccessUsers = true
}) => {
    const classes = useStyles();
    let nextPage = `/venues/${id}/appearance`;
    const requiredFields = venueSchema.required;

    const localization = useSelector(strings);

    const {
        item,
        showToggle,
        enableToggle,
        isInProgress,
        valueChanged,
        error,
        handleChange,
        handleToggleChange,
        onFormSubmit,
        form
    } = useForm({
        service: 'venues',
        initialState: venues,
        id,
        requiredFields,
        setRefresh
    });

    useEffect(() => {
        handleUpdate(valueChanged);
    }, [valueChanged]);

    const {
        name,
        address,
        capacity,
        city,
        state,
        country,
        zip,
        time_zone,
        architect,
        sportradar_id,
        metadata,

        let_override: {
            name: override_name,
            address: override_address,
            capacity: override_capacity,
            city: override_city,
            state: override_state,
            country: override_country,
            zip: override_zip,
            time_zone: override_time_zone,
            architect: override_architect,
            sportradar_id: override_sportradar_id,
            metadata: override_metadata
        } = {}
    } = item;

    return (
        <div className={classes.root}>
            <Container maxWidth='lg'>
                <Loader isInProgress={isInProgress} />
                <form noValidate autoComplete='on' onSubmit={onFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Input
                                label={`${localization.venue_name}:`}
                                name='name'
                                showToggle={showToggle}
                                value={override_name || name || form.name || ''}
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={(override_name && name) || ''}
                                handleToggleChange={handleToggleChange}
                                error={error.name}
                                showCopy={true}
                                required={requiredFields.includes('name')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.venue_address}:`}
                                name='address'
                                showToggle={showToggle}
                                value={
                                    override_address ||
                                    address ||
                                    form.address ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    (override_address && address) || ''
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('address')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.venue_capacity}:`}
                                type='number'
                                showToggle={showToggle}
                                name='capacity'
                                value={
                                    override_capacity ||
                                    capacity ||
                                    form.capacity ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    (override_capacity && capacity) || ''
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('number')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.venue_city}:`}
                                name='city'
                                showToggle={showToggle}
                                value={override_city || city || form.city || ''}
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={(override_city && city) || ''}
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('city')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.venue_state}:`}
                                name='state'
                                showToggle={showToggle}
                                value={
                                    override_state || state || form.state || ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={(override_state && state) || ''}
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('state')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.venue_country}:`}
                                name='country'
                                showToggle={showToggle}
                                value={
                                    override_country ||
                                    country ||
                                    form.country ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    (override_country && country) || ''
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('country')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.headers_zip_code}:`}
                                name='zip'
                                showToggle={showToggle}
                                value={override_zip || zip || form.zip || ''}
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={(override_zip && zip) || ''}
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('zip')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.event_info_time_zone}:`}
                                name='time_zone'
                                showToggle={showToggle}
                                value={
                                    override_time_zone ||
                                    time_zone ||
                                    form.time_zone ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    (override_time_zone && time_zone) || ''
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('time_zone')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.venue_architect}:`}
                                name='architect'
                                showToggle={showToggle}
                                value={
                                    override_architect ||
                                    architect ||
                                    form.architect ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    (override_architect && architect) || ''
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('architect')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.sportradar_id}:`}
                                name='sportradar_id'
                                showToggle={showToggle}
                                value={
                                    override_sportradar_id ||
                                    sportradar_id ||
                                    form.sportradar_id ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    (override_sportradar_id && sportradar_id) ||
                                    ''
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes(
                                    'sportradar_id'
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                label={`${localization.venue_metadata}:`}
                                name='metadata'
                                showToggle={showToggle}
                                value={
                                    override_metadata ||
                                    metadata ||
                                    form.metadata ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    (override_metadata && metadata) || ''
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('metadata')}
                            />
                        </Grid>
                    </Grid>

                    <Box
                        display='flex'
                        justifyContent='flex-end'
                        gap={3}
                        mt={2}
                    >
                        <Buttons
                            type='submit'
                            label={localization.save}
                            showForWriteAccessUsers={showForWriteAccessUsers}
                        />
                        {id !== 'new' && (
                            <IconButton
                                aria-label='next-page'
                                style={{ color: 'black' }}
                                onClick={(e) => handleForward(e, nextPage)}
                            >
                                <RemoveOutlined
                                    fontSize='large'
                                    color='primary'
                                    style={{ marginRight: '-16px' }}
                                />
                                <ArrowForwardOutlined
                                    fontSize='large'
                                    color='primary'
                                />
                            </IconButton>
                        )}
                    </Box>
                </form>
            </Container>
        </div>
    );
};
