import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Check, Close } from '@mui/icons-material';
import services from '../../services/apiService';
// Notifications:
import { notifyError, notifySuccess } from '../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialog-paper': {
            width: '30%',
            maxHeight: 300,

            '& .MuiButton-text': {
                padding: 0
            }
        },
        '& .MuiSvgIcon-root': {
            paddingRight: '0.5rem',
            fontSize: '14px'
        }
    }
}));

export const Confirm = (props) => {
    const l10n = useSelector(strings);

    const dispatch = useDispatch();

    const {
        onClose,
        open,
        path,
        title,
        content,
        isDelete,
        onYes,
        onNo,
        ...other
    } = props;

    const classes = useStyles();

    const handleNo = () => {
        onClose ? onClose() : onNo ? onNo() : '';
    };

    const handleYes = () => {
        if (!isDelete) {
            onYes();
        } else {
            isDelete &&
                services
                    .deleteItem(path)
                    .then((res) => {
                        dispatch(
                            notifySuccess(`The event item has been deleted!`)
                        );
                    })
                    .catch((error) => {
                        dispatch(
                            notifyError(`${error.name}:  ${error.message}`)
                        );
                    })
                    .finally(() => {
                        onClose(true);
                    });
        }
    };

    return (
        <Dialog className={classes.root} maxWidth='xs' open={open} {...other}>
            <DialogTitle>{title || 'Warning'}</DialogTitle>
            <DialogContent dividers>
                {content ||
                    'You have unsaved changes. If you leave, your changes will be lost. Do you want to continue?'}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleYes} data-testid='confirm-yes'>
                    <Check />
                    {l10n.yes}
                </Button>
                <Button autoFocus onClick={handleNo} data-testid='confirm-no'>
                    <Close />
                    {l10n.no}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
