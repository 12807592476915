import React from 'react';
import { Grid } from '@mui/material';
import { Input } from '../../../shared/Input';
import { Textarea } from '../../../shared/Textarea';
import MultiLanguageContainer from '../MultiLanguageContainer';
import MultiLanguageTextInput from '../MultiLanguageTextInput';
import MultiLanguageTextAreaInput from '../MultiLanguageTextAreaInput';
import SecondaryTitle from '../SecondaryTitle';
import { useSelector } from 'react-redux';
import { getIsMultiLanguage, getLanguages } from '../../../../reducer/multilanguageSlice';

const getMaxMultiLanguageInputs = (props) => {
    const {
        description_body,
        description_body_ml,
        enableToggle,
        error,
        event,
        form,
        handleChange,
        handleToggleChange,
        id,
        localization,
        name,
        name_ml,
        override_description_body,
        override_description_body_ml,
        override_name,
        override_name_ml,
        override_secondary_title,
        override_secondary_title_ml,
        requiredFields,
        secondary_title,
        secondary_title_ml,
        showToggle,
        tenant
    } = props;

    const isMultiLanguage = useSelector(getIsMultiLanguage);
    const languages = useSelector(getLanguages);

    let inputs = <></>;

    if (id !== 'new' && !event.hasOwnProperty('let_override')) {
        return inputs;
    }

    if (tenant.isMax) {
        if (isMultiLanguage) {
            const getDefault = () => {
                const placeholder = {};
                (languages).map((language) => {
                    const isoCode = language?.isoCode;
                    if(isoCode){
                        placeholder[isoCode] = '';
                    }else{
                        console.error(`Unreadable ISO Code (${JSON.stringify(language)})`);                    
                    }                    
                });
                return placeholder;
            };

            inputs = (
                <MultiLanguageContainer
                    l10n={localization}
                    languagesSupported={languages}
                >
                    <MultiLanguageTextInput
                        handleChange={handleChange}
                        label={localization.event_info_event_name}
                        name={'name_ml'}
                        originalValue={name_ml || form.name_ml || {}}
                        overrideValue={override_name_ml || name_ml || getDefault()}
                        simpleTitle={true}
                    />
                    <MultiLanguageTextInput
                        handleChange={handleChange}
                        label={localization.secondary_title}
                        name={'secondary_title_ml'}
                        originalValue={
                            secondary_title_ml || form.secondary_title_ml || {}
                        }
                        overrideValue={
                            override_secondary_title_ml || getDefault()
                        }
                        simpleTitle={true}
                    />
                    <MultiLanguageTextAreaInput
                        handleChange={handleChange}
                        label={localization.description}
                        name={'description_body_ml'}
                        originalValue={
                            description_body_ml ||
                            form.description_body_ml ||
                            {}
                        }
                        overrideValue={
                            override_description_body_ml || getDefault()
                        }
                        simpleTitle={true}
                    />
                </MultiLanguageContainer>
            );
        } else {
            inputs = (
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Input
                            label={`${localization.event_info_event_name}:`}
                            name='name'
                            value={override_name || name || form?.name || ''}
                            handleChange={handleChange}
                            showToggle={showToggle}
                            enableToggle={enableToggle}
                            originalValue={name || ''}
                            handleToggleChange={handleToggleChange}
                            error={error?.name}
                            showCopy={true}
                            required={requiredFields.includes('name')}
                        />
                    </Grid>
                    <SecondaryTitle
                        inputTitle={localization.secondary_title}
                        value={
                            override_secondary_title ||
                            secondary_title ||
                            form?.secondary_title ||
                            ''
                        }
                        handleChange={handleChange}
                        name="secondary_title"
                        originalValue={secondary_title || ''}
                    />
                    <Grid item xs={12}>
                        <Textarea
                            label={`${localization.description}:`}
                            name='description_body'
                            defaultValue={
                                override_description_body ||
                                description_body ||
                                form?.description_body ||
                                ''
                            }
                            originalValue={description_body}
                            handleChange={handleChange}
                            required={requiredFields.includes(
                                'description_body'
                            )}
                            showCopy={true}
                            showToggle={false}
                            enableToggle={false}
                        />
                    </Grid>
                </Grid>
            );
        }
    }
    return inputs;
};

export default getMaxMultiLanguageInputs;
