import React, { useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ArrowBackOutlined, RemoveOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { UploadPreview } from '../../shared/UploadPreview';
import { Buttons } from '../../shared/Buttons';
import { Loader } from '../../shared/Loader';
import { useForm } from '../../hooks/UseForm';
import { useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        }
    }
}));

export const Appearance = ({
    id,
    sport,
    setRefresh,
    sportSchema,
    config,
    handleUpdate,
    handleForward
}) => {
    const classes = useStyles();
    const prevPage = `/sports/${id}/sport-info`;
    const requiredFields = sportSchema.required;

    const localization = useSelector(strings);

    const {
        item,
        isInProgress,
        valueChanged,
        handleUploadChange,
        onFormSubmit
    } = useForm({
        service: 'sports',
        initialState: sport,
        id: id,
        requiredFields,
        setRefresh: setRefresh
    });

    useEffect(() => {
        handleUpdate(valueChanged);
    }, [valueChanged]);

    const {
        icon_light,
        icon_dark,
        image,
        name,
        let_override: {
            icon_light: override_icon_light,
            icon_dark: override_icon_dark,
            image: override_image,
            name: override_name
        } = {}
    } = item;

    const bucket = name || override_name;

    return (
        <div className={classes.root}>
            <Container maxWidth='lg'>
                <Loader isInProgress={isInProgress} />
                <form noValidate autoComplete='off' onSubmit={onFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <UploadPreview
                                label={`${localization.icon_light}:`}
                                name='icon_light'
                                bucket={bucket}
                                enableDelete={true}
                                item='sport_icon_light'
                                type='image'
                                config={config}
                                fileName={override_icon_light || icon_light}
                                handleChange={handleUploadChange}
                                required={requiredFields.includes('icon_light')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <UploadPreview
                                label={`${localization.icon_dark}:`}
                                name='icon_dark'
                                bucket={bucket}
                                enableDelete={true}
                                item='sport_icon_dark'
                                type='image'
                                config={config}
                                fileName={override_icon_dark || icon_dark}
                                handleChange={handleUploadChange}
                                required={requiredFields.includes('icon_dark')}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <UploadPreview
                                label={`${localization.sport_image}:`}
                                name='image'
                                bucket={bucket}
                                enableDelete={true}
                                item='sport_image'
                                type='image'
                                config={config}
                                fileName={override_image || image}
                                handleChange={handleUploadChange}
                                required={requiredFields.includes('image')}
                            />
                        </Grid>

                        <Grid container item xs={12}>
                            <Grid item xs={6}>
                                <IconButton
                                    aria-label='delete'
                                    style={{ color: 'black' }}
                                    onClick={(e) => handleForward(e, prevPage)}
                                >
                                    <ArrowBackOutlined fontSize='large' />
                                    <RemoveOutlined
                                        fontSize='large'
                                        style={{ marginLeft: '-16px' }}
                                    />
                                </IconButton>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={6}
                                justifyContent='flex-end'
                            >
                                <Buttons
                                    type='submit'
                                    label={localization.save}
                                    showForWriteAccessUsers
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};
