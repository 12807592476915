import React, { useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { RemoveOutlined, ArrowBackOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { UploadPreview } from '../../shared/UploadPreview';
import { Buttons } from '../../shared/Buttons';
import { Loader } from '../../shared/Loader';
import { useForm } from '../../hooks/UseForm';
import { useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        }
    }
}));

export const Appearance = ({
    id,
    tournament,
    setRefresh,
    tournamentSchema,
    config,
    handleUpdate,
    handleForward
}) => {
    const classes = useStyles();
    let prevPage = `/tournaments/${id}/tournament-info`;

    const localization = useSelector(strings);

    const {
        item,
        isInProgress,
        handleUploadChange,
        valueChanged,
        onFormSubmit
    } = useForm({
        service: 'tournaments',
        initialState: tournament,
        id: id,
        requiredFields: tournamentSchema.required,
        setRefresh: setRefresh
    });

    useEffect(() => {
        handleUpdate(valueChanged);
    }, [valueChanged]);

    const {
        logo_light,
        logo_dark,
        background_image,
        name,
        logo_split,
        let_override: {
            logo_light: override_logo_light,
            logo_dark: override_logo_dark,
            logo_split: override_logo_split,
            background_image: override_background_image,
            name: override_name
        } = {}
    } = item;

    const bucket = 'tournaments';

    return (
        <div className={classes.root}>
            <Container maxWidth='lg'>
                <Loader isInProgress={isInProgress} />
                <form noValidate autoComplete='off' onSubmit={onFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <UploadPreview
                                label={`${localization.icon_light}:`}
                                name='logo_light'
                                bucket={bucket}
                                enableDelete={true}
                                item='tournament_logo_light'
                                type='image'
                                config={config}
                                fileName={override_logo_light || logo_light}
                                handleChange={handleUploadChange}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <UploadPreview
                                label={`${localization.icon_dark}:`}
                                name='logo_dark'
                                bucket={bucket}
                                enableDelete={true}
                                item='tournament_logo_dark'
                                type='image'
                                config={config}
                                fileName={override_logo_dark || logo_dark}
                                handleChange={handleUploadChange}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <UploadPreview
                                label={`${localization.split_logo}:`}
                                name='logo_split'
                                bucket={bucket}
                                enableDelete={true}
                                item='tournament_logo_split'
                                type='image'
                                config={config}
                                fileName={override_logo_split || logo_split}
                                handleChange={handleUploadChange}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <UploadPreview
                                label={`${localization.background_image}:`}
                                name='background_image'
                                bucket={bucket}
                                enableDelete={true}
                                item='tournament_image'
                                type='image'
                                config={config}
                                fileName={
                                    override_background_image ||
                                    background_image
                                }
                                handleChange={handleUploadChange}
                            />
                        </Grid>
                        <Grid container>
                            <Grid item xs={6}>
                                <IconButton
                                    aria-label='delete'
                                    style={{ color: 'black' }}
                                    onClick={(e) => handleForward(e, prevPage)}
                                >
                                    <ArrowBackOutlined fontSize='large' />
                                    <RemoveOutlined
                                        fontSize='large'
                                        style={{ marginLeft: '-16px' }}
                                    />
                                </IconButton>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={6}
                                justifyContent='flex-end'
                            >
                                <Buttons
                                    type='submit'
                                    label={localization.save}
                                    showForWriteAccessUsers
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};
