import React from 'react';
import { LANGUAGE_NAMES } from '../../constants';
import { Buttons } from './Buttons';
import { Textarea } from './Textarea';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { strings } from '../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    button_container: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: '0 0 0 10px'
    },
    component_container: {
        alignContent: 'flex-end',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '0 0 20px 0',
        width: '100%'
    },
    input_container: {
        width: '100%'
    },
    error: {
        color: 'red',
        fontWeight: 'bold'
    },
    error_container: {
        border: '2px solid red',
        padding: '10px'
    }
}));

export const MultilanguageTextarea = (props) => {
    let {
        label,
        value,
        handleChange,
        name,
        languages,
        type,
        showToggle,
        enableToggle,
        originalValue,
        handleToggleChange,
        error,
        showCopy,
        onKeyDown,
        required
    } = props;

    const l10n = useSelector(strings);
    const canRender = Array.isArray(languages);
    const classes = useStyles();
    const keyReplace = (str, thisKey, withThatString) =>
        str.replace(thisKey, withThatString);
    const localHandleChange = (e) => {
        const elemId = e.target.name;
        const lang = elemId.substring(elemId.lastIndexOf('_') + 1) || null;
        if (lang) {
            const event = {
                target: {
                    name,
                    value: {
                        ...value,
                        [lang]: e.target.value
                    }
                }
            };
            handleChange(event);
        }
    };
    const resetValue = (language) => {
        const mockEvent = {
            target: {
                name,
                value: {
                    ...value,
                    [language]: originalValue[language]
                }
            }
        };
        handleChange(mockEvent);
    };

    return (
        <>
            {canRender &&
                languages.map((language, index) => {
                    const localLabel = keyReplace(
                        label,
                        '%LANG%',
                        LANGUAGE_NAMES[language]
                    );
                    const localName = `${name}_${language}`;
                    const localOriginalValue =
                        (originalValue && originalValue[language]) || '';
                    const localValue = value[language];
                    const showRevert =
                        localValue !== localOriginalValue && localOriginalValue;

                    return (
                        <Grid item xs={12} key={`${name}_${language}`}>
                            <div className={classes.component_container}>
                                <div className={classes.input_container}>
                                    <Textarea
                                        handleChange={localHandleChange}
                                        label={localLabel}
                                        name={localName}
                                        originalValue={localOriginalValue}
                                        required={required}
                                        showCopy={showCopy}
                                        showToggle={showToggle}
                                        enableToggle={enableToggle}
                                        defaultValue={localValue}
                                    />
                                </div>
                                {showRevert && (
                                    <div className={classes.button_container}>
                                        {' '}
                                        <Buttons
                                            title={`${l10n.reset_to} "${
                                                originalValue[language] || ''
                                            }"`}
                                            data-test={`reset-${language}`}
                                            name={`reset-${language}`}
                                            label={l10n.reset}
                                            onClick={() => {
                                                resetValue(language);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </Grid>
                    );
                })}
            {!canRender && (
                <Grid item xs={12}>
                    <div className={classes.error_container}>
                        <span className={classes.error}>Error:</span> No
                        languages provided for the <strong>{name}</strong>{' '}
                        multi-language component. Please provide an array of the
                        supported language(s).
                    </div>
                </Grid>
            )}
        </>
    );
};
