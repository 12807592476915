import { createSlice } from '@reduxjs/toolkit';

export const NOTIFICATION_LEVELS = Object.freeze({
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning'
});

export const notificationReducer = createSlice({
    name: 'notification',
    initialState: {
        level: null,
        body: null
    },
    reducers: {
        resetNotification: (state) => {
            state.level = state.body = null;
        },
        notifyError: (state, action) => {
            (state.level = NOTIFICATION_LEVELS.ERROR),
                (state.body = action.payload);
        },
        notifyInfo: (state, action) => {
            (state.level = NOTIFICATION_LEVELS.INFO),
                (state.body = action.payload);
        },
        notifySuccess: (state, action) => {
            (state.level = NOTIFICATION_LEVELS.SUCCESS),
                (state.body = action.payload);
        },
        notifyWarning: (state, action) => {
            (state.level = NOTIFICATION_LEVELS.WARNING),
                (state.body = action.payload);
        },
        setNotification: (state, action) => {
            const { level, body } = action.payload;
            state.level = level;
            state.body = body;
        }
    }
});

export const {
    notifyError,
    notifyInfo,
    notifySuccess,
    notifyWarning,
    resetNotification,
    setNotification
} = notificationReducer.actions;
export const getNotification = (state) => state.notification;
export default notificationReducer.reducer;
