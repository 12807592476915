import React from 'react';
import { Redirect } from 'react-router-dom';

const getRedirect = (path) => <Redirect to={path} replace={true} />;

export const InfoRedirect = () => {
    const path = window.location.pathname;
    const parts = path.split('/');
    const segment = parts[1];
    let info = null;

    switch (segment) {
        case 'competitors':
            info = 'competitor-info';
            break;
        case 'conferences':
            info = 'conference-info';
            break;
        case 'divisions':
            info = 'division-info';
            break;
        case 'events':
            info = 'event-info';
            break;
        case 'leagues':
            info = 'league-info';
            break;
        case 'series':
            info = 'series-info';
            break;
        case 'sports':
            info = 'sport-info';
            break;
        case 'tournaments':
            info = 'tournament-info';
            break;
        case 'venues':
            info = 'venue-info';
            break;
    }

    const finalPath = path.indexOf(info) >= 0 ? path : `${path}/${info}`;

    return <Redirect to={finalPath} replace={true} />;
};
