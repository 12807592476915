import { createSlice } from '@reduxjs/toolkit';
import { LANGUAGE_OPTIONS, LET_SCRIPT } from '../l10n/localization';
import LocalizedStrings from 'react-localization';
// Themes:
import { enUS, esES } from '@mui/material/locale';
import { enUS as dataGridUS, esES as dataGridES } from '@mui/x-data-grid';
import { enUS as pickerUS, esES as pickerES } from '@mui/x-date-pickers';

import { createTheme } from '@mui/material';

export const INITIAL_STATE = Object.freeze({
    dataGridLocaleText: {},
    datePickerLocaleText: {},
    strings: new LocalizedStrings(LET_SCRIPT),
    language: '',
    languageName: '',
    languageOptions: LANGUAGE_OPTIONS,
    localeText: null,
    theme: null
});

export const getLanguageName = (languageId) => {
    let name;
    LANGUAGE_OPTIONS.map((lang) => {
        if (lang.id === languageId) name = lang.name;
    });
    return name;
};

export const getDatePickerLocaleText = (theme) => {
    const localeText =
        theme?.pickerLocale?.components?.MuiLocalizationProvider?.defaultProps
            ?.localeText;

    if (localeText) {
        return localeText;
    } else {
        throw new Error('Date Picker locale text not found in theme.');
    }
};

export const getDataGridLocaleText = (theme) => {
    const localeText =
        theme?.dataGridLocale?.components?.MuiDataGrid?.defaultProps
            ?.localeText;

    if (localeText) {
        return localeText;
    } else {
        throw new Error('Data Grid locale text not found in theme.');
    }
};

export const getTheme = (language) => {
    if (!language || typeof language !== 'string' || language === '') {
        throw new Error('Language type is undefined');
    }

    let coreLocale;
    let dataGridLocale;
    let pickerLocale;

    switch (language) {
        case 'en':
            coreLocale = enUS;
            dataGridLocale = dataGridUS;
            pickerLocale = pickerUS;
            break;
        case 'es':
            coreLocale = esES;
            dataGridLocale = dataGridES;
            pickerLocale = pickerES;
            break;
        default:
            throw new Error(`Language "${language}" does not exist in the LET`);
    }

    return createTheme({
        coreLocale,
        dataGridLocale,
        pickerLocale
    });
};

export const getComponentLocaleText = (componentObject) => {
    let styles = {};
    if (componentObject) {
        Object.keys(componentObject).forEach((key) => {
            styles = {
                ...styles,
                ...(componentObject[key]?.defaultProps?.localeText || {})
            };
        });
    }
    return styles;
};

export const getLocaleText = (theme) => {
    return {
        ...getComponentLocaleText(theme?.coreLocale?.components),
        ...getComponentLocaleText(theme?.dataGridLocale?.components),
        ...getComponentLocaleText(theme?.pickerLocale?.components)
    };
};

export const localizationSlice = createSlice({
    name: 'localization',
    initialState: INITIAL_STATE,
    reducers: {
        setLanguage: (state, action) => {
            state.strings.setLanguage(action.payload);
            const theme = getTheme(action.payload);
            return {
                ...state,
                language: action.payload,
                languageName: getLanguageName(action.payload),
                localeText: getLocaleText(theme),
                theme: theme,
                datePickerLocaleText: getDatePickerLocaleText(theme),
                dataGridLocaleText: getDataGridLocaleText(theme)
            };
        }
    }
});

export const { setLanguage } = localizationSlice.actions;
export const currentLanguage = (state) => state.localization.language;
export const currentLanguageName = (state) => state.localization.languageName;
export const strings = (state) => state.localization.strings;
export const datePickerLocaleText = (state) =>
    state.localization.datePickerLocaleText;
export const dataGridLocaleText = (state) =>
    state.localization.dataGridLocaleText;
export default localizationSlice.reducer;
