import channelJson from './channels.json';
import countriesJson from './countries.json';
import dvrWindowListJson from './dvrWindowList.json';
import hboImagesJson from './hboImages.json';
import languagesJson from './languages.json';
import maxImagesJson from './maxImages.json';
import videoProfilesJson from './videoProfiles.json';

export const brChannels = channelJson.brChannels;
export const channels = channelJson.channels;
export const countries = countriesJson.countries;
export const dvrWindowList = dvrWindowListJson.dvrWindowList;
export const hboImages = hboImagesJson.images
export const languages = languagesJson;
export const maxImages = maxImagesJson.images;
export const videoProfiles = videoProfilesJson.video_profiles;

const data = {
    brChannels,
    channels,
    countries,
    dvrWindowList,
    hboImages,
    languages,
    maxImages,
    videoProfiles
}

export default data;