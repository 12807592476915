import { languages as KNOWN_LANGUAGES } from '../../data';

export const US_ENGLISH = Object.freeze('en-US');

export const allLanguages = Object.keys(KNOWN_LANGUAGES).map((langCode) => {
    return {
        isoCode: langCode,
        languageName: KNOWN_LANGUAGES[langCode]
    };
});

export const fullLanguageArrayFromIsoCodes = (languageCodes = []) => {
    // Uncomment the next line to force english into the language array:
    // languageCodes.push(US_ENGLISH);
    // Guantee the array contains only unique values:
    languageCodes = uniqueArray(languageCodes);
    // Return a fully qualified list of languages:
    return languageCodes.map((isoCode) =>
        allLanguages.find((language) => language.isoCode === isoCode)
    );
};

export const getLanguageName = (languageId) => {
    let name = '';
    const lang = allLanguages.find((lang) => lang.isoCode === languageId);
    return lang?.languageName || '';
};

export const orderLanguages = (languages = []) => {
    // Will error if not passed a valid list of languages in the
    // { isoCode="az-AZ", languageName="SOME LANGUAGE" } format.
    try {
        const usEnglish = allLanguages.find(
            (lang) => lang.isoCode === US_ENGLISH
        );
        const filtered = languages
            .filter((language) => language.isoCode !== US_ENGLISH)
            .map((language) => language)
            .sort((a, b) => (a.languageName > b.languageName ? 1 : -1));
        filtered.splice(0, 0, usEnglish);
        return filtered;
    } catch (e) {
        // Error from being passed a garbage list of languages
        // console.info(`Unable to parse language "${e}"`);
        // Return an empty set:
        return [];
    }
};

export const uniqueArray = (array = []) => Array.from(new Set(array));
