import React from 'react';
import { Grid } from '@mui/material';
import { Input } from '../../shared/Input';

const SecondaryTitle = (props) => {
    const { inputTitle, value, handleChange, originalValue } = props;

    return (
        <Grid item xs={12}>
            <Input
                label={`${inputTitle}:`}
                name='secondary_title'
                value={value}
                handleChange={handleChange}
                showToggle={false}
                enableToggle={false}
                originalValue={originalValue}
                showCopy={true}
                required={false}
            />
        </Grid>
    );
};

export default SecondaryTitle;