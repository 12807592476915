import React from 'react';
import { Grid } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { date as dateUtil } from '../../utils/date';

export const formatDate = (dateString = '') =>
    dateUtil.format(dateUtil.toEst(dateString), 'YYYY/MM/DD hh:mm A');

const Offer = (props) => {
    const labelStyle = {
        color: 'gray',
        fontWeight: 'bold'
    };

    const simpleButtonStyle = {
        alignItems: 'center',
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        display: 'inline-block',
        fontWeight: 'bold',
        height: '24px',
        justifyContent: 'center',
        width: '24px'
    };
    const {
        currentOffer,
        destination,
        editOffer,
        index,
        idIndex,
        localization,
        offerId,
        removeOffer
    } = props;

    const {
        isBrandOriginal,
        isFreePreview,
        isTVE,
        territories
    } = currentOffer;

    return (
        <Grid
            container
            data-testid={`offer_${index}-${idIndex}`}
            item
            sm={6}
            spacing={1}
            key={`offer_${index}-${idIndex}`}
        >
            <Grid
                container
                item
                sm={12}
                display={'flex'}
                justifyContent={'space-between'}
            >
                <Grid item>
                    <strong>{localization.offers_id}:</strong>
                    <br />
                    <span
                        style={{ fontFamily: 'monospace' }}
                        data-testid={`offer_id_${index}-${idIndex}`}
                    >
                        {offerId}
                    </span>
                </Grid>
                <Grid item>
                    <button
                        data-testid={`edit-offer-${index}-${idIndex}`}
                        title={`${localization.offers_edit_offer} ${offerId}`}
                        onClick={(e) => {
                            e.preventDefault();
                            editOffer(destination, offerId);
                        }}
                        style={simpleButtonStyle}
                    >
                        <Edit />
                    </button>

                    <button
                        data-testid={`delete-offer-${index}-${idIndex}`}
                        title={`${localization.offers_delete_offer} ${offerId}`}
                        onClick={(e) => {
                            e.preventDefault();
                            removeOffer(offerId);
                        }}
                        style={{
                            ...simpleButtonStyle,
                            margin: '0 10px 0 0'
                        }}
                    >
                        <Delete />
                    </button>
                </Grid>
            </Grid>

            <Grid item sm={12} data-testid={`is_tve_${isTVE.toString()}`}>
                <span style={labelStyle}>{localization.offers_is_tve}:</span>{' '}
                {isTVE ? '\u2611' : '\u2610'}
            </Grid>

            <Grid
                item
                sm={12}
                data-testid={`is_free_preview_${isFreePreview.toString()}`}
            >
                <span style={labelStyle}>
                    {localization.offers_free_preview}:
                </span>{' '}
                {isFreePreview ? '\u2611' : '\u2610'}
            </Grid>

            <Grid
                item
                sm={12}
                data-testid={`is_brand_original_${isBrandOriginal.toString()}`}
            >
                <span style={labelStyle}>
                    {localization.offers_is_brand_original}
                </span>
                : {isBrandOriginal ? '\u2611' : '\u2610'}
            </Grid>

            <Grid
                item
                sm={12}
                style={{
                    margin: '0 0 10px 0'
                }}
                data-testid={`territories_${(territories || [])
                    .join('-')
                    .toLowerCase()}`}
            >
                <span style={labelStyle}>
                    {`${localization.offers_territories}`}:
                </span>{' '}
                {currentOffer.territories.join(', ')}
            </Grid>
        </Grid>
    );
};

export default Offer;
