import productsService from './productsService';

export const liveAdminHelper = {
    // Functions to format the Descriptors, utilised in LiveAdmin page.
    addLabelValue: function (data) {
        return Object.assign({}, data, {
            label: data.name,
            value: data.id
        });
    },

    isEmptyObject: function (obj) {
        return obj && Object.keys(obj).length === 0;
    },

    formatDescriptorsChoices: function (allDescriptors) {
        let allFormattedDescriptors = {};
        if (!this.isEmptyObject(allDescriptors)) {
            Object.keys(allDescriptors).forEach((key) => {
                allFormattedDescriptors[key] =
                    allDescriptors[key].length > 0
                        ? allDescriptors[key].map((element) =>
                              this.addLabelValue(element)
                          )
                        : [];
            });
        }
        return allFormattedDescriptors;
    },

    prepareErrorString: function (errorReason) {
        if (errorReason) {
            const errData = errorReason.response?.data
                ? errorReason.response.data
                : null;
            if (errData) {
                if (typeof errData === 'string') {
                    return errData;
                } else if (
                    typeof errData === 'object' &&
                    errData.error &&
                    errData.status === false
                ) {
                    return errData.error;
                }
            }
        }
        return 'Error processing request.';
    },

    productRegistrationEnabled: function (selectedTenant) {
        return !selectedTenant.isMaxFamily;
    },

    processProductsHandlerResponse: function (responseObj) {
        const processedObj = {};
        if (responseObj.status === 'fulfilled') {
            processedObj.promiseStatus = true;
            processedObj.data = responseObj.value;
        } else {
            processedObj.promiseStatus = false;
            processedObj.data = this.prepareErrorString(responseObj.reason);
        }
        return processedObj;
    },

    // Making a set of product Service calls for LiveAdmin Page.
    getProductServiceData: async function (ids, selectedTenant) {
        const { athenaId, profileId, mediaId } = ids;

        let allPromises = [
            productsService.getAllDescriptors(profileId),
            productsService.getAsset(athenaId)
        ];

        if (this.productRegistrationEnabled(selectedTenant)) {
            allPromises.push(productsService.getProducts());
            allPromises.push(productsService.getEntitlements(mediaId));
        } else {
            allPromises.push(Promise.reject(null));
            allPromises.push(Promise.reject(null));
        }

        const allResponses = await Promise.allSettled(allPromises);
        const productsResult = {
            allDescriptors: this.processProductsHandlerResponse(
                allResponses[0]
            ),
            assetResponse: this.processProductsHandlerResponse(allResponses[1]),
            productsResponse: this.processProductsHandlerResponse(
                allResponses[2]
            ),
            registeredProductsResponse: this.processProductsHandlerResponse(
                allResponses[3]
            )
        };
        return productsResult;
    },

        /**
     * 
     * @param {*} mediaList 
     * @param {*} name 
     * @returns String.
     * example mediaList
     * [
         {
            "name":"dash_primary_playback_url",
            "value":"https://123-hbomaxlive.akamaiorigin.net/cmaf/live/4567/dash/cenc/34357/master.mpd"
         },
         {
            "name":"hls_primary_playback_url",
            "value":"https://123-hbomaxlive.akamaiorigin.net/cmaf/live/4567/cbcs/34357/master.m3u8"
         }
      ]
     */
    filterMediaAssetURL: function (mediaList, name, defaultReturn) {
        const found = mediaList.find(element => element.name === name);
        if (found) {
            return found.value ? found.value: defaultReturn;
        }
        return defaultReturn;
    }
};

export default liveAdminHelper;
