import {
    AccountBalance,
    ArrowDropDown,
    ArrowRight,
    CalendarToday,
    CalendarMonth,
    Dashboard,
    DeviceHub,
    EmojiEvents,
    FormatListBulleted,
    Group,
    GroupWork,
    SportsSoccer
} from '@mui/icons-material';
import { Collapse, List } from '@mui/material';
import { ListItem } from '@mui/material';
import React, { useState } from 'react';
import { isAdmin, isOperator } from '../reducer/userReducer';

import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { strings } from '../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .Mui-selected': {
            backgroundColor: 'black',
            color: 'white',
            '& .MuiListItemIcon-root': {
                color: 'white'
            },

            '&:hover': {
                color: 'black'
            }
        }
    }
}));

export const MENU_STATES = Object.freeze({ OPEN: true, COLLAPSED: false });

export const getStorageKey = (menuName = '') => {
    if (typeof menuName !== 'string') {
        menuName = '';
    }
    return `${menuName.trim().toLowerCase().replace(' ', '_')}_state`;
};

export const getMenuState = (storageKey) => {
    const menuState = localStorage.getItem(storageKey);
    return menuState === 'false' ? false : true;
};

export const setMenuState = (storageKey, menuState) => {
    localStorage.setItem(storageKey, menuState);
};

let SubMenu = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const storageKey = getStorageKey(props.menuName);

    const [open, setOpen] = useState(getMenuState(storageKey));

    const handleClick = () => {
        setMenuState(storageKey, !open);
        setOpen(!open);
    };

    const handleSubmenuItemClick = (action, index) => {
        // New way to go to a different page.
        // push a new page onto the browser history.
        history.push(action);
    };

    const isSelected = (route) => {
        const currentUrl = window.location.href;
        if (currentUrl.includes(route)) return true;
        else return false;
    };

    let menuItems = [];
    let index = 0;
    for (let item of props.items) {
        menuItems.push(
            <ListItem
                selected={isSelected(item.route)}
                key={index}
                index={index}
                button={true}
                onClick={() => handleSubmenuItemClick(item.route, index)}
            >
                {item.icon} {item.name}
            </ListItem>
        );
        index += 1;
    }
    return (
        <div className={classes.root}>
            <ListItem button={true} onClick={() => handleClick()}>
                {open ? (
                    <ArrowDropDown style={{ fontSize: 30, marginTop: 5 }} />
                ) : (
                    <ArrowRight style={{ fontSize: 30, marginTop: 5 }} />
                )}
                <ListItemText primary={props.menuName} />
            </ListItem>
            <Collapse in={open} timeout='auto' unmountOnExit={true}>
                <List>{menuItems}</List>
            </Collapse>
        </div>
    );
};

const Menu = () => {
    const localization = useSelector(strings);
    const isAdminUser = useSelector(isAdmin);
    const isOperatorUser = useSelector(isOperator);
    const menuItems = [
        {
            name: localization.main_menu_competitors,
            route: '/competitors',
            icon: <Group style={{ fontSize: 30, marginRight: '10px' }} />
        },
        /*
        {
            name: localization.main_menu_conferences,
            route: '/conferences',
            icon: <GroupWork style={{ fontSize: 30, marginRight: '10px' }} />
        },
        {
            name: localization.main_menu_divisions,
            route: '/divisions',
            icon: <DeviceHub style={{ fontSize: 30, marginRight: '10px' }} />
        },
        */
        {
            name: localization.main_menu_events,
            route: '/events',
            icon: (
                <CalendarToday style={{ fontSize: 30, marginRight: '10px' }} />
            )
        },
        {
            name: localization.main_menu_leagues,
            route: '/leagues',
            icon: (
                <FormatListBulleted
                    style={{ fontSize: 30, marginRight: '10px' }}
                />
            )
        },
        {
            name: localization.main_menu_series,
            route: '/series',
            icon: <Dashboard style={{ fontSize: 30, marginRight: '10px' }} />
        },
        {
            name: localization.main_menu_sports,
            route: '/sports',
            icon: <SportsSoccer style={{ fontSize: 30, marginRight: '10px' }} />
        },
        {
            name: localization.main_menu_tournaments,
            route: '/tournaments',
            icon: <EmojiEvents style={{ fontSize: 30, marginRight: '10px' }} />
        },
        {
            name: localization.main_menu_venues,
            route: '/venues',
            icon: (
                <AccountBalance style={{ fontSize: 30, marginRight: '10px' }} />
            )
        }
    ];
    if (isAdminUser || isOperatorUser) {
        menuItems.push({
            name: localization.main_menu_calendar,
            route: '/calendar',
            icon: (
                <CalendarMonth
                    style={{
                        fontSize: 30,
                        marginRight: '10px'
                    }}
                />
            )
        });
    }
    return (
        <div>
            <SubMenu
                key='main-menu'
                menuName={localization.main_menu_title}
                items={menuItems}
            />
        </div>
    );
};

export default Menu;
