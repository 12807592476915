import React, { useEffect } from 'react';
import { Container, Grid, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ArrowBackOutlined, RemoveOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { UploadPreview } from '../../shared/UploadPreview';
import { Buttons } from '../../shared/Buttons';
import { Loader } from '../../shared/Loader';
import { useForm } from '../../hooks/UseForm';
import { useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        }
    }
}));

export const Appearance = ({
    id,
    venues,
    setRefresh,
    venueSchema,
    config,
    handleUpdate,
    handleForward
}) => {
    const bucket = 'venue';
    const classes = useStyles();
    const prevPage = `/venues/${id}/venue-info`;

    const localization = useSelector(strings);

    const {
        item,
        isInProgress,
        valueChanged,
        handleUploadChange,
        onFormSubmit,
        form
    } = useForm({
        service: 'venues',
        initialState: venues,
        id: id,
        requiredFields: venueSchema.required,
        setRefresh: setRefresh
    });

    useEffect(() => {
        handleUpdate(valueChanged);
    }, [valueChanged]);

    const { image, let_override: { image: override_image } = {} } = item;

    return (
        <div className={classes.root}>
            <Container maxWidth='lg'>
                <Loader isInProgress={isInProgress} />
                <form noValidate autoComplete='off' onSubmit={onFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <UploadPreview
                                label={`${localization.venue_image}:`}
                                name='image'
                                bucket={bucket}
                                enableDelete={true}
                                item='venue-image'
                                type='image'
                                config={config}
                                fileName={
                                    override_image || image || form.image || ''
                                }
                                handleChange={handleUploadChange}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='flex-end'
                        gap={3}
                        mt={2}
                    >
                        <IconButton
                            aria-label='delete'
                            style={{ color: 'black' }}
                            onClick={(e) => handleForward(e, prevPage)}
                        >
                            <ArrowBackOutlined fontSize='large' />
                            <RemoveOutlined
                                fontSize='large'
                                style={{ marginLeft: '-16px' }}
                            />
                        </IconButton>
                        <Buttons
                            type='submit'
                            label={localization.save}
                            showForWriteAccessUsers
                        />
                    </Box>
                </form>
            </Container>
        </div>
    );
};
