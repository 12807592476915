import React from 'react';
import { useHistory } from "react-router-dom"

import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Check } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      width: '30%',
      maxHeight: 300,

      '& .MuiButton-text': {
        padding: 0
      }
    },
    '& .MuiSvgIcon-root': {
      paddingRight: '0.5rem',
      fontSize: '14px'
    }
  }
}));

export const Error = (props) => {
  const { onClose, open, message, ...other } = props;
  const classes = useStyles();
  
  const handleOK = () => {
    onClose();
  };

  return (
    <Dialog
      className={classes.root}
      maxWidth="xs"
      open={open}
      {...other}
      data-testid='error'
    >
      <DialogTitle>Error</DialogTitle>
      <DialogContent dividers>
        {message}
        <p>
          Click OK to continue.
        </p>
      </DialogContent>
      <DialogActions>

        <Button autoFocus onClick={handleOK}><Check />OK</Button>
      </DialogActions>
    </Dialog>
  )
}
