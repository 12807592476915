import { createSlice } from '@reduxjs/toolkit';
import { utils } from '../utils/utils';
export const tenantSlice = createSlice({
    name: 'tenant',
    initialState: {
        tenantName: 'max',
        isHBO: false,
        isMax: false,
        isMaxFamily: false,
        isDomestic: false,
        isBR: false,
        isBR2: false,
        isChile: false,
        isMultiLanguage: false,
        languages: [],
        allTenants: [],
        eventSteps: []
    },
    reducers: {
        changeTenant: (state, action) => {
            const tenant = action.payload;
            state.tenantName = tenant;
            state.isHBO = utils.isHBO(tenant);
            state.isDomestic = utils.isDomestic(tenant);
            state.isMax = utils.isMax(tenant);
            state.isMaxFamily = utils.isMaxFamily(tenant);
            state.isBR = utils.isBR(tenant);
            state.isBR2 = utils.isBR2(tenant);
            state.isChile = utils.isChile(tenant);
            state.isMultiLanguage = utils.isMultiLanguage(
                tenant,
                state.allTenants
            );
            state.languages = utils.getLanguages(tenant, state.allTenants);

            const steps = ["EVENT_INFO", "EVENT_APPEARANCE"];
            if(!state.isBR2 && !state.isMax){
                steps.push("EVENT_LIVE_ADMIN");
            }

            state.eventSteps = steps;
        },
        setAllTenants: (state, action) => {
            state.allTenants = action.payload;
        }
    }
});

export const { changeTenant, setAllTenants } = tenantSlice.actions;
export const selectTenant = (state) => state.tenant;
export const getCurrentTenant = (state) => state.tenant.tenantName;
export const allTenants = (state) => state.tenant.allTenants;
export default tenantSlice.reducer;
