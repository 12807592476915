import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import services from '../../../services/apiService';
import { ArrowForwardOutlined, RemoveOutlined } from '@mui/icons-material';
import { AutocompleteBox } from '../../shared/AutocompleteBox';
import { Buttons } from '../../shared/Buttons';
import { CheckBox } from '../../shared/Checkbox';
import { Container, Grid } from '@mui/material';
import { countries } from '../../../data';
import { Input } from '../../shared/Input';
import { Loader } from '../../shared/Loader';
import { makeStyles } from '@mui/styles';
import { MultiSelectBox } from '../../shared/MultiSelectBox';
import { selectTenant } from '../../../reducer/tenantSlice';
import { useForm } from '../../hooks/UseForm';
import { useSelector } from 'react-redux';
import { utils } from '../../../utils/utils';
// Notifications:
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        }
    },
    buttonright: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

export const Info = ({
    id,
    league,
    leagueTypes,
    setRefresh,
    leagueSchema,
    handleUpdate,
    handleForward
}) => {
    const [loading, setLoading] = useState(false);
    const [sports, setSports] = useState([]);
    const [types, setLeagueTypes] = useState(leagueTypes);

    const classes = useStyles();
    const nextPage = `/leagues/${id}/appearance`;

    const tenant = useSelector(selectTenant);
    const isBR = tenant.isBR;
    const requiredFields = leagueSchema.required;

    const dispatch = useDispatch();

    const localization = useSelector(strings);

    const {
        item,
        showToggle,
        enableToggle,
        isInProgress,
        error,
        valueChanged,
        handleChange,
        handleMultipleSelectChange,
        handleToggleChange,
        handleFieldChange,
        onFormSubmit,
        form
    } = useForm({
        service: 'leagues',
        initialState: league,
        id,
        requiredFields,
        setRefresh: setRefresh,
        extraFields: ['type', 'sport_id']
    });

    useEffect(() => {
        handleUpdate(valueChanged);
    }, [valueChanged]);

    useEffect(() => {
        setLoading(true);
        services
            .fetchAllData('sports')
            .then((data) => {
                setSports(data);
            })
            .catch((err) => {
                setLeagues([]);
                dispatch(notifyError(`${err.name}: ${err.message}`));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const {
        name,
        alias,
        display_name,
        display_name_short,
        type,
        sport_id,
        territories_available,
        has_media,
        followable,
        let_override: {
            territories_available:
                override_territories_available = utils.getDefaultCountry(),
            type: override_type,
            sport_id: override_sport_id,
            name: override_name,
            alias: override_alias,
            display_name: override_display_name,
            display_name_short: override_display_name_short,
            has_media: override_has_media,
            followable: override_followable
        } = {}
    } = item;

    return (
        <div className={classes.root}>
            <Container maxWidth='lg'>
                <Loader isInProgress={isInProgress || loading} />
                <form noValidate autoComplete='off' onSubmit={onFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Input
                                label={`${localization.league_name}:`}
                                name='name'
                                showToggle={showToggle}
                                value={override_name || name || form.name || ''}
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={league && league.type}
                                handleToggleChange={handleToggleChange}
                                error={error.name}
                                showCopy={true}
                                required={requiredFields.includes('name')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <AutocompleteBox
                                label={`${localization.league_type}:`}
                                options={types}
                                name='type'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={
                                    override_type || type || form.type || ''
                                }
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={(league && league.type) || null}
                                handleToggleChange={handleToggleChange}
                                error={error.type}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes('type')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.alias}:`}
                                name='alias'
                                showToggle={showToggle}
                                value={
                                    override_alias || alias || form.alias || ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={league && league.alias}
                                handleToggleChange={handleToggleChange}
                                error={error.alias}
                                required={requiredFields.includes('alias')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.display_name}:`}
                                name='display_name'
                                showToggle={showToggle}
                                value={
                                    override_display_name ||
                                    display_name ||
                                    form.display_name ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={league && league.diplay_name}
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes(
                                    'display_name'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.display_name_short}:`}
                                name='display_name_short'
                                showToggle={showToggle}
                                value={
                                    override_display_name_short ||
                                    display_name_short ||
                                    form.display_name_short ||
                                    ''
                                }
                                handleChange={handleChange}
                                enableToggle={enableToggle}
                                originalValue={
                                    league && league.diplay_name_short
                                }
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes(
                                    'display_name_short'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <AutocompleteBox
                                label={`${localization.sport}:`}
                                options={sports}
                                name='sport_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={
                                    override_sport_id ||
                                    sport_id ||
                                    form.sport_id ||
                                    ''
                                }
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    (league && league.sport_id) || null
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.sport_id}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes('sport_id')}
                            />
                        </Grid>
                        {isBR && (
                            <Grid item md={6} xs={12}>
                                <MultiSelectBox
                                    label={`${localization.league_available_in}:`}
                                    options={countries}
                                    name='territories_available'
                                    handleMultipleSelectChange={
                                        handleMultipleSelectChange
                                    }
                                    defaultValue={
                                        override_territories_available ||
                                        territories_available ||
                                        form.territories_available ||
                                        []
                                    }
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={
                                        league?.territories_available || []
                                    }
                                    handleToggleChange={handleToggleChange}
                                    localizedPlaceholder={localization.choose}
                                    required={requiredFields.includes(
                                        'territories_available'
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid container item xs={12}>
                            <Grid item md={6} xs={12}>
                                <CheckBox
                                    label={`${localization.has_media}`}
                                    checked={
                                        override_has_media ||
                                        has_media ||
                                        form.has_media ||
                                        false
                                    }
                                    name='has_media'
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <CheckBox
                                    label={`${localization.followable}`}
                                    checked={
                                        override_followable ||
                                        followable ||
                                        form.followable ||
                                        false
                                    }
                                    name='followable'
                                    handleChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                justifyContent='flex-end'
                                spacing={3}
                            >
                                <div className={classes.buttonright}>
                                    <Buttons
                                        type='submit'
                                        label={localization.save}
                                        showForWriteAccessUsers
                                    />
                                    {id !== 'new' && (
                                        <IconButton
                                            aria-label='next-page'
                                            style={{ color: 'black' }}
                                            onClick={(e) =>
                                                handleForward(e, nextPage)
                                            }
                                        >
                                            <RemoveOutlined
                                                fontSize='large'
                                                style={{ marginRight: '-16px' }}
                                            />
                                            <ArrowForwardOutlined fontSize='large' />
                                        </IconButton>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};
