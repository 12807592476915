import React, { useEffect, useState, useCallback } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Input } from '../../shared/Input';
import { Buttons } from '../../shared/Buttons';
import { Loader } from '../../shared/Loader';
import { AutocompleteBox } from '../../shared/AutocompleteBox';
import { CheckBox } from '../../shared/Checkbox';
import { MultiSelectBox } from '../../shared/MultiSelectBox';
import { Calendar } from '../../shared/Calendar';
import { useForm } from '../../hooks/UseForm';
import services from '../../../services/apiService';
import { countries } from '../../../data';
import { getRoute } from '../../shared/AdvancedSearch';
import { selectTenant } from '../../../reducer/tenantSlice';
import { useSelector } from 'react-redux';
// Notifications
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    buttonright: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

export const Info = ({ id, series, setRefresh, seriesSchema }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [leagues, setLeagues] = useState([]);
    const [sports, setSports] = useState([]);
    const [tournaments, setTournaments] = useState([]);

    const tenant = useSelector(selectTenant);
    const isBR = tenant.isBR;
    const requiredFields = seriesSchema.required;

    const localization = useSelector(strings);

    const {
        item,
        showToggle,
        enableToggle,
        isInProgress,
        error,
        handleChange,
        handleMultipleSelectChange,
        handleToggleChange,
        handleDateChange,
        handleFieldChange,
        onFormSubmit,
        form
    } = useForm({
        service: 'series',
        initialState: series,
        id,
        requiredFields,
        setRefresh
    });

    const {
        name,
        title,
        status,
        round,
        points,
        sportradar_id,
        season,
        start_date,
        territories_available,
        has_media,
        let_override: {
            name: override_name,
            title: override_title,
            status: override_status,
            round: override_round,
            points: override_points,
            sportradar_id: override_sportradar_id,
            season: override_season,
            start_date: override_start_date,
            territories_available: override_territories_available,
            has_media: override_has_media
        } = {}
    } = item;

    useEffect(() => {
        setLoading(true);
        services
            .fetchAllData('sports')
            .then((data) => {
                setSports(data);
            })
            .catch((err) => {
                setLeagues([]);
                dispatch(notifyError(`${err.name}: ${err.message}`));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const fetchLeagues = useCallback(() => {
        let sportId = form.sport_id || undefined;

        if (sportId !== undefined) {
            setLoading(true);
            let route = getRoute('leagues', { sport_id: sportId });
            const data = services
                .fetchAllData(route)
                .then((data) => {
                    setLeagues(data);
                })
                .catch((err) => {
                    setLeagues([]);
                    dispatch(notifyError(`${err.name}: ${err.message}`));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLeagues([]);
        }
        setTournaments([]);
    }, [form.sport_id]);

    useEffect(() => {
        fetchLeagues();
    }, [fetchLeagues]);

    const fetchTournaments = useCallback(() => {
        const leagueId = form.league_id || undefined;
        if (leagueId !== undefined) {
            setLoading(true);
            let route = getRoute('tournaments', { league_id: leagueId });
            const data = services
                .fetchAllData(route)
                .then((data) => {
                    setTournaments(data);
                })
                .catch((err) => {
                    setTournaments([]);
                    dispatch(notifyError(`${err.name}: ${err.message}`));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setTournaments([]);
        }
    }, [form.league_id]);

    useEffect(() => {
        fetchTournaments();
    }, [fetchTournaments]);

    return (
        <div className={classes.root}>
            <Container maxWidth='lg'>
                <Loader isInProgress={isInProgress || loading} />
                <form noValidate autoComplete='off' onSubmit={onFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Input
                                label={`${localization.series_name}:`}
                                name='name'
                                value={override_name || name || form.name || ''}
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={series && series.name}
                                handleToggleChange={handleToggleChange}
                                error={error.name}
                                showCopy={true}
                                required={requiredFields.includes('name')}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Input
                                label={`${localization.title}:`}
                                name='title'
                                value={
                                    override_title || title || form.title || ''
                                }
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={series && series.alias}
                                handleToggleChange={handleToggleChange}
                                error={error.title}
                                required={requiredFields.includes('title')}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Input
                                label={`${localization.status}:`}
                                name='status'
                                value={
                                    override_status ||
                                    status ||
                                    form.status ||
                                    ''
                                }
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={series && series.status}
                                handleToggleChange={handleToggleChange}
                                error={error.status}
                                required={requiredFields.includes('status')}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Input
                                label={`${localization.round}:`}
                                name='round'
                                value={
                                    override_round || round || form.round || ''
                                }
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={series && series.round}
                                handleToggleChange={handleToggleChange}
                                error={error.round}
                                required={requiredFields.includes('round')}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <AutocompleteBox
                                label={`${localization.sport}:`}
                                options={sports}
                                name='sport_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={form.sport_id || null}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    (series && series.sport_id) || null
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.sport_id}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes('sport_id')}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <AutocompleteBox
                                label={`${localization.league}:`}
                                options={leagues}
                                name='league_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={form.league_id || null}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    (series && series.league_id) || null
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.league_id}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes('league_id')}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <AutocompleteBox
                                label={`${localization.tournament}:`}
                                options={tournaments}
                                name='tournament_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={form.tournament_id || null}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    (series && series.tournament_id) || null
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.tournament_id}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes(
                                    'tournament_id'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Input
                                label={`${localization.points}:`}
                                type='number'
                                name='points'
                                value={
                                    override_points ||
                                    points ||
                                    form.points ||
                                    ''
                                }
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={series && series.points}
                                handleToggleChange={handleToggleChange}
                                error={error.points}
                                required={requiredFields.includes('points')}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Input
                                label={`${localization.sportradar_id}:`}
                                name='sportradar_id'
                                value={
                                    override_sportradar_id ||
                                    sportradar_id ||
                                    form.sportradar_id ||
                                    ''
                                }
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={series && series.sportradar_id}
                                handleToggleChange={handleToggleChange}
                                error={error.sportradar_id}
                                required={requiredFields.includes(
                                    'sportradar_id'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Input
                                label={`${localization.season}:`}
                                type='number'
                                name='season'
                                value={
                                    override_season ||
                                    season ||
                                    form.season ||
                                    ''
                                }
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={series && series.season}
                                handleToggleChange={handleToggleChange}
                                error={error.season}
                                required={requiredFields.includes('season')}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Calendar
                                label={`${localization.start_date} (ET):`}
                                name='start_date'
                                defaultValue={
                                    override_start_date ||
                                    start_date ||
                                    form.start_date ||
                                    ''
                                }
                                handleChange={handleDateChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={series && series.start_date}
                                handleToggleChange={handleToggleChange}
                                error={error.start_date}
                                required={requiredFields.includes('start_date')}
                            />
                        </Grid>
                        {isBR && (
                            <Grid item md={6} sm={12}>
                                <MultiSelectBox
                                    label={`${localization.series_available_in}:`}
                                    options={countries}
                                    name='territories_available'
                                    handleMultipleSelectChange={
                                        handleMultipleSelectChange
                                    }
                                    defaultValue={
                                        override_territories_available ||
                                        territories_available ||
                                        form.territories_available ||
                                        []
                                    }
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={
                                        series?.territories_available || []
                                    }
                                    handleToggleChange={handleToggleChange}
                                    localizedPlaceholder={localization.choose}
                                    required={requiredFields.includes(
                                        'territories_available'
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <CheckBox
                                    label={`${localization.has_media}`}
                                    checked={
                                        override_has_media ||
                                        has_media ||
                                        form.has_media ||
                                        false
                                    }
                                    name='has_media'
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    justifyContent='flex-end'
                                    spacing={3}
                                >
                                    <div className={classes.buttonright}>
                                        <Buttons
                                            type='submit'
                                            label={localization.save}
                                            variant='text'
                                            size='large'
                                            showForWriteAccessUsers
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};
