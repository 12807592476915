import { createSlice } from '@reduxjs/toolkit';
import { loadState } from "./../utils/utils"

export const advancedSearchKey = 'redux-advanced-search';

const initialState = loadState(advancedSearchKey) || { params: {} }

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchParams: (state, action) => {
      const { params, route } = action.payload
      state.params[route] = params
    },
    resetSearchParams: (state, action) => {
      state.params = {}
    }
  }
})

export const { setSearchParams, resetSearchParams } = searchSlice.actions
export const advancedSearchParams = (state) => state.search?.params
export default searchSlice.reducer
