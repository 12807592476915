import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        },
        '& .Mui-disabled': {
            background: 'lightgray',
            color: 'white'
        },
        '& form': {
            margin: '0 0 40px 0'
        }
    },
    buttons: {
        alignItems: 'center',
        marginTop: '1rem'
    },
    buttonright: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    sectionHeading: theme.sectionHeading,
    reportMetaData: {
        margin: '10px 0'
    },
    cdn: {
        padding: 8
    },
    errorNotification: {
        color: 'red',
        cursor: 'pointer',
        width: '20px',
        height: 'auto'
    },
    loading: {
        margin: '0 0 0 10px',
        fontSize: '0.8rem',
        color: 'gray'
    },
    metaButton: {
        marginBottom: 20
    },
    productNote: {
        textAlign: 'left',
        fontWeight: 'bold'
    },
    productName: {
        color: 'red'
    },
    small: {
        margin: '0 0 0 10px',
        fontSize: '0.8rem'
    }
}));

// Render SKU from GIZMO in products GRID.
export const getSKUDisplay = (params, key) => {
    if (key === 'sku') {
        if (
            params.value.gizmo &&
            params.value.gizmo.length > 0 &&
            params.value.gizmo[0] &&
            params.value.gizmo[0].sku
        ) {
            return params.value.gizmo[0].sku;
        }
    }
    return '';
};

// Render Amount value in products GRID.
export const getValueToDisplay = (params, key) => {
    if (params.value && params.value[key]) {
        if (key === 'amount') {
            return `$${params.value[key]}`;
        } else {
            return params.value[key];
        }
    }
    return '';
};

export const getTableHeaderStyles = (renderCheckboxContent, localization) => {
    return [
        {
            field: 'description',
            headerName: localization.name,
            flex: 3,
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'billingInterval',
            headerName: localization.type,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true
        },
        {
            field: 'price',
            headerName: localization.price,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'super-app-theme--header',
            disableColumnMenu: true,
            renderCell: (params) => <p>{getValueToDisplay(params, 'amount')}</p>
        },
        {
            field: 'storefrontProducts',
            headerName: localization.sku,
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            cellClassName: `super-app-theme-id-cell`,
            disableColumnMenu: true,
            renderCell: (params) => <p>{getSKUDisplay(params, 'sku')}</p>
        },
        {
            field: 'id',
            headerName: localization.package_id,
            flex: 2,
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            cellClassName: `super-app-theme-id-cell`
        },
        {
            field: 'product',
            headerName: localization.associate,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            cellClassName: `super-app-theme-id-cell`,
            renderCell: renderCheckboxContent
        }
    ];
};
