import React from 'react';
import { CopyToClipboardButton } from './CopyToClipboardButton';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ToggleButton } from './ToggleButton';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        borderRadius: '4px',

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.23)' // default
            },
            '&.Mui-focused fieldset': {
                border: '1px solid black' // customized
            }
        }
    },

    labelStyle: {
        fontWeight: 600,
        color: 'black',
        fontSize: 'large',
    },
}));

export const Textarea = (props) => {
    const classes = useStyles();
    let {
        label,
        defaultValue,
        handleChange,
        name,
        type,
        showCopy,
        showToggle,
        enableToggle,
        originalValue,
        handleToggleChange,
        error,
        disabled,
        required,
    } = props;

    return (
        <Grid container justifyContent='space-between' alignItems='flex-end'>
            {showToggle && !showCopy && (
                <>
                    <Grid item xs={6}>
                        <InputLabel
                            className={classes.labelStyle}
                            shrink
                            htmlFor={name}
                        >
                            {label} {required && <span className='text-danger'>*</span>}
                        </InputLabel>
                    </Grid>
                    <Grid item xs={6} style={{ flexBasis: '1%' }}>
                        <ToggleButton
                            showToggle={
                                showToggle &&
                                (defaultValue != null ||
                                    defaultValue !== undefined) &&
                                !_.isEqual(defaultValue, originalValue)
                            }
                            name={name}
                            originalValue={originalValue}
                            enableToggle={enableToggle}
                            handleToggleChange={handleToggleChange}
                        />
                    </Grid>
                </>
            )}
            {showCopy && !showToggle && (
                <>
                    <Grid item xs={6}>
                        <InputLabel
                            className={classes.labelStyle}
                            shrink
                            htmlFor={name}
                        >
                            {label} {required && <span className='text-danger'>*</span>}
                        </InputLabel>
                    </Grid>
                    <CopyToClipboardButton
                        value={defaultValue || originalValue}
                    />
                </>
            )}
            {!showToggle && !showCopy && (
                <>
                    <Grid item xs={12}>
                        <InputLabel
                            className={classes.labelStyle}
                            shrink
                            htmlFor={name}
                        >
                            {label} {required && <span className='text-danger'>*</span>}
                        </InputLabel>
                    </Grid>
                </>
            )}

            <Grid item xs={12}>
                <TextField
                    variant='outlined'
                    name={name}
                    onChange={handleChange}
                    defaultValue={defaultValue}
                    multiline
                    minRows={4}
                    classes={{
                        root: classes.root
                    }}
                />
            </Grid>
        </Grid>
    );
};
