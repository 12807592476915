import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams, Redirect } from 'react-router-dom';
import { Heading } from '../../shared/Heading';
import { CustomBreadcrumbs } from '../../shared/Breadcrumbs';
import { TournamentInfo } from './Info';
import services from '../../../services/apiService';
import { countries } from '../../../data';
import { Details } from '../../Details';
import { Appearance } from '../../tournaments/tournaments-details/Appearance';
const JSONSchema = require('let-json-schemas/v1/input_db/tournament.json');
import { Confirm } from '../../shared/Confirm';
import { utils } from '../../../utils/utils';
// Notifications:
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const customRequiredFields = ['display_name', 'display_name_short'];
JSONSchema.required = [...JSONSchema.required, ...customRequiredFields];

const TournamentSteps = ({
    slug,
    id,
    tournament,
    setRefresh,
    config,
    handleUpdate,
    handleForward
}) => {
    if (id === 'new' && slug !== 'tournament-info') {
        return <Redirect to='/tournaments/new/tournament-info' />;
    }
    switch (slug) {
        case 'tournament-info':
            return (
                <TournamentInfo
                    id={id}
                    tournament={tournament}
                    setRefresh={setRefresh}
                    tournamentSchema={JSONSchema}
                    handleUpdate={handleUpdate}
                    handleForward={handleForward}
                />
            );
        case 'appearance':
            return (
                <Appearance
                    id={id}
                    tournament={tournament}
                    setRefresh={setRefresh}
                    tournamentSchema={JSONSchema}
                    config={config}
                    handleUpdate={handleUpdate}
                    handleForward={handleForward}
                />
            );
        default:
            return <div></div>;
    }
};

export const CreateEditTournaments = ({ config }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    let location = useLocation();
    let path = location.pathname.split('/').slice(-1)[0];
    let { id } = useParams();

    const localization = useSelector(strings);

    const [isUpdating, setIsUpdating] = useState(false);
    const [link, setLink] = useState(path);
    const [loadError, setLoadError] = useState();
    const [forwarding, setForwarding] = useState({});
    const [openConfirm, setOpenConfirm] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [tournament, setTournament] = useState({});

    let steps = [
        {
            title: localization.tournament_info,
            slug: `tournament-info`
        },
        {
            title: localization.appearance,
            slug: `appearance`
        }
    ];

    const [details, setDetails] = useState([]);

    const fetchTournaments = useCallback(() => {
        id !== 'new' &&
            services
                .getItem('tournaments', id, true)
                .then((res) => {
                    if (res && Object.keys(res).length === 0) {
                        setLoadError(
                            `Error: Tournament ${id} does not exist on this tenant`
                        );
                        history.push(`/tournaments`);
                        return;
                    }
                    const {
                        let_override: { territories_available } = {},
                        territories_available: territories = []
                    } = res;

                    setTournament({
                        ...res,
                        territories_available: utils.getTerritories(
                            territories,
                            countries
                        ),
                        let_override: {
                            ...res.let_override,
                            territories_available: utils.getTerritories(
                                territories_available,
                                countries
                            )
                        }
                    });

                    const summary = utils.serializeSummary(
                        localization.tournament,
                        res,
                        localization
                    );

                    id !== 'new' && setDetails(summary);
                })
                .catch((err) => {
                    setLoadError(`${err.name}: ${err.message}`);
                });
    }, [refresh, id]);

    useEffect(() => {
        fetchTournaments();
    }, [fetchTournaments]);

    useEffect(() => {
        if (loadError) {
            dispatch(notifyError(loadError));
            setLoadError();
        }
    }, [loadError]);

    const handleUpdate = (event) => {
        setIsUpdating(event);
    };

    const handleForward = (e, path) => {
        e.preventDefault();
        if (isUpdating) {
            setLink(path);
            setOpenConfirm(true);
            setForwarding({ e, path });
        } else {
            id !== 'new' && history.push(path);
        }
    };

    const handleNavigation = (e, id, slug) => {
        const path = `/tournaments/${id}/${slug}`;
        handleForward(e, path);
    };

    const handleStay = () => {
        setOpenConfirm(false);
    };

    const handleOverride = () => {
        setIsUpdating(false);
        setOpenConfirm(false);

        if (forwarding.path) {
            history.push(forwarding.path);
        }
    };

    return (
        <>
            <Heading heading={localization.create_edit_tournament} />
            <CustomBreadcrumbs
                steps={steps}
                id={id}
                onChange={handleNavigation}
            />
            {id !== 'new' && Object.keys(tournament).length > 0 && (
                <Details details={details} />
            )}
            <TournamentSteps
                slug={path}
                id={id}
                tournament={tournament}
                setRefresh={setRefresh}
                config={config}
                handleUpdate={handleUpdate}
                handleForward={handleForward}
            />
            <Confirm
                path={link}
                open={openConfirm}
                onYes={handleOverride}
                onNo={handleStay}
            />
        </>
    );
};
