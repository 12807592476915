import React, { useEffect, useState, useCallback } from 'react';
import services from '../../../services/apiService';
import { Appearance } from './Appearance';
import { Confirm } from '../../shared/Confirm';
import { CustomBreadcrumbs } from '../../shared/Breadcrumbs';
import { Details } from '../../Details';
import { getTerritories } from '../../../utils/TerritoriesUtils';
import { Heading } from '../../shared/Heading';
import { Info } from './Info';
import { RecordRankManagement } from '../../leagues/league-details/RecordRankManagement';
import { useParams, useLocation, useHistory, Redirect } from 'react-router-dom';
import { utils } from '../../../utils/utils';
// Notifications:
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const JSONSchema = require('let-json-schemas/v1/input_db/league.json');
// Adds type to req'd per Angular LET.
const customRequiredFields = ['sport_id', 'type'];
JSONSchema.required = [...JSONSchema.required, ...customRequiredFields];
/*
 TODO: Sort out why ^^^ this ^^^ was added on the LET-side,
 but not in the JSON schemas.
 */

const LeagueSteps = ({
    slug,
    id,
    league,
    leagueTypes,
    setRefresh,
    JSONSchema,
    config,
    handleUpdate,
    handleForward
}) => {
    if (id === 'new' && slug !== 'league-info') {
        return <Redirect to='/leagues/new/league-info' />;
    }
    switch (slug) {
        case 'league-info':
            return (
                !!Object.keys(league) && (
                    <Info
                        id={id}
                        league={league}
                        leagueTypes={leagueTypes}
                        setRefresh={setRefresh}
                        leagueSchema={JSONSchema}
                        handleUpdate={handleUpdate}
                        handleForward={handleForward}
                    />
                )
            );
        case 'appearance':
            return (
                <Appearance
                    id={id}
                    league={league}
                    setRefresh={setRefresh}
                    leagueSchema={JSONSchema}
                    config={config}
                    handleUpdate={handleUpdate}
                    handleForward={handleForward}
                />
            );
        case 'record-rank-management':
            return (
                <RecordRankManagement
                    id={id}
                    league={league}
                    setRefresh={setRefresh}
                    leagueSchema={JSONSchema}
                    handleUpdate={handleUpdate}
                    handleForward={handleForward}
                />
            );
        default:
            return <></>;
    }
};

export const CreateEditLeague = ({ config }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let location = useLocation();

    const localization = useSelector(strings);

    let path = location.pathname.split('/').slice(-1)[0];
    let leagueTypes = [
        { name: 'College', id: 'college' },
        { name: 'Pro', id: 'pro' },
        { name: 'Other', id: 'other' }
    ];
    let { id } = useParams();

    const [isUpdating, setIsUpdating] = useState(false);
    const [league, setLeague] = useState({});
    const [link, setLink] = useState(path);
    const [loadError, setLoadError] = useState();
    const [forwarding, setForwarding] = useState({});
    const [openConfirm, setOpenConfirm] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const handleUpdate = (event) => {
        setIsUpdating(event);
    };

    let steps = [
        {
            title: localization.league_info,
            slug: `league-info`
        },
        {
            title: localization.appearance,
            slug: `appearance`
        },
        {
            title: localization.record_rank_management,
            slug: 'record-rank-management'
        }
    ];

    const [details, setDetails] = useState([]);

    const fetchLeague = useCallback(() => {
        id !== 'new' &&
            services
                .getItem('leagues', id, true)
                .then((res) => {
                    if (res && Object.keys(res).length === 0) {
                        setLoadError(
                            `Error: League ${id} does not exist on this tenant`
                        );
                        history.push(`/leagues`);
                        return;
                    }

                    const { territories_available, let_override } =
                        getTerritories(res);

                    setLeague({
                        ...res,
                        territories_available,
                        let_override
                    });

                    const summary = utils.serializeSummary(
                        localization.league,
                        res,
                        localization
                    );

                    id !== 'new' && setDetails(summary);
                })
                .catch((err) => {
                    setLoadError(`${err.name}:  ${err.message}`);
                });
    }, [id]);

    useEffect(() => {
        fetchLeague();
    }, [refresh, fetchLeague]);

    useEffect(() => {
        if (loadError) {
            dispatch(notifyError(loadError));
            setLoadError();
        }
    }, [loadError]);

    const handleStay = () => {
        setOpenConfirm(false);
    };

    const handleOverride = () => {
        setIsUpdating(false);
        setOpenConfirm(false);

        if (forwarding.path) {
            history.push(forwarding.path);
        }
    };

    const handleForward = (e, path) => {
        e.preventDefault();
        if (isUpdating) {
            setLink(path);
            setOpenConfirm(true);
            setForwarding({ e, path });
        } else {
            id !== 'new' && history.push(path);
        }
    };

    const handleNavigation = (e, id, slug) => {
        const path = `/leagues/${id}/${slug}`;
        handleForward(e, path);
    };

    return (
        <>
            <Heading heading={localization.create_edit_competitor} />
            <CustomBreadcrumbs
                steps={steps}
                id={id}
                onChange={handleNavigation}
            />
            {id !== 'new' && Object.keys(league).length > 0 && (
                <Details details={details} />
            )}
            <LeagueSteps
                slug={path}
                id={id}
                league={league}
                leagueTypes={leagueTypes}
                setRefresh={setRefresh}
                JSONSchema={JSONSchema}
                config={config}
                handleUpdate={handleUpdate}
                handleForward={handleForward}
            />
            <Confirm
                path={link}
                open={openConfirm}
                onYes={handleOverride}
                onNo={handleStay}
            />
        </>
    );
};
