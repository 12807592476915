import React, {
    useEffect,
    useState,
    useCallback,
    useContext,
    Suspense
} from 'react';
import { useSelector } from 'react-redux';
import { Button, CircularProgress, Container, Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Input } from '../../shared/Input';
import { MultilanguageInput } from '../../shared/MultilanguageInput';
import { Buttons } from '../../shared/Buttons';
import { Loader } from '../../shared/Loader';
import { useForm } from '../../hooks/UseForm';
import services from '../../../services/apiService';
import { countries } from '../../../data/countries';
import { MultiSelectBox } from '../../shared/MultiSelectBox';
import { CheckBox } from '../../shared/Checkbox';
import { AutocompleteBox } from '../../shared/AutocompleteBox';
import { LiveAutocomplete } from '../../shared/LiveAutocomplete';
import { Calendar } from '../../shared/Calendar';
import { TeamContext } from '../../context/TeamContext';
import { Detail } from './Detail';
import {
    ArrowForwardOutlined,
    OpenInNew,
    RemoveOutlined
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { selectTenant } from '../../../reducer/tenantSlice';
import { getRoute } from '../../shared/AdvancedSearch';
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch } from 'react-redux';
import { getSortedSeriesList } from '../../../utils/SortUtil';
import { strings } from '../../../reducer/localizationSlice';
import Channels from './Channels';
import Group, { GROUP_OPTIONS } from './Group';
import TestEvent from './TestEvent';
import getMaxMultiLanguageInputs from './maxUtils/getMaxMultiLanguageInputs';
import CountriesAvailable from './partials/CountriesAvailable';
import { LanguageSelect } from '../../shared/LanguageSelect';
import { Destinations } from '../../shared/Destinations';
import Offers from '../../shared/Offers';
import InputLabel from '@mui/material/InputLabel';
// import PreCaid from '../../shared/PreCaid';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        },
        '& form': {
            margin: '0 0 40px 0'
        }
    },
    labelStyle: {
        fontWeight: 600,
        color: 'black',
        fontSize: 'large',
    },
}));

const BR_CHANNELS = [
    {
        id: 'TBS',
        name: 'TBS'
    },
    {
        id: 'TNT',
        name: 'TNT'
    },
    {
        id: 'TruTV',
        name: 'TruTV'
    }
];

const EVENT_STATE_OPTIONS = Object.freeze();

const ROOT_SMART_VIEW_URL = 'https://smartview.turnerapps.com';

const NOOP = Object.freeze({
    preventDefault: () => {},
    target: { type: 'noop' }
});

export const padDate = (n = 0) => {
    return (n + '').padStart(2, '0');
};

export const transformDate = (s) => {
    let dateString = null;

    if (!s) {
        return null;
    }
    try {
        const d = new Date(s);
        if (!isNaN(d)) {
            dateString = `${d.getFullYear()}-${padDate(
                d.getMonth() + 1
            )}-${padDate(d.getDate())}`;
        }
    } catch (e) {
        // Nothing.
    }
    return dateString;
};

export const getFormattedChannel = (str) => {
    if (!str || !str.length || str.length === 0) {
        return null;
    }
    str = str.toUpperCase();
    return str === 'TRUTV' ? `${str},N` : `${str},E`;
};

export const EventInfo = ({
    id,
    event,
    setRefresh,
    eventSchema,
    handleUpdate,
    path,
    handleForward
}) => {
    // Required very early on:
    const allTeams = useContext(TeamContext);
    const findTeam = (idToFind) => {
        const teamCount = allTeams?.length || 0;
        return allTeams && allTeams.length > 0
            ? allTeams.find((option) => option.id == idToFind)
            : { id: '', name: '' };
    };
    const tenant = useSelector(selectTenant);
    const tenantLanguages = tenant.languages;
    const localization = useSelector(strings);

    // States
    const [awayIdOverride, setAwayIdOverride] = useState(null);
    const [homeIdOverride, setHomeIdOverride] = useState(null);
    const [isTveEvent, setIsTveEvent] = useState(false);
    const [leagues, setLeagues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [series, setSeries] = useState({});
    const [seriesList, setSeriesList] = useState([]);
    const [sports, setSports] = useState([]);
    const [teams, setTeams] = useState([]);
    const [tournaments, setTournaments] = useState([]);
    const [tournament, setTournament] = useState();
    const [venue, setVenue] = useState({});

    const requiredFields = eventSchema.required;

    // GSP-3158
    requiredFields.push('scheduled_utc', 'scheduled_utc_end');
    // GSP-3999: Remove standard name, add multi-language field for name:
    if (tenant.isMultiLanguage) {
        requiredFields.splice(requiredFields.indexOf('name'), 1);
        requiredFields.push('name_ml');
    }
    if (tenant.isBR) {
        requiredFields.push('video_start_utc');
        requiredFields.push('video_end_utc');
    }

    const {
        item,
        showToggle,
        enableToggle,
        isInProgress,
        error,
        valueChanged,
        handleChange,
        handleFieldChange,
        handleMultipleSelectChange,
        handleToggleChange,
        handleDateChange,
        handleMetaDateChange,
        onFormSubmit,
        form
    } = useForm({
        service: 'events',
        initialState: event,
        id,
        requiredFields,
        setRefresh
    });

    const {
        name,
        description_body,
        description_body_ml,
        secondary_title,
        secondary_title_ml,
        name_ml,
        sport_id,
        league_id,
        home_competitor_id,
        away_competitor_id,
        season,
        scheduled_utc,
        scheduled_utc_end,
        territories_available,
        free_preview,
        free_event,
        venue_id,
        week,
        season_type,
        series_id,
        hidden,
        test_event,
        tve_event,
        br_channel,
        br_titleId,
        blackout,
        title_id,
        video_start_utc,
        video_end_utc,
        group,
        channel,
        countries_available,
        destinations,
        edit_id,
        event_state,
        offers,
        availabilityEndTime,
        availabilityStartTime,
        isComposite,
        chapca_payload,
        adInventoryId,
        let_override: {
            name: override_name,
            description_body: override_description_body,
            description_body_ml: override_description_body_ml,
            secondary_title: override_secondary_title,
            secondary_title_ml: override_secondary_title_ml,
            name_ml: override_name_ml,
            sport_id: override_sport_id,
            league_id: override_league_id,
            home_competitor_id: override_home_competitor_id,
            away_competitor_id: override_away_competitor_id,
            season: override_season,
            scheduled_utc: override_scheduled_utc,
            scheduled_utc_end: override_scheduled_utc_end,
            free_preview: override_free_preview,
            free_event: override_free_event,
            venue_id: override_venue_id,
            week: override_week,
            season_type: override_season_type,
            series_id: override_series_id,
            hidden: override_hidden,
            test_event: override_test_event,
            tve_event: override_tve_event,
            br_channel: override_br_channel,
            br_titleId: override_br_titleId,
            blackout: override_blackout,
            title_id: override_title_id,
            video_start_utc: override_video_start_utc,
            video_end_utc: override_video_end_utc,
            group: override_group,
            channel: override_channel,
            countries_available: override_countries_available,
            destinations: override_destinations,
            edit_id: override_edit_id,
            event_state: override_event_state,
            offers: override_offers,
            availabilityEndTime: override_availabilityEndTime,
            availabilityStartTime: override_availabilityStartTime,
            adInventoryId: override_adInventoryId,
            isComposite: override_isComposite,
            chapca_payload: override_chapca_payload,
        } = {}
    } = item;

    const handleSmartView = () => {
        const isMultiLanguage = form?.name_ml ? true : false;
        const name = isMultiLanguage
            ? form.name_ml['en'] ||
              form.name_ml['en-US'] ||
              form.name_ml[Object.keys(form.name_ml)[0]]
            : form.name || override_name || name;

        const date =
            form.scheduled_utc ||
            override_scheduled_utc ||
            scheduled_utc ||
            null;
        const startDate = transformDate(date);
        const scheduledDate = new Date(date);
        const endDate = transformDate(
            scheduledDate.setDate(scheduledDate.getDate() + 2 * 7)
        );
        const url = `${ROOT_SMART_VIEW_URL}`;
        let channel = getFormattedChannel(
            override_br_channel || br_channel || form.br_channel
        );
        const elseValue = `${url}/search?networkFeed=${encodeURIComponent(
            channel
        )}&text=${encodeURIComponent(
            name
        )}&startDate=${startDate}&endDate=${endDate}`;

        const link =
            !date || !name || !startDate || !endDate
                ? ROOT_SMART_VIEW_URL
                : elseValue;

        window.open(link);
    };

    // Special case for the territories_available override:
    const override_territories_available =
        item?.let_override?.territories_available || null;

    // States that require competitor IDs:
    const [awayCompetitorId, setAwayCompetitorId] = useState(
        findTeam(away_competitor_id)
    );
    const [homeCompetitorId, setHomeCompetitorId] = useState(
        findTeam(home_competitor_id)
    );
    // Standard consts
    const classes = useStyles();
    const empty = <span></span>;
    const nextPage = `/events/${id}/appearance`;
    const offering_start_utc =
        item?.tenant_metadata?.hbo_max?.offering_start_utc;
    const offering_end_utc = item?.tenant_metadata?.hbo_max?.offering_end_utc;
    const override_offering_start_utc =
        item?.let_override?.tenant_metadata?.hbo_max?.offering_start_utc;
    const override_offering_end_utc =
        item?.let_override?.tenant_metadata?.hbo_max?.offering_end_utc;

    if (tenant.isMaxFamily)
        requiredFields.push('offering_start_utc', 'offering_end_utc');

    const dispatch = useDispatch();

    // Smart View Button Label:
    const SMART_VIEW_LABEL = (
        <Grid
            item
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
        >
            Smart View <OpenInNew className={classes.smartView} />
        </Grid>
    );
    // Home & Away from callbacks:
    const awayOverride = useCallback(() => {
        let awayIdOverride =
            teams && teams.length > 0
                ? teams.find(
                      (option) =>
                          option.id ==
                          (override_away_competitor_id || away_competitor_id)
                  )
                : null;
        if (awayIdOverride == null) {
            awayIdOverride = allTeams.find(
                (option) =>
                    option.id ==
                    (override_away_competitor_id || away_competitor_id)
            );
        }
        setAwayIdOverride(awayIdOverride);
    }, [
        JSON.stringify(teams),
        awayIdOverride,
        override_away_competitor_id,
        away_competitor_id
    ]);
    const homeOverride = useCallback(() => {
        let homeIdOverride =
            teams && teams.length > 0
                ? teams.find(
                      (option) =>
                          option.id ==
                          (override_home_competitor_id || home_competitor_id)
                  )
                : null;

        if (homeIdOverride == null) {
            homeIdOverride = allTeams.find(
                (option) =>
                    option.id ==
                    (override_home_competitor_id || home_competitor_id)
            );
        }

        setHomeIdOverride(homeIdOverride);
    }, [
        JSON.stringify(teams),
        homeIdOverride,
        override_home_competitor_id,
        home_competitor_id
    ]);

    // COMPETITORS:
    const fetchCompetitors = useCallback(() => {
        const sportId =
            override_sport_id || sport_id || form.sport_id || undefined;
        const leagueId =
            override_league_id || league_id || form.league_id || undefined;
        if (sportId !== undefined && leagueId !== undefined) {
            setLoading(true);
            services
                .getAttributes('competitors', 'sport_id', sportId)
                .then((competitors) => {
                    setTeams(competitors);
                })
                .catch((err) => {
                    dispatch(notifyError(`${err.name}: ${err.message}`));
                    setTeams([]);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setTeams([]);
        }
    }, [form.league_id || form.sport_id]);
    // LEAGUES:
    const fetchLeagues = useCallback(() => {
        let sportId =
            override_sport_id || sport_id || form.sport_id || undefined;

        if (sportId !== undefined) {
            setLoading(true);
            let route = getRoute('leagues', { sport_id: sportId });
            const data = services
                .fetchAllData(route)
                .then((data) => {
                    setLeagues(data);
                })
                .catch((err) => {
                    setLeagues([]);
                    setTeams([]);
                    dispatch(notifyError(`${err.name}: ${err.message}`));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLeagues([]);
            setTeams([]);
        }
    }, [override_sport_id || sport_id || form.sport_id]);
    // SERIES:
    const fetchSeries = useCallback(() => {
        if (override_series_id || series_id || form.series_id) {
            setLoading(true);
            services
                .getItem(
                    'series',
                    override_series_id || series_id || form.series_id
                )
                .then((data) => {
                    const tournament = tournaments.find(
                        (t) => t.id === data.tournament_id
                    );
                    setTournament(tournament?.name || '');
                    setSeries(data);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setSeries({});
        }
    }, [override_series_id || series_id || form.series_id, tournaments]);
    // SERIES List:
    const fetchSeriesList = useCallback(() => {
        const leagueId = override_league_id || league_id || form.league_id;
        if (leagueId) {
            setLoading(true);
            services
                .getItemWithQueryString(`series`, `league_id=${leagueId}`)
                .then((data) => {
                    if (Array.isArray(data)) {
                        setSeriesList(getSortedSeriesList(data));
                    }
                })
                .catch((err) => {
                    dispatch(notifyError(`${err.name}: ${err.message}`));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [override_league_id || league_id || form.league_id]);
    // VENUES:
    const fetchVenues = useCallback(() => {
        if (override_venue_id || venue_id || form.venue_id) {
            setLoading(true);
            services
                .getItem(
                    'venues',
                    override_venue_id || venue_id || form.venue_id
                )
                .then((data) => {
                    setVenue(data);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setVenue({});
        }
    }, [override_venue_id || venue_id || form.venue_id]);

    useEffect(() => {
        handleUpdate(valueChanged);
    }, [valueChanged]);

    // Leagues:
    useEffect(() => {
        if (form) {
            if (leagues && leagues.length) {
                // If there's only one and it's not the one assigned, select it.
                if (leagues.length === 1 && form.league_id !== leagues[0].id) {
                    handleFieldChange(NOOP, leagues[0].id, 'league_id');
                }
            }
        }
    }, [leagues]);
    // Fetch all sports:
    useEffect(() => {
        setLoading(true);
        services
            .fetchAllData('sports')
            .then((data) => {
                setSports(data);
            })
            .catch((err) => {
                setLeagues([]);
                dispatch(notifyError(`${err.name}: ${err.message}`));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    // Fetch all tournaments:
    useEffect(() => {
        setLoading(true);
        services
            .fetchAllData('tournaments')
            .then((data) => {
                setTournaments(data);
            })
            .catch((err) => {
                setLeagues([]);
                dispatch(notifyError(`${err.name}: ${err.message}`));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // Auto-select the tenant if on BR2:
    useEffect(() => {
        if (tenant.isBR2 && id === 'new') {
            services
                .fetchAllData('venues')
                .then((data) => {
                    if (data && data.length === 1) {
                        const venue = data[0];
                        handleFieldChange(NOOP, venue.id, 'venue_id');
                    }
                })
                .catch((err) => {
                    console.error(
                        `Error fetching all venues (${JSON.stringify(err)})`
                    );
                });
        }
    }, [id, tenant]);

    useEffect(() => {
        setIsTveEvent(override_tve_event || tve_event || form.tve_event);
    }, [override_tve_event || tve_event || form.tve_event]);

    useEffect(() => {
        if (!form.sport_id || form.sport_id === '') {
            // Clear the selected league:
            handleFieldChange(NOOP, null, 'league_id');
            setLeagues([]);
            // Clear any selected competitors:
            handleFieldChange(NOOP, null, 'home_competitor_id');
            handleFieldChange(NOOP, null, 'away_competitor_id');
            setTeams([]);
        } else {
            setTeams([]);
            fetchLeagues();
            fetchCompetitors();
        }
    }, [form.sport_id]);

    useEffect(() => {
        setTeams([]);
        fetchCompetitors();
    }, [form.league_id]);

    useEffect(() => {
        let homeCompetitorId = findTeam(home_competitor_id);
        setHomeCompetitorId(homeCompetitorId);
    }, [form.home_competitor_id]);

    useEffect(() => {
        let awayCompetitorId = findTeam(away_competitor_id);
        setAwayCompetitorId(awayCompetitorId);
    }, [form.away_competitor_id]);

    useEffect(() => {
        fetchSeriesList();
    }, [override_league_id, league_id, form.league_id]);

    useEffect(() => {
        homeOverride();
    }, [homeOverride]);

    useEffect(() => {
        awayOverride();
    }, [awayOverride]);

    useEffect(() => {
        fetchLeagues();
    }, [fetchLeagues]);

    useEffect(() => {
        fetchVenues();
    }, [fetchVenues]);

    useEffect(() => {
        fetchSeries();
    }, [fetchSeries]);

    const maxBlackoutGrid = (
        <Grid container item>
            <Grid item xs={12} alignItems='flex-end' justify='flex-start'>
                <CheckBox
                    label={localization.has_blackout}
                    name='blackout'
                    checked={
                        override_blackout || blackout || form.blackout || false
                    }
                    handleChange={handleChange}
                />
            </Grid>
            <Grid
                container
                alignItems='flex-end'
                justify='flex-start'
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    alignItems='flex-end'
                    justify='flex-start'
                >
                    <Input
                        label={`${localization.title_id}:`}
                        name='title_id'
                        type='number'
                        value={
                            override_title_id ||
                            title_id ||
                            form.title_id ||
                            null
                        }
                        handleChange={handleChange}
                        originalValue={(override_title_id && title_id) || null}
                        handleToggleChange={handleToggleChange}
                        required={requiredFields.includes('title_id')}
                    />
                </Grid>
                <Grid item xs={12} alignItems='flex-end' justify='flex-start'>
                    <Button
                        style={{
                            margin: '0 0 0 10px',
                            height: '40px',
                            margin: '0 0 12px 0'
                        }}
                        onClick={handleSmartView}
                    >
                        {SMART_VIEW_LABEL}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );

    const maxBlackout = tenant.isMax ? maxBlackoutGrid : empty;

    const videoTimesGrid = (
        <>
            <Grid item md={6} xs={12}>
                <Calendar
                    name='video_start_utc'
                    label={`${localization.video_start_time} (ET):`}
                    defaultValue={
                        override_video_start_utc ||
                        video_start_utc ||
                        form.video_start_utc ||
                        ''
                    }
                    handleChange={handleDateChange}
                    showToggle={showToggle}
                    enableToggle={enableToggle}
                    originalValue={event?.video_start_utc || null}
                    handleToggleChange={handleToggleChange}
                    required={requiredFields.includes('video_start_utc')}
                    error={error.video_start_utc}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <Calendar
                    name='video_end_utc'
                    label={`${localization.video_end_time} (ET):`}
                    defaultValue={
                        override_video_end_utc ||
                        video_end_utc ||
                        form.video_end_utc ||
                        ''
                    }
                    handleChange={handleDateChange}
                    originalValue={event?.video_end_utc || null}
                    error={error.video_end_utc}
                    required={requiredFields.includes('video_end_utc')}
                />
            </Grid>
        </>
    );

    const videoTimes = tenant.isBR ? videoTimesGrid : empty;

    // Offering Times for MAX
    const offeringCalendarGrid = (
        <>
            <Grid item md={6} xs={12}>
                <Calendar
                    name='offering_start_utc'
                    label={`${localization.offering_start_time} (ET):`}
                    defaultValue={
                        override_offering_start_utc ||
                        offering_start_utc ||
                        form?.tenant_metadata?.hbo_max?.offering_start_utc ||
                        ''
                    }
                    handleChange={handleMetaDateChange}
                    originalValue={
                        event?.tenant_metadata?.hbo_max?.offering_start_utc
                    }
                    error={error.offering_start_utc}
                    required={requiredFields.includes('offering_start_utc')}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <Calendar
                    name='offering_end_utc'
                    label={`${localization.offering_end_time} (ET):`}
                    defaultValue={
                        override_offering_end_utc ||
                        offering_end_utc ||
                        form?.tenant_metadata?.hbo_max?.offering_end_utc ||
                        ''
                    }
                    handleChange={handleMetaDateChange}
                    originalValue={
                        event?.tenant_meta_data?.hbo_max?.offering_end_utc
                    }
                    error={error.offering_end_utc}
                    required={requiredFields.includes('offering_end_utc')}
                />
            </Grid>
        </>
    );

    const offerTimeRange = tenant.isMaxFamily ? offeringCalendarGrid : empty;

    // Availability Times for BR 2
    const availabilityCalendarGrid = (
        <Grid item container spacing={2} sm={12}>
            <Grid item md={6} xs={12}>
                <Calendar
                    name='availabilityStartTime'
                    label={`${localization.availability_start_time} (ET):`}
                    defaultValue={
                        override_availabilityStartTime ||
                        availabilityStartTime ||
                        form?.availabilityStartTime ||
                        ''
                    }
                    handleChange={handleDateChange}
                    originalValue={event?.availabilityStartTime}
                    error={error.availabilityStartTime}
                    required={requiredFields.includes('availabilityStartTime')}
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <Calendar
                    name='availabilityEndTime'
                    label={`${localization.availability_end_time} (ET):`}
                    defaultValue={
                        override_availabilityEndTime ||
                        availabilityEndTime ||
                        form?.availabilityEndTime ||
                        ''
                    }
                    handleChange={handleDateChange}
                    originalValue={event?.availabilityEndTime}
                    error={error.availabilityEndTime}
                    required={requiredFields.includes('availabilityEndTime')}
                />
            </Grid>
        </Grid>
    );

    const availabilityTimeRange = tenant.isBR2
        ? availabilityCalendarGrid
        : empty;

    // Video Territories
    const videoTerritoriesGrid = (
        <Grid item md={6} xs={12}>
            <MultiSelectBox
                label={localization.video_available_in}
                options={countries}
                name='territories_available'
                handleMultipleSelectChange={handleMultipleSelectChange}
                defaultValue={
                    override_territories_available ||
                    territories_available ||
                    form.territories_available ||
                    []
                }
                showToggle={showToggle}
                enableToggle={enableToggle}
                originalValue={event?.territories_available || []}
                handleToggleChange={handleToggleChange}
                required={requiredFields.includes('territories_available')}
                localizedPlaceholder={localization.choose}
            />
        </Grid>
    );

    const videoAvailableInTerritories = tenant.isBR
        ? videoTerritoriesGrid
        : empty;

    const sponsorshipOptionsGrid = (
        <Grid container item spacing={2}>
            <Grid item xs={12}>
                <div className={classes.root}>
                    {`${localization.event_info_sponsorship_options}:`}
                </div>
            </Grid>
            <Grid item md={3} sm={4} xs={6}>
                <CheckBox
                    label={localization.event_info_free_preview}
                    name='free_preview'
                    checked={
                        override_free_preview ||
                        free_preview ||
                        form.free_preview ||
                        false
                    }
                    handleChange={handleChange}
                />
            </Grid>
            <Grid item md={3} sm={4} xs={6}>
                <CheckBox
                    label={localization.event_info_free_coverage}
                    name='free_event'
                    checked={
                        override_free_event ||
                        free_event ||
                        form.free_event ||
                        false
                    }
                    handleChange={handleChange}
                />
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    border: '2px solid black',
                    margin: '8px',
                    padding: '10px 10px 20px'
                }}
            >
                <CheckBox
                    label={localization.event_info_is_tve_event}
                    name='tve_event'
                    checked={
                        override_tve_event ||
                        tve_event ||
                        form.tve_event ||
                        false
                    }
                    handleChange={(e) => handleTveEvent(e)}
                    description={localization.event_info_tve_subheading}
                    size='lg'
                />
            </Grid>
        </Grid>
    );

    const sponsorshipOptions = tenant.isMax ? empty : sponsorshipOptionsGrid;

    const handleTveEvent = (e) => {
        if (!e.target.checked) {
            form.br_channel = null;
            form.br_titleId = null;
        }
        form.br_blackout = e.target.checked;
        handleChange(e);
    };

    const handleFormSubmit = (e) => {
        // GSP-3999: Force English name value onto name for save.
        if (tenant.isMultiLanguage) {
            form['name'] =
                form['name_ml']?.en || form['name_ml']?.['en-US'] || '';
        }
        onFormSubmit(e);
    };

    const duplicateTeamSet = (value, name) => {
        const home =
            form?.home_competitor_id || event?.home_competitor_id || null;
        const away =
            form?.away_competitor_id || event?.away_competitor_id || null;

        return (
            (name === 'home_competitor_id' && away === value) ||
            (name === 'away_competitor_id' && home === value) ||
            false
        );
    };

    const handleTeamChange = (event, value, name) => {
        event.preventDefault();
        if (value !== undefined && duplicateTeamSet(value, name)) {
            dispatch(
                notifyError(`Error: Home and Away teams cannot be the same`)
            );
            value = undefined;
        }
        handleFieldChange(event, value, name);
    };

    const handleSportChange = (event, value, name) => {
        handleFieldChange(NOOP, '', 'league_id');
        handleFieldChange(event, value, name);
    };

    const getMultilanguageStub = () => {
        const name_ml = {};
        tenantLanguages.forEach((language) => {
            name_ml[language] = '';
        });
        return name_ml;
    };
    
    const getBooleanValue = (arr = [], defaultValue = false) => {
        if (!Array.isArray(arr)) {
            return defaultValue;
        }
        for (var i = 0; i < arr.length; i++) {
            const value = arr[i];
            if (typeof value === 'boolean') {
                return value;
            } else if (typeof value === 'number') {
                return value === 0 ? false : true;
            } else if (typeof value === 'string') {
                return value.toLowerCase() === 'true' ? true : false;
            }
        }
        return defaultValue;
    };

    const maxInputs = getMaxMultiLanguageInputs({
        description_body,
        description_body_ml,
        enableToggle,
        error,
        event,
        form,
        handleChange,
        handleToggleChange,
        id,
        localization,
        name,
        name_ml,
        override_description_body,
        override_description_body_ml,
        override_name,
        override_name_ml,
        override_secondary_title,
        override_secondary_title_ml,
        requiredFields,
        secondary_title,
        secondary_title_ml,
        showToggle,
        tenant
    });

    const nameInput = (() => {
        let input = <></>;

        if (tenant.isMax) {
            // Skip this step; use the max-specific inputs instead.
            return input;
        }

        if (tenant.isMultiLanguage) {
            input = (
                <MultilanguageInput
                    error={error.name_ml}
                    handleChange={handleChange}
                    label={`${localization.event_info_event_name} (%LANG%):`}
                    languages={tenantLanguages}
                    name='name_ml'
                    originalValue={event?.name_ml || getMultilanguageStub()}
                    required={requiredFields.includes('name_ml')}
                    showCopy={true}
                    value={
                        override_name_ml ||
                        name_ml ||
                        form.name_ml ||
                        getMultilanguageStub()
                    }
                />
            );
        } else {
            input = (
                <Grid item xs={12}>
                    <Input
                        enableToggle={enableToggle}
                        error={error.name}
                        handleChange={handleChange}
                        handleToggleChange={handleToggleChange}
                        label={`${localization.event_info_event_name}:`}
                        name='name'
                        originalValue={event?.name || ''}
                        required={requiredFields.includes('name')}
                        showCopy={true}
                        showToggle={showToggle}
                        value={override_name || name || form.name || ''}
                    />
                </Grid>
            );
        }

        return input;
    })();

    const cloneEvent = async (id) => {
        const result = await services.cloneItem(`events/clone/${id}`);
        if (result && result.data) {
            if (result.data.status === true) {
                console.info('Clone Event Initiated');
                window.open(`/events/${result.data._id}`, '_blank');
            } else if (result.data.status === false) {
                dispatch(notifyError(`Error - Clone event`));
            }
        }
    };

    const spacer = (
        <Grid item xs={12}>
            <hr
                style={{
                    borderTop: '1px solid #cccccc',
                    margin: '0 0 20px',
                    padding: '0'
                }}
            />
        </Grid>
    );

    const preCaidGrid = empty; 
    /*
    const preCaidGrid = tenant.isBR2 ? (
        <PreCaid
            localization={{
                select: localization.select,
                invalid_selection:
                    localization.invalid_selection
            }}
            name={'adInventoryId'}
            value={override_adInventoryId || adInventoryId || form.adInventoryId || null}
            handleChange={handleChange}            
            spacer={spacer}
        />
    ) : (
        empty
    );
    */

    return (
        <div className={classes.form}>
            <div className={classes.root}>
                <Container maxWidth='lg'>
                    {/* Loader */}
                    <Loader isInProgress={isInProgress || loading} />
                    {/* Form */}
                    <form
                        noValidate
                        autoComplete='on'
                        onSubmit={(e) => handleFormSubmit(e)}
                        style={{
                            display: isInProgress || loading ? 'none' : 'block'
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    border: '2px solid black',
                                    margin: '8px',
                                    padding: '10px 10px 20px'
                                }}
                            >
                                <CheckBox
                                    label={localization.event_info_hide_event}
                                    name='hidden'
                                    checked={getBooleanValue(
                                        [override_hidden, hidden, form.hidden],
                                        false
                                    )}
                                    handleChange={handleChange}
                                    description={
                                        localization.event_info_hide_event_subheading
                                    }
                                    size='lg'
                                />
                            </Grid>
                            {id !== 'new' && Object.keys(event).length > 0 && (
                                <Grid container spacing={0}>
                                    <Detail path={path} id={id} event={event} />
                                </Grid>
                            )}
                            {nameInput}
                            {maxInputs}
                            <Grid item md={6} xs={12}>
                                <AutocompleteBox
                                    label={`${localization.event_info_sport}:`}
                                    options={sports}
                                    name='sport_id'
                                    nameField='name'
                                    handleChange={handleSportChange}
                                    defaultValue={
                                        override_sport_id ||
                                        sport_id ||
                                        form.sport_id ||
                                        null
                                    }
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={event?.sport_id || null}
                                    handleToggleChange={handleToggleChange}
                                    error={error.sport_id}
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                    required={requiredFields.includes(
                                        'sport_id'
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <AutocompleteBox
                                    label={`${localization.event_info_league}:`}
                                    options={leagues}
                                    name='league_id'
                                    nameField='name'
                                    handleChange={handleFieldChange}
                                    defaultValue={
                                        override_league_id ||
                                        league_id ||
                                        form.league_id ||
                                        null
                                    }
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={event?.league_id || null}
                                    handleToggleChange={handleToggleChange}
                                    error={error.league_id}
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                    required={requiredFields.includes(
                                        'league_id'
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <AutocompleteBox
                                    label={`${localization.event_info_home_team}:`}
                                    options={teams.filter((team) => {
                                        const away =
                                            form?.away_competitor_id ||
                                            awayIdOverride ||
                                            awayCompetitorId ||
                                            null;
                                        return team?.id !== away?.id;
                                    })}
                                    name='home_competitor_id'
                                    nameField='name'
                                    handleChange={handleTeamChange}
                                    defaultValue={
                                        homeIdOverride ||
                                        form.home_competitor_id ||
                                        null
                                    }
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={
                                        homeIdOverride ||
                                        form.home_competitor_id ||
                                        null
                                    }
                                    handleToggleChange={handleToggleChange}
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                    required={requiredFields.includes(
                                        'home_competitor_id'
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <AutocompleteBox
                                    label={`${localization.event_info_away_team}:`}
                                    options={teams.filter((team) => {
                                        const home =
                                            form?.home_competitor_id ||
                                            homeIdOverride ||
                                            homeCompetitorId ||
                                            null;
                                        return team?.id !== home?.id;
                                    })}
                                    id='away_competitor_id'
                                    name='away_competitor_id'
                                    nameField='name'
                                    handleChange={handleTeamChange}
                                    defaultValue={
                                        awayIdOverride ||
                                        form.away_competitor_id ||
                                        null
                                    }
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={
                                        awayIdOverride ||
                                        form.away_competitor_id ||
                                        null
                                    }
                                    handleToggleChange={handleToggleChange}
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                    required={requiredFields.includes(
                                        'away_competitor_id'
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <LiveAutocomplete
                                    label={`${localization.event_info_venue}:`}
                                    name='venue_id'
                                    nameField='name'
                                    handleChange={handleFieldChange}
                                    defaultValue={
                                        override_venue_id ||
                                        venue_id ||
                                        form.venue_id ||
                                        null
                                    }
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={event?.venue_id || null}
                                    handleToggleChange={handleToggleChange}
                                    localization={localization}
                                    serviceName='venues'
                                    error={error.venue_id}
                                    required={requiredFields.includes(
                                        'venue_id'
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Input
                                    label={`${localization.event_info_venue_country}`}
                                    value={
                                        venue?.let_override?.country ||
                                        venue?.country ||
                                        ''
                                    }
                                    showToggle={false}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Input
                                    label={`${localization.event_info_venue_state}:`}
                                    value={
                                        venue?.let_override?.state ||
                                        venue?.state ||
                                        ''
                                    }
                                    showToggle={false}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Input
                                    label={`${localization.event_info_venue_city}:`}
                                    value={
                                        venue?.let_override?.city ||
                                        venue?.city ||
                                        ''
                                    }
                                    showToggle={false}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Input
                                    label={`${localization.event_info_time_zone}:`}
                                    value={(venue && venue.time_zone) || ''}
                                    showToggle={false}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <AutocompleteBox
                                    label={`${localization.event_info_series_name}:`}
                                    options={seriesList}
                                    id='series_id'
                                    name='series_id'
                                    nameField='name'
                                    handleChange={handleFieldChange}
                                    defaultValue={
                                        override_series_id ||
                                        series_id ||
                                        form.series_id ||
                                        null
                                    }
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={
                                        override_series_id || series_id || null
                                    }
                                    handleToggleChange={handleToggleChange}
                                    localizedPlaceholder={
                                        localization.advanced_search_select
                                    }
                                    required={requiredFields.includes(
                                        'series_id'
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Input
                                    label={`${localization.event_info_tournament}:`}
                                    value={tournament || ''}
                                    showToggle={false}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Input
                                    label={`${localization.event_info_week}:`}
                                    name='week'
                                    type='number'
                                    value={
                                        override_week || week || form.week || ''
                                    }
                                    handleChange={handleChange}
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={
                                        (override_week && week) || ''
                                    }
                                    handleToggleChange={handleToggleChange}
                                    required={requiredFields.includes('week')}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Input
                                    label={`${localization.event_info_season}:`}
                                    name='season'
                                    type='number'
                                    value={
                                        override_season ||
                                        season ||
                                        form.season ||
                                        ''
                                    }
                                    handleChange={handleChange}
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={
                                        (override_season && season) || ''
                                    }
                                    handleToggleChange={handleToggleChange}
                                    required={requiredFields.includes('season')}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Input
                                    label={`${localization.event_info_season_type}:`}
                                    name='season_type'
                                    value={
                                        override_season_type ||
                                        season_type ||
                                        form.season_type ||
                                        ''
                                    }
                                    handleChange={handleChange}
                                    originalValue={
                                        (override_season_type && season_type) ||
                                        ''
                                    }
                                    handleToggleChange={handleToggleChange}
                                    required={requiredFields.includes(
                                        'season_type'
                                    )}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Calendar
                                    name='scheduled_utc'
                                    label={`${localization.event_info_start_time} (ET):`}
                                    defaultValue={
                                        override_scheduled_utc ||
                                        scheduled_utc ||
                                        form.scheduled_utc ||
                                        ''
                                    }
                                    handleChange={handleDateChange}
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={event?.scheduled_utc || null}
                                    handleToggleChange={handleToggleChange}
                                    required={requiredFields.includes(
                                        'scheduled_utc'
                                    )}
                                    error={error.scheduled_utc}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Calendar
                                    name='scheduled_utc_end'
                                    label={`${localization.event_info_end_time} (ET):`}
                                    defaultValue={
                                        override_scheduled_utc_end ||
                                        scheduled_utc_end ||
                                        form.scheduled_utc_end ||
                                        ''
                                    }
                                    handleChange={handleDateChange}
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    originalValue={
                                        event?.scheduled_utc_end || null
                                    }
                                    handleToggleChange={handleToggleChange}
                                    required={requiredFields.includes(
                                        'scheduled_utc_end'
                                    )}
                                    error={error.scheduled_utc_end}
                                />
                            </Grid>
                            {offerTimeRange}
                            {availabilityTimeRange}
                            {videoTimes}
                            {videoAvailableInTerritories}
                            {maxBlackout}
                            {sponsorshipOptions}
                            {isTveEvent && (
                                <>
                                    <Grid
                                        xs={12}
                                        container
                                        item
                                        spacing={1}
                                        style={{
                                            alignItems: 'flex-end',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        <Grid item xs={6} md={3}>
                                            <AutocompleteBox
                                                labe={`${localization.wbd_channel}:`}
                                                options={BR_CHANNELS}
                                                name='br_channel'
                                                nameField='name'
                                                handleChange={handleFieldChange}
                                                defaultValue={
                                                    override_br_channel ||
                                                    br_channel ||
                                                    form.br_channel ||
                                                    null
                                                }
                                                originalValue={
                                                    override_br_channel ||
                                                    br_channel ||
                                                    null
                                                }
                                                handleToggleChange={
                                                    handleToggleChange
                                                }
                                                showToggle={false}
                                                enableToggle={false}
                                                localizedPlaceholder={
                                                    localization.advanced_search_select
                                                }
                                                required={requiredFields.includes(
                                                    'br_channel'
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={4}>
                                            <Button
                                                style={{
                                                    height: '40px',
                                                    margin: '0 0 12px 0'
                                                }}
                                                onClick={handleSmartView}
                                            >
                                                {SMART_VIEW_LABEL}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        style={{ paddingLeft: '10px' }}
                                    >
                                        <Grid item xs={5}>
                                            <Input
                                                label={localization.title_id}
                                                name='br_titleId'
                                                type='number'
                                                value={
                                                    override_br_titleId ||
                                                    br_titleId ||
                                                    form.br_titleId ||
                                                    ''
                                                }
                                                handleChange={handleChange}
                                                required={requiredFields.includes(
                                                    'br_titleId'
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {
                                // Event State & Edit ID
                            }
                            {(tenant?.isBR2 || tenant?.isMax) && (
                                <Grid item container xs={12}>
                                    {spacer}
                                    <Grid item container spacing={2}>
                                        <Grid item xs={6}>
                                            <Input
                                                label={`${localization.event_state_title}:`}
                                                name='event_state'
                                                value={
                                                    override_event_state ||
                                                    event_state ||
                                                    null
                                                }
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                label={`${localization.edit_id_title}:`}
                                                name='edit_id'
                                                value={
                                                    override_edit_id ||
                                                    edit_id ||
                                                    form.edit_id ||
                                                    null
                                                }
                                                handleChange={handleChange}
                                                originalValue={
                                                    (override_edit_id &&
                                                        edit_id) ||
                                                    null
                                                }
                                                handleToggleChange={
                                                    handleToggleChange
                                                }
                                                required={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {
                                // Destinations:
                            }
                            {tenant?.isBR2 && (
                                <Grid item container xs={12}>
                                    {spacer}
                                    <Destinations
                                        label={
                                            localization.event_destinations_title
                                        }
                                        name='destinations'
                                        id='destinations'
                                        handleChange={handleChange}
                                        value={
                                            destinations ||
                                            override_destinations ||
                                            form.destinations ||
                                            []
                                        }
                                    />
                                </Grid>
                            )}
                            {
                                // Offers:
                            }
                            {tenant?.isBR2 && (
                                <Grid item container xs={12}>
                                    {spacer}
                                    <Offers
                                        label={localization.offers_title}
                                        localization={localization}
                                        name='offers'
                                        id='offers'
                                        handleChange={handleChange}
                                        availableDestinations={
                                            destinations ||
                                            override_destinations ||
                                            form.destinations ||
                                            []
                                        }
                                        value={
                                            offers ||
                                            override_offers ||
                                            form.offers ||
                                            {}
                                        }
                                    />
                                </Grid>
                            )}
                            {
                                // Groups for MAX & BR2:
                            }
                            {tenant && (tenant.isMax || tenant.isBR2) && (
                                <Grid item container xs={12}>
                                    {spacer}
                                    <Grid item xs={12}>
                                        <Group
                                            groups={GROUP_OPTIONS}
                                            handleChange={handleChange}
                                            localization={{
                                                select: localization.select,
                                                invalid_selection:
                                                    localization.invalid_selection
                                            }}
                                            originalValue={event?.group || ''}
                                            selectTitle={
                                                localization.group_title
                                            }
                                            value={
                                                override_group ||
                                                group ||
                                                form.group ||
                                                ''
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ padding: '20px 0 10px' }}
                                    >
                                        <Channels
                                            handleChange={handleChange}
                                            localization={{
                                                select: localization.select,
                                                invalid_selection:
                                                    localization.invalid_selection
                                            }}
                                            originalValue={event?.channel || ''}
                                            selectTitle={
                                                localization.channel_title
                                            }
                                            tenant={tenant}
                                            value={
                                                override_channel ||
                                                channel ||
                                                form.channel ||
                                                ''
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            )}                            
                            { tenant && tenant?.isMax && (
                                <Grid item container xs={12}>
                                    {spacer}
                                    <Grid item container spacing={2}>
                                        <Grid
                                            item
                                            sm={12}
                                            key={`isComposite`}
                                            data-testid={`isComposite`}                                        >
                                            <CheckBox
                                                checked={getBooleanValue([override_isComposite, isComposite, form.isComposite])}
                                                disabled={!tenant.isMax}
                                                name={"isComposite"}
                                                handleChange={handleChange}
                                                label={localization.is_composite}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={2}>
                                        <Grid item xs={8}>
                                            <InputLabel
                                                className={classes.labelStyle}
                                                shrink
                                            >
                                                {localization.chapca_payload}
                                            </InputLabel>
                                            {
                                                // The below textarea isn't part of the actual form; no name, no send.
                                            }
                                            <textarea 
                                                style={{height: '26rem', width: '100%', resize: 'none'}}
                                                value={(override_chapca_payload || chapca_payload) && (JSON.stringify(override_chapca_payload || chapca_payload, null, 2))} 
                                                disabled />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {
                                // Countries Available:
                            }
                            {tenant && tenant.isMax && (
                                <Grid item container xs={12}>
                                    {spacer}
                                    <CountriesAvailable
                                        handleMultipleSelectChange={
                                            handleMultipleSelectChange
                                        }
                                        localization={{
                                            choose: localization.choose,
                                            select: localization.select,
                                            invalid_selection:
                                                localization.invalid_selection
                                        }}
                                        name={'countries_available'}
                                        originalValue={
                                            event?.let_override
                                                ?.countries_available || []
                                        }
                                        selectTitle={
                                            localization.countries_available
                                        }
                                        value={
                                            override_countries_available ||
                                            countries_available ||
                                            form.countries_available ||
                                            []
                                        }
                                    />
                                </Grid>
                            )}
                            {
                                // Test Event:
                            }
                            {tenant && tenant.isMaxFamily && (
                                <TestEvent
                                    checked={
                                        override_test_event ||
                                        test_event ||
                                        form.test_event ||
                                        false
                                    }
                                    handleChange={handleChange}
                                    label={localization.test_event}
                                    name={'test_event'}
                                />
                            )}
                            {
                                // Pre-CAID for BR2
                            }
                            {preCaidGrid}
                        </Grid>
                        {/* Clone and Save Button */}
                        <Box
                            className={classes.info}
                            display='flex'
                            justifyContent='flex-end'
                            gap={3}
                            mt={2}
                        >
                            <Buttons
                                type='button'
                                label={localization.event_info_clone}
                                onClick={(e) => cloneEvent(id)}
                                showForWriteAccessUsers
                            />
                            <Buttons
                                type='submit'
                                label={localization.save}
                                showForWriteAccessUsers
                            />
                            {id !== 'new' && (
                                <IconButton
                                    aria-label='next-page'
                                    style={{ color: 'black' }}
                                    onClick={(e) => handleForward(e, nextPage)}
                                >
                                    <RemoveOutlined
                                        fontSize='large'
                                        style={{ marginRight: '-16px' }}
                                    />
                                    <ArrowForwardOutlined fontSize='large' />
                                </IconButton>
                            )}
                        </Box>
                    </form>
                </Container>
            </div>
            <Suspense fallback={<CircularProgress color='inherit' />}>
                <LanguageSelect setRefresh={setRefresh} />
            </Suspense>
        </div>
    );
};
