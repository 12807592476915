import React, { useEffect, useState, useCallback } from 'react';
import { Appearance } from './Appearance';
import { Confirm } from '../../shared/Confirm';
import { CustomBreadcrumbs } from '../../shared/Breadcrumbs';
import { Details } from '../../Details';
import { Heading } from '../../shared/Heading';
import { Info } from './Info';
import { useHistory, useLocation, useParams, Redirect } from 'react-router-dom';
import { utils } from '../../../utils/utils';
import services from '../../../services/apiService';
// Notifications
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const JSONSchema = require('let-json-schemas/v1/input_db/venue.json');

const VenuesSteps = ({
    slug,
    id,
    venues,
    setRefresh,
    JSONSchema,
    config,
    handleUpdate,
    handleForward
}) => {
    if (id === 'new' && slug !== 'venue-info') {
        return <Redirect to='/venues/new/venue-info' />;
    }

    switch (slug) {
        case 'venue-info':
            return (
                <Info
                    id={id}
                    venues={venues}
                    setRefresh={setRefresh}
                    venueSchema={JSONSchema}
                    handleUpdate={handleUpdate}
                    handleForward={handleForward}
                />
            );
        case 'appearance':
            return (
                <Appearance
                    id={id}
                    venues={venues}
                    setRefresh={setRefresh}
                    venueSchema={JSONSchema}
                    config={config}
                    handleUpdate={handleUpdate}
                    handleForward={handleForward}
                />
            );
        default:
            return <div></div>;
    }
};

export const CreateEditVenues = ({ config }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    let location = useLocation();
    let path = location.pathname.split('/').slice(-1)[0];
    let { id } = useParams();

    const localization = useSelector(strings);

    const [details, setDetails] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [link, setLink] = useState(path);
    const [loadError, setLoadError] = useState();
    const [forwarding, setForwarding] = useState({});
    const [openConfirm, setOpenConfirm] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [venues, setVenues] = useState({});

    let steps = [
        {
            title: localization.venue_info,
            slug: `venue-info`
        },
        {
            title: localization.appearance,
            slug: `appearance`
        }
    ];

    const fetchVenues = useCallback(() => {
        id !== 'new' &&
            services
                .getItem('venues', id, true)
                .then((res) => {
                    if (res && Object.keys(res).length === 0) {
                        setLoadError(
                            `Error: Venue ${id} does not exist on this tenant`
                        );
                        history.push(`/venues`);
                        return;
                    }

                    setVenues(res);

                    const summary = utils.serializeSummary(
                        'Venue',
                        res,
                        localization
                    );

                    id !== 'new' && setDetails(summary);
                })
                .catch((err) => {
                    setLoadError(`${err.name}:  ${err.message}`);
                })
                .finally(() => {
                    // Nothing.
                });
    }, [refresh, id]);

    useEffect(() => {
        fetchVenues();
    }, [fetchVenues]);

    useEffect(() => {
        if (loadError) {
            dispatch(notifyError(loadError));
            setLoadError();
        }
    }, [loadError]);

    const handleUpdate = (event) => {
        setIsUpdating(event);
    };

    const handleForward = (e, path) => {
        e.preventDefault();
        if (isUpdating) {
            setLink(path);
            setOpenConfirm(true);
            setForwarding({ e, path });
        } else {
            id !== 'new' && history.push(path);
        }
    };

    const handleStay = () => {
        setOpenConfirm(false);
    };

    const handleOverride = () => {
        setIsUpdating(false);
        setOpenConfirm(false);

        if (forwarding.path) {
            history.push(forwarding.path);
        }
    };

    const handleNavigation = (e, id, slug) => {
        const path = `/venues/${id}/${slug}`;
        handleForward(e, path);
    };

    return (
        <>
            <Heading heading={localization.create_edit_venues} />
            <CustomBreadcrumbs
                steps={steps}
                id={id}
                onChange={handleNavigation}
            />
            {id !== 'new' && Object.keys(venues).length > 0 && (
                <Details details={details} />
            )}
            <VenuesSteps
                slug={path}
                id={id}
                venues={venues}
                setRefresh={setRefresh}
                JSONSchema={JSONSchema}
                config={config}
                handleUpdate={handleUpdate}
                handleForward={handleForward}
            />
            <Confirm
                path={link}
                open={openConfirm}
                onYes={handleOverride}
                onNo={handleStay}
            />
        </>
    );
};
