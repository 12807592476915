import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { Grid, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    getEventId,
    getLanguages,
    getShowEdit,
    setShowEdit
} from '../../reducer/multilanguageSlice';
import { notifyError } from '../../reducer/notificationReducer';
import { strings } from '../../reducer/localizationSlice';
import { Buttons } from './Buttons';
import { Modal } from '@mui/material';
import { allLanguages } from '../../reducer/includes/multilanguage';
import services from '../../services/apiService';

let dispatch;

export const getModalStyle = () => {
    const top = 50;
    const left = 50;
    return {
        overflow: 'scroll',
        maxHeight: '90vh',
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
};

export const handleClose = () => {
    dispatch(setShowEdit(false));
    setUpdateDisabled(true);
};

export const makeParameter = (isoCode) => {
    return isoCode.replace(/_|\-/gi, '').toLowerCase();
};

export const useStyles = makeStyles((theme) => ({
    cancelButton: {
        background: 'gray',
        color: 'white'
    },
    countryList: {
        background: 'rgba(150,150,150,0.03)',
        borderTop: '1px solid gray',
        borderLeft: '1px solid gray',
        borderRight: '1px solid rgba(150,150,150,0.5)',
        borderBottom: '1px solid rgba(150,150,150,0.5)',
        padding: '1em',
        maxHeight: '50vh',
        overflow: 'scroll',
        scrolly: 'auto'
    },
    iso: {
        fontFamily: 'monospace',
        margin: '0 0.8rem 0 0'
    },
    searchGrid: {
        marginTop: '50px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#000'
    },
    paper: {
        position: 'absolute',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: '25px',
        '& .scroll': {
            minHeight: '80vh',
            maxHeight: '80vh',
            overflowY: 'scroll'
        }
    },
    scroll: {
        minHeight: '80vh',
        maxHeight: '80vh',
        overflowY: 'scroll'
    }
}));

export const LanguageSelect = (props) => {
    dispatch = useDispatch();

    const { setRefresh } = props;

    const classes = useStyles();
    const currentLanguages = useSelector(getLanguages);
    const eventId = useSelector(getEventId);

    const l10n = useSelector(strings);
    const open = useSelector(getShowEdit);

    const [modalStyle] = useState(getModalStyle);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [updateDisabled, setUpdateDisabled] = useState(true);

    const handleChecked = (e) => {
        let newSelectedLanguages = selectedLanguages.filter(
            (value) => value !== e.target.value
        );
        if (e.target.checked) {
            newSelectedLanguages.push(e.target.value);
        }
        setSelectedLanguages(Array.from(new Set(newSelectedLanguages)));
        setUpdateDisabled(false);
    };

    const getChecked = (isoCode) => {
        return selectedLanguages.find((code) => isoCode === code);
    };

    const handleOnUpdate = async () => {
        // Disable the update button:
        setUpdateDisabled(true);
        // Get the refresh ready:
        setRefresh(false);
        try {
            // Build the payload:
            let response = await services.updateLanguages(
                eventId,
                selectedLanguages
            );

            if (response && response?.data) {
                setRefresh(true);
            }
        } catch (e) {
            // Handle Ooopsies Here
            dispatch(notifyError(`${e.name}:  ${e.message}`));
        } finally {
            // Close the modal
            dispatch(setShowEdit(false));
        }
    };

    const handleOnCancel = () => {
        let arr = currentLanguages.map((lang) => lang.isoCode);
        setSelectedLanguages([...arr]);
        setUpdateDisabled(true);
        dispatch(setShowEdit(false));
    };

    useMemo(() => {
        setSelectedLanguages(currentLanguages.map((lang) => lang.isoCode));
    }, [currentLanguages]);

    return (
        <Modal
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
            open={open}
            onClose={handleClose}
            style={{ overflow: 'scroll' }}
            disableEnforceFocus
        >
            <div style={modalStyle} className={classes.paper}>
                <Container maxWidth={'lg'} sx={{ mb: 3 }}>
                    <Grid>
                        <h2>{l10n.supported_languages}</h2>
                    </Grid>
                    <Grid id='languages' className={classes.countryList}>
                        {allLanguages.map((language, index) => {
                            return (
                                <Grid
                                    container
                                    key={`language_option_${index}`}
                                    style={{
                                        margin: '8px 0'
                                    }}
                                >
                                    <Grid item>
                                        <input
                                            defaultChecked={getChecked(
                                                language.isoCode
                                            )}
                                            id={makeParameter(language.isoCode)}
                                            name={makeParameter(
                                                language.isoCode
                                            )}
                                            onChange={handleChecked}
                                            style={{
                                                margin: '0 8px 0 0'
                                            }}
                                            type='checkbox'
                                            value={language.isoCode}
                                        />
                                    </Grid>
                                    <Grid>
                                        <label
                                            htmlFor={makeParameter(
                                                language.isoCode
                                            )}
                                        >
                                            {language.languageName}{' '}
                                            <span className={classes.iso}>
                                                {language.isoCode}
                                            </span>
                                        </label>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        justifyContent={'right'}
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        <Grid item>
                            <Button
                                className={classes.cancelButton}
                                label={l10n.cancel}
                                onClick={() => {
                                    handleOnCancel();
                                }}
                                type='submit'
                            >
                                {l10n.cancel}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Buttons
                                disabled={updateDisabled}
                                label={l10n.update}
                                onClick={handleOnUpdate}
                                showForWriteAccessUsers
                                type='submit'
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Modal>
    );
};
