import React from 'react';
import { Grid } from '@mui/material';
import { Textarea } from '../../shared/Textarea';
import { useMemo, useState } from 'react';
// Current list of all known languages.
// NOTE: This will likely be externalized at some point.
//       For now, here for convenience.
//       *** ALSO in SecondaryTitleML ***
import { languages as LANGUAGE_CONSTANTS } from '../../../data';

const MultiLanguageTextAreaInput = (props) => {
    const {
        currentlanguage,
        handleChange,
        label,
        name,
        originalValue,
        overrideValue,
        simpleTitle
    } = props;

    const [value, setValue] = useState(null);

    useMemo(() => {
        setValue({
            ...(originalValue || {}),
            ...(overrideValue || {})
        });
    }, [originalValue, overrideValue]);

    const onChange = (e) => {
        // Create a faux event and send it to handleChange
        const fauxEvent = {
            target: {
                name: name,
                value: {
                    ...originalValue,
                    ...overrideValue,
                    [e.target.name]: e.target.value
                }
            }
        };
        handleChange(fauxEvent);
    };

    const getLabel = (lang) => {
        let str = label || '[No Label Provided]';
        return simpleTitle
            ? `${str}:`
            : `${str} (${LANGUAGE_CONSTANTS[lang]}, ${lang}): `;
    };

    return (
        <Grid
            item
            container
            xs={12}
            spacing={2}
            data-testid={'MultilanguageTextInput'}
        >
            {value &&
                Object.keys(value).map((key, index) => {
                    const style = {
                        display:
                        currentlanguage && currentlanguage !== key
                                ? 'none'
                                : 'block'
                    };
                    return (
                        <Grid
                            item
                            xs={12}
                            key={`lang_${index}`}
                            style={style}
                            data-testid={`MultilanguageTextInput:${name}:${key}`}
                        >
                            <Textarea
                                handleChange={onChange}
                                label={getLabel(key)}
                                name={key}
                                originalValue={originalValue[key] || ''}
                                defaultValue={overrideValue[key] || ''}
                            />
                        </Grid>
                    );
                })}
        </Grid>
    );
};

export default MultiLanguageTextAreaInput;
