import React, { useState } from 'react';
import awsStorage from '../../services/awsStorage';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';
import { FormControl, Grid, InputLabel } from '@mui/material';
import { Input } from './Input';
import { isAdmin, isOperator } from '../../reducer/userReducer';
import { Loader } from './Loader';
import { makeStyles, withStyles } from '@mui/styles';
import {
    PanoramaOutlined,
    PlayCircleOutlineOutlined
} from '@mui/icons-material';
import { selectTenant } from '../../reducer/tenantSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { encodeFilename, getFileFilter, utils } from '../../utils/utils';
// Notifications:
import { notifyError } from '../../reducer/notificationReducer';
import { Confirm } from './Confirm';
import { strings } from '../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            //margin: theme.spacing(1),
        },
        display: 'flex',
        flexDirection: 'row',
        '& .MuiButton-containedPrimary': {
            color: '#fff',
            backgroundColor: '#000'
        }
    },
    buttonWarning: {
        margin: '0 0 0 5px',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'darkred'
        }
    },
    dataContainer: {},
    input: {
        display: 'none'
    },
    upload: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0 8px 0 0'
    },
    buttonLabel: {
        alignself: 'center',
        marginTop: 33
    },
    imgPreview: {
        display: 'flex',
        minHeight: '9.5rem',
        padding: '.75rem',
        position: 'relative',
        background: '#d8d8d8',
        color: '#c3c3c3',
        height: '9.5rem',
        margin: '0 8px 10px'
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        width: '100%'
    },
    icon: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)'
    },
    labelStyle: {
        fontWeight: 600,
        fontSize: '1.5em',
        color: 'black',
        marginLeft: '-12px'
    },
    margin: {
        margin: theme.spacing(1),
        width: '100%'
    },
    outerContainer: {
        padding: ' 0 0 20px 0'
    }
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            boxShadow: `${alpha(
                theme.palette.primary.main,
                0.25
            )} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main
        }
    }
}))(InputBase);

const MultilanguageImageUpload = (props) => {
    const isAdminUser = useSelector(isAdmin);
    const isOperatorUser = useSelector(isOperator);
    const tenant = useSelector(selectTenant);

    const dispatch = useDispatch();

    const {
        bucket,
        config,
        data,
        enableDelete,
        fileName,
        handleFileChange,
        handleStateChange,
        handleTextChange,
        hideBrowseButton,
        imageId,
        item,
        label,
        language,
        metadata,
        name,
        overrideUrlVal,
        required,
        type,
        allowedFormat
    } = props;

    const { aspect_ratio, format, kind, notes, size } = data;

    const overrideUrlFieldName = `${name}_override`;
    const metaDataFieldName = `${name}_metadata`;
    const classes = useStyles();

    const [confirmContent, setConfirmContent] = useState(null);
    const [confirmTitle, setConfirmTitle] = useState('Confirmation');
    const [metaDataState, setMetaDataState] = useState({
        name: metaDataFieldName,
        val: metadata,
        updated: false
    });
    const [showConfirm, setShowConfirm] = useState(false);
    const [state, setState] = useState({
        [name]: { file: '', fileName: fileName }
    });
    const [tenantName, setTenantName] = useState();
    const [urlOverride, setUrlOverride] = useState({
        name: overrideUrlFieldName,
        val: overrideUrlVal,
        updated: false
    });

    const allowedFilter = getFileFilter(type, allowedFormat);

    useEffect(() => {
        setTenantName(tenant.tenantName);
    }, [tenant]);

    useEffect(() => {
        if (!urlOverride.updated) {
            setUrlOverride({
                val: overrideUrlVal,
                name: overrideUrlFieldName,
                updated: false
            });
        }
    }, [overrideUrlVal]);

    useEffect(() => {
        if (!metaDataState.updated) {
            setMetaDataState({
                name: metaDataFieldName,
                val: metadata,
                updated: false
            });
        }
    }, [metadata]);

    useEffect(() => {
        if (handleStateChange && state) {
            const { file, fileName } = state;
            if (file !== '' && fileName !== '') {
                handleStateChange(state);
            }
        }
    }, [state]);

    const handleConfirmNo = () => {
        setShowConfirm(false);
    };

    const handleConfirmYes = () => {
        setMetaDataState({
            ...metaDataState,
            val: '',
            updated: true
        });
        setUrlOverride({
            val: '',
            updated: true
        });
        setState({
            [name]: {
                ...state[name],
                file: null,
                fileName: null,
                updated: true
            }
        });
        handleFileChange(name, '');
        setShowConfirm(false);
    };

    const handleShowConfirm = () => {
        setConfirmTitle(localization.confirm_delete);
        const file = fullFilePath.split('/').pop();
        setConfirmContent(
            localization.confirm_message.replace(/\$\{file\}/gi, file)
        );
        setShowConfirm(true);
    };

    const handleMetadataUpdate = (e) => {
        setMetaDataState({
            val: e.target.value,
            name: e.target.name,
            updated: true
        });
        handleTextChange(e);
    };

    let [isInProgress, setIsInProgress] = useState(false);

    useEffect(() => {
        fileName &&
            setState({
                [name]: {
                    ...state[name],
                    fileName: fileName
                }
            });
    }, [fileName]);

    useEffect(() => handleFileChange(name, state[name].fileName), [state]);

    useEffect(() => triggerMetadataChangeEvent(), [metaDataState.val]);

    const getImageFileMeta = (file) => {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.readAsDataURL(file);
            fr.onload = () => {
                // when file has loaded
                const img = new Image();
                img.src = fr.result.toString();
                img.onload = (event) => {
                    resolve({
                        height: img.naturalHeight,
                        width: img.naturalWidth
                    });
                };
            };
        });
    };

    const renderDelete = () => {
        const file = state && state[name]?.fileName;
        return (
            (isAdminUser || isOperatorUser) &&
            enableDelete &&
            file &&
            file !== ''
        );
    };

    const triggerMetadataChangeEvent = () => {
        const event = {
            target: {
                type: 'textfield',
                language: language,
                name: imageId,
                value: metaDataState.val
            }
        };
        handleTextChange(event);
    };

    const handleImageChange = async (event) => {
        event.preventDefault();
        setIsInProgress(true);
        try {
            const reader = new FileReader();
            const file = event.target.files[0];
            const imageMetadata = await getImageFileMeta(file);

            if (aspect_ratio) {
                imageMetadata.aspect_ratio = aspect_ratio; // Adds the aspect ratio from the external config file.
            }

            if (imageId) {
                imageMetadata.image_id = imageId; // Adds the image id from the external config file.
            }

            const filePath = utils.getFilePath(
                tenantName,
                bucket,
                encodeFilename(file.name)
            );

            const uploadResult = await awsStorage.upload({
                folder: '',
                name: filePath,
                file: file
            });

            setState({
                [name]: {
                    ...state[name],
                    file: file,
                    imagePreviewUrl: reader.result,
                    fileName: uploadResult.key
                }
            });

            const s3Download = await awsStorage.download(uploadResult.key);

            const ETAG = s3Download?.ETag;
            imageMetadata['etag'] = ETAG;
            const updatedMetaData = JSON.stringify(imageMetadata);

            setMetaDataState({
                val: updatedMetaData,
                name: metaDataFieldName,
                updated: true
            });

            setIsInProgress(false);
        } catch (err) {
            console.log(err);
            dispatch(notifyError(`${err.name}:  ${err.message}`));
            setIsInProgress(false);
        }
        setIsInProgress(false);
    };

    const fullFilePath = utils.getFullFilePath(
        config.imageBaseUrl,
        tenantName,
        bucket,
        state[name].fileName
    );

    const localization = useSelector(strings);

    return (
        <>
            <Loader isInProgress={isInProgress} />
            <Grid container spacing={2} className={classes.outerContainer}>
                <Grid item xs={12} className={classes.upload}>
                    <FormControl className={classes.margin}>
                        <InputLabel
                            className={classes.labelStyle}
                            shrink
                            htmlFor={name}
                        >
                            {label}{' '}
                            {required && <span className='text-danger'>*</span>}
                        </InputLabel>
                        <BootstrapInput
                            name={name}
                            label={label}
                            type='text'
                            id={name}
                            value={(fullFilePath !== '' && fullFilePath) || ''}
                        />
                    </FormControl>
                    <input
                        name={name}
                        accept={allowedFilter}
                        className={classes.input}
                        id={item}
                        type='file'
                        onChange={handleImageChange}
                    />
                    {(isAdminUser || isOperatorUser) && !hideBrowseButton && (
                        <label htmlFor={item} className={classes.buttonLabel}>
                            <Button
                                variant='contained'
                                color='primary'
                                component='span'
                            >
                                {localization.browse}...
                            </Button>
                        </label>
                    )}
                    {renderDelete() && (
                        <label className={classes.buttonLabel}>
                            <Button
                                className={classes.buttonWarning}
                                variant='contained'
                                color='warning'
                                component='span'
                                onClick={handleShowConfirm}
                            >
                                {localization.delete}
                            </Button>
                        </label>
                    )}
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid className={classes.imgPreview} item xs={12}>
                        {type === 'image' && !fullFilePath && (
                            <div className={classes.icon}>
                                <PanoramaOutlined style={{ fontSize: 80 }} />
                            </div>
                        )}
                        {type === 'image' && fullFilePath && (
                            <img
                                className={classes.img}
                                src={fullFilePath}
                                alt=''
                            />
                        )}
                        {type === 'video' && (
                            <div className={classes.icon}>
                                <PlayCircleOutlineOutlined
                                    style={{ fontSize: 80 }}
                                />
                            </div>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={6} className={classes.dataContainer}>
                    <Grid item xs={12} lg={6}>
                        <strong>Target Aspect Ratio: </strong>{' '}
                        <span data-testid='aspect-ratio'>{aspect_ratio}</span>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <strong>Kind: </strong>{' '}
                        <span data-testid='kind'>{kind}</span>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <strong>Target Size: </strong>{' '}
                        <span data-testid='size'>{size}</span>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <strong>Anticipated Format: </strong>{' '}
                        <span data-testid='format'>{format}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <strong>Image ID: </strong>{' '}
                        <span
                            data-testid='imageId'
                            style={{ fontFamily: 'monospace' }}
                        >
                            {imageId}
                        </span>
                    </Grid>
                    <Grid item xs={12}>
                        <strong>Notes: </strong>{' '}
                        <span data-testid='notes'>{notes}</span>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ margin: '0 0 0 8px' }}>
                    <Input
                        label={'Metadata - ' + label}
                        name={metaDataFieldName}
                        value={metaDataState.val}
                        disabled={true}
                        handleChange={handleMetadataUpdate}
                        showCopy={true}
                    />
                </Grid>
            </Grid>
            <Confirm
                open={showConfirm}
                onClose={handleConfirmNo}
                onYes={handleConfirmYes}
                title={confirmTitle}
                content={confirmContent}
            />
        </>
    );
};

export default MultilanguageImageUpload;
