import React, { useRef, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Menu, MenuItem, ListItemText, IconButton } from '@mui/material';
import { Buttons } from './Buttons';
import { Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@mui/styles';
// Notifications:
import { notifyError } from '../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../reducer/localizationSlice';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: '150px',
        backgroundColor: '#484848',
        color: 'white'
    }
})((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white
            }
        },
        '&:hover': {
            backgroundColor: theme.palette.common.black
        }
    }
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
    searchGrid: {
        marginTop: '50px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#000'
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

export const TableToolBar = ({ handleExport, open, createNew, onSearch }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const exportButtonRef = useRef(null);
    let [anchorEl, setAnchorEl] = useState(null);

    const handleClick = () => {
        setAnchorEl(exportButtonRef && exportButtonRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const localization = useSelector(strings);

    return (
        <div className={classes.searchGrid}>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color='inherit' />
            </Backdrop>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Buttons
                    component={Link}
                    to={createNew}
                    showForWriteAccessUsers
                >
                    {localization.listing_create_new}
                </Buttons>

                <Buttons
                    onClick={onSearch}
                    label={localization.listing_advanced_search}
                />

                <Buttons
                    ref={exportButtonRef}
                    onClick={handleClick}
                    label={localization.listing_export}
                />
            </Box>

            <StyledMenu
                id='customized-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem>
                    <ListItemText
                        primary='CSV'
                        onClick={() => {
                            setAnchorEl(null);
                            const exportResponse = handleExport('csv')
                                .catch((e) => {
                                    dispatch(notifyError(e.message));
                                })
                                .finally(() => {
                                    handleClose();
                                });
                        }}
                    />
                </StyledMenuItem>
                <StyledMenuItem>
                    <ListItemText
                        primary='JSON'
                        onClick={() => {
                            setAnchorEl(null);
                            handleExport('json')
                                .then(() => {
                                    handleClose();
                                })
                                .catch((e) => {
                                    dispatch(notifyError(e.message));
                                });
                        }}
                    />
                </StyledMenuItem>
            </StyledMenu>
        </div>
    );
};
