import React, { useEffect, useState, useCallback } from 'react';
import { Container, Grid } from '@mui/material';
import { AutocompleteBox } from '../../shared/AutocompleteBox';
import { makeStyles } from '@mui/styles';
import { Input } from '../../shared/Input';
import { Buttons } from '../../shared/Buttons';
import { Loader } from '../../shared/Loader';
import { useForm } from '../../hooks/UseForm';
import services from '../../../services/apiService';
import { getRoute } from '../../shared/AdvancedSearch';
// Notifications
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        }
    },
    buttonright: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

export const Info = ({ id, conference, conferenceSchema, setRefresh }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [sports, setSports] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const requiredFields = conferenceSchema.required;
    const {
        item,
        showToggle,
        enableToggle,
        isInProgress,
        error,
        handleChange,
        handleToggleChange,
        handleFieldChange,
        onFormSubmit,
        form
    } = useForm({
        service: 'conferences',
        initialState: conference,
        id,
        requiredFields: conferenceSchema.required,
        setRefresh
    });

    const {
        name,
        alias,
        sport_id,
        sportradar_id,
        let_override: {
            name: override_name,
            alias: override_alias,
            sport_id: override_sport_id,
            sportradar_id: override_sportradar_id
        } = {}
    } = item;

    useEffect(() => {
        setLoading(true);
        services
            .fetchAllData('sports')
            .then((data) => {
                setSports(data);
            })
            .catch((err) => {
                setLeagues([]);
                dispatch(notifyError(`${err.name}:  ${err.message}`));
            })
            .finally(() => {
                setLoading(false);
            });
        return () => {
            services.cancelCall();
            setLoading(false);
        };
    }, []);

    const fetchLeagues = useCallback(() => {
        let sportId =
            override_sport_id || sport_id || form.sport_id || undefined;

        if (sportId !== undefined) {
            setLoading(true);
            let route = getRoute('leagues', { sport_id: sportId });
            const data = services
                .fetchAllData(route)
                .then((data) => {
                    setLeagues(data);
                })
                .catch((err) => {
                    setLeagues([]);
                    dispatch(notifyError(`${err.name}:  ${err.message}`));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLeagues([]);
            setLoading(false);
        }
    }, [override_sport_id || sport_id || form.sport_id]);

    useEffect(() => {
        fetchLeagues();
        return () => {
            setLoading(false);
        };
    }, [fetchLeagues]);

    const localization = useSelector(strings);

    return (
        <div className={classes.root}>
            <Container maxWidth='lg'>
                <Loader isInProgress={isInProgress || loading} />
                <form noValidate autoComplete='off' onSubmit={onFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.conference_name}:`}
                                name='name'
                                value={override_name || name || form.name || ''}
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={name || ''}
                                handleToggleChange={handleToggleChange}
                                error={error.name}
                                showCopy={true}
                                required={requiredFields.includes('name')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.conference_alias}:`}
                                name='alias'
                                value={
                                    override_alias || alias || form.alias || ''
                                }
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={alias || ''}
                                handleToggleChange={handleToggleChange}
                                error={error.alias}
                                required={requiredFields.includes('alias')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <AutocompleteBox
                                label={`${localization.sport}:`}
                                options={sports}
                                name='sport_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={form.sport_id || null}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    (conference && conference.sport_id) || null
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.sport_id}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes('sport_id')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <AutocompleteBox
                                label={`${localization.league}:`}
                                options={leagues}
                                name='league_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={form.league_id || null}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    (conference && conference.league_id) || null
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.league_id}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes('league_id')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.sportradar_id}:`}
                                name='sportradar_id'
                                value={
                                    override_sportradar_id ||
                                    sportradar_id ||
                                    form.sportradar_id ||
                                    ''
                                }
                                originalValue={sportradar_id || ''}
                                handleChange={handleChange}
                                required={requiredFields.includes(
                                    'sportradar_id'
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                justifyContent='flex-end'
                                spacing={3}
                            >
                                <div className={classes.buttonright}>
                                    <Buttons
                                        type='submit'
                                        label={localization.save}
                                        showForWriteAccessUsers
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};
