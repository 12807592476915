import React from 'react';
import { Grid } from '@mui/material';
import { CheckBox } from '../../shared/Checkbox';

const TestEvent = (props) => {
    const { checked, handleChange, label, name } = props;
    return (
        <Grid item xs={2}>
            <CheckBox
                label={label}
                name={name}
                checked={checked}
                handleChange={handleChange}
            />
        </Grid>
    );
};

export default TestEvent;