import React, { useEffect, useState, useMemo } from 'react';
import services from '../../../services/apiService';
import { Buttons } from '../../shared/Buttons';
import { Container, Grid } from '@mui/material';
import { Error } from '../../shared/Error';
import { Loader } from '../../shared/Loader';
import { makeStyles } from '@mui/styles';
import { SelectBox } from '../../shared/SelectBox';
import { useForm } from '../../hooks/UseForm';
import { utils } from '../../../utils/utils';
import { CustomFields } from '../live-admin/CustomFields';
import { CustomSubFields } from '../live-admin/CustomSubFields';
import productsService from '../../../services/productsService';
import eventService from '../../../services/eventsService';
import { date } from '../../../utils/date';
// Notifications
import { selectTenant } from '../../../reducer/tenantSlice';
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        },
        '& form': {
            margin: '0 0 40px 0'
        }
    },
    buttons: {
        alignItems: 'center',
        marginTop: '1rem'
    },
    buttonright: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    alert: {
        width: '520px',
        padding: '20px',
        margin: '20px',
        backgroundColor: '#ff9800',
        color: 'black'
    }
}));

export const CreateVideoAsset = ({
    id,
    event,
    setRefresh,
    eventSchema,
    localization
}) => {
    const classes = useStyles();
    const tenant = useSelector(selectTenant);
    const dispatch = useDispatch();
    // State
    const [profiles, setProfiles] = useState([]);
    const [selectedProfileId, setSelectedProfileId] = useState('');
    const [cdnList, setCdnList] = useState([]);
    const [currentCdn, setCdn] = useState('');
    const [currentFieldSelected, setMainFieldSelected] = useState('');
    const [customFields, setCustomFields] = useState({});
    const [allDescriptors, setAllDescriptors] = useState({});
    const [allFormattedDescriptors, setAllFormattedDescriptors] = useState({});
    const [isCreatingVideoAsset, setIsCreatingVideoAsset] = useState(false);
    const [formFields, setFormFields] = useState({});

    const [openErrorPage, setOpenErrorPage] = useState({
        open: false,
        message: ''
    });
    const [showLoading, setShowLoading] = useState(false);

    // To Get all the proifiles for the specific tenants.
    useEffect(() => {
        (async () => {
            try {
                const profiles = await productsService.getProfiles();
                setProfiles(profiles);
            } catch (err) {
                handleCommError(err);
            }
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, [JSON.stringify(profiles)]);

    useEffect(() => {
        if (selectedProfileId && selectedProfileId !== '') {
            productsService
                .getCustomFields(selectedProfileId)
                .then((fields) => {
                    setCustomFields(fields.customFields);
                    productsService
                        .getAllDescriptors(selectedProfileId)
                        .then((descriptors) => {
                            setAllDescriptors(descriptors);
                            let formattedDescriptors =
                                eventService.formatDescriptorsChoices(
                                    descriptors
                                );
                            let cdn = formattedDescriptors.hasOwnProperty(
                                'cdn_config'
                            )
                                ? formattedDescriptors.cdn_config
                                : [];
                            setCdnList(cdn);
                            setAllFormattedDescriptors(formattedDescriptors);
                        });
                })
                .catch((err) => handleCommError(err))
                .finally(() => {
                    setShowLoading(false);
                });
        } else {
            setShowLoading(false);
            setCdnList({});
        }
        return () => {
            setCustomFields({});
            setFormFields({});
            // this now gets called when the component unmounts
        };
    }, [selectedProfileId]);

    const isEmptyObject = (obj) => {
        return obj && Object.keys(obj).length === 0;
    };

    const handleCommError = (errorString) => {
        dispatch(notifyError(`Error: ${errorString}`));
    };
    // OnChange Handlers
    const onProfileChangeHandler = (event) => {
        setShowLoading(true);
        const target = event.target;
        const profileId = target ? target.value : '';
        setSelectedProfileId(profileId);
        setMainFieldSelected('');
        setFormFields({});
    };

    const handleCdnChange = (event) => {
        const target = event.target;
        const cdn = target ? target.value : '';
        setCdn(cdn);
    };

    const handleMainFieldChange = (event) => {
        const target = event.target;
        const mainFieldSelectedElement = target ? target.value : '';
        setMainFieldSelected(mainFieldSelectedElement);
        setFormFields({});
    };

    const handleSubFieldChange = (element) => {
        setField(element.name, element.value);
    };

    const setField = (fieldName, fieldValue) => {
        let fields = formFields;
        if (fieldValue === '') {
            delete fields[fieldName];
        } else {
            fields[fieldName] = fieldValue;
        }
        setFormFields(fields);
    };

    const handleClose = () => {
        setOpenErrorPage({ open: false, message: '' });
    };

    const { item, isInProgress, error, handleChange, onFormSubmit } = useForm({
        service: 'events',
        initialState: event,
        id: id,
        requiredFields: tenant.isMaxFamily
            ? [
                  ...eventSchema.required,
                  'offering_start_utc',
                  'offering_end_utc'
              ]
            : eventSchema.required,
        setRefresh: setRefresh
    });

    const {
        asset_id,
        away_competitor_id,
        br_channel,
        br_blackout,
        br_titleId,
        free_event,
        free_preview,
        hidden,
        home_competitor_id,
        league_id,
        name,
        profile_id,
        season_type,
        scheduled_utc,
        scheduled_utc_end,
        season,
        series_id,
        sport_id,
        territories_available,
        test_event,
        tve_event,
        venue_id,
        week,

        let_override: {
            asset_id: override_asset_id,
            away_competitor_id: override_away_competitor_id,
            br_channel: override_br_channel,
            br_blackout: override_br_blackout,
            br_titleId: override_br_titleId,
            free_event: override_free_event,
            free_preview: override_free_preview,
            hidden: override_hidden,
            home_competitor_id: override_home_competitor_id,
            league_id: override_league_id,
            name: override_name,
            profile_id: override_profile_id,
            scheduled_utc: override_scheduled_utc,
            scheduled_utc_end: override_scheduled_utc_end,
            season: override_season,
            season_type: override_season_type,
            series_id: override_series_id,
            sportradar_id: override_sportradar_id,
            sport_id: override_sport_id,
            territories_available:
                override_territories_available = utils.getDefaultCountry(),
            test_event: override_test_event,
            tve_event: override_tve_event,
            venue_id: override_venue_id,
            week: override_week
        } = {}
    } = item;

    const runtimeDefault = useMemo(() => {
        if (tenant.isBR) {
            if (
                item?.let_override?.video_start_utc &&
                item?.let_override?.video_end_utc
            ) {
                const diff =
                    date.format(item.let_override.video_end_utc, 'X') -
                    date.format(item.let_override.video_start_utc, 'X');
                return Math.round(diff);
            }
        } else {
            if (
                item?.let_override?.scheduled_utc &&
                item?.let_override?.scheduled_utc_end
            ) {
                const diff =
                    date.format(item.let_override.scheduled_utc_end, 'X') -
                    date.format(item.let_override.scheduled_utc, 'X');
                return Math.round(diff);
            }
        }
        return null;
    }, [item]);

    const validFields = () => {
        let validationResponse = {
            status: false,
            message: 'Please select all the fields to create video asset.'
        };
        if (
            selectedProfileId === undefined ||
            selectedProfileId === '' ||
            currentCdn === undefined ||
            currentCdn === ''
        ) {
            return validationResponse;
        }
        // also need to check for Tenant here the following validation is for BR only.

        if (customFields && !isEmptyObject(customFields)) {
            if (currentFieldSelected === 'tve') {
                //   const isTVE = item.let_override.tve_event ? item.let_override.tve_event :
                //               item.tve_event ? item.tve_event : false;
                const brChannel =
                    item.let_override.br_channel || item.br_channel || null;
                if (brChannel === null) {
                    validationResponse.message =
                        'Please select a channel in Event Info, If its a TVE event.';
                    return validationResponse;
                }
            }
            if (
                currentFieldSelected === undefined ||
                currentFieldSelected === ''
            ) {
                return validationResponse;
            }
            if (customFields.customs.length > 0) {
                const subFieldsArray = customFields.customs.filter((field) =>
                    field.condition_on.includes(currentFieldSelected)
                );
                let subFieldCount = subFieldsArray.length;
                if (currentFieldSelected === 'free' && !formFields?.br_league) {
                    // Free profile, but no pre-CAID value has been provided. Allow this to pass through.
                    // Rduce the subfield count for comparison below:
                    --subFieldCount;
                }
                if (Object.keys(formFields).length != subFieldCount) {
                    return validationResponse;
                }
            }
        }

        // When everything is Fine.
        validationResponse.status = true;
        validationResponse.message = '';

        return validationResponse;
    };

    const areDatesSet = (event) => {
        return Boolean(
            (event?.scheduled_utc || event?.let_override?.scheduled_utc) &&
                (event?.scheduled_utc_end ||
                    event?.let_override?.scheduled_utc_end)
        );
    };

    const getDateBegin = (event) => {
        return tenant.isBR
            ? event?.let_override?.video_start_utc || event.video_start_utc
            : event?.let_override?.scheduled_utc || event.scheduled_utc;
    };
    const getDateEnd = (event) => {
        return tenant.isBR
            ? event?.let_override?.video_end_utc || event.video_end_utc
            : event?.let_override?.scheduled_utc_end || event.scheduled_utc_end;
    };

    const createVidAsset = (profile, cdn) => {
        if (isCreatingVideoAsset) {
            // Already in process; bail.
            return;
        }

        setIsCreatingVideoAsset(true);
        setShowLoading(true);

        const validate = validFields(profile, cdn);

        if (!validate.status) {
            setOpenErrorPage({
                open: true,
                message: validate.message
            });
            setIsCreatingVideoAsset(false);
            setShowLoading(false);
        } else {
            const dateBegin = getDateBegin(event);
            const dateEnd = getDateEnd(event);

            const payload = {
                eventId: id,
                name: event?.let_override?.name || event.name,
                cdn_config: cdn,
                profileId: profile,
                dateBegin,
                dateEnd
            };
            if (JSON.stringify(formFields) !== '{}') {
                const extraFields = { ...formFields };
                extraFields[customFields.name] = currentFieldSelected;
                payload['customFields'] = extraFields;
            }

            productsService
                .createAsset(payload)
                .then(async (response) => {
                    const res = response.data;
                    // Now, update the event record...
                    const eventPayload = {
                        id: id,
                        athena_id: res.id,
                        profile_id: profile,
                        has_media: true,
                        event_status: 'upcoming',
                        is_medium: res.is_medium,
                        asset_id: res.asset_id,
                        runtime: runtimeDefault
                    };
                    if (res.is_medium) {
                        eventPayload['media_asset_id_live'] =
                            res.media_asset_id_live;
                    } else {
                        eventPayload['urls_asset_id_live'] =
                            res.urls_asset_id_live;
                    }
                    if (
                        currentFieldSelected != undefined &&
                        currentFieldSelected != ''
                    ) {
                        eventPayload['media_asset_type'] = currentFieldSelected;
                    }

                    // Ok, now do a PUT to the path: `events/${eventId}`
                    const eventsPutResponse = await services.updateItem(
                        `events/${id}`,
                        eventPayload
                    );

                    // TODO: Cleanup

                    // Ok, now do a GET to the path: `events/${eventId}`

                    // Ok, now do a GET to the path: 'products'
                    // ...in order to populate the Subscription Products section

                    // Ok, now do a GET to the path: `media/profiles/${selectedProfileId}/cdn_config
                    const cdnConfigResponse =
                        await productsService.getDescriptors(
                            profile,
                            'cdn_config'
                        );
                    setCdnList(cdnConfigResponse.cdn_config);

                    // Ok, now do a GET to the path: `media/profiles/${selectedProfileId}/custom`
                    // const customResponse = await services.fetchServiceAPIData(`media/profiles/${profile}/custom`);
                    // setProfileId(profile);
                    // setCustomFields(customResponse.?????);

                    // Ok, now do a GET to the path: `media/profiles/${selectedProfileId}/create_archive`

                    // Ok, now do a GET to the path: `get-registered-products/${res.media_asset_id_live}`

                    // Ok. Now do a POST to https://bam.nr-data.net/ins/1/NRJS-09e5e3663c9e0a07fb5?a=838745480&v=1215.1253ab8&to=Y1FaNhdSXhBSBkIIClobfRoVQVUQQA9FTiJxYBdNTw==&rst=301773&ck=1&ref=https://let-ui.int.nonprod.wmsports.io/events/61f667884cc7700009084b0e/live-admin

                    // Ok. Now do a GET to the path: `media/assets/${res.id}` (res.id is athena_id)

                    // Ok. Now do another POST to https://bam.nr-data.net/ins/1/NRJS-09e5e3663c9e0a07fb5?a=838745480&v=1215.1253ab8&to=Y1FaNhdSXhBSBkIIClobfRoVQVUQQA9FTiJxYBdNTw==&rst=301773&ck=1&ref=https://let-ui.int.nonprod.wmsports.io/events/61f667884cc7700009084b0e/live-admin
                    window.location.reload();
                })
                .catch((error) => {
                    dispatch(notifyError(`${error}`));
                    setIsCreatingVideoAsset(false);
                })
                .finally(() => {
                    setShowLoading(false);
                });
        }
    };

    if (event && Object.keys(event).length > 0 && !areDatesSet(event)) {
        return (
            <div className={classes.alert}>
                <span>
                    This event must have a start and end date before creating
                    your asset.
                </span>
            </div>
        );
    }

    if (event && Object.keys(event).length > 0) {
        return (
            <div className={classes.root}>
                <Container maxWidth='lg'>
                    <Loader isInProgress={isInProgress || showLoading} />
                    <form noValidate autoComplete='off' onSubmit={onFormSubmit}>
                        <div>
                            <Error
                                open={openErrorPage.open}
                                message={openErrorPage.message}
                                onClose={handleClose}
                            />

                            <Grid item xs={12}>
                                <h2>Would you like to create a video asset?</h2>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                    <SelectBox
                                        label={
                                            localization.athena_profile_information
                                        }
                                        name='profile_id'
                                        options={profiles}
                                        defaultValue={
                                            override_profile_id ||
                                            profile_id ||
                                            selectedProfileId ||
                                            ''
                                        }
                                        handleChange={onProfileChangeHandler}
                                        showToggle={false}
                                        enableToggle={false}
                                        originalValue={
                                            event && event.profile_id
                                        }
                                        error={error.profile_id}
                                        localization={localization}
                                    />
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <SelectBox
                                        label='CDN'
                                        name='cdn'
                                        options={cdnList ? cdnList : []}
                                        defaultValue={currentCdn}
                                        handleChange={handleCdnChange}
                                        localization={localization}
                                    />
                                </Grid>
                                <Grid item sm={12} g={12}>
                                    {customFields &&
                                    !isEmptyObject(customFields) ? (
                                        <CustomFields
                                            customFields={customFields}
                                            defaultValue={currentFieldSelected}
                                            handleChange={handleMainFieldChange}
                                            localization={localization}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </Grid>

                                <CustomSubFields
                                    customFields={customFields}
                                    currentFieldSelected={currentFieldSelected}
                                    allFormattedDescriptors={
                                        allFormattedDescriptors
                                    }
                                    formFields={formFields}
                                    handleChange={handleSubFieldChange}
                                    localization={localization}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.buttons}>
                                    <Buttons
                                        disabled={isCreatingVideoAsset}
                                        onClick={() =>
                                            createVidAsset(
                                                selectedProfileId,
                                                currentCdn
                                            )
                                        }
                                        label={localization.yes}
                                    />
                                </div>
                            </Grid>
                        </div>
                    </form>
                </Container>
            </div>
        );
    } else {
        return <p />;
    }
};
