import React from 'react';
import { isEqual } from 'lodash';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { InputLabel, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { makeStyles } from '@mui/styles';
import { ToggleButton } from './ToggleButton';
import { date } from '../../utils/date';
// Localization:
import { useSelector } from 'react-redux';
import { datePickerLocaleText } from '../../reducer/localizationSlice';
import { parseISO } from 'date-fns';
import { date as dateUtil } from '../../utils/date';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiInputLabel-formControl': {
            position: 'inherit',
            marginBottom: 5
        },
        '& .MuiIconButton-root': {
            backgroundColor: 'black',
            borderRadius: '0px 4px 4px 0px',
            padding: 8,
            color: 'white'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        },

        '& .MuiInputAdornment-root button:hover': {
            background: 'black'
        },
        '& .custom .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid black'
            },
        '& .custom.error .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid red'
            },
        marginBottom: '10px'
    },
    margin: {
        //margin: theme.spacing(1),
        width: '100%'
    },
    labelStyle: {
        fontWeight: 600,
        fontSize: 'large',
        color: 'black'
    },
    error: {
        borderRadius: 4,
        border: '1px solid red',

        '& .MuiInputBase-input:focus': {
            border: 'none'
        },
        '& .MuiInputBase-input:hover': {
            border: 'none'
        }
    },
    dateTimePickerToggle: {
        '& .MuiIconButton-root': {
            background: 'none',
            padding: 4
        }
    }
}));

export const Calendar = (props) => {
    const classes = useStyles();

    let {
        label,
        defaultValue,
        handleChange,
        name,
        showToggle,
        enableToggle,
        originalValue,
        handleToggleChange,
        error,
        minDateTime,
        required
    } = props;

    const handleDateChange = (value) => {
        const dateUTC = value ? date.estToIso(value) : value;
        handleChange(name, dateUTC || value);
    };

    const setLocalDateTime = (record_date) => {
        let localDate = record_date
            ? date.format(date.toEst(record_date), 'MM/DD/YYYY hh:mm a')
            : record_date;
        return parseISO(localDate || record_date || null);
    };

    const localeText = useSelector(datePickerLocaleText);

    return (
        <div className={classes.root}>
            <Grid
                container
                justifyContent='space-between'
                alignItems='flex-end'
            >
                {showToggle && (
                    <>
                        <Grid item xs={9}>
                            <InputLabel
                                className={classes.labelStyle}
                                shrink
                                htmlFor={name}
                            >
                                {label}{' '}
                                {required && (
                                    <span className='text-danger'>*</span>
                                )}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={3} style={{ flexBasis: '5%' }}>
                            <ToggleButton
                                style={classes.dateTimePickerToggle}
                                showToggle={
                                    showToggle &&
                                    (defaultValue != null ||
                                        defaultValue !== undefined) &&
                                    !isEqual(defaultValue, originalValue)
                                }
                                name={name}
                                originalValue={originalValue}
                                enableToggle={enableToggle}
                                handleToggleChange={handleToggleChange}
                            />
                        </Grid>
                    </>
                )}
                {!showToggle && (
                    <>
                        <Grid item xs={12}>
                            <InputLabel
                                className={classes.labelStyle}
                                shrink
                                htmlFor={name}
                            >
                                {label}{' '}
                                {required && (
                                    <span className='text-danger'>*</span>
                                )}
                            </InputLabel>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item xs={12}>
                <div
                    className={`custom ${!!error ? 'error' : ''}`}
                    data-testid={name}
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        localeText={localeText}
                    >
                        <DateTimePicker
                            InputAdornmentProps={{
                                style: { marginRight: '-1px' }
                            }}
                            inputProps={{ placeholder: '' }}
                            minDateTime={minDateTime}
                            onChange={handleDateChange}
                            slotProps={{
                                textField: {
                                    error: false,
                                    fullWidth: true,
                                    size: 'small',
                                    variant: 'outlined'
                                }
                            }}
                            timezone='America/New_York'
                            value={new Date(dateUtil.toEst(defaultValue))}
                            viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                            }}
                        />
                    </LocalizationProvider>
                </div>
            </Grid>
        </div>
    );
};
