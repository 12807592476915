import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { createRef, useRef, useEffect, useMemo, useState } from 'react';

export const COOKIE_NAME = 'let.drawers';

const useStyles = makeStyles((theme) => ({
    styling: {
        fontWeight: 700,
        margin: '3rem 0 1rem 0'
    },
    drawer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
    drawerButton: {
        height: '40px',
        width: '40px',
        border: '1px solid gray',
        cursor: 'pointer !important',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center'
    },
    drawerClosed: {
        flex: 0,
        height: 0,
        boxShadow: '0 5px 8px -2px rgba(127,127,127,0.25)'
    },
    drawerContents: {
        padding: '10px 0 0 0',
        overflow: 'hidden',
        transition: 'all 0.5s ease-out',
        boxShadow: '0 0 0 0 rgba(127,127,127,0)'
    },
    drawerNavigation: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 'auto',
        flex: 1,
        margin: '0 0 10px 0'
    },
    drawerOpen: {
        // Nothing yet;
    },
    drawerTitle: {
        // Nothing yet;
    }
}));

const l10n = {
    show_more: 'Show More',
    show_less: 'Show Less'
};

export const getDrawerState = (drawerId) => {
    // If an ID isn't passed, just  return open.
    if (!drawerId) {
        return null;
    }
    const cookie = JSON.parse(localStorage.getItem(COOKIE_NAME) || '{}');
    // If the cookie exists and the drawerId is defined, return that:
    if (drawerId in cookie) {
        return cookie[drawerId];
    }
    // ... Otherwise default to open.
    return null;
};

export const saveDrawerState = (drawerId, state) => {
    if (!drawerId) {
        return;
    }
    const cookie = JSON.parse(localStorage.getItem(COOKIE_NAME) || '{}');
    localStorage.setItem(
        COOKIE_NAME,
        JSON.stringify({
            ...cookie,
            [drawerId]: state
        })
    );
};

export const toggleDrawer = (open, drawerRef, setter) => {};

export const Drawer = (props) => {
    const classes = useStyles();

    const { children, drawerId, open, title } = props;

    const savedState = useMemo(() => getDrawerState(drawerId), [drawerId]);

    const currentState =
        savedState === null ? (open === undefined ? 1 : open) : savedState;

    const [isOpen, setIsOpen] = useState(currentState);

    useEffect(() => {
        if (isOpen) {
            drawerRef.current.style.maxHeight = null;
        } else {
            drawerRef.current.style.maxHeight = 0;
        }
        saveDrawerState(drawerId, isOpen);
    }, [isOpen]);

    const drawerRef = useRef(null);

    const id = drawerId
        ? drawerId
        : `drawer_id_${(Math.random() * 999 + 1000) | 0}`;

    const toggleDrawer = () => {
        setIsOpen(isOpen === 1 ? 0 : 1);
    };

    return (
        <Grid item container xs={12} className={classes.drawer}>
            <Grid item xs={12} className={classes.drawerNavigation}>
                <Typography
                    variant='h5'
                    className={classes.drawerTitle}
                    data-testid='drawerTitle'
                >
                    {title}
                </Typography>
                <div
                    className={classes.drawerButton}
                    onClick={toggleDrawer}
                    style={{ display: isOpen ? 'flex' : 'none' }}
                    title={l10n.show_less}
                    data-testid='drawerClose'
                >
                    {<ExpandLess />}
                </div>
                <div
                    className={classes.drawerButton}
                    onClick={toggleDrawer}
                    style={{ display: isOpen ? 'none' : 'flex' }}
                    title={l10n.show_more}
                    data-testid='drawerOpen'
                >
                    {<ExpandMore />}
                </div>
            </Grid>
            <Divider />
            <div
                id={id}
                ref={drawerRef}
                className={`${classes.drawerContents} ${
                    isOpen ? classes.drawerOpen : classes.drawerClosed
                }`}
                data-testid='drawer-contents'
            >
                {children}
            </div>
        </Grid>
    );
};
