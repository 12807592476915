export const getSortedSeriesList = (data) => {
    const seriesList = [];
    const rawData = {};
    data.forEach((series) => {
        const season = series?.season || 0;
        const tournamentName = (
            series?.tournament?.alias ||
            series?.tournament?.name ||
            'Unknown Tournament'
        ).toUpperCase();
        if (!rawData[tournamentName]) {
            rawData[tournamentName] = {};
        }
        if (!rawData[tournamentName][season]) {
            rawData[tournamentName][season] = [];
        }
        const seriesName = `${tournamentName} ${series?.name}${
            season === 0 ? '' : ` (${season})`
        }`;
        rawData[tournamentName][season].push({
            ...series,
            name: seriesName
        });
    });

    Object.keys(rawData)
        .sort((a, b) => (a > b ? 1 : -1))
        .forEach((tourney) => {
            const seasons = Object.keys(rawData[tourney]).sort((a, b) =>
                a < b ? 1 : -1
            );
            seasons.forEach((season) => {
                const seasonSeries = rawData[tourney][season].sort((a, b) =>
                    a.round > b.round ? 1 : -1
                );
                seasonSeries.forEach((match) => seriesList.push(match));
            });
        });

    return seriesList;
};
