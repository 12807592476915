import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Heading } from '../../shared/Heading';
import { CustomBreadcrumbs } from '../../shared/Breadcrumbs';
import { Info } from './Info';
import services from '../../../services/apiService';
import { Details } from '../../Details';
import { utils } from '../../../utils/utils';
// Notifications:
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const JSONSchema = require('let-json-schemas/v1/input_db/conference.json');
// Add the Alias to the required fields:
const customRequiredFields = ['alias'];
JSONSchema.required = [...JSONSchema.required, ...customRequiredFields];
/*
TODO: Sort out why ^^^ this ^^^ was added on the LET-side,
but not in the JSON schemas.
*/

const ConferenceSteps = ({
    config,
    id,
    conference,
    handleUpdate,
    setRefresh,
    slug
}) => {
    switch (slug) {
        case 'conference-info':
            return (
                <Info
                    id={id}
                    config={config}
                    conference={conference}
                    conferenceSchema={JSONSchema}
                    handleUpdate={handleUpdate}
                    setRefresh={setRefresh}
                />
            );
        default:
            return <span />;
    }
};

export const CreateEditConferences = ({ config }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    let location = useLocation();
    let path = location.pathname.split('/').slice(-1)[0];
    let { id } = useParams();

    const [conference, setConference] = useState({});
    const [details, setDetails] = useState([]);
    const [loadError, setLoadError] = useState();
    const [refresh, setRefresh] = useState(false);

    const localization = useSelector(strings);

    const steps = [
        {
            title: localization.conference_info,
            slug: `conference-info`
        }
    ];

    const fetchConferences = useCallback(() => {
        id !== 'new' &&
            services
                .getItem('conferences', id, true)
                .then((res) => {
                    if (res && Object.keys(res).length === 0) {
                        setLoadError(
                            `Error: Conference ${id} does not exist on this tenant`
                        );
                        history.push('/conferences');
                        return;
                    }

                    setConference(res);

                    const summary = utils.serializeSummary(
                        localization.conference_id,
                        res,
                        localization
                    );

                    id !== 'new' && setDetails(summary);
                })
                .catch((err) => {
                    setLoadError(`Error: ${err.message}`);
                });
    }, [refresh, id]);

    useEffect(() => {
        fetchConferences();
    }, [fetchConferences]);

    useEffect(() => {
        if (loadError) {
            dispatch(notifyError(loadError));
            setLoadError();
        }
    }, [loadError]);

    const handleUpdate = (event) => {
        setIsUpdating(event);
    };

    return (
        <>
            <Heading heading={localization.create_edit_conference} />
            <CustomBreadcrumbs steps={steps} id={id} />
            {id !== 'new' && Object.keys(conference).length > 0 && (
                <Details details={details} />
            )}
            <ConferenceSteps
                config={config}
                slug={path}
                id={id}
                conference={conference}
                handleUpdate={handleUpdate}
                setRefresh={setRefresh}
            />
        </>
    );
};
