import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import { ArrowBackOutlined, RemoveOutlined } from '@mui/icons-material';
import { Buttons } from '../../shared/Buttons';
import { Calendar } from '../../shared/Calendar';
import { CheckBox } from '../../shared/Checkbox';
import { Container, Grid, Typography } from '@mui/material';
import { Input } from '../../shared/Input';
import { Loader } from '../../shared/Loader';
import { makeStyles } from '@mui/styles';
import { useForm } from '../../hooks/UseForm';
import { useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        }
    },
    buttons: {
        alignItems: 'center',
        marginTop: '1rem'
    }
}));

export const RecordRankManagement = ({
    id,
    league,
    setRefresh,
    leagueSchema,
    handleUpdate,
    handleForward
}) => {
    const classes = useStyles();
    let prevPage = `/leagues/${id}/appearance`;
    const requiredFields = leagueSchema.required;

    const localization = useSelector(strings);

    const {
        item,
        error,
        valueChanged,
        isInProgress,
        handleChange,
        handleNumberChange,
        handleDateChange,
        onFormSubmit,
        form
    } = useForm({
        service: 'leagues',
        id: id,
        initialState: league,
        requiredFields,
        setRefresh
    });

    useEffect(() => {
        handleUpdate(valueChanged);
    }, [valueChanged]);

    const {
        season_start,
        season_end,
        current_season,
        current_week,
        season_type,
        new_schedule_date,
        incomplete_season,
        team_league,
        active,

        let_override: {
            season_start: override_season_start,
            season_end: override_season_end,
            current_season: override_current_season,
            current_week: override_current_week,
            season_type: override_season_type,
            new_schedule_date: override_new_schedule_date,
            incomplete_season: override_incomplete_season,
            team_league: override_team_league,
            active: override_active
        } = {}
    } = item;

    return (
        <div className={classes.root}>
            <Container maxWidth='lg'>
                <Loader isInProgress={isInProgress} />
                <form noValidate autoComplete='on' onSubmit={onFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Calendar
                                name='season_start'
                                label={`${localization.season_start} (ET):`}
                                defaultValue={
                                    override_season_start ||
                                    season_start ||
                                    form.season_start ||
                                    ''
                                }
                                handleChange={handleDateChange}
                                showToggle={false}
                                enableToggle={false}
                                originalValue={league && league.season_start}
                                error={error.season_start}
                                required={requiredFields.includes(
                                    'season_start'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Calendar
                                name='season_end'
                                label={`${localization.season_end} (ET):`}
                                defaultValue={
                                    override_season_end ||
                                    season_end ||
                                    form.season_end ||
                                    ''
                                }
                                handleChange={handleDateChange}
                                showToggle={false}
                                enableToggle={false}
                                originalValue={league && league.season_end}
                                error={error.season_end}
                                required={requiredFields.includes('season_end')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.season_current}:`}
                                name='current_season'
                                value={
                                    override_current_season ||
                                    current_season ||
                                    form.current_season ||
                                    ''
                                }
                                originalValue={current_season || ''}
                                handleChange={handleNumberChange}
                                type='number'
                                required={requiredFields.includes(
                                    'current_season'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.week_current}:`}
                                name='current_week'
                                value={
                                    override_current_week ||
                                    current_week ||
                                    form.current_week ||
                                    ''
                                }
                                originalValue={current_week || ''}
                                handleChange={handleNumberChange}
                                type='number'
                                required={requiredFields.includes(
                                    'current_week'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.season_type}:`}
                                name='season_type'
                                value={
                                    override_season_type ||
                                    season_type ||
                                    form.season_type ||
                                    ''
                                }
                                originalValue={
                                    season_type || form.season_type || ''
                                }
                                handleChange={handleChange}
                                required={requiredFields.includes(
                                    'season_type'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Calendar
                                name='new_schedule_date'
                                label={`${localization.new_schedule_date} (ET):`}
                                defaultValue={
                                    override_new_schedule_date ||
                                    new_schedule_date ||
                                    form.new_schedule_date ||
                                    ''
                                }
                                handleChange={handleDateChange}
                                showToggle={false}
                                enableToggle={false}
                                originalValue={
                                    league && league.new_schedule_date
                                }
                                error={error.season_end}
                                required={requiredFields.includes(
                                    'new_schedule_date'
                                )}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CheckBox
                                label={localization.incomplete_season}
                                checked={
                                    override_incomplete_season ||
                                    incomplete_season ||
                                    form.incomplete_season ||
                                    false
                                }
                                name='incomplete_season'
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CheckBox
                                label={localization.team_league}
                                checked={
                                    override_team_league ||
                                    team_league ||
                                    form.team_league ||
                                    false
                                }
                                name='team_league'
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <CheckBox
                                label={localization.active}
                                checked={
                                    override_active ||
                                    active ||
                                    form.active ||
                                    false
                                }
                                name='active'
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                    <Grid container className={classes.buttons} item xs={12}>
                        <Grid item md={6}>
                            <IconButton
                                aria-label='delete'
                                style={{ color: 'black' }}
                                onClick={(e) => handleForward(e, prevPage)}
                            >
                                <ArrowBackOutlined fontSize='large' />
                                <RemoveOutlined
                                    fontSize='large'
                                    style={{ marginLeft: '-16px' }}
                                />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align='right'>
                                <Buttons
                                    type='submit'
                                    label={localization.save}
                                    showForWriteAccessUsers
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};
