import { maxImages } from '../data';

export const hasValue = (str) =>
    (str && typeof str === 'string' && str.length > 0) || false;

export const getFilteredMultiLanguageImages = (images) => {
    const filtered = {};

    Object.keys(images).forEach((languageKey) => {
        if(!Array.isArray(images[languageKey])){
            images[languageKey] = [];
        }
        const definedImages = images[languageKey].filter(
            (img) => img.image && img.image !== ''
        );
        if (definedImages && definedImages.length) {
            filtered[languageKey] = definedImages;
        }
    });
    return filtered;
};

export const getEtag = (metadata) => {
    const parsedMetadata = (metadata && JSON.parse(metadata)) || {};
    const etag = (parsedMetadata?.etag || '').replace(/['"]+/g, '');
    return etag;
};

export const getImages = (tenant) => {
    if (!tenant) {
        throw new Error(
            'maxImageUtils.getImages called with no tenant provided'
        );
    }
    return tenant.isMax
        ? maxImages.map((img) => img.name)
        : tenant.isDomestic
        ? maxImages.filter((img) => !img.max_only).map((img) => img.name)
        : [];
};

export const indexSort = (a, b) => (a.index > b.index ? 1 : -1);
