import React from 'react';
import {
    setIndex,
    getCurrentIndex,
    getLanguages
} from '../../reducer/multilanguageSlice';
import { useDispatch, useSelector } from 'react-redux';

const MultiLanguagePill = (props) => {
    const dispatch = useDispatch();
    const getName = (str) => {
        let shortName = '';
        try{
            shortName = str.substr(0, str.indexOf(' '))
        }catch(e){
            // Nothing;
        }
        return shortName;
    };
    const pillStyle = {
        fontWeight: 0,
        background: "linear-gradient(360deg, #cccabd5b 31%, #48cae416 100%)",
        padding: "8px 14px", 
        border: props.selected
                    ? '1px solid rgb(75,75,75)'
                    : '1px solid #cccccc',
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 12px 15px -10px",
        borderRadius: "50px",
        margin: '10px',
        transform: "translateY(0)",
        cursor: props.selected ? 'default' : 'pointer'
    }
    
    return (
        <button
            style={pillStyle}
            title={`${props.isoCode}: ${props.languageName}`}
            onClick={(e) => {
                e.preventDefault();
                dispatch(setIndex(props.languageIndex));
            }}
        >
            {getName(props.languageName)}
        </button>
    );
};

export const MultiLanguageSelector = (props) => {
    const currentIndex = useSelector(getCurrentIndex);
    const languages = useSelector(getLanguages);
    const pillContainerStyle = {
        flexWrap: 'wrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };

    return (
        <div id='MultilanguageNav' style={pillContainerStyle}>
            {languages.map((lang, index) => {
                const { languageName, isoCode } = lang;
                return (
                    <MultiLanguagePill
                        key={`lang_${index}`}
                        languageIndex={index}
                        languageName={languageName}
                        isoCode={isoCode}
                        selected={index === currentIndex}
                    />
                );
            })}
        </div>
    );
};
