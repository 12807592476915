const constants = Object.freeze({
    letVersion: 'v1/let/',
    apiVersion: 'v1/',
    COLUMN_VISIBILITY_KEY: 'listing-columns-to-display'
});

export const EventStatusOptions = [
    { name: 'Ended', id: 'ended', key: 'eso-00' },
    { name: 'Live', id: 'live', key: 'eso-01' },
    { name: 'Upcoming', id: 'upcoming', key: 'eso-02' },
    { name: 'Replay', id: 'replay', key: 'eso-03' }
];

export const LANGUAGE_NAMES = Object.freeze({
    en: 'English',
    es: 'Spanish',
    pt: 'Portuguese'
});

export const EventStateOptions = [
    {
        name: 'Upcoming',
        id: 'UPCOMING',
        key: 'est-00'
    },
    {
        name: 'Live',
        id: 'LIVE',
        key: 'est-01'
    },
    {
        name: 'VOD',
        id: 'VOD',
        key: 'est-02'
    },
    {
        name: 'FER',
        id: 'FER',
        key: 'est-03'
    }
];

export default constants;
