export const tenantIsValid = (allTenants = [], tenant = '') => {
    return Array.isArray(allTenants)
        ? allTenants.find((t) => t?.key === tenant)
        : false;
};
export const userHasTenantAccess = (userTenants = [], tenant = '') => {
    return Array.isArray(userTenants)
        ? userTenants.indexOf(tenant) !== -1
        : false;
};

export const TENANT_CHECK = {
    INVALID_TENANT: 'invalid_tenant',
    OK: 'ok',
    USER_HAS_NO_TENANTS: 'no tenants',
    USER_NO_TENANT_ACCESS: 'no tenant access'
}

export const getTenantCheck = (user, tenant, tenants, location) => {
    if(!user){
        return;
    }
    const { isAuthenticated, userTenants } = user;
    const userHasAccess = userHasTenantAccess(userTenants, tenant);
    const validTenant = tenantIsValid(tenants, tenant);

    if (isAuthenticated && tenant && location) {
        if (!validTenant) {
            console.info(`Tenant ${tenant} is unrecognized.`);            
            return TENANT_CHECK.INVALID_TENANT;            
        }
        if (!userHasAccess) {
            const { firstName, lastName } = user;
            const userName = `${firstName} ${lastName}`;
            if (userTenants.length === 0) {
                // No tenants:
                console.info(
                    `User ${userName} does not have access to any tenants.`
                );
                return TENANT_CHECK.USER_HAS_NO_TENANTS;
            }
            console.info(
                `User ${userName} does not have access to the tenant "${tenant}".`
            );
            return TENANT_CHECK.USER_NO_TENANT_ACCESS;            
        }
        return TENANT_CHECK.OK;        
    }
};

export default {
    getTenantCheck,
    tenantIsValid,
    userHasTenantAccess
};
