import React, { forwardRef } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';

import { isAdmin, isOperator } from '../../reducer/userReducer';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1)
    }
}));

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900],
        '&:hover': {
            backgroundColor: grey[900]
        }
    }
}))(Button);

export const Buttons = forwardRef((props, ref) => {
    const isAdminUser = useSelector(isAdmin);
    const isOperatorUser = useSelector(isOperator);

    let showForWriteAccessUsers = false;
    if (props.showForWriteAccessUsers) showForWriteAccessUsers = true;

    if (
        showForWriteAccessUsers &&
        isAdminUser === false &&
        isOperatorUser === false
    )
        return '';

    if (props.component) {
        let inheritProps = { ...props };
        if (inheritProps.hasOwnProperty('showForWriteAccessUsers')) {
            delete inheritProps.showForWriteAccessUsers;
        }
        return (
            <Button ref={ref ? ref : null} {...inheritProps}>
                {props.children}
            </Button>
        );
    } else if (props.onClick) {
        // We may have a regular button with an onClick handler...
        return (
            <Button
                id={props.id}
                name={props.name}
                data-testid={props['data-testid']}
                data-test={props['data-test']}
                title={props.title}
                ref={ref ? ref : null}
                type={props.type}
                onClick={() => props.onClick()}
                disabled={props.disabled || false}
                color={props.color}
                variant="contained"
            >
                {props.label}
            </Button>
        );
    } else {
        // ...or, we may have a submit button for a form
        return (
            <Button ref={ref ? ref : null} type={props.type} style={props.style} className={props.className}>
                {props.label}
            </Button>
        );
    }
});
