import { environment } from '../environments/environment';
import { date } from '../utils/date';
import { v4 as uuidv4 } from 'uuid';
import { oxfordComa } from './stringUtils';
import React from 'react';

const isHBO = (tenant) => /^hbo/i.test(tenant);
const isDomestic = (tenant) => /^hbo.*.domestic$/i.test(tenant);
const isMax = (tenant) => /^max$/i.test(tenant);

export const isMaxFamily = (tenant) => isHBO(tenant) || isMax(tenant);

export const utils = {
    mapBoolean: (val) => {
        return val ? 'Yes' : 'No';
    },

    getImagePath: (baseUrl, tenant, bucket, filename) => {
        return `${baseUrl}${filename}`;
    },

    getFilePath: (tenant, bucket, filename) => {
        if (bucket == 'source') {
            return `${tenant}/${bucket}/${filename}`;
        } else {
            const today = new Date();
            const year = today.getFullYear();
            let month = today.getMonth() + 1;
            month = month < 10 ? '0' + month : '' + month;
            let day = today.getDate();
            day = day < 10 ? '0' + day : '' + day;
            let newBucket = bucket.toLowerCase().replace(/\ /g, '-');
            return `${tenant}/${newBucket}/${year}/${month}/${day}/${filename}`;
        }
    },

    getFullFilePath: (server, tenant, bucket, filename) => {
        // This function handles the legacy image paths from the Angular tools
        // (GSP-2965 and displays the full image path (GSP-2963)
        if (filename) {
            if (filename.startsWith(tenant)) {
                return `${server}${filename}`;
            } else {
                return `${server}${tenant}/${bucket}/${filename}`;
            }
        }
    },

    getImageDestination: (baseUrl, tenant, bucket, filename) => {
        if (bucket == 'source') {
            return `${baseUrl}${tenant}/${bucket}/${filename}`;
        } else {
            const today = new Date();
            const year = today.getFullYear();
            let month = today.getMonth() + 1;
            month = month < 10 ? '0' + month : '' + month;
            let day = today.getDate();
            day = day < 10 ? '0' + day : '' + day;
            // estadio-chile/sponsors/2021/12/30/
            let newBucket = bucket.toLowerCase().replace(/\ /g, '-');
            return `${baseUrl}${tenant}/${newBucket}/${year}/${month}/${day}/${filename}`;
        }
    },

    getImageUrl: (baseUrl, bucket, name) => {
        return `${baseUrl}${bucket}/${name}`;
    },

    getImageSourceUrl: (baseUrl, bucket, name) => {
        return `${baseUrl}${bucket}/source/${name}`;
        // return `${baseUrl}${bucket}/${name}`;
    },

    getVideoUrl: (bucket, name) => {
        return `${environment.REACT_APP_VIDEO_BASE_URL}${bucket}/${name}`;
    },

    capitalize: (str) => {
        if (!str || !str.length) {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.substr(1);
    },

    getTerritories: (territories_available = [], countries) =>
        territories_available.map((item) =>
            countries.find((t) => t.value === item)
        ),

    getContentLength: (headers) => {
        let result = 0;
        if ('content-range' in headers) {
            const contentRange = headers['content-range'];
            result = parseInt(
                contentRange.substring(contentRange.indexOf('/') + 1)
            );
        }
        return result;
    },

    getSortFilters: (sortModel = []) => {
        let result = '';

        if (!sortModel.length) return '';

        const { field, sort } = sortModel[0];

        if (!field || !sort) {
            return '';
        }

        let sortingOrder;
        switch (sort) {
            case 'asc':
                sortingOrder = '1';
                break;
            case 'desc':
                sortingOrder = '-1';
                break;
            default:
                sortingOrder = '1';
                break;
        }

        result = `sort=${field}:${sortingOrder}`;
        return result;
    },

    getAdvancedFilters: (searchParms) => {
        let result = '';
        if (typeof searchParms === 'undefined') return '';

        for (const key of Object.keys(searchParms)) {
            if (
                searchParms[key] === undefined ||
                searchParms[key] === '' ||
                key === 'tenant'
            )
                continue;

            result += `${key}=${searchParms[key]}&`;
        }

        return result;
    },

    paramsToObject: (str) => {
        return Object.fromEntries(new URLSearchParams(str));
    },

    getLanguages: (tenantName = '', tenants = []) => {
        if (Array.isArray(tenants)) {
            const tenant = tenants.find((tenant) => tenant.key === tenantName);
            const languages = tenant?.languages || [];
            return Array.isArray(languages) ? languages : [languages];
        }
        return [];
    },

    isHBO,
    isDomestic,
    isMax,
    isMaxFamily,

    isBR: (tenant) => tenant === 'br',
    isBR2: (tenant) => tenant === 'br2',

    isChile: (tenant) => tenant === 'estadio-chile',

    isMultiLanguage: (tenantName = '', tenants = []) => {
        if (Array.isArray(tenants)) {
            const tenant = tenants.find((tenant) => tenant.key === tenantName);
            const languages = tenant?.languages;
            return Array.isArray(languages) && languages.length > 1;
        }
        return false;
    },

    tenantIsHBO: () => {
        const storageTenantIsHBO = localStorage.getItem('tenantIsHBO');

        return storageTenantIsHBO === 'true' ? true : false;
    },

    removeEmpty: (object) => {
        Object.entries(object).forEach(([k, v]) => {
            if (v && typeof v === 'object') {
                utils.removeEmpty(v);
            }
            if (
                (v && typeof v === 'object' && !Object.keys(v).length) ||
                v === null ||
                v === undefined
            ) {
                if (Array.isArray(object)) {
                    object.splice(k, 1);
                } else {
                    delete object[k];
                }
            }
        });

        return object;
    },

    setDate: (record_date) => {
        let d = date.isoToEst(record_date) || '';

        const result = date.isValidDate(d)
            ? `${date.format(d, 'MM/DD/YY')} @ ${date.format(d, 'h:mmA')}/ET`
            : undefined;
        return result;
    },

    serializeSummary: (
        section,
        data,
        localization = {},
        tenant,
        languages = [],
        userIsAdmin = false,
        showEdit = null
    ) => {
        let detail = [
            {
                label: localization.legacy_id,
                value: data?.let_override?.legacy_id || data?.legacy_id
            },
            { label: `${section}`, value: data?.id }
        ];
        const createdAt = utils.setDate(
            data?.let_override?.created_at || data?.created_at
        );
        const updatedAt = utils.setDate(
            data?.updated_at || data?.let_override?.updated_at || undefined
        ) || "N/A";
        const sportradar_id =
            data?.let_override?.sportradar_id || data?.sportradar_id;
        const status = utils.capitalize(data?.derived_status);

        if (tenant?.name && tenant.name.startsWith('estadio')) {
            sportradar_id &&
                detail.push({
                    label: localization.sportradar_id,
                    value:
                        data?.let_override?.sportradar_id || data?.sportradar_id
                });
            status &&
                status.length &&
                detail.push({
                    label: localization.headers_status,
                    value: status
                });
            detail.push({ label: localization.created_at, value: createdAt });
            updatedAt &&
                updatedAt.length &&
                detail.push({
                    label: localization.updated_at,
                    value: updatedAt
                });
        } else {
            detail.push({ label: localization.created_at, value: createdAt });
            updatedAt &&
                updatedAt.length &&
                detail.push({
                    label: localization.updated_at,
                    value: updatedAt
                });
            sportradar_id &&
                detail.push({
                    label: localization.sportradar_id,
                    value:
                        data?.let_override?.sportradar_id || data?.sportradar_id
                });
        }

        if ((tenant?.isMax || tenant?.isDomstic) && languages && languages.length) {
            const MAX_NUM_LANGS = 3;

            const title = oxfordComa(
                languages.map((lang) => `${lang.isoCode}: ${lang.languageName}`)
            );

            const valueHeaderStyle = {
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'inherit',
                textAlign: 'inherit'
            };

            const valueItemStyle = { textAlign: 'inherit' };
            const valuePlugStyle = { ...valueItemStyle };
            const valueCodeStyle = { fontFamily: 'monospace' };

            const label = userIsAdmin ? (
                <>
                    {localization.supported_languages} (
                    <a
                        href='#'
                        onClick={() => {
                            showEdit && showEdit(true);
                        }}
                    >
                        Edit
                    </a>
                    )
                </>
            ) : (
                localization.supported_languages
            );

            detail.push({
                label,
                value: (
                    <div style={valueHeaderStyle} title={title}>
                        {languages.map((lang, index) => {
                            if (index < MAX_NUM_LANGS) {
                                return (
                                    <div
                                        style={valueItemStyle}
                                        key={`lang_${index}_${lang.isoCode}`}
                                    >
                                        <span style={valueCodeStyle}>
                                            {lang.isoCode}
                                        </span>
                                        : {lang.languageName}
                                    </div>
                                );
                            }
                            if (
                                index === MAX_NUM_LANGS &&
                                languages.length > MAX_NUM_LANGS
                            ) {
                                return (
                                    <div
                                        style={valuePlugStyle}
                                        key={`lang_${index}_plug`}
                                    >
                                        <span style={{fontSize: "0.75rem"}}>+{languages.length - MAX_NUM_LANGS} more {'\u2026'}</span>
                                    </div>
                                );
                            }
                        })}
                    </div>
                )
            });
        }

        if (data?.metadata?.isClonedEvent && data?.metadata?.cloneOf) {
            const url = `${window.location.origin}/events/${data.metadata.cloneOf}/event-info`;
            const stealthLink = {
                color: 'inherit',
                textDecoration: 'none'
            };

            detail.push({
                label: localization.clone_of,
                value: (
                    <a
                        id='cloneLink'
                        style={stealthLink}
                        href={url}
                        target={'blank'}
                    >
                        {data?.metadata?.cloneOf}
                    </a>
                )
            });
        }

        return detail;
    },

    getDefaultCountry: () => {
        return [
            {
                id: 78,
                label: 'USA',
                value: 'USA'
            }
        ];
    },

    deepEqual: (object1, object2) => {
        const keys1 = Object.keys(object1).sort((a, b) => (a > b ? 1 : -1));
        const keys2 = Object.keys(object2).sort((a, b) => (a > b ? 1 : -1));

        const delta = Math.abs(keys1.length - keys2.length);

        let difference = [
            ...keys1.filter((x) => !keys2.includes(x)),
            ...keys2.filter((x) => !keys1.includes(x))
        ];

        if (difference.length) {
            return false;
        }

        for (const key of keys1) {
            // Pull both values:
            const val1 = object1[key];
            const val2 = object2[key];

            // If they're both null or undefined, that's fine. Skip.
            if (!val1 && !val2) {
                continue;
            }

            // Determine if objects or different:
            const areObjects = utils.isObject(val1) && utils.isObject(val2);

            if (
                (areObjects && !utils.deepEqual(val1, val2)) ||
                (!areObjects && val1 !== val2)
            ) {
                return false;
            }
        }

        return true;
    },

    isObject: (object) => {
        return object != null && typeof object === 'object';
    },

    renameFromKey: (key) => {
        let keyLabel = '';
        if (typeof key !== 'string') {
            throw new TypeError(
                `Unsupported key type: ${utils.makePascalCase(typeof key)}`
            );
        }

        if (key && typeof key === 'string') {
            const keyName = key.replace('_', ' ');
            keyLabel = utils.makePascalCase(keyName);
        }

        return keyLabel;
    },

    makePascalCase: (str) => {
        return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
            letter.toUpperCase()
        );
    },

    getHostUrl: () => {
        return location.protocol + '//' + location.host;
    },

    stripSpecialChars: (string) => {
        const modString = string.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
        return modString;
    }
};

export function loadState(key) {
    try {
        const serializedState = localStorage.getItem(key);
        if (!serializedState) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
}

export async function saveState(key, state) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(key, serializedState);
    } catch (e) {
        // Ignore
    }
}

export function generateSportradarId() {
    return `INVALID-${uuidv4()}`;
}

export const handleOnlyNumber4 = (event) => {
    const editKeys = [8, 9, 35, 36, 37, 39, 46];
    // backspace, tab, arrow(<, >), del, home, end
    const { value } = event.target;
    const charCode = event.charCode || event.which || event.keyCode;
    // only number keyboard
    if (
        !event.shiftKey &&
        ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106))
    ) {
        // prevent when length==5 and no text selection
        const selectionStart = event.target.selectionStart;
        const selectionEnd = event.target.selectionEnd;
        if (value.length > 3 && selectionStart === selectionEnd) {
            event.preventDefault();
            return false;
        }
        return true;
    } else if (editKeys.includes(charCode)) {
        return true;
    }
    event.preventDefault();
    return false;
};

export const updateNonMultilanguageEnglishOverrides = (obj) => {
    const multilanguageFields = ['name_ml', 'description_body_ml'];
    multilanguageFields.forEach((multilanguageField) => {
        const englishOverride = obj?.[multilanguageField]?.['en'] || '';
        const nonMultilanguageField = multilanguageField.substring(
            0,
            multilanguageField.lastIndexOf('_ml')
        );
        if (englishOverride && nonMultilanguageField) {
            // We should track this override.
            obj[nonMultilanguageField] = englishOverride;
        }
    });
    return obj;
};

// Tests whether a string is only composed of empty spaces:
export const emptyString = (str) => /^\s+$/.test(str);

// Friendly way to concat an array of strings.
export const friendlyConcat = (arr = []) => {
    let str = '';
    const elementCount = arr.length;
    if (elementCount === 1) {
        str += arr[0];
    } else if (elementCount === 2) {
        str += `${arr[0]} and ${arr[1]}`;
    } else {
        arr.forEach((elem, index) => {
            const joiner = index === elementCount - 1 ? ', and ' : ', ';
            if (index > 0 && index < elementCount) {
                str += joiner;
            }
            str += elem;
        });
    }

    return str;
};

export const encodeFilename = (str = '') =>
    str.replace(/[^a-zA-Z\d\.-]/gi, '_');

export const getCleanString = (str) => str.replace(/[^a-z0-9\-\_]/gi, '');

/* Used to cull any properties with null, undefined, and empty strings from payloads  */
export const removeNullUndefined = (obj) =>
    Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined)
    );

// Used to build a file filter format for input fields:
export const getFileFilter = (type, allowedFormat) => {
    if (type === 'image') {
        if (
            allowedFormat &&
            typeof allowedFormat === 'string' &&
            allowedFormat.length > 0
        ) {
            return `.${allowedFormat.toLowerCase()}`;
        }
        // Return a list of acceptable images. 
        return '.jpeg,.jpg,.png';
    }
    return 'video/*';
};

export const getQueryParams = (search = '') => {
    return decodeURI(search)
        .replace('?', '')
        .split('&')
        .map((param) => param.split('='))
        .reduce((values, [key, value]) => {
            values[key] = value;
            return values;
        }, {});
};
