import React from 'react';
import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    styling: {
        '& .FAILED': {
            color: 'red'
        },

        '& .STARTING': {
            color: 'green'
        },

        '& .PROVISIONING': {
            color: 'green'
        },

        '& .RUNNING': {
            color: 'green'
        },

        '& .STOPPED': {
            color: 'green'
        },

        '& .detailValue': {
            color: 'black'
        }
    }
}));

export const Details = (props) => {
    const classes = useStyles();
    const { details } = props;

    const detailInfo =
        details.length > 0 &&
        details.map((info, index) => (
            <Grid
                container
                item
                xs={6}
                key={info.label}
                justifyContent={index % 2 === 0 ? 'flex-start' : 'flex-end'}
            >
                <div>
                    <div
                        style={{
                            fontWeight: 'bold',
                            justifyContent:
                                index % 2 === 0 ? 'flex-start' : 'flex-end',
                            margin: '0 0 6px 0',
                            textAlign: index % 2 === 0 ? 'left' : 'right'
                        }}
                    >
                        {info.label}:
                    </div>
                    <div
                        className={
                            info.label === 'Athena State'
                                ? info.value
                                : 'detailValue'
                        }
                        style={{
                            fontWeight: 'normal',
                            textAlign: index % 2 === 0 ? 'left' : 'right'
                        }}
                    >
                        {info.value}
                    </div>
                </div>
            </Grid>
        ));

    return (
        <Container maxWidth={'lg'} sx={{ mb: 3 }}>
            <Grid container spacing={2}>
                {detailInfo}
            </Grid>
        </Container>
    );
};
