import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// Notifications:
import { notifyError, notifySuccess } from '../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../reducer/localizationSlice';

export const CopyToClipboardButton = (props) => {
    const dispatch = useDispatch();
    const { value } = props;
    const disabled = !value || value === '';

    const copyToClipboard = async () => {
        if (disabled) {
            return;
        }
        try {
            await navigator.clipboard.writeText(value);
            dispatch(
                notifySuccess(`"${value}" has been copied to the clipboard`)
            );
        } catch (e) {
            dispatch(notifyError(e.message));
        }
    };

    const localization = useSelector(strings);

    return (
        <div
            alt={disabled ? '' : localization.copy_to_clipboard}
            title={disabled ? '' : localization.copy_to_clipboard}
            data-testid="copy-to-clipboard"
        >
            <ContentCopyIcon
                onClick={copyToClipboard}
                disabled
                style={{
                    width: 20,
                    height: 'auto',
                    margin: 0,
                    padding: 0,
                    cursor: disabled ? 'default' : 'pointer',
                    opacity: disabled ? 0.2 : 1.0
                }}
            />
        </div>
    );
};
