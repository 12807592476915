import React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { strings } from '../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%'
    },
    spinner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

export default function Spinner(props) {
    const classes = useStyles();
    const l10n = useSelector(strings);
    return (
        <div data-name='spinner-container' className={classes.container}>
            <div className={classes.spinner}>
                <div>
                    <CircularProgress color='inherit' />
                </div>
                <div data-name='spinner-loading'>{`${l10n.loading}...`}</div>
            </div>
        </div>
    );
}
