import { createSlice } from '@reduxjs/toolkit';

export const configSlice = createSlice({
    name: 'config',
    initialState: {
        amplify: {},
        apiKey: null,
        imageBaseUrl: null,
        gspCdnBasePath: null,
        topPlayer: {},
        domains: {},
        environment: null
    },
    reducers: {
        updateConfig: (state, action) => {
            const config = action.payload;
            state.amplify = config.amplify || {};
            state.imageBaseUrl = config.imageBaseUrl || '';
            state.gspCdnBasePath = config.gspCdnBasePath || '';
            state.topPlayer = config.topPlayer || {};
            state.domains = config.domains || {};
            state.environment = config.environment || '';
        },
        updateAPIKey: (state, action) => {
            const tokenData = action.payload;
            state.apiKey = tokenData || '';
        }
    }
});

export const { updateConfig } = configSlice.actions;
export const { updateAPIKey } = configSlice.actions;
export const getConfigs = (state) => state.config;
export const getEnvironment = (state) => state.config.environment;
export default configSlice.reducer;
