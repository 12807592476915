import React from 'react';
import _ from 'lodash';
import { CopyToClipboardButton } from './CopyToClipboardButton';
import { InputBase, InputLabel, Box } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { ToggleButton } from './ToggleButton';

const BootstrapInput = withStyles((theme) => ({
    root: {
        marginBottom: '10px'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        padding: '9px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        width: '100%',
        '&:focus': {
            boxShadow: 'none',
            borderColor: 'black'
        },
        '&:hover': {
            boxShadow: 'none',
            borderColor: 'black'
        },
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            appearance: 'none',
            margin: 0
        },
        '&[type=number]': {
            appearance: 'textfield'
        }
    }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    labelStyle: {
        fontWeight: 600,
        fontSize: 'large',
        color: 'black'
    },
    error: {
        borderRadius: 4,
        border: '1px solid red',

        '& .MuiInputBase-input:focus': {
            border: 'none'
        },
        '& .MuiInputBase-input:hover': {
            border: 'none'
        }
    }
}));

export const badChars = /[^a-zA-Z\d\-\_\/\.\:]/gi;

export const Input = (props) => {
    const classes = useStyles();

    let {
        autoTrim,
        label,
        value,
        handleChange,
        name,
        removeSpecialCharacters,
        type,
        showToggle,
        enableToggle,
        originalValue,
        handleToggleChange,
        error,
        disabled,
        showCopy,
        onKeyDown,
        required
    } = props;

    const onHandleChange = (e) => {
        let updatedValue;

        if (autoTrim && /\w/gi.test(e.target.value)) {
            updatedValue = e.target.value.trim();
            console.info(
                `Removing leading and trailing whitespace\n\t("${e.target.value}" updates to "${updatedValue}")`
            );
            e.target.value = updatedValue;
        }
        if (removeSpecialCharacters && badChars.test(e.target.value)) {
            updatedValue = e.target.value.replace(badChars, '');
            console.info(
                `Removing illegal characters\n\t("${e.target.value}" updates to "${updatedValue}")`
            );
            e.target.value = updatedValue;
        }
        handleChange(e);
    };

    const handleKeyDown = (e) => {
        if (onKeyDown) onKeyDown(e);
    };

    return (
        <Box className={classes.root}>
            <Box
                justifyContent='space-between'
                display='flex'
                alignItems='flex-end'
            >
                <InputLabel
                    className={classes.labelStyle}
                    shrink
                    htmlFor={name}
                >
                    {label} {required && <span className='text-danger'>*</span>}
                </InputLabel>

                {(showToggle || showCopy) && (
                    <Box
                        xs={2}
                        style={{
                            flexBasis: '1%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        {showToggle && (
                            <ToggleButton
                                showToggle={
                                    showToggle &&
                                    (value != null || value !== undefined) &&
                                    !_.isEqual(value, originalValue)
                                }
                                name={name}
                                originalValue={originalValue}
                                enableToggle={enableToggle}
                                handleToggleChange={handleToggleChange}
                            />
                        )}
                        {showCopy && (
                            <CopyToClipboardButton
                                value={value || originalValue}
                            />
                        )}
                    </Box>
                )}
            </Box>

            {!!showToggle && (
                <BootstrapInput
                    name={name}
                    type={type || 'text'}
                    id={name}
                    onChange={handleChange}
                    {...(!!error && { className: classes.error })}
                    {...(!enableToggle[name] && {
                        placeholder: value.toString() || null
                    })}
                    value={(enableToggle[name] && value) || ''}
                    onKeyDown={(e) => handleKeyDown(e)}
                />
            )}
            {!showToggle && (
                <BootstrapInput
                    name={name}
                    type={type || 'text'}
                    id={name}
                    onChange={onHandleChange}
                    {...(!!error && { className: classes.error })}
                    {...{ value: value || '' }}
                    disabled={disabled}
                    onKeyDown={(e) => handleKeyDown(e)}
                />
            )}
        </Box>
    );
};
