/* eslint-disable no-use-before-define */

import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { ToggleButton } from './ToggleButton';
import { Grid } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
        width: '100%'
    },
    labelStyle: {
        fontWeight: 600,
        fontSize: 'large',
        color: 'black'
    },
    multipleBox: {
        ' & .MuiOutlinedInput-root': {
            padding: '3px !important'
        },
        '& .MuiInputBase-sizeSmall': {
            height: '43px'
        }
    }
}));

export const MultiSelectBox = (props) => {
    const classes = useStyles();
    const {
        options,
        label,
        name,
        handleMultipleSelectChange,
        defaultValue,
        showToggle,
        enableToggle,
        originalValue,
        handleToggleChange,
        compareValue,
        localizedPlaceholder,
        optionLabel,
        required
    } = props;

    const defaultGetOptionLabel = (option) => option?.label;

    const defaultCompare = (option, value) => {
        return option.id === value.id;
    };

    const compare = compareValue !== undefined ? compareValue : defaultCompare;
    const getOptionLabel =
        optionLabel !== undefined ? optionLabel : defaultGetOptionLabel;

    return (
        <>
            <Grid
                container
                justifyContent='space-between'
                alignItems='flex-end'
            >
                {showToggle && (
                    <>
                        <Grid item xs={9}>
                            <InputLabel
                                className={classes.labelStyle}
                                shrink
                                htmlFor={name}
                            >
                                {label}{' '}
                                {required && (
                                    <span className='text-danger'>*</span>
                                )}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={3} style={{ flexBasis: '5%' }}>
                            <ToggleButton
                                showToggle={
                                    showToggle &&
                                    (defaultValue != null ||
                                        defaultValue !== undefined) &&
                                    !_.isEqual(defaultValue, originalValue)
                                }
                                name={name}
                                originalValue={originalValue}
                                enableToggle={enableToggle}
                                handleToggleChange={handleToggleChange}
                            />
                        </Grid>
                    </>
                )}
                {!showToggle && (
                    <>
                        <Grid item xs={12}>
                            <InputLabel
                                className={classes.labelStyle}
                                shrink
                                htmlFor={name}
                            >
                                {label}{' '}
                                {required && (
                                    <span className='text-danger'>*</span>
                                )}
                            </InputLabel>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    className={classes.multipleBox}
                    name={name}
                    id={name}
                    multiple
                    size='small'
                    limitTags={5}
                    onChange={(event, newValue) =>
                        handleMultipleSelectChange(event, newValue, name)
                    }
                    options={options}
                    value={defaultValue}
                    disableCloseOnSelect
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={compare}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='outlined'
                            placeholder={localizedPlaceholder}
                        />
                    )}
                />
            </Grid>
        </>
    );
};
