import { useState } from 'react';

export const useReset = (searchParms, newSearchParms, asOpen) => {
    const [searchParams, setSearchParams] = useState(searchParms);
    const [newSearchParams, setNewSearchParams] = useState(newSearchParms);
    const [openStatus, setOpenStatus] = useState(asOpen);

    const initSearchParams = () => {
        setSearchParams({});
        setNewSearchParams({});
        setOpenStatus(false);
    };

    return {
        initSearchParams,
        searchParams,
        newSearchParams,
        openStatus
    };
};
