import React from 'react';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTooltip-tooltip':{
      fontSize: 13
    }
  }
}));

export const ToggleButton = (props) => {
  const classes = useStyles();
  const {
    showToggle, name:attrName, originalValue, enableToggle, handleToggleChange, style
  } = props;
  
  return (
    <div className={`${classes.root} ${style}`}>
      {showToggle && originalValue!==null && 
        <Switch
          checked={enableToggle[attrName] || false}
          onChange={(e) => handleToggleChange(e, attrName)}
          name={attrName} disabled={!enableToggle[attrName]}
          inputProps={{ 'aria-label': attrName }}
          size="small"
        />      
      }
    </div>
  )
}
