import { configureStore } from '@reduxjs/toolkit';
import advancedSearchReducer from '../reducer/advancedSearchReducer';
import configReducer from '../reducer/configSlice';
import encoderStatusReducer from '../reducer/encoderSlice';
import localizationReducer from '../reducer/localizationSlice';
import multilanguageSlice from '../reducer/multilanguageSlice';
import notificationReducer from '../reducer/notificationReducer';
import tenantReducer from '../reducer/tenantSlice';
import userReducer from '../reducer/userReducer';

const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
    reducer: {
        config: configReducer,
        encoderStatus: encoderStatusReducer,
        localization: localizationReducer,
        multilanguage: multilanguageSlice,
        notification: notificationReducer,
        search: advancedSearchReducer,
        tenant: tenantReducer,
        user: userReducer
    }
});

export default store;
