import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavigateNext } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(5)
    },
    styling: {
        textDecoration: 'none !important',
        color: '#bdbdbd'
    }
}));

export const CustomBreadcrumbs = (props) => {
    const { steps, id, onChange } = props;
    const location = useLocation();

    const classes = useStyles();
    const handleNavigation = (e, id, slug) => {
        if (onChange && id !== 'new') {
            onChange(e, id, slug);
        }
    };
    const currentSlug = location.pathname;
    const activeStyle = (slug) => {
        return currentSlug.indexOf(slug) > 0
            ? { color: 'black' }
            : { color: 'grey' };
    };
    return (
        <div className={classes.root}>
            <Breadcrumbs
                separator={<NavigateNext fontSize='small' />}
                aria-label='breadcrumb'
            >
                {steps.map((step, i) => {
                    const path = id === 'new' ? '#' : step.slug;
                    return (
                        <NavLink
                            key={i}
                            to={path}
                            onClick={(e) => handleNavigation(e, id, path)}
                            className={classes.styling}
                            activeStyle={activeStyle(step.slug)}
                        >
                            {step.title}
                        </NavLink>
                    );
                })}
            </Breadcrumbs>
        </div>
    );
};
