import services from '../../services/apiService';
import { exportService } from '../../services/exportService';
import { utils } from '../../utils/utils';
import { date } from '../../utils/date';

const JSONSchema = require('let-json-schemas/v1/input_db/event.json');

export const eventsProcessingExportFiles = async (type, eventData) => {
    const _maxExportLength = 1000;
    let _cols = [];

    //Set Columns
    (() => {
        _cols.push(
            { header: 'Id', field: 'id' },
            { header: 'Legacy Id', field: 'legacy_id' },
            { header: 'Name', field: 'name' },
            { header: 'Tournament Id', field: 'tournament_id' },
            { header: 'Tournament Name', field: 'tournament_name' },
            { header: 'Date', field: 'scheduled_utc' },
            { header: 'Time', field: 'scheduled_utc' },
            { header: 'Live', field: 'is_live' },
            { header: 'Visibility', field: 'hidden' },
            { header: 'Media', field: 'has_media' },
            { header: 'Created', field: 'created_at' },
            { header: 'Updated', field: 'updated_at' },
            { header: 'Action', field: null }
        );
        Object.keys(JSONSchema.properties).map((key) => {
            const header = key
                .split('_')
                .map((word) => utils.capitalize(word))
                .join(' ');
            const match = _cols.find((item) => item.field === key);
            if (match) {
                return;
            }
            _cols.push({
                header: header,
                field: key
            });
            if (
                [
                    'sport_id',
                    'venue_id',
                    'league_id',
                    'series_id',
                    'division_id',
                    'conference_id',
                    'home_competitor_id',
                    'away_competitor_id'
                ].includes(key)
            ) {
                const field = key.replace('_id', '');
                const header = field.replace('_', ' ');

                _cols.push({ header: utils.capitalize(header), field: field });
            }
        });
    })();

    const exportCSV = async () => {
        //Call events service here
        try {
            let processedEventsObj = [];
            eventData.map((item) => {
                const event = Object.assign({}, item);
                getTournamentId(event, 'sport');

                objToName(
                    event,
                    'sport',
                    'league',
                    'venue',
                    'home_competitor',
                    'away_competitor',
                    'series',
                    'conference',
                    'division'
                );

                updateImageUrls(
                    event,
                    'image',
                    'logo_dark',
                    'logo_light',
                    'logo_split'
                );

                updateSponsorImageUrls(
                    event,
                    'sponsor_logo',
                    'sponsor_logo_light',
                    'sponsor_logo_dark'
                );

                toTimeZoneIso(
                    event,
                    'scheduled_utc',
                    'scheduled_utc_end',
                    'scheduled_local'
                );
                processedEventsObj.push(event);
            });
            let events_tournaments =
                processedEventsObj.length &&
                (await getTournamentNameById(processedEventsObj));
            let rows =
                events_tournaments.length > 0 &&
                addTournamentName([processedEventsObj, events_tournaments]);
            return exportService.exportCSV(_cols, rows, 'event');
        } catch (err) {
            console.error(`Export error: ${err.message}`);
        }
    };

    const exportJSON = async () => {
        let processedEventsObj = [];
        eventData.map((item) => {
            const event = Object.assign({}, item);
            getTournamentId(event, 'sport');
            processedEventsObj.push(event);
        });

        let events_tournaments =
            processedEventsObj.length > 0 &&
            (await getTournamentNameById(processedEventsObj));
        let rows =
            events_tournaments.length > 0 &&
            addTournamentName([processedEventsObj, events_tournaments]);
        return exportService.exportJSON(rows, 'event');
    };

    const getTournamentId = (event, ...keys) => {
        return keys
            .filter((key) => {
                return event[key];
            })
            .map(
                () =>
                    (event['tournament_id'] =
                        (event.series && event.series.tournament_id) || null)
            );
    };

    const objToName = (event, ...keys) => {
        return keys
            .filter((key) => event[key])
            .forEach((key) => {
                event[key] = event[key].name;
            });
    };

    const getTournamentNameById = async (events) => {
        let tournaments = [];
        const set = new Set();
        const hasId = events.filter((evt) => evt.tournament_id);

        hasId.forEach((obj) => set.add(obj.tournament_id));

        const setValues = Array.from(set.values());

        try {
            for (let tr of setValues) {
                tournaments.push(
                    await services.fetchAllData(`tournaments/${tr}`)
                );
            }
            return tournaments;
        } catch (err) {
            console.error(err);
        }
    };

    const addTournamentName = ([events, tournaments]) => {
        const resolvedTournaments = tournaments.reduce((t, next) => {
            const tournamentEvents = t.filter(
                (s) => s.tournament_id === next.id
            );

            tournamentEvents.forEach(
                (tEvt) => (tEvt['tournament_name'] = next.display_name || '')
            );

            return t;
        }, events);
        return resolvedTournaments;
    };

    const toTimeZoneIso = (event, ...keys) => {
        keys.filter((key) => event[key]).forEach((key) => {
            event[key] = date.toTimeZoneIso(
                event[key],
                'America/New_York',
                true
            );
        });
    };

    const updateImageUrls = (event, ...keys) => {
        keys.forEach((key) => {
            let bucket = event.league ? event.league.toLowerCase() : '';

            if ('image' === key && event.bucket_name) {
                bucket = event.bucket_name;
            }

            if (event[key]) {
                event[key] = utils.getImageSourceUrl(bucket, event[key]);
            }
        });
    };

    const updateSponsorImageUrls = (event, ...keys) => {
        keys.forEach((key) => {
            if (event[key]) {
                event[key] = utils.getImageSourceUrl('sponsors', event[key]);
            }
        });
    };

    switch (type) {
        case 'csv':
            exportCSV();
            break;
        case 'json':
            exportJSON();
            break;
    }
};
