import store from '../redux/store';
import httpService from './httpService';
const controller = new AbortController();

const services = {
    httpInstanceProtected: null,
    httpInstance: null,

    letApiInstanceProtected: function () {
        if (!this.httpInstanceProtected) {
            this.httpInstanceProtected =
                httpService.getLetAPIHttpInstanceProtected();
        }
        return this.httpInstanceProtected;
    },

    letApiInstance: function () {
        if (!this.httpInstance) {
            this.httpInstance = httpService.getLetAPIHttpInstance();
        }
        return this.httpInstance;
    },

    getTenant: function () {
        const state = store.getState();
        return state?.tenant?.tenantName || null;
    },

    fetchList: async function (apiPath) {
        try {
            let apiData = await this.letApiInstanceProtected().get(apiPath, {
                headers: { range: 'brlive=0-9999' }
            });
            return apiData.data;
        } catch (err) {
            throw err;
        }
    },

    fetchData: async function (apiPath, range = '') {
        try {
            let apiData;
            if (range !== '') {
                apiData = await this.letApiInstanceProtected().get(apiPath, {
                    headers: { range: range }
                });
            } else {
                apiData = await this.letApiInstanceProtected().get(apiPath);
            }
            return apiData;
        } catch (err) {
            throw err;
        }
    },

    cancelCall: function () {
        controller.abort();
    },

    fetchAllData: async function (apiPath) {
        try {
            let apiData = await this.letApiInstanceProtected().get(apiPath, {
                headers: { range: 'brlive=0-9999' }
            });
            return apiData.data;
        } catch (err) {
            throw err;
        }
    },

    getAttribute: async function (apiPath, attributeName, attributeValue) {
        const API_PATH = `${apiPath}/?${attributeName}=${attributeValue}`;
        return this.fetchList(API_PATH);
    },

    getAttributes: async function (
        apiPath,
        attributeName,
        attributeValue,
        attrName2,
        attrValue2
    ) {
        const API_PATH = `${apiPath}/?${attributeName}=${attributeValue}${
            attrName2 && attrValue2 ? `&${attrName2}=${attrValue2}` : ''
        }`;
        return this.fetchAllData(API_PATH);
    },

    getItem: async function (apiPath, id, separate = true) {
        const API_PATH = `${apiPath}/${id}?r=${new Date().getTime()}&separate=${
            separate && ''
        }`;
        let response;
        try {
            response = await this.fetchList(API_PATH);
        } catch (e) {
            response = {};
        }
        return response;
    },

    updateLanguages: async function (id, languages = []) {
        const payload = [{
            metadata: {
                languages
            }
        }];
        const API_PATH = `events/languages/${id}`;
        let response;
        try {
            response = await this.letApiInstanceProtected().patch(
                API_PATH,
                payload
            );
        } catch (e) {
            console.error(e);
            response = {};
        }
        return response;
    },

    getItemWithQueryString: async function (apiPath, query, range) {
        let response;
        try {
            response = await this.fetchList(`${apiPath}?${query}`, range);
        } catch (e) {
            response = {};
        }
        return response;
    },

    createItem: async function (apiPath, payload) {
        try {
            return await this.letApiInstanceProtected().post(apiPath, payload);
        } catch (err) {
            throw err;
        }
    },

    deleteItem: async function (apiPath, payload) {
        try {
            return await this.letApiInstanceProtected().delete(apiPath);
        } catch (err) {
            throw err;
        }
    },

    updateItem: async function (apiPath, payload) {
        try {
            return await this.letApiInstanceProtected().put(apiPath, payload);
        } catch (err) {
            if (err.response) {
                throw err.response.data;
            } else {
                throw err;
            }
        }
    },

    cloneItem: async function (apiPath) {
        try {
            return await this.letApiInstanceProtected().get(apiPath, {
                headers: { 'Cache-Control': 'no-cache' }
            });
        } catch (err) {
            throw err;
        }
    },

    stubServiceAPIData: async function (service) {
        return {};
    }
};

export default services;
