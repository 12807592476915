import store from '../redux/store';
import { hboImages, maxImages } from '../data';
import { getImageProperty } from '../utils/hboImageUtils';

export const VALID_IMAGES = (() => {
    let images = {};
    hboImages.forEach((img) => {
        images[img.name] = img.type;
    });
    maxImages.forEach((img) => {
        images[img.name] = `${img.name}_metadata`;
    });
    return Object.freeze(images);
})();

export const eventsService = {
    getPreProcessedImgObj: function (imageData, imageKey, tag, type) {
        const state = store.getState();
        const imageBaseUrl = state.config.imageBaseUrl;

        const aspect_ratio = getImageProperty(imageKey, 'aspectRatio');

        const imgObj = {
            image: type === 'image' ? imageData[imageKey] : '',
            aspect_ratio,
            cdnBasePath: state.config.gspCdnBasePath,
            path: type === 'image' ? `${imageBaseUrl}` : '',
            key: imageKey,
            width: 0,
            height: 0,
            tag: tag,
            etag: '',
            overrideUrl: imageData[imageKey + '_override']
                ? imageData[imageKey + '_override']
                : ''
        };

        const imageMetadataKey = `${imageKey}_metadata`;
        const rawMetadata = imageData[imageMetadataKey];

        if (rawMetadata) {
            try {
                const metaData = JSON.parse(rawMetadata);
                imgObj.width = metaData.width;
                imgObj.height = metaData.height;
                imgObj.etag = metaData.etag;
            } catch (e) {
                console.error(e);
            }
        } else {
            console.error(
                `getPreProcessedImgObj error processing image ${imageKey}`
            );
        }

        return imgObj;
    },

    preProcessHBOImage: function (imageData) {
        const toFilter = {
            ...imageData
        };
        const filteredImageData = (() => {
            const filtered = {};
            Object.keys(toFilter).forEach((data) => {
                data && toFilter[data] && (filtered[data] = toFilter[data]);
            });
            return filtered;
        })();

        const rv_image = [];

        if (imageData) {
            const obj = this;
            for (const imageKey in VALID_IMAGES) {
                if (VALID_IMAGES[imageKey]) {
                    if (
                        imageData.hasOwnProperty(imageKey) &&
                        imageData[imageKey]
                    ) {
                        rv_image.push(
                            obj.getPreProcessedImgObj(
                                imageData,
                                imageKey,
                                VALID_IMAGES[imageKey],
                                'image'
                            )
                        );
                    } else if (
                        imageData.hasOwnProperty(`${imageKey}_override`) &&
                        imageData[`${imageKey}_override`]
                    ) {
                        rv_image.push(
                            obj.getPreProcessedImgObj(
                                imageData,
                                imageKey,
                                VALID_IMAGES[imageKey],
                                'override'
                            )
                        );
                    }
                } else {
                    console.error(`Unknown image key: ${imageKey}`);
                }
            }
        }
        return rv_image;
    },

    validateHBOImagesInputs: function (HBOImagesData) {
        const invalidImageFields = [];

        if (VALID_IMAGES) {
            for (const key in VALID_IMAGES) {
                if (VALID_IMAGES.hasOwnProperty(key)) {
                    const imageKey = key;
                    const metaKey = `${imageKey}_metadata`;
                    if (HBOImagesData[imageKey]) {
                        if (HBOImagesData[metaKey]) {
                            if (
                                !this.isValidHboMetadata(HBOImagesData[metaKey])
                            ) {
                                invalidImageFields.push(imageKey);
                            }
                        } else {
                            invalidImageFields.push(imageKey);
                        }
                    }
                }
            }
        }

        return invalidImageFields;
    },

    isValidHboMetadata: function (meta) {
        if (meta && meta !== '' && this.IsJsonString(meta)) {
            try {
                const metaDataObj = JSON.parse(meta);
                let hasValidWidth = false;
                let hasValidHeight = false;
                let hasValidEtag = false;

                if (
                    metaDataObj.width &&
                    metaDataObj.width !== '' &&
                    metaDataObj.width !== 0 &&
                    parseInt(metaDataObj.width, 10) > 0
                ) {
                    hasValidWidth = true;
                }
                if (
                    metaDataObj.height &&
                    metaDataObj.height !== '' &&
                    metaDataObj.height !== 0 &&
                    parseInt(metaDataObj.height, 10) > 0
                ) {
                    hasValidHeight = true;
                }
                if (
                    metaDataObj.etag &&
                    metaDataObj.etag !== '' &&
                    metaDataObj.etag !== 0 &&
                    metaDataObj.etag !== '""'
                ) {
                    hasValidEtag = true;
                }
                return hasValidWidth && hasValidHeight && hasValidEtag;
            } catch (e) {
                return false;
            }
        } else {
            return false;
        }
    },

    IsJsonString: function (str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },

    // Functions to format the Descriptors, utilised in LiveAdmin page.
    addLabelValue: function (data) {
        return Object.assign({}, data, {
            label: data.name,
            value: data.id
        });
    },

    isEmptyObject: function (obj) {
        return obj && Object.keys(obj).length === 0;
    },

    formatDescriptorsChoices: function (allDescriptors) {
        let allFormattedDescriptors = {};
        if (!this.isEmptyObject(allDescriptors)) {
            Object.keys(allDescriptors).forEach((key) => {
                allFormattedDescriptors[key] =
                    allDescriptors[key].length > 0
                        ? allDescriptors[key].map((element) =>
                              this.addLabelValue(element)
                          )
                        : [];
            });
        }
        return allFormattedDescriptors;
    }
};

export default eventsService;
