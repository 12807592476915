/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import services from '../../services/apiService';
import { selectTenant } from '../../reducer/tenantSlice';
import { date } from '../../utils/date';
import {
    emptyString,
    generateSportradarId,
    updateNonMultilanguageEnglishOverrides,
    utils,
    friendlyConcat
} from '../../utils/utils';
// Notifications:
import { notifyError, notifySuccess } from '../../reducer/notificationReducer';
import { useDispatch } from 'react-redux';
import { HBO_AND_MAX_IMAGE_DATA_FIELDS } from '../../utils/hboImageUtils';
import { COUNTRIES_WORLDWIDE } from '../../data/countriesList';
import { agnosticSort } from '../events/event-details/partials/CountriesAvailable';
// Image Data
import { hboImages, maxImages } from '../../data';
// ML
import { getIsMultiLanguage } from '../../reducer/multilanguageSlice';
// Max & HBO Images:
import { getImages as getHboImages } from '../../utils/hboImageUtils';
import { getImages as getMaxImages } from '../../utils/maxImageUtils';
import store from '../../redux/store';
import { VALID_IMAGES } from '../../services/eventsService';

export const useForm = (props) => {
    const history = useHistory();
    const location = useLocation();
    const tenant = useSelector(selectTenant);
    const languages = tenant.languages;

    const isLiveAdmin = /\/events\/.+\/live-admin/.test(location.pathname);
    const isAppearance = /\/events\/.+\/appearance/.test(location.pathname);

    const isMultilanguage = useSelector(getIsMultiLanguage);

    const {
        service,
        initialState,
        id,
        requiredFields,
        setRefresh,
        extraFields
    } = props;

    let fields = requiredFields.filter((field) => field !== 'legacy_id');

    // TEST FOR MULTI-LANGUAGE
    /*
    TODO: This codeblock can likely be removed at some point in the future
          when we no longer need to trap for undefined _ml values.
          */
    if (tenant.isMultiLanguage && Object.keys(initialState).length > 0) {
        // Multi-language Name:
        if (!initialState.name_ml) {
            const currentName = initialState.name || '';
            const multilangageName = {};
            languages.forEach((language) => {
                multilangageName[language] = currentName;
            });
            initialState.name_ml = multilangageName;
            const hasNameOverride = initialState?.let_override?.name_ml;
            if (!hasNameOverride) {
                const nameOverride = initialState?.let_override?.name;
                if (nameOverride) {
                    const multilanguageNameOverride = {};
                    languages.forEach((language) => {
                        multilanguageNameOverride[language] = nameOverride;
                    });
                    initialState.let_override.name_ml =
                        multilanguageNameOverride;
                }
            }
        }
        // Multi-language Description:
        if (!initialState.description_body_ml) {
            const currentDescription = initialState.description_body || '';
            const multilangageDescription = {};
            languages.forEach((language) => {
                multilangageDescription[language] = currentDescription;
            });
            initialState.description_body_ml = multilangageDescription;
            const hasDescriptionOverride =
                initialState?.let_override?.description_body_ml;
            if (!hasDescriptionOverride) {
                const descriptionOverride =
                    initialState?.let_override?.description_body;
                if (descriptionOverride) {
                    const multilanguageDescriptionOverride = {};
                    languages.forEach((language) => {
                        multilanguageDescriptionOverride[language] =
                            descriptionOverride;
                    });
                    initialState.let_override.description_body_ml =
                        multilanguageDescriptionOverride;
                }
            }
        }
    }
    // END TEST FOR MULTI-LANGUAGE

    if (extraFields !== undefined) {
        fields = fields.concat(extraFields);
    }

    const dispatch = useDispatch();

    const [toggle, setToggle] = useState({
        showToggle: false,
        enableToggle: {}
    });
    const [item, setItem] = useState(initialState);
    const [valueChanged, setValueChanged] = useState(false);
    const [isInProgress, setIsInProgress] = useState(id !== 'new' && true);
    const [error, setError] = useState({});

    const tenant_meta = item?.tenant_metadata?.hbo_max || [];
    const override_tenant_meta =
        item?.let_override?.tenant_metadata?.hbo_max || [];

    const defaultOverrides = (event) => {
        const eventDefaults = {
            color_1: '000000',
            color_2: '000000',
            has_media: false,
            hidden: true,
            is_live: false,
            medium_vod: false,
            recommended: false,
            sponsor_text: 'B/R Live Free match of the day presented by',
            sportradar_id: generateSportradarId(),
            status: 'scheduled',
            trending: false
        };
        return { ...event, ...eventDefaults };
    };

    const getTerritories = (let_override) => {
        if (let_override?.territories_available?.length > 0) {
            let territories_values = [];
            territories_values = let_override.territories_available.map(
                (territories) => {
                    return territories?.value;
                }
            );
            return territories_values;
        }
        return null;
    };

    const initializeForm = () => {
        const { let_override } = initialState || {};
        let tempForm = {};

        id !== 'new' && { ...tempForm, id };

        if (service === 'events') {
            if (tenant.isMaxFamily) {
                const imageData = (() => {
                    const imageFields = {};
                    hboImages.map((img) => {
                        const key = img.name;
                        imageFields[key] =
                            initialState?.let_override?.[key] || null;
                        const override_key = `${key}_metadata`;
                        imageFields[override_key] =
                            initialState?.let_override?.[override_key] || null;
                        const metadata_key = `${key}_metadata`;
                        imageFields[metadata_key] =
                            initialState?.let_override?.[metadata_key] || null;
                    });
                    maxImages.map((img) => {
                        const key = img.name;
                        imageFields[key] =
                            initialState?.let_override?.[key] || null;
                        const override_key = `${key}_metadata`;
                        imageFields[override_key] =
                            initialState?.let_override?.[override_key] || null;
                        const metadata_key = `${key}_metadata`;
                        imageFields[metadata_key] =
                            initialState?.let_override?.[metadata_key] || null;
                    });
                    return imageFields;
                })();
                const hboMaxMeta = {
                    game_start:
                        initialState?.let_override?.tenant_metadata?.hbo_max
                            ?.game_start || null,
                    game_end:
                        initialState?.let_override?.tenant_metadata?.hbo_max
                            ?.game_end || null,
                    live_runtime:
                        initialState?.let_override?.tenant_metadata?.hbo_max
                            ?.live_runtime || null,
                    post_game_end:
                        initialState?.let_override?.tenant_metadata?.hbo_max
                            ?.post_game_end || null,
                    post_game_start:
                        initialState?.let_override?.tenant_metadata?.hbo_max
                            ?.post_game_start || null,
                    pre_game_start:
                        initialState?.let_override?.tenant_metadata?.hbo_max
                            ?.pre_game_start || null,
                    quick_vod_runtime:
                        initialState?.let_override?.tenant_metadata?.hbo_max
                            ?.quick_vod_runtime || null,
                    quick_vod_start:
                        initialState?.let_override?.tenant_metadata?.hbo_max
                            ?.quick_vod_start || null,
                    quick_vod_runtime:
                        initialState?.let_override?.tenant_metadata?.hbo_max
                            ?.quick_vod_runtime || null,
                    live_runtime:
                        initialState?.let_override?.tenant_metadata?.hbo_max
                            ?.live_runtime || null,
                    offering_start_utc:
                        initialState?.let_override?.tenant_metadata?.hbo_max
                            ?.offering_start_utc || null,
                    offering_end_utc:
                        initialState?.let_override?.tenant_metadata?.hbo_max
                            ?.offering_end_utc || null
                };

                tempForm = {
                    ...tempForm,
                    tenant_metadata: {
                        hbo_max: hboMaxMeta
                    },
                    ...imageData
                };
            }
            if (id === 'new') {
                const defaultOverridesObject = defaultOverrides(tempForm);
                tempForm = {
                    ...tempForm,
                    ...defaultOverridesObject
                };
            }
        }

        if (id === 'new') {
            if (service === 'series') {
                tempForm['has_media'] = false;
            } else if (service === 'competitors') {
                tempForm['hidden'] = false;
                tempForm['has_media'] = false;
            } else if (service === 'leagues') {
                tempForm['followable'] = false;
                tempForm['has_media'] = false;
            }
        }

        fields.map((field) => {
            const originalData =
                let_override !== undefined && let_override[field] !== undefined
                    ? let_override
                    : initialState;
            let value = originalData[field];

            if (field === 'offering_start_utc' || field === 'offering_end_utc')
                value = originalData?.tenant_metadata?.hbo_max[field];

            tempForm = { ...tempForm, [field]: value ? value : '' };
        });

        const territories = getTerritories(let_override);

        if (
            id === 'new' &&
            [
                'competitors',
                'events',
                'leagues',
                'series',
                'sports',
                'tournaments'
            ].includes(service)
        ) {
            tempForm['territories_available'] = utils.getDefaultCountry();
        }

        if (let_override) {
            'sport_id' in let_override &&
                (tempForm = {
                    ...tempForm,
                    sport_id: let_override['sport_id']
                });

            'league_id' in let_override &&
                (tempForm = {
                    ...tempForm,
                    league_id: let_override['league_id']
                });

            'conference_id' in let_override &&
                (tempForm = {
                    ...tempForm,
                    conference_id: let_override['conference_id']
                });

            'division_id' in let_override &&
                (tempForm = {
                    ...tempForm,
                    division_id: let_override['division_id']
                });

            'tournament_id' in let_override &&
                (tempForm = {
                    ...tempForm,
                    tournament_id: let_override['tournament_id']
                });

            [
                'sponsor_logo',
                'background_image',
                'image',
                'logo_light',
                'logo_dark',
                'hbo_bottom_left_logo',
                'sponsor_logo_dark',
                'sponsor_logo_light',
                'logo_split',
                'scheduled_utc',
                'scheduled_utc_end',
                ...HBO_AND_MAX_IMAGE_DATA_FIELDS
            ].forEach((element) => {
                if (element in let_override) {
                    tempForm[element] = let_override[element];
                }
            });
        }

        if ((tenant.isMax || tenant.isDomestic) && let_override?.images_ml) {
            tempForm.images_ml = let_override.images_ml;
        }

        return tempForm;
    };

    const [form, setForm] = useState(initializeForm);

    const getValueCount = () => {
        const { let_override } = initialState;

        const itemValues =
            let_override !== undefined || let_override !== null
                ? Object.values(initialState).filter((v) => v !== undefined)
                : Object.values(let_override).filter((v) => v !== undefined);

        return Object.keys(itemValues).length;
    };

    const resetFields = (parent) => {
        let newForm = { ...form };
        let let_override = item?.let_override;
        switch (parent) {
            case 'sport':
                if (
                    requiredFields.includes('league_id') ||
                    'league_id' in form
                ) {
                    newForm = { ...newForm, league_id: null };
                }

                if (
                    requiredFields.includes('conference_id') ||
                    'conference_id' in form
                ) {
                    newForm = { ...newForm, conference_id: null };
                }

                if (
                    requiredFields.includes('tournament_id') ||
                    'tournament_id' in form
                ) {
                    newForm = { ...newForm, tournament_id: null };
                }

                if (
                    requiredFields.includes('series_id') ||
                    'series_id' in form
                ) {
                    newForm = { ...newForm, series_id: null };
                }

                if (
                    requiredFields.includes('division_id') ||
                    'division_id' in form
                ) {
                    newForm = { ...newForm, division_id: null };
                }
                break;
            case 'league':
                if (
                    requiredFields.includes('conference_id') ||
                    'conference_id' in form
                ) {
                    newForm = { ...newForm, conference_id: null };
                }

                if (
                    requiredFields.includes('tournament_id') ||
                    'tournament_id' in form
                ) {
                    newForm = { ...newForm, tournament_id: null };
                }

                if (
                    requiredFields.includes('series_id') ||
                    'series_id' in form
                ) {
                    newForm = { ...newForm, series_id: null };
                }

                if (
                    requiredFields.includes('division_id') ||
                    'division_id' in form
                ) {
                    newForm = { ...newForm, division_id: null };
                }

                break;
            case 'tournament':
                if (
                    requiredFields.includes('series_id') ||
                    'series_id' in form
                ) {
                    newForm = { ...newForm, series_id: null };
                }

                if (
                    requiredFields.includes('division_id') ||
                    'division_id' in form
                ) {
                    newForm = { ...newForm, division_id: null };
                }
                break;
            case 'conference':
                if (
                    (requiredFields.includes('division_id') ||
                        'division_id' in form) &&
                    valueChanged
                ) {
                    newForm = { ...newForm, division_id: null };
                }
                break;
        }

        setItem({
            ...item,
            let_override
        });

        setForm(newForm);
    };

    useEffect(() => {
        Object.keys(initialState).length > 0 && setItem(initialState);

        const tempForm = initializeForm();
        setForm(tempForm);

        const valueCount = getValueCount();
        if (valueCount > 0) {
            setIsInProgress(false);
        }
    }, [initialState]);

    useEffect(() => {
        const originForm = initializeForm();
        setValueChanged(!utils.deepEqual(form, originForm));
    }, [form]);

    useEffect(() => {
        resetFields('sport');
    }, [form.sport_id]);

    useEffect(() => {
        resetFields('league');
    }, [form.league_id]);

    useEffect(() => {
        resetFields('tournament');
    }, [form.tournament_id]);

    useEffect(() => {
        resetFields('conference');
    }, [form.conference_id]);

    useEffect(() => {
        if (Object.keys(item).length > 0 && item.let_override !== undefined) {
            if (
                Object.keys(item.let_override).length >=
                Object.keys(item).length
            ) {
                return setToggle({
                    ...toggle,
                    showToggle: false
                });
            } else {
                let enableFields = {};
                Object.assign(enableFields, toggle.enableToggle);
                for (const key in item) {
                    if (
                        item.let_override &&
                        item.let_override[key] &&
                        item[key]
                    ) {
                        enableFields = {
                            ...enableFields,
                            [key]: item.let_override[key] !== item[key]
                        };
                    }
                }
                return setToggle({
                    ...toggle,
                    showToggle: true,
                    enableToggle: enableFields
                });
            }
        } else {
            id === 'new' &&
                setItem({
                    let_override: {
                        sport_id: undefined,
                        league_id: undefined,
                        conference_id: undefined,
                        tournament_id: undefined,
                        territories_available: utils.getDefaultCountry()
                    }
                });
        }
    }, [item?.images_ml, item?.let_override?.images_ml]);

    const customFormSubmit = async (e, extraFormVars = {}) => {
        // Add the extra form vars to the form
        const newForm = form;
        const keys = Object.keys(extraFormVars);
        // For each key, make a corresponding form variable and copy its value
        keys.forEach((key) => {
            newForm[key] = extraFormVars[key];
        });
        await setForm(newForm);

        // Now call the regular form submit handler
        await onFormSubmit(e);
    };

    const formValidate = async () => {
        const isMultiLanguageField = (label) => /_ml$/.test(label);
        const winnowedFields = Array.from(new Set(fields));

        let initError = {};

        // Standard Fields:
        const standardFields = winnowedFields
            .filter((key) => !isMultiLanguageField(key))
            .reduce(
                (acc, current) => ({
                    ...acc,
                    [current]:
                        (form[current] === null ||
                            form[current] === '' ||
                            form[current] === undefined) &&
                        true
                }),
                {}
            );
        // ML Fields:
        const multiLanguageFields = winnowedFields
            .filter((key) => isMultiLanguageField(key))
            .reduce(
                (acc, current) => ({
                    ...acc,
                    [current]: objectKeyMissingValue(form[current])
                }),
                {}
            );

        initError = {
            ...standardFields,
            ...multiLanguageFields
        };

        let errorArray = [];

        // Event Section
        if (service === 'events') {
            if (!isAppearance && !isLiveAdmin) {
                // Only check these when on the Info tab:
                const { scheduled_utc, scheduled_utc_end } = form;

                const validEndDate = date.compareDates(
                    scheduled_utc,
                    scheduled_utc_end,
                    'before'
                );

                if (!validEndDate) {
                    const startLength = scheduled_utc?.length || 0;
                    const endLength = scheduled_utc_end?.length || 0;
                    initError = {
                        ...initError,
                        scheduled_utc: startLength === 0,
                        scheduled_utc_end: endLength === 0 || !validEndDate
                    };
                    errorArray.push(`End Time must be after Start Time`);
                } else {
                    initError = {
                        ...initError,
                        scheduled_utc: false,
                        scheduled_utc_end: false
                    };
                }

                if (tenant.isMaxFamily) {
                    const {
                        offering_start_utc,
                        offering_end_utc,
                        tenant_metadata
                    } = form;
                    const hbo_max = tenant_metadata?.hbo_max;
                    const offeringStart =
                        hbo_max?.offering_start_utc || offering_start_utc;
                    const offeringEnd =
                        hbo_max?.offering_end_utc || offering_end_utc;
                    const validOfferingEndDate = date.compareDates(
                        offeringStart,
                        offeringEnd,
                        'before'
                    );

                    const offeringStartLength = offeringStart?.length || 0;
                    const offeringEndLength = offeringEnd?.length || 0;

                    initError.offering_start_utc = offeringStartLength > 0;
                    initError.offering_end_utc = offeringEndLength > 0;

                    if (!validOfferingEndDate) {
                        initError = {
                            ...initError,
                            offering_start_utc: offeringStartLength === 0,
                            offering_end_utc:
                                offeringEndLength === 0 || !validOfferingEndDate
                        };
                        errorArray.push(
                            `Offering End Time must be after Offering Start Time`
                        );
                    } else {
                        initError = {
                            ...initError,
                            offering_start_utc: false,
                            offering_end_utc: false
                        };
                    }
                }
            } else {
                initError = {
                    ...initError,
                    offering_start_utc: false,
                    offering_end_utc: false
                };
            }
        }

        setError(initError);

        // Just in case, need to confirm the requireFields of the schemas
        if (error !== undefined && id === 'new') {
            delete error['legacy_id'];
        }

        const errorCount = Object.keys(initError).reduce(
            (count, e) => (initError[e] === true ? ++count : count),
            0
        );

        if (errorCount) {
            let errorMsg =
                errorCount === 1
                    ? `Required fields: There is 1 missing required field.${
                          errorArray.length
                              ? ` (${friendlyConcat(errorArray)})`
                              : ''
                      }`
                    : `Required fields: There are ${errorCount} missing required fields.${
                          errorArray.length
                              ? ` (${friendlyConcat(errorArray)})`
                              : ''
                      }`;
            dispatch(notifyError(`${errorMsg}`));
            return false;
        }

        return true;
    };

    const handleChange = (event) => {
        try {
            const { target } = event;
            const { name, value } = target;

            value === ''
                ? requiredFields.includes(name) &&
                  setError({ ...error, [name]: true })
                : Object.keys(error).includes(name) &&
                  setError({ ...error, [name]: false });

            let isToggleEnable = value !== '' ? true : false;

            setToggle({
                ...toggle,
                enableToggle: {
                    ...toggle.enableToggle,
                    [name]: isToggleEnable
                }
            });

            let formValue;

            if (target.type === 'checkbox') {
                formValue = target.checked;
            } else if (target.type === 'number') {
                formValue = parseInt(value);
            } else {
                formValue = value;
            }

            // Test against ML fields.
            // Fixes issue w/Secondary Title not being able to get saved.

            if (/_ml$/.test(name)) {
                if (!item.let_override) {
                    item.let_override = {};
                }
                item.let_override[name] = formValue;
                setItem({ ...item });
                setForm({ ...form, [name]: formValue });
            } else {
                [name] in item &&
                    setItem({
                        ...item,
                        [name]:
                            formValue !== '' ? formValue : initialState[name]
                    });
                item.let_override &&
                    [name] in item.let_override &&
                    setItem({
                        ...item,
                        let_override: {
                            ...item.let_override,
                            [name]: formValue
                        }
                    });
                setForm({ ...form, [name]: formValue });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleColorChange = (name, value) => {
        let formValue = value.hex;
        if (formValue === '000000') {
            formValue = null;
        }

        [name] in item &&
            setItem({
                ...item,
                [name]: formValue !== '' ? formValue : initialState[name]
            });
        setItem({
            ...item,
            let_override: {
                ...item.let_override,
                [name]: formValue
            }
        });
        setForm({ ...form, [name]: formValue });
    };

    const handleCountriesChange = (obj, attr) => {
        let updated = false;
        if (attr in item) {
            item[attr] = obj;
            updated = true;
        }
        if ([attr] in item.let_override) {
            item.let_override[attr] = obj;
            updated = true;
        }
        // If we've gotten this far and it has not been updated,
        // add it to the LET Override.
        if (!updated) {
            item.let_override[attr] = obj;
        }

        setForm({
            ...form,
            [attr]: obj.map(({ id }) => id)
        });
    };

    const handleDateChange = (attr, date) => {
        [attr] in item &&
            setItem({
                ...item,
                [attr]: date !== '' ? date : initialState[attr]
            });

        item?.let_override &&
            [attr] in item.let_override &&
            setItem({
                ...item,
                let_override: {
                    ...item.let_override,
                    [attr]: date
                }
            });
        setForm({ ...form, [attr]: date });
    };

    const handleDeleteValues = (names) => {
        if (!names) {
            throw new Error(
                'handleImageDelete called without arguments. This is a NOOP.'
            );
        }
        if (names && !Array.isArray(names)) {
            names = [names];
        }
        names.forEach((prop) => {
            form[prop] = null;
        });
    };

    const handleFieldChange = (event, value, name) => {
        const target = event.target;

        value === undefined
            ? requiredFields.includes(name) &&
              setError({ ...error, [name]: true })
            : Object.keys(error).includes(name) &&
              setError({ ...error, [name]: false });

        let isToggleEnable = value !== undefined ? true : false;

        setToggle({
            ...toggle,
            enableToggle: {
                ...toggle.enableToggle,
                [name]: isToggleEnable
            }
        });

        let formValue = value === undefined ? null : value;

        switch (target.type) {
            case 'checkbox':
                formValue = target.checked;
                break;
            case 'number':
                formValue = parseInt(value);
                break;
            default:
                break;
        }

        if (id !== 'new') {
            [name] in item &&
                setItem({
                    ...item,
                    [name]: formValue !== null ? formValue : initialState[name]
                });

            const { let_override } = item;
            if (let_override !== undefined) {
                let_override[name] = formValue;
                setItem({
                    ...item,
                    let_override
                });
            }
        }

        setForm({ ...form, [name]: formValue });
    };

    const handleHBOMediaURLChange = (event) => {
        const { name, value } = event.target;
        const updatedMediaURLs = [...item.let_override.urls_asset_id_live];
        let found = false;
        updatedMediaURLs.map((item, index) => {
            if (item.name === name) {
                item.value = value;
                found = true;
            }
        });
        if (found) {
            setItem({
                ...item,
                let_override: {
                    ...item.let_override,
                    urls_asset_id_live: [...updatedMediaURLs]
                }
            });
        }
    };

    const handleHBOMediaURLReset = (name, value) => {
        const mediaURLs = [...item.let_override.urls_asset_id_live];
        const foundIndex = mediaURLs.findIndex(
            (element) => element.name === name
        );

        if (foundIndex !== -1) {
            mediaURLs[foundIndex].value = value;
            const newItem = {
                ...item,
                let_override: {
                    ...item.let_override,
                    urls_asset_id_live: [...mediaURLs]
                }
            };

            setItem(newItem);
        }
    };

    const handleHBOVodMediaUrlChange = (value) => {
        setItem({
            ...item,
            let_override: {
                ...item.let_override,
                urls_asset_id_vod: [...value]
            }
        });
    };

    const handleMetaDataChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        value === ''
            ? requiredFields.includes(name) &&
              setError({ ...error, [name]: true })
            : Object.keys(error).includes(name) &&
              setError({ ...error, [name]: false });

        let isToggleEnable = value !== '' ? true : false;

        setToggle({
            ...toggle,
            enableToggle: {
                ...toggle.enableToggle,
                [name]: isToggleEnable
            }
        });

        let formValue =
            target.type === 'checkbox'
                ? target.checked
                : target.type === 'number'
                ? parseInt(value)
                : value;

        [name] in item &&
            setItem({
                ...item,
                [name]: formValue !== '' ? formValue : initialState[name]
            });
        [name] in item.let_override &&
            setItem({
                ...item,
                let_override: {
                    ...item.let_override,
                    tenant_metadata: {
                        hbo_max: {
                            [name]: formValue
                        }
                    }
                }
            });

        setForm({
            ...form,
            tenant_metadata: {
                ...form.tenant_metadata,
                hbo_max: {
                    ...form.tenant_metadata.hbo_max,
                    [name]: formValue
                }
            }
        });
    };

    const handleMetaDateChange = (attr, date) => {
        date === null
            ? requiredFields.includes(attr) &&
              setError({ ...error, [attr]: true })
            : Object.keys(error).includes(attr) &&
              setError({ ...error, [attr]: false });

        [attr] in tenant_meta &&
            setItem({
                ...item,
                tenant_metadata: {
                    hbo_max: {
                        [attr]: date !== '' ? date : initialState[attr]
                    }
                }
            });
        [attr] in override_tenant_meta &&
            setItem({
                ...item,
                let_override: {
                    ...item.let_override,
                    tenant_metadata: {
                        hbo_max: {
                            [attr]: date
                        }
                    }
                }
            });

        setForm({
            ...form,
            tenant_metadata: {
                ...form.tenant_metadata,
                hbo_max: {
                    ...form.tenant_metadata?.hbo_max,
                    [attr]: date
                }
            }
        });
    };

    const handleMultipleSelectChange = (e, obj, attr) => {
        if (attr === 'territories_available') {
            handleTerritoriesChange(obj, attr);
            return;
        }
        if (attr === 'countries_available') {
            handleCountriesChange(obj, attr);
            return;
        }
        [attr] in item &&
            setItem({
                ...item,
                [attr]: obj
            });
        [attr] in item.let_override &&
            setItem({
                ...item,
                let_override: {
                    ...item.let_override,
                    [attr]: obj
                }
            });

        setToggle({
            ...toggle,
            enableToggle: {
                ...toggle.enableToggle,
                [attr]: true
            }
        });
        setForm({
            ...form,
            [attr]: obj.map(({ value }) => value)
        });
    };

    const handleNumberChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        value === ''
            ? requiredFields.includes(name) &&
              setError({ ...error, [name]: true })
            : Object.keys(error).includes(name) &&
              setError({ ...error, [name]: false });

        let formValue =
            target.type === 'number' && name !== 'games_back'
                ? parseInt(value)
                : value;

        [name] in item &&
            setItem({
                ...item,
                [name]: formValue !== '' ? formValue : initialState[name]
            });

        if (name === 'quick_vod_runtime' || name === 'live_runtime') {
            setItem({
                ...item,
                let_override: {
                    ...item.let_override,
                    tenant_metadata: {
                        hbo_max: {
                            [name]: formValue
                        }
                    }
                }
            });

            setForm({
                ...form,
                tenant_metadata: {
                    ...form.tenant_metadata,
                    hbo_max: {
                        ...form.tenant_metadata.hbo_max,
                        [name]: formValue
                    }
                }
            });
        } else {
            [name] in item.let_override &&
                setItem({
                    ...item,
                    let_override: {
                        ...item.let_override,
                        [name]: formValue
                    }
                });
            setForm({ ...form, [name]: formValue });
        }
    };

    const handleTerritoriesChange = (obj, attr) => {
        if (attr in item) {
            item[attr] = obj;
        }
        if ([attr] in item.let_override) {
            item.let_override[attr] = obj;
        }

        setToggle({
            ...toggle,
            enableToggle: {
                ...toggle.enableToggle,
                [attr]: true
            }
        });
        setForm({
            ...form,
            [attr]: obj.map(({ value }) => value)
        });
    };

    const handleToggleChange = (e, attr) => {
        let ele = document.getElementById(attr);
        ele !== null && ele.type === 'text' ? (ele.value = '') : '';

        setToggle({
            ...toggle,
            enableToggle: {
                ...toggle.enableToggle,
                [attr]: false
            }
        });
        setItem({
            ...item,
            let_override: {
                ...item.let_override,
                [attr]: ''
            },
            [attr]: initialState[attr]
        });

        if (attr === 'sport_id') {
            setForm({
                ...form,
                [attr]: initialState[attr],
                ['league_id']: initialState['league_id']
            });
        } else {
            setForm({ ...form, [attr]: initialState[attr] });
        }
    };

    const handleUploadChange = (attr, value) => {
        setForm({ ...form, [attr]: value });
    };

    // Multi-Language fields:
    const objectKeyMissingValue = (obj) => {
        let isMissingValue = false;
        if (!obj || Object.keys(obj).length === 0) {
            isMissingValue = true;
        } else {
            Object.keys(obj).forEach((key) => {
                const value = obj[key];
                if (!value || value === '' || emptyString(value)) {
                    isMissingValue = true;
                }
            });
        }
        return isMissingValue;
    };

    // Image Pre-Processing (relocated from the Event Service class)
    const getPreProcessedImages = (tenantImages, form, tenant) => {
        const state = store?.getState();
        const config = state?.config;

        const gspCdnBasePath = config?.gspCdnBasePath || '';
        const imageBaseUrl = config?.imageBaseUrl || '';

        const images = [];
        const invalidImages = [];

        tenantImages.forEach((img, index) => {
            if (form[img] && form[img] !== '') {
                /*
                console.log(
                    `${index + 1}.) Verify metadata for ${img} = ${form[img]}`
                );
                */
                const metadataStr = form[`${img}_metadata`];
                try {
                    const metadata = JSON.parse(metadataStr);
                    const { width, height, etag } = metadata;
                    if (!width || !height || !etag) {
                        invalidImages.push(img);
                    } else {
                        const imageQuery = (coreImage) =>
                            coreImage.name === img;
                        const coreImageFields =
                            maxImages.find(imageQuery) ||
                            hboImages.find(imageQuery);
                        const coreImageDimensions = coreImageFields.size;
                        let imgWidth, imgHeight;
                        if (coreImageDimensions) {
                            [imgWidth, imgHeight] = coreImageDimensions
                                .split('x')
                                .map(
                                    (dimension) =>
                                        +dimension
                                            .trim()
                                            .replace(/[^0-9]/gi, '')
                                );
                        } else {
                            // We can't pull from the dictaded dimensions; use the uploaded ones.
                            imgWidth = width;
                            imgHeight = height;
                        }

                        // Standard non-ML images do not need the let_url parameter,
                        // so we can leave it behind in the letImage property.
                        const letImage = form[img];
                        const pathPrefix = letImage.substr(
                            0,
                            letImage.lastIndexOf('/') + 1
                        );
                        const image = letImage.slice(pathPrefix.length);

                        const baseImage = {
                            image,
                            path: `${imageBaseUrl}${pathPrefix}`,
                            key: coreImageFields.name,
                            width: imgWidth,
                            height: imgHeight,
                            tag: VALID_IMAGES[img],
                            etag,
                            overrideUrl: form[`${img}_override`] || ''
                        };

                        if (tenant.isMax || tenant.isDomestic) {
                            images.push({
                                ...baseImage,
                                aspect_ratio: coreImageFields.aspectRatio,
                                cdnBasePath: `${gspCdnBasePath}${pathPrefix}`,
                                image_id: coreImageFields.imageId
                            });
                        } else {
                            images.push(baseImage);
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        });

        return {
            images,
            invalidImages
        };
    };

    const getMultiLanguageImages = (images_ml) => {
        Object.keys(images_ml).forEach((isoCode) => {
            const definedImages = [];
            images_ml[isoCode]
                .filter((img) => img?.image && img?.let_url)
                .forEach((img) => {
                    const state = store?.getState();
                    const config = state?.config;

                    const gspCdnBasePath = config?.gspCdnBasePath || '';
                    const imageBaseUrl = config?.imageBaseUrl || '';

                    const urlSuffix = img.let_url.substring(
                        0,
                        img.let_url.lastIndexOf('/') + 1
                    );

                    definedImages.push({
                        ...img,
                        cdnBasePath: `${gspCdnBasePath}${urlSuffix}`,
                        path: `${imageBaseUrl}${urlSuffix}`
                    });
                });
            images_ml[isoCode] = [...definedImages];
        });
        return { ...images_ml };
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();

        const validate = await formValidate();

        if (!validate) return;

        /*
        The following two values are removed because they're *always* empty strings and 
        their actual values are carried in the tenant_metadata field.
        */
        tenant.isMaxFamily && delete form?.offering_start_utc;
        tenant.isMaxFamily && delete form?.offering_end_utc;

        let arr = [];
        arr.push(form);

        if (tenant.isMaxFamily && isAppearance) {
            const tenantImages = [
                ...getMaxImages(tenant),
                ...getHboImages(tenant)
            ];

            if (isMultilanguage) {
                // No need for the form-level images array:
                form.hasOwnProperty('images') && delete form.images;
                form.images_ml = getMultiLanguageImages(form.images_ml);
            } else {
                // No need for the form-level images array:
                form.hasOwnProperty('images_ml') && delete form.images_ml;

                const tenantImages = [
                    ...getMaxImages(tenant),
                    ...getHboImages(tenant)
                ];

                const { images, invalidImages } = getPreProcessedImages(
                    tenantImages,
                    form,
                    tenant
                );

                if (invalidImages.length) {
                    const detailError =
                        'The following image fields have issues with their meta data (image height, width, or md5 hash), ' +
                        'Please wait for few seconds and click save again. If issue persist please re-upload the following images: ' +
                        invalidImages.join(', ');
                    dispatch(notifyError(`${detailError}`));
                    console.error(
                        `Invalid images: ${invalidImages.join(', ')}`
                    );
                    return;
                } else {
                    form.images = images;
                }
            }
        }

        // Clean up territories_available
        if (form?.territories_available?.length) {
            form.territories_available = form.territories_available
                .sort((a, b) => (a.label > b.label ? 1 : -1))
                .map((territory) =>
                    territory.id ? territory.value : territory
                );
        }

        // Clean up any countries assigned to the event:
        if (form?.countries_available) {
            let countries = [];
            form.countries_available.map((countryCode) => {
                const { isoCode, country } = COUNTRIES_WORLDWIDE.filter(
                    (cw) => cw.isoCode === countryCode
                ).pop();
                countries.push({
                    id: isoCode,
                    label: country
                });
            });
            form.countries_available = agnosticSort(countries, 'label');
        }

        setIsInProgress(true);
        setRefresh(false);

        const filteredForm = updateNonMultilanguageEnglishOverrides(form);

        if (!isMultilanguage) {
            const valuesToRemove = ["description_body_ml", "images_ml", "name_ml", "secondary_title_ml"];
            valuesToRemove.forEach((key) => {
                if(item?.let_override[key]){
                    filteredForm[key] = null;
                }
            });
        }

        id === 'new'
            ? services.createItem(service, arr).then(
                  (response) => {
                      setRefresh(true);
                      setIsInProgress(false);
                      setValueChanged(false);
                      setError({});
                      dispatch(
                          notifySuccess(
                              `Success:  ${service} created successfully!`
                          )
                      );
                      const path = location.pathname;
                      const newId = response?.data?.inserted[0]?.id;
                      newId && history.push(path.replace('new', newId));
                  },
                  (err) => {
                      setIsInProgress(false);
                      setValueChanged(false);
                      dispatch(notifyError(`${err.name}:  ${err.message}`));
                  }
              )
            : services.updateItem(`${service}/${id}`, filteredForm).then(
                  (response) => {
                      setRefresh(true);
                      setIsInProgress(false);
                      setValueChanged(false);
                      setError({});
                      dispatch(
                          notifySuccess(
                              `Success:  ${service} updated successfully!`
                          )
                      );
                  },
                  (err) => {
                      setIsInProgress(false);
                      setValueChanged(false);
                      dispatch(notifyError(`${err.name}:  ${err.message}`));
                  }
              );
    };

    return {
        customFormSubmit,
        enableToggle: toggle.enableToggle,
        error,
        form,
        isInProgress,
        handleChange,
        handleColorChange,
        handleDateChange,
        handleDeleteValues,
        handleFieldChange,
        handleHBOMediaURLChange,
        handleHBOMediaURLReset,
        handleHBOVodMediaUrlChange,
        handleMetaDataChange,
        handleMetaDateChange,
        handleMultipleSelectChange,
        handleNumberChange,
        handleToggleChange,
        handleUploadChange,
        item,
        onFormSubmit,
        showToggle: toggle.showToggle,
        valueChanged
    };
};
