import _ from 'lodash';
import { countries } from '../data';
import { utils } from './utils';

export const getTerritories = (res) => {
    let { territories_available, let_override } = res;
    let override_territories_available =
        let_override?.territories_available || null;

    // Test if the two are equal, and, if so, delete the LET Override:
    if (_.isEqual(territories_available, override_territories_available)) {
        override_territories_available = null;
        delete let_override.territories_available;
    }

    // Convert both to the proper format:
    territories_available = utils.getTerritories(
        territories_available || [],
        countries
    );

    if (Array.isArray(override_territories_available)) {
        override_territories_available = utils.getTerritories(
            override_territories_available || [],
            countries
        );
        // If there's a legit territories_available override
        // pass it through, if not, delete the empty array
        // to prevent it from giving a false positive on it
        // being overridden.
        let_override = {
            ...res.let_override,
            territories_available: override_territories_available
        };
    }
    return {
        territories_available,
        let_override
    };
};
