import React, { useState } from 'react';
import awsStorage from '../../services/awsStorage';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';
import { FormControl, Grid, InputLabel } from '@mui/material';
import { isAdmin, isOperator } from '../../reducer/userReducer';
import { Loader } from './Loader';
import { makeStyles, withStyles } from '@mui/styles';
import {
    PanoramaOutlined,
    PlayCircleOutlineOutlined
} from '@mui/icons-material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { encodeFilename, getFileFilter, utils } from '../../utils/utils';
// Notifications:
import { notifyError } from '../../reducer/notificationReducer';
import { Confirm } from './Confirm';
import { selectTenant } from '../../reducer/tenantSlice';
import { strings } from '../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        '& .MuiButton-containedPrimary': {
            color: '#fff',
            backgroundColor: '#000'
        }
    },
    buttonWarning: {
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'darkred'
        }
    },
    input: {
        display: 'none'
    },
    upload: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: '10px',
        marginBottom: 10
    },
    imgPreview: {
        display: 'flex',
        minHeight: '9.5rem',
        padding: '.75rem',
        position: 'relative',
        background: '#d8d8d8',
        color: '#c3c3c3',
        marginBottom: '2rem',
        height: '9.5rem'
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        width: '100%'
    },
    icon: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)'
    },
    margin: {
        width: '100%'
    },
    labelStyle: {
        fontWeight: 600,
        fontSize: 'large',
        color: 'black',
        transform: 'translate(0, -1.5px) scale(0.75)'
    }
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            boxShadow: `${alpha(
                theme.palette.primary.main,
                0.25
            )} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main
        }
    }
}))(InputBase);

export const UploadPreview = (props) => {
    const isAdminUser = useSelector(isAdmin);
    const isOperatorUser = useSelector(isOperator);
    const tenant = useSelector(selectTenant);
    const dispatch = useDispatch();

    const {
        name,
        label,
        bucket,
        enableDelete,
        type,
        fileName,
        handleChange,
        item,
        config,
        hideBrowseButton,
        required,
        allowedFormat
    } = props;

    const classes = useStyles();

    const [state, setState] = useState({
        [name]: { file: '', imagePreviewUrl: '', fileName: fileName }
    });

    let [isInProgress, setIsInProgress] = useState(false);

    const [confirmContent, setConfirmContent] = useState(null);
    const [confirmTitle, setConfirmTitle] = useState('Confirmation');
    const [showConfirm, setShowConfirm] = useState(false);
    const [tenantName, setTenantName] = useState('');

    const acceptFilter = getFileFilter(type, allowedFormat);

    useEffect(() => {
        setTenantName(tenant.tenantName);
    }, [tenant]);

    useEffect(() => {
        fileName &&
            setState({
                [name]: {
                    ...state[name],
                    fileName: fileName
                }
            });
    }, [fileName]);

    useEffect(() => handleChange(name, state[name].fileName), [state]);

    const handleImageVideoChange = (event) => {
        event.preventDefault();
        setIsInProgress(true);
        let reader = new FileReader();
        let file = event.target.files[0];

        reader.onloadend = () => {
            const filePath = utils.getFilePath(
                tenantName,
                bucket,
                encodeFilename(file.name)
            );
            awsStorage
                .upload({
                    folder: '',
                    name: filePath,
                    file: file
                })
                .then((res) => {
                    setState({
                        [name]: {
                            ...state[name],
                            file: file,
                            imagePreviewUrl: reader.result,
                            fileName: type === 'video' ? '/' + res.key : res.key
                        }
                    });
                })
                .catch((err) => {
                    dispatch(notifyError(`${err.name}:  ${err.message}`));
                })
                .finally(() => {
                    setIsInProgress(false);
                });
        };
        reader.readAsDataURL(file);
    };

    const fullFilePath = utils.getFullFilePath(
        config.imageBaseUrl,
        tenantName,
        bucket,
        state[name].fileName
    );

    const handleConfirmNo = () => {
        setShowConfirm(false);
    };

    const handleConfirmYes = () => {
        setState({
            [name]: {
                ...state[name],
                fileName: ''
            }
        });
        setShowConfirm(false);
    };

    const handleShowConfirm = () => {
        setConfirmTitle(`Confirm Delete`);
        const file = fileName.split('/').pop();
        setConfirmContent(
            `Are you sure you want to delete the image "${file}"? This cannot be un-done.`
        );
        setShowConfirm(true);
    };

    const renderDelete = () => {
        const file = state && state[name]?.fileName;
        return (
            (isAdminUser || isOperatorUser) &&
            enableDelete &&
            file &&
            file !== ''
        );
    };

    const localization = useSelector(strings);

    return (
        <div className={classes.root}>
            <Loader isInProgress={isInProgress} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className={classes.upload}>
                        <FormControl className={classes.margin}>
                            <InputLabel
                                className={classes.labelStyle}
                                shrink
                                htmlFor={name}
                            >
                                {label}{' '}
                                {required && (
                                    <span className='text-danger'>*</span>
                                )}
                            </InputLabel>
                            <BootstrapInput
                                name={name}
                                label={label}
                                type='text'
                                id={name}
                                data-testid={`${item}-input`}
                                value={
                                    (fullFilePath !== '' && fullFilePath) || ''
                                }
                            />
                        </FormControl>
                        <input
                            name={name}
                            accept={acceptFilter}
                            className={classes.input}
                            id={item}
                            type='file'
                            onChange={handleImageVideoChange}
                        />
                        {(isAdminUser || isOperatorUser) &&
                            !hideBrowseButton && (
                                <label
                                    htmlFor={item}
                                    className={classes.buttonLabel}
                                >
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        component='span'
                                    >
                                        {localization.browse}...
                                    </Button>
                                </label>
                            )}
                        {renderDelete() && (
                            <label className={classes.buttonLabel}>
                                <Button
                                    className={classes.buttonWarning}
                                    variant='contained'
                                    color='warning'
                                    component='span'
                                    onClick={handleShowConfirm}
                                >
                                    Delete
                                </Button>
                            </label>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.imgPreview}>
                        {type === 'image' && !fullFilePath && (
                            <div className={classes.icon}>
                                <PanoramaOutlined style={{ fontSize: 80 }} />
                            </div>
                        )}
                        {type === 'image' && fullFilePath && (
                            <img
                                className={classes.img}
                                src={fullFilePath}
                                alt=''
                            />
                        )}
                        {type === 'video' && (
                            <div className={classes.icon}>
                                <PlayCircleOutlineOutlined
                                    style={{ fontSize: 80 }}
                                />
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>

            <Confirm
                open={showConfirm}
                onClose={handleConfirmNo}
                onYes={handleConfirmYes}
                title={confirmTitle}
                content={confirmContent}
            />
        </div>
    );
};
