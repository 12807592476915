import React, { useEffect, useState, useCallback } from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Input } from '../../shared/Input';
import { Buttons } from '../../shared/Buttons';
import { Loader } from '../../shared/Loader';
import { AutocompleteBox } from '../../shared/AutocompleteBox';
import { useForm } from '../../hooks/UseForm';
import services from '../../../services/apiService';
import { countries } from '../../../data';
import { MultiSelectBox } from '../../shared/MultiSelectBox';
import { CheckBox } from '../../shared/Checkbox';
import { ArrowForwardOutlined, RemoveOutlined } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { utils } from '../../../utils/utils';
import { getRoute } from '../../shared/AdvancedSearch';
import { selectTenant } from '../../../reducer/tenantSlice';
import { useSelector } from 'react-redux';
// Notifications:
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        }
    },
    buttonright: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

export const TournamentInfo = ({
    id,
    tournament,
    setRefresh,
    tournamentSchema,
    handleUpdate,
    handleForward
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const localization = useSelector(strings);

    const [sports, setSports] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [loading, setLoading] = useState(false);

    const nextPage = `/tournaments/${id}/appearance`;
    const requiredFields = tournamentSchema.required;

    const {
        item,
        showToggle,
        enableToggle,
        isInProgress,
        error,
        valueChanged,
        handleChange,
        handleFieldChange,
        handleMultipleSelectChange,
        handleToggleChange,
        onFormSubmit,
        form
    } = useForm({
        service: 'tournaments',
        initialState: tournament,
        id,
        requiredFields,
        setRefresh
    });

    const tenant = useSelector(selectTenant);
    const isBR = tenant.isBR;

    useEffect(() => {
        handleUpdate(valueChanged);
    }, [valueChanged]);

    const {
        name,
        sport_id,
        territories_available,
        has_media,
        sportradar_id,
        display_name,
        display_name_short,
        points,
        let_override: {
            name: override_name,
            sport_id: override_sport_id,
            territories_available:
                override_territories_available = utils.getDefaultCountry(),
            has_media: override_has_media,
            sportradar_id: override_sportradar_id,
            display_name: override_display_name,
            display_name_short: override_display_name_short,
            points: override_points
        } = {}
    } = item;

    useEffect(() => {
        setLoading(true);
        services
            .fetchAllData('sports')
            .then((data) => {
                setSports(data);
            })
            .catch((err) => {
                setLeagues([]);
                dispatch(notifyError(`${err.name}:  ${err.message}`));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const fetchLeagues = useCallback(() => {
        let sportId =
            override_sport_id || sport_id || form.sport_id || undefined;

        if (sportId !== undefined) {
            setLoading(true);
            let route = getRoute('leagues', { sport_id: sportId });
            const data = services
                .fetchAllData(route)
                .then((data) => {
                    setLeagues(data);
                })
                .catch((err) => {
                    setLeagues([]);
                    dispatch(notifyError(`${err.name}:  ${err.message}`));
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLeagues([]);
        }
    }, [override_sport_id || sport_id || form.sport_id]);

    useEffect(() => {
        fetchLeagues();
    }, [fetchLeagues]);

    return (
        <div className={classes.root}>
            <Container maxWidth='lg'>
                <Loader isInProgress={isInProgress || loading} />
                <form noValidate autoComplete='off' onSubmit={onFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Input
                                label={`${localization.tournament_name}:`}
                                name='name'
                                value={override_name || name || form.name || ''}
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={tournament && tournament.name}
                                handleToggleChange={handleToggleChange}
                                error={error.name}
                                showCopy={true}
                                required={requiredFields.includes('name')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.display_name}:`}
                                name='display_name'
                                value={
                                    override_display_name ||
                                    display_name ||
                                    form.display_name ||
                                    ''
                                }
                                handleChange={handleChange}
                                originalValue={
                                    tournament && tournament.display_name
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.display_name}
                                required={requiredFields.includes(
                                    'display_name'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.display_name_short}:`}
                                name='display_name_short'
                                value={
                                    override_display_name_short ||
                                    display_name_short ||
                                    form.display_name_short ||
                                    ''
                                }
                                handleChange={handleChange}
                                originalValue={
                                    tournament && tournament.display_name_short
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.display_name_short}
                                required={requiredFields.includes(
                                    'display_name_short'
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <AutocompleteBox
                                label={`${localization.sport}:`}
                                options={sports}
                                name='sport_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={form.sport_id || null}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    (tournament && tournament.sport_id) || null
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.sport_id}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes('sport_id')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <AutocompleteBox
                                label={`${localization.league}:`}
                                options={leagues}
                                name='league_id'
                                nameField='name'
                                handleChange={handleFieldChange}
                                defaultValue={form.league_id || null}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={
                                    (tournament && tournament.league_id) || null
                                }
                                handleToggleChange={handleToggleChange}
                                error={error.league_id}
                                localizedPlaceholder={
                                    localization.advanced_search_select
                                }
                                required={requiredFields.includes('league_id')}
                            />
                        </Grid>
                        {isBR && (
                            <Grid item md={6} xs={12}>
                                <MultiSelectBox
                                    label={`${localization.tournament_available_in}:`}
                                    options={countries}
                                    name='territories_available'
                                    handleMultipleSelectChange={
                                        handleMultipleSelectChange
                                    }
                                    defaultValue={
                                        override_territories_available ||
                                        territories_available ||
                                        form.territories_available ||
                                        []
                                    }
                                    originalValue={
                                        (tournament &&
                                            tournament.territories_available) ||
                                        []
                                    }
                                    showToggle={showToggle}
                                    enableToggle={enableToggle}
                                    handleToggleChange={handleToggleChange}
                                    localizedPlaceholder={localization.choose}
                                    required={requiredFields.includes(
                                        'territories_available'
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.points}:`}
                                name='points'
                                type='number'
                                value={
                                    override_points ||
                                    points ||
                                    form.points ||
                                    ''
                                }
                                handleChange={handleChange}
                                originalValue={tournament && tournament.points}
                                handleToggleChange={handleToggleChange}
                                required={requiredFields.includes('points')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.sportradar_id}:`}
                                name='sportradar_id'
                                value={
                                    override_sportradar_id ||
                                    sportradar_id ||
                                    form.sportradar_id ||
                                    ''
                                }
                                originalValue={sportradar_id || ''}
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                required={requiredFields.includes(
                                    'sportradar_id'
                                )}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <CheckBox
                                    label={localization.has_media}
                                    checked={
                                        override_has_media ||
                                        has_media ||
                                        form.has_media ||
                                        false
                                    }
                                    name='has_media'
                                    handleChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            justifyContent='flex-end'
                            spacing={3}
                            xs={12}
                        >
                            <div className={classes.buttonright}>
                                <Buttons
                                    type='submit'
                                    label={localization.save}
                                    showForWriteAccessUsers
                                />
                                {id !== 'new' && (
                                    <IconButton
                                        aria-label='next-page'
                                        style={{ color: 'black' }}
                                        onClick={(e) =>
                                            handleForward(e, nextPage)
                                        }
                                    >
                                        <RemoveOutlined
                                            fontSize='large'
                                            style={{ marginRight: '-16px' }}
                                        />
                                        <ArrowForwardOutlined fontSize='large' />
                                    </IconButton>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};
