import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Confirm } from './Confirm';
import { SelectBox } from './SelectBox';
import { notifyError } from '../../reducer/notificationReducer';
import productsService from '../../services/productsService';
import store from '../../redux/store';
// Encoder Button customization:
import {
    ENCODER_BUTTON_ACTIONS,
    ENCODER_BUTTON_TEXT,
    ENCODER_STATES,
    encoderStop,
    encoderStart,
    enableConfigSave,
    resetOnError,
    saveComplete,
    savingCdnConfig,
    setAthenaId,
    setEncoderConfig,
    updateAsset,
    updateEncoderStatus
} from '../../reducer/encoderSlice';
import { strings } from '../../reducer/localizationSlice';

export const CdnConfigGrid = (props) => {
    const localization = useSelector(strings);

    const [confirmAction, setConfirmAction] = useState('');
    const [content, setContent] = useState('');
    const [openConfirm, setOpenConfirm] = useState(false);
    const dispatch = useDispatch();
    const [cdnConfig, setCdnConfig] = useState(props.cdnDefaultValue);
    const [source, setSource] = useState('');
    const [createArchive, setCreateArchive] = useState('');
    const [dvrWindow, setDvrWindow] = useState('');

    const currentEvent = props.currentEvent;
    const disabled = props.disabled;
    const profileId = props.profileId;
    const tenant = props.tenant;
    const isBR = tenant.isBR || tenant.tenantName === 'br';

    // Pulldowns
    const cdnOptions = props.cdnOptions;
    const cdnDefaultValue = props.cdnDefaultValue;
    const sourceOptions = props.sourceOptions;
    const sourceDefaultValue = props.sourceDefaultValue;
    const createArchOptions = props.createArchOptions;
    const createArchDefaultValue = props.createArchDefaultValue;
    const dvrWindowOptions = props.dvrWindowOptions;
    const dvrWindowDefaultValue = props.dvrWindowDefaultValue;

    // Encoder Button
    const encoderButtonDisabled = props.encoderButtonDisabled;
    const encoderButtonText = props.encoderButtonText;

    // Used to poll the product service when starting/stopping the encoder:
    let encoderPollingInterval = 0;

    // Source Checks
    const noSourceValue = sourceDefaultValue.length === 0;
    const disableEncoderButton =
        noSourceValue || encoderButtonDisabled === true;

    const EncoderButton = (
        <Button
            disabled={disableEncoderButton}
            onClick={() => handleEncoderAction()}
            variant='contained'
            component='span'
            color={
                ENCODER_BUTTON_TEXT.START == encoderButtonText
                    ? 'danger'
                    : ENCODER_BUTTON_TEXT.STOP === encoderButtonText
                    ? 'primary'
                    : 'info'
            }
        >
            {localization[encoderButtonText]}
        </Button>
    );

    const handleEncoderAction = () => {
        const encoderStatus = store.getState().encoderStatus;
        const {
            athenaId,
            buttonAction,
            cdnConfig,
            createArchive,
            dvrWindow,
            source
        } = encoderStatus;
        switch (buttonAction) {
            case ENCODER_BUTTON_ACTIONS.SAVE:
                const payload = {
                    cdn_config: cdnConfig,
                    create_archive: createArchive,
                    eventId: currentEvent.id,
                    profileId: profileId,
                    source: source
                };
                if (dvrWindow) {
                    // Only add this if it's defined.
                    // (Not all profiles have it.)
                    payload.dvr_window = dvrWindow;
                }

                dispatch(savingCdnConfig());

                productsService
                    .updateAsset(athenaId, payload)
                    .then(() => {
                        productsService
                            .getAsset(athenaId)
                            .then((innerResponse) => {
                                const inner_cdn_config =
                                    innerResponse?.cdn_config;
                                const inner_create_archive =
                                    innerResponse?.create_archive;
                                const inner_dvr_window =
                                    innerResponse?.dvr_window;
                                const inner_source = innerResponse?.source;
                                const inner_urls_asset_id_live =
                                    innerResponse?.urls_asset_id_live;
                                const encoderConfigPayload = {
                                    cdnConfig: inner_cdn_config,
                                    createArchive: inner_create_archive,
                                    dvrWindow: inner_dvr_window,
                                    source: inner_source,
                                    urls_asset_id_live: inner_urls_asset_id_live
                                };
                                dispatch(
                                    setEncoderConfig(encoderConfigPayload)
                                );
                            })
                            .catch((error) => {
                                dispatch(
                                    notifyError(error.response.data?.error)
                                );
                            });
                    })
                    .finally(() => {
                        dispatch(saveComplete());
                    });
                break;
            case ENCODER_BUTTON_ACTIONS.START:
                setConfirmAction(ENCODER_BUTTON_ACTIONS.START);
                setContent('Are you sure you want to Start Encoder?');
                setOpenConfirm(true);
                break;
            case ENCODER_BUTTON_ACTIONS.STOP:
                setConfirmAction(ENCODER_BUTTON_ACTIONS.STOP);
                setContent('Are you sure you want to Stop Encoder?');
                setOpenConfirm(true);
                break;
            default:
                console.warn(
                    `Unhandled encoder button state: ${encoderStatus.buttonAction}`
                );
        }
    };

    const handleCdnChange = (event) => {
        const encoderStatus = store.getState().encoderStatus;
        const { source, createArchive, dvrWindow } = encoderStatus;
        const target = event ? event.target : null;
        const newCdnConfig = target ? target.value : '';
        setCdnConfig(newCdnConfig);
        if (hasConfigChanged(newCdnConfig, source, createArchive, dvrWindow)) {
            dispatch(
                enableConfigSave({
                    cdnConfig: newCdnConfig,
                    source,
                    createArchive,
                    dvrWindow
                })
            );
        }
    };

    const handleSourceChange = (event) => {
        const encoderStatus = store.getState().encoderStatus;
        const { cdnConfig, createArchive, dvrWindow } = encoderStatus;
        const target = event ? event.target : null;
        const newSource = target ? target.value : '';
        setSource(newSource);
        if (hasConfigChanged(cdnConfig, newSource, createArchive, dvrWindow)) {
            dispatch(
                enableConfigSave({
                    cdnConfig,
                    source: newSource,
                    createArchive,
                    dvrWindow
                })
            );
        }
    };

    const handleCreateArchiveChange = (event) => {
        const encoderStatus = store.getState().encoderStatus;
        const { cdnConfig, source, dvrWindow } = encoderStatus;
        const target = event ? event.target : null;
        const newCreateArchive = target ? target.value : '';
        setCreateArchive(createArchive);
        if (hasConfigChanged(cdnConfig, source, newCreateArchive, dvrWindow)) {
            dispatch(
                enableConfigSave({
                    cdnConfig,
                    source,
                    createArchive: newCreateArchive,
                    dvrWindow
                })
            );
        }
    };

    const handleDvrWindowChange = (event) => {
        const encoderStatus = store.getState().encoderStatus;
        const { cdnConfig, source, createArchive } = encoderStatus;
        const target = event ? event.target : null;
        const newDvrWindow = target ? target.value : '';
        setDvrWindow(newDvrWindow);
        if (hasConfigChanged(cdnConfig, source, createArchive, newDvrWindow)) {
            dispatch(
                enableConfigSave({
                    cdnConfig,
                    source,
                    createArchive,
                    dvrWindow: newDvrWindow
                })
            );
        }
    };

    const hasConfigChanged = (cdnConfig, source, createArchive, dvrWindow) => {
        const encoder = store.getState().encoderStatus;
        return (
            encoder?.cdnConfig !== cdnConfig ||
            encoder?.source !== source ||
            encoder?.createArchive !== createArchive ||
            encoder?.dvrWindow !== dvrWindow
        );
    };

    const handleClose = () => {
        setOpenConfirm(false);
    };

    const handleYes = () => {
        setOpenConfirm(false);
        const encoderStatus = store.getState().encoderStatus;
        const { athenaId } = encoderStatus;
        if (confirmAction) {
            // start | stop
            const encoderAction =
                confirmAction === ENCODER_BUTTON_ACTIONS.START
                    ? encoderStart
                    : encoderStop;
            dispatch(encoderAction());
            productsService
                .actionEncoder(athenaId, confirmAction)
                .then((assetResponse) => {
                    const poll = setInterval(() => {
                        productsService.getAsset(athenaId).then((response) => {
                            const { state } = response;
                            if (
                                state === ENCODER_STATES.DEGRADED ||
                                state === ENCODER_STATES.RUNNING ||
                                state === ENCODER_STATES.WARNING ||
                                state === ENCODER_STATES.FAILED ||
                                state === ENCODER_STATES.STOPPED
                            ) {
                                clearInterval(encoderPollingInterval);
                            }
                            dispatch(updateEncoderStatus(state));
                        });
                    }, 1000);
                    encoderPollingInterval = poll;
                })
                .catch((error) => {
                    clearInterval(encoderPollingInterval);
                    dispatch(notifyError(error.response.data?.error));
                    dispatch(resetOnError());
                });
        } else {
            customFormSubmit(eventForSave, {
                urls_asset_id_live: override_urls_asset_id_live
            });
            setEntSelectionsChanged(false);
            setPermissionsChanged(false);
        }
    };

    const placeDvrWindow = isBR;
    const cdnGridMd = placeDvrWindow ? 2 : 3;

    return (
        <Grid container item className={props.classes} spacing={1}>
            <Confirm
                open={openConfirm}
                onClose={handleClose}
                onYes={handleYes}
                title='Confirmation'
                content={content}
            />
            <Grid item md={cdnGridMd} xs={12}>
                <SelectBox
                    label={`${localization.event_live_admin_cdn_config}:`}
                    name='cdn'
                    options={cdnOptions}
                    defaultValue={cdnDefaultValue}
                    handleChange={handleCdnChange}
                    showToggle={false}
                    enableToggle={false}
                    disabled={disabled}
                    localization={localization}
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <SelectBox
                    label={`${localization.event_live_admin_source}:`}
                    name='source'
                    options={sourceOptions}
                    defaultValue={sourceDefaultValue}
                    handleChange={handleSourceChange}
                    showToggle={false}
                    enableToggle={false}
                    disabled={disabled}
                    error={noSourceValue}
                    localization={localization}
                />
            </Grid>
            <Grid item md={cdnGridMd} xs={12}>
                <SelectBox
                    label={`${localization.event_live_admin_create_archive}:`}
                    name='createArchive'
                    options={createArchOptions}
                    defaultValue={createArchDefaultValue}
                    handleChange={handleCreateArchiveChange}
                    showToggle={false}
                    enableToggle={false}
                    disabled={disabled}
                    localization={localization}
                />
            </Grid>
            {placeDvrWindow && (
                <Grid item md={2} xs={12}>
                    <SelectBox
                        label='DVR Window:'
                        name='dvrWindow'
                        options={dvrWindowOptions}
                        defaultValue={dvrWindowDefaultValue}
                        handleChange={handleDvrWindowChange}
                        showToggle={false}
                        enableToggle={false}
                        disabled={disabled}
                        localization={localization}
                    />
                </Grid>
            )}
            <Grid
                item
                align='center'
                md={3}
                xs={12}
                display='flex'
                alignItems={'flex-end'}
                justifyContent='center'
            >
                {EncoderButton}
            </Grid>
            <Grid>&nbsp;</Grid>
        </Grid>
    );
};
