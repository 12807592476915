const VALID_EXPORT_TYPES = ['csv', 'json'];

const isValidExportType = (fileType) => {
    return (
        typeof fileType === 'string' &&
        VALID_EXPORT_TYPES.some(
            (aType) =>
                (aType || '').toLowerCase() === (fileType || '').toLowerCase()
        )
    );
};

const exportFile = (
    fileType,
    services,
    route,
    setOpen,
    processingExportFile
) => {
    if (isValidExportType(fileType)) {
        setOpen(true);
        // services.removeRange();
        return services
            .fetchAllData(route)
            .then((data) => processingExportFile(fileType, data))
            .catch((e) => {
                throw new Error(`Result set too large (${e.message})`);
            })
            .finally(() => {
                setOpen(false);
            });
    } else {
        return Promise.reject('Invalid file type.');
    }
};

module.exports = {
    exportFile
};
