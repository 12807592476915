import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation, useParams, Redirect } from 'react-router-dom';
import { Heading } from '../../shared/Heading';
import { CustomBreadcrumbs } from '../../shared/Breadcrumbs';
import { Info } from './Info';
import { Appearance } from './Appearance';
import services from '../../../services/apiService';
import { Details } from '../../Details';
import { countries } from '../../../data';
import { utils } from '../../../utils/utils';
const JSONSchema = require('let-json-schemas/v1/input_db/sport.json');
import { Confirm } from '../../shared/Confirm';
// Notifications:
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const SportSteps = ({
    slug,
    id,
    sport,
    setRefresh,
    JSONSchema,
    config,
    handleUpdate,
    handleForward
}) => {
    if (id === 'new' && slug !== 'sport-info') {
        return <Redirect to='/sports/new/sport-info' />;
    }

    const localization = useSelector(strings);

    switch (slug) {
        case 'sport-info':
            return !!Object.keys(sport) ? (
                <Info
                    id={id}
                    sport={sport}
                    setRefresh={setRefresh}
                    sportSchema={JSONSchema}
                    handleUpdate={handleUpdate}
                    handleForward={handleForward}
                />
            ) : (
                <div></div>
            );
        case 'appearance':
            return (
                <Appearance
                    id={id}
                    sport={sport}
                    setRefresh={setRefresh}
                    sportSchema={JSONSchema}
                    config={config}
                    handleUpdate={handleUpdate}
                    handleForward={handleForward}
                />
            );
        default:
            return <div></div>;
    }
};

export const CreateEditSport = ({ config }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const localization = useSelector(strings);

    let location = useLocation();
    let path = location.pathname.split('/').slice(-1)[0];
    let { id } = useParams();

    const [isUpdating, setIsUpdating] = useState(false);
    const [link, setLink] = useState(path);
    const [loadError, setLoadError] = useState();
    const [forwarding, setForwarding] = useState({});
    const [openConfirm, setOpenConfirm] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [sport, setSport] = useState({});

    let steps = [
        {
            title: localization.sport_info,
            slug: `sport-info`
        },
        {
            title: localization.appearance,
            slug: `appearance`
        }
    ];

    const [details, setDetails] = useState([]);

    const fetchSport = useCallback(() => {
        id !== 'new' &&
            services
                .getItem('sports', id, true)
                .then((res) => {
                    if (res && Object.keys(res).length === 0) {
                        setLoadError(
                            `Sport ${id} does not exist on this tenant`
                        );
                        history.push(`/sports`);
                        return;
                    }
                    const {
                        let_override: { territories_available } = {},
                        territories_available: territories = []
                    } = res;

                    setSport({
                        ...res,
                        territories_available: utils.getTerritories(
                            territories,
                            countries
                        ),
                        let_override: {
                            ...res.let_override,
                            territories_available: utils.getTerritories(
                                territories_available,
                                countries
                            )
                        }
                    });

                    const summary = utils.serializeSummary(
                        localization.sport,
                        res,
                        localization
                    );

                    id !== 'new' && setDetails(summary);
                })
                .catch((err) => {
                    setLoadError(`${err.name}: ${err.message}`);
                });
    }, [id]);

    useEffect(() => {
        fetchSport();
    }, [refresh, fetchSport]);

    useEffect(() => {
        if (loadError) {
            dispatch(notifyError(loadError));
            setLoadError();
        }
    }, [loadError]);

    const handleUpdate = (event) => {
        setIsUpdating(event);
    };

    const handleForward = (e, path) => {
        e.preventDefault();
        if (isUpdating) {
            setLink(path);
            setOpenConfirm(true);
            setForwarding({ e, path });
        } else {
            id !== 'new' && history.push(path);
        }
    };

    const handleNavigation = (e, id, slug) => {
        const path = `/sports/${id}/${slug}`;
        handleForward(e, path);
    };

    const handleOverride = () => {
        setIsUpdating(false);
        setOpenConfirm(false);

        if (forwarding.path) {
            history.push(forwarding.path);
        }
    };

    const handleStay = () => {
        setOpenConfirm(false);
    };
    return (
        <>
            <Heading heading={localization.create_edit_sport} />
            <CustomBreadcrumbs
                steps={steps}
                id={id}
                onChange={handleNavigation}
            />
            {id !== 'new' && Object.keys(sport).length > 0 && (
                <Details details={details} />
            )}
            <SportSteps
                slug={path}
                id={id}
                sport={sport}
                setRefresh={setRefresh}
                JSONSchema={JSONSchema}
                config={config}
                handleUpdate={handleUpdate}
                handleForward={handleForward}
            />
            <Confirm
                path={link}
                open={openConfirm}
                onYes={handleOverride}
                onNo={handleStay}
            />
        </>
    );
};
