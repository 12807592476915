import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        border: '1px solid black',
        backgroundColor: 'white',
        color: 'black',
        cursor: 'pointer',

        '&:hover': {
            color: 'white'
        }
    }
}));

export const ClearButton = (props) => {
    const classes = useStyles();
    const { cta } = props;
    return (
        <Button
            aria-labelledby='advanced_search_clear'
            className={classes.root}
            onClick={() => props.onClick()}
        >
            {cta}
        </Button>
    );
};
