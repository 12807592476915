import React, { useEffect, useState } from 'react';
import { maxImages } from '../../../data';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { UploadMaxPreview } from '../../shared/UploadMaxPreview';
import { Drawer } from '../../shared/Drawer';
import MultiLanguageContainer from './MultiLanguageContainer';
import MultiLanguageImageInput from './MultiLanguageImageInput';
import store from '../../../redux/store';
import { getEtag, indexSort } from '../../../utils/maxImageUtils';
import multilanguageSlice, {
    getIsMultiLanguage,
    getLanguages
} from '../../../reducer/multilanguageSlice';
import { useSelector } from 'react-redux';

export const nodeId = 'max_images';

const useStyles = makeStyles((theme) => ({
    styling: {
        fontWeight: 'bold',
        '& .MuiContainer-root': {
            padding: 0,
            marginTop: '10px'
        }
    }
}));

export const buildPlaceholder = (languages, state) => {
    const placeholder = {};
    languages.forEach((supportedLanguage) => {
        const langCode = supportedLanguage?.isoCode ? supportedLanguage.isoCode : supportedLanguage;        
        const imagesArray = [];
        maxImages.forEach((image, index) => {
            const { aspectRatio, format, imageId, kind, label, notes, size } =
                image;

            let [width, height] = size
                .split('x')
                .map((dimension) => +dimension.trim().replace(/[^0-9]/gi, ''));

            const baseImageFormat = {
                aspect_ratio: aspectRatio,
                cdnBasePath: state.config.gspCdnBasePath || '',
                etag: '',
                format,
                height,
                image: '',
                image_id: imageId,
                index,
                key: image.name,
                kind,
                label,
                metadata: '',
                notes,
                overrideUrl: '',
                path: state.config.imageBaseUrl,
                size: `${width} x ${height} px`,
                tag: image.metadataVal.default || '',
                width
            };

            imagesArray.push(baseImageFormat);            
        });
        placeholder[langCode] = imagesArray;
    });

    return placeholder;
};

export const reformatImagesML = (mlImages, placeholder, state) => {
    // (override_images_ml, multiLanguagePlaceholder);
    const response = {};
    Object.keys(placeholder).forEach((langCode) => {
        // Initialize to an empty array:
        response[langCode] = [];
        // Pull the current value in the record:
        let currentValue = mlImages[langCode];
        // Pull the fully qualified placeholder:
        let placeholderValue = placeholder[langCode];
        if (!currentValue) {
            // Images for this language code are currently undefined.
            // Use the placeholder in its entirety.
            response[langCode] = [...placeholderValue];
        } else if (Array.isArray(currentValue)) {
            // Already in the correct format.
            if (currentValue.length === placeholderValue.length) {
                // The stored value & the placeholder's count are equal;
                // trust that they're correct.
                response[langCode] = currentValue;
            } else {
                // The stored array has a different image count than
                // the placeholder. It has likely been culled, so
                // cycle through the placeholder and replace any missing
                // images.
                const missingImages = placeholderValue.filter(
                    (placeholderImage) => {
                        let found = currentValue.filter(
                            (a) => a.image_id === placeholderImage.image_id
                        );
                        return !found || found.length === 0;
                    }
                );
                response[langCode] = [...currentValue, ...missingImages];
            }
        } else {
            // Prior version; object. Transform it.
            const allImages = [...placeholder[langCode]];
            allImages.forEach((img) => {
                let oldRecord, newRecord;
                oldRecord = currentValue[img.image_id];
                if (oldRecord) {
                    const { fileName, image, metadata } = oldRecord;
                    const etag = getEtag(metadata);
                    newRecord = {
                        ...img,
                        cdnBasePath: state?.config?.gspCdnBasePath || '',
                        etag,
                        image: image || fileName, // Old version used fileName
                        metadata
                    };
                } else {
                    newRecord = { ...img, image: '' };
                }
                response[langCode].push(newRecord);
            });
        }
        response[langCode] = response[langCode].sort(indexSort);
    });
    return response;
};

export const MaxImages = ({
    bucket,
    config,
    currentlanguage,
    handleChange,
    handleDeleteValues,
    handleUploadChange,
    item,
    localization,
    requiredFields,
    tenant
}) => {
    const [el, setEl] = useState(<span id={nodeId} />);
    const classes = useStyles();
    
    const isMultiLanguage = useSelector(getIsMultiLanguage);
    const languages = useSelector(getLanguages);

    const getEl = () => {
        const tenantQualifies = tenant?.isDomestic || tenant?.isMax || false;

        if(!tenantQualifies){
            return el;
        }
        
        // Filter the image list:
        const images = tenant.isMax
            ? maxImages
            : maxImages.filter((image) => !image.max_only);

        let newEl;

        if (isMultiLanguage) {
            
            const multiLanguagePlaceholder = buildPlaceholder(
                languages,
                store.getState()
            );
            
            const images_ml = {
                ...multiLanguagePlaceholder,
                ...(item?.images_ml || {}),
                ...(item?.let_override?.images_ml || {})
            };
            
            const override_images_ml = reformatImagesML(
                item?.let_override?.images_ml || {},
                multiLanguagePlaceholder,
                store.getState()
            );

            newEl = (
                <Drawer title={localization.max_image_upload} drawerId={nodeId}>
                    <MultiLanguageContainer
                        languagesSupported={languages}
                        l10n={localization}
                        offset={true}
                    >
                        <MultiLanguageImageInput
                            bucket={bucket}
                            config={config}
                            currentlanguage={currentlanguage}
                            handleChange={handleChange}
                            name={'images_ml'}
                            originalValue={images_ml || {}}
                            overrideValue={override_images_ml}
                        />
                    </MultiLanguageContainer>
                </Drawer>
            );
        } else {
            try {
                // If there's no override, add an empty one:
                !item?.let_override && (item.let_override = {});

                // Helper for getting vars from the core item.
                const fromItem = (property) => {
                    return item?.let_override?.[property] || item?.[property];
                };

                // Define req'd fields:
                const requiredFieldsForm = [];
                requiredFieldsForm.push(
                    ...maxImages.map((imageItem) => imageItem.name)
                );

                newEl = (
                    <Drawer
                        title={localization.max_image_upload}
                        drawerId={nodeId}
                    >
                        {images.map((imageItem, index) => {
                            const fileName = fromItem(imageItem.name);
                            const metadata = fromItem(
                                `${imageItem.name}_metadata`
                            );
                            const overrideUrlVal = fromItem(
                                `${imageItem.name}_override`
                            );
                            const required = requiredFields.includes(
                                imageItem.name
                            );

                            return (
                                <Grid key={imageItem.name} item xs={12}>
                                    <UploadMaxPreview
                                        aspectRatio={imageItem.aspectRatio}
                                        bucket={bucket}
                                        config={config}
                                        data={imageItem}
                                        enableDelete={true}
                                        fileName={fileName}
                                        handleFileChange={handleUploadChange}
                                        handleDeleteValues={handleDeleteValues}
                                        handleTextChange={handleChange}
                                        imageId={imageItem.imageId}
                                        item={imageItem.itemId}
                                        label={imageItem.label}
                                        metadata={metadata}
                                        name={imageItem.name}
                                        overrideUrlVal={overrideUrlVal}
                                        required={required}
                                        type='image'
                                        allowedFormat={imageItem?.format}
                                    />
                                </Grid>
                            );
                        })}
                    </Drawer>
                );
            } catch (e) {
                // Nothing; most likely still waiting for things to load.
                console.error(e);
            }
        }

        return newEl ? newEl : el;
    };

    useEffect(() => {
        const eventLoaded = item && item.hasOwnProperty('let_override');
        const tenantQualifies = tenant && (tenant.isMax || tenant.isDomestic);

        if (tenantQualifies) {
            if (eventLoaded) {
                setEl(getEl());
            } else {
                setEl(<p data-testid='max-images-loading'>Loading ....</p>);
            }
        }
    }, [item]);

    return el;
};
