import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Divider, Grid, Typography } from '@mui/material';
import { UploadHBOMaxPreview } from '../../shared/UploadHBOMaxPreview';
import { hboImages } from '../../../data';

export const nodeId = 'hbo_images';

const debug = false;

const useStyles = makeStyles((theme) => ({
    styling: {
        fontWeight: 'bold',
        '& .MuiContainer-root': {
            padding: 0,
            marginTop: '10px'
        }
    }
}));

export const HBOImages = ({
    bucket,
    config,
    handleChange,
    handleDeleteValues,
    handleUploadChange,
    item,
    localization,
    requiredFields,
    tenant
}) => {
    const [el, setEl] = useState(<span id={nodeId}></span>);

    const classes = useStyles();

    const eventLoaded = item && item.hasOwnProperty('let_override');
    const tenantQualifies =
        tenant && tenant?.isMaxFamily && !tenant.isDomestic && !tenant.isMax;

    useEffect(() => {
        if (tenantQualifies) {
            if (eventLoaded) {
                try {
                    const resolveDefaultData = (item) => {
                        return eval(item.update) || eval(item.default);
                    };

                    const requiredFieldsForm = [];

                    requiredFieldsForm.push(
                        ...hboImages.map((imageItem) => imageItem.name)
                    );

                    const {
                        hbo_bottom_center_logo,
                        hbo_bottom_center_logo_override,
                        hbo_bottom_center_logo_metadata,
                        hbo_bottom_left_logo,
                        hbo_bottom_left_logo_override,
                        hbo_bottom_left_logo_metadata,
                        hbo_horizontal_logo,
                        hbo_horizontal_logo_override,
                        hbo_horizontal_logo_metadata,
                        hbo_horizontal_textless,
                        hbo_horizontal_textless_override,
                        hbo_horizontal_textless_metadata,
                        hbo_square_textless,
                        hbo_square_textless_override,
                        hbo_square_textless_metadata,
                        hbo_tall_textless,
                        hbo_tall_textless_override,
                        hbo_tall_textless_metadata,
                        hbo_vertical_logo,
                        hbo_vertical_logo_override,
                        hbo_vertical_logo_metadata,
                        hbo_square_logo,
                        hbo_square_logo_override,
                        hbo_square_logo_metadata,
                        hbo_wide_textless,
                        hbo_wide_textless_metadata,
                        hbo_wide_textless_override,
                        let_override: {
                            hbo_bottom_center_logo:
                                override_hbo_bottom_center_logo,
                            hbo_bottom_center_logo_override:
                                override_hbo_bottom_center_logo_override,
                            hbo_bottom_center_logo_metadata:
                                override_hbo_bottom_center_logo_metadata,
                            hbo_bottom_left_logo: override_hbo_bottom_left_logo,
                            hbo_bottom_left_logo_override:
                                override_hbo_bottom_left_logo_override,
                            hbo_bottom_left_logo_metadata:
                                override_hbo_bottom_left_logo_metadata,
                            hbo_horizontal_logo: override_hbo_horizontal_logo,
                            hbo_horizontal_logo_override:
                                override_hbo_horizontal_logo_override,
                            hbo_horizontal_logo_metadata:
                                override_hbo_horizontal_logo_metadata,
                            hbo_horizontal_textless:
                                override_hbo_horizontal_textless,
                            hbo_horizontal_textless_override:
                                override_hbo_horizontal_textless_override,
                            hbo_horizontal_textless_metadata:
                                override_hbo_horizontal_textless_metadata,
                            hbo_square_logo: override_hbo_square_logo,
                            hbo_square_logo_override:
                                override_hbo_square_logo_override,
                            hbo_square_logo_metadata:
                                override_hbo_square_logo_metadata,
                            hbo_square_textless: override_hbo_square_textless,
                            hbo_square_textless_override:
                                override_hbo_square_textless_override,
                            hbo_square_textless_metadata:
                                override_hbo_square_textless_metadata,
                            hbo_tall_textless: override_hbo_tall_textless,
                            hbo_tall_textless_override:
                                override_hbo_tall_textless_override,
                            hbo_tall_textless_metadata:
                                override_hbo_tall_textless_metadata,
                            hbo_vertical_logo: override_hbo_vertical_logo,
                            hbo_vertical_logo_override:
                                override_hbo_vertical_logo_override,
                            hbo_vertical_logo_metadata:
                                override_hbo_vertical_logo_metadata,
                            hbo_wide_textless: override_hbo_wide_textless,
                            hbo_wide_textless_metadata:
                                override_hbo_wide_textless_metadata,
                            hbo_wide_textless_override:
                                override_hbo_wide_textless_override
                        }
                    } = item;

                    setEl(
                        <Grid item container spacing={2} id={nodeId}>
                            <Grid item xs={12}>
                                <Typography variant='h5'>
                                    {localization.hbo_image_upload}
                                </Typography>
                                <Divider />
                            </Grid>
                            {hboImages.map((imageItem) => {
                                return (
                                    <Grid
                                        key={imageItem.name}
                                        item
                                        md={12}
                                        xs={12}
                                    >
                                        <UploadHBOMaxPreview
                                            aspectRatio={imageItem.aspectRatio}
                                            label={imageItem.label}
                                            name={imageItem.name}
                                            bucket={bucket}
                                            enableDelete={true}
                                            imageId={imageItem.imageId}
                                            item={imageItem.itemId}
                                            type='image'
                                            allowedFormat={imageItem?.format}
                                            config={config}
                                            fileName={resolveDefaultData(
                                                imageItem.imageVal
                                            )}
                                            handleDeleteValues={
                                                handleDeleteValues
                                            }
                                            handleFileChange={
                                                handleUploadChange
                                            }
                                            handleTextChange={handleChange}
                                            overrideUrlVal={resolveDefaultData(
                                                imageItem.urlOverrideVal
                                            )}
                                            metadata={resolveDefaultData(
                                                imageItem.metadataVal
                                            )}
                                            required={requiredFields.includes(
                                                imageItem.name
                                            )}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    );
                } catch (e) {
                    // Nothing; most likely still waiting for things to load.
                    debug && console.error(e);
                }
            } else {
                setEl(<p>Loading ...</p>);
            }
        }
    }, [item]);

    return el;
};
