import React, { useContext, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import {
    ArrowBackOutlined,
    ArrowForwardOutlined,
    RemoveOutlined
} from '@mui/icons-material';
import { Buttons } from '../../shared/Buttons';
import { CompetitorLogo } from '../../shared/CompetitorLogo';
import { CircularProgress, Container, Grid } from '@mui/material';
import { Detail } from './Detail';
import { LeagueContext } from '../../context/LeagueContext';
import { makeStyles } from '@mui/styles';
import {
    getIsMultiLanguage,
    multilanguageSlice
} from '../../../reducer/multilanguageSlice';
import { MultilanguageTextarea } from '../../shared/MultilanguageTextarea';
import { selectTenant } from '../../../reducer/tenantSlice';
import { Textarea } from '../../shared/Textarea';
import { UploadPreview } from '../../shared/UploadPreview';
import { useForm } from '../../hooks/UseForm';
import { useSelector } from 'react-redux';
import { utils } from '../../../utils/utils';
import { strings } from '../../../reducer/localizationSlice';
import { HBOImages } from './HBOImages';
import { MaxImages } from './MaxImages';
import { Drawer } from '../../shared/Drawer';
import { LanguageSelect } from '../../shared/LanguageSelect';
import { Suspense } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        }
    }
}));

export const getMultilanguageStub = (tenantLanguages = []) => {
    const description_body_ml = {};
    tenantLanguages.forEach((language) => {
        description_body_ml[language] = '';
    });
    return description_body_ml;
};

export const Appearance = ({
    id,
    event,
    setRefresh,
    eventSchema,
    config,
    handleUpdate,
    path,
    handleForward
}) => {
    const tenant = useSelector(selectTenant);
    const [imageUploadTitle, setImageUploadTitle] = useState('');
    useEffect(() => {
        tenant.isDomestic || tenant.isMax
            ? setImageUploadTitle('Max Image Upload')
            : setImageUploadTitle('HBO Max Image Upload');
    }, [tenant]);

    const localization = useSelector(strings);

    const classes = useStyles();
    const leagues = useContext(LeagueContext);

    const prevPage = `/events/${id}/event-info`;
    const nextPage = `/events/${id}/live-admin`;
    const requiredFields = eventSchema.required;
    const requiredFieldsForm = [
        'description_body',
        'logo_light',
        'logo_dark',
        'image'
    ];

    const {
        item,
        error,
        valueChanged,
        handleChange,
        handleDeleteValues,
        handleUploadChange,
        onFormSubmit,
        form
    } = useForm({
        service: 'events',
        initialState: event,
        id: id,
        requiredFields: requiredFields.reduce((pre, cur) => {
            if (requiredFieldsForm.includes(cur)) return [...pre, cur];
            else return pre;
        }, []),
        setRefresh: setRefresh
    });

    const {
        description_body,
        description_body_ml,
        image,
        images_ml,
        logo_dark,
        logo_light,
        league_id,
        let_override: {
            description_body: override_description_body,
            description_body_ml: override_description_body_ml,
            image: override_image,
            images_ml: override_images_ml,
            logo_dark: override_logo_dark,
            logo_light: override_logo_light,
            league_id: override_league_id
        } = {}
    } = item;

    const leagueMatchValue = league_id || override_league_id;
    const foundLeague = leagues.find(
        (element) => element.id == leagueMatchValue
    );
    // Estadio Chile league values may include commas - remove them
    const leagueBucket = utils.stripSpecialChars(
        foundLeague ? foundLeague.name : 'no-league'
    );

    const fullTenant = useSelector(selectTenant);

    const isMultiLanguage =
        useSelector(getIsMultiLanguage) || fullTenant.isMultiLanguage;
    const tenantLanguages = fullTenant.languages;

    useEffect(() => {
        return () => {};
    });

    useEffect(() => {
        handleUpdate(valueChanged);
    }, [valueChanged]);

    return (
        <div className={classes.root}>
            <LeagueContext.Provider value={leagues}>
                <Container maxWidth='lg'>
                    <form noValidate autoComplete='off' onSubmit={onFormSubmit}>
                        {id !== 'new' && Object.keys(event).length > 0 && (
                            <Detail path={path} id={id} event={event} />
                        )}
                        <Grid container spacing={2}>
                            {!tenant?.isMax && (
                                <Grid item xs={12}>
                                    {isMultiLanguage && (
                                        <MultilanguageTextarea
                                            error={error.description_body_ml}
                                            handleChange={handleChange}
                                            label={`${localization.description} (%LANG%):`}
                                            languages={tenantLanguages}
                                            name='description_body_ml'
                                            originalValue={
                                                event?.description_body_ml ||
                                                getMultilanguageStub(
                                                    tenantLanguages
                                                )
                                            }
                                            required={requiredFields.includes(
                                                'description_body_ml'
                                            )}
                                            showCopy={true}
                                            showToggle={false}
                                            enableToggle={false}
                                            value={
                                                override_description_body_ml ||
                                                description_body_ml ||
                                                form.description_body_ml ||
                                                getMultilanguageStub()
                                            }
                                        />
                                    )}
                                    {!isMultiLanguage && (
                                        <Textarea
                                            label={`${localization.description}:`}
                                            name='description_body'
                                            defaultValue={
                                                override_description_body ||
                                                description_body ||
                                                form.description_body ||
                                                ''
                                            }
                                            originalValue={description_body}
                                            handleChange={handleChange}
                                            required={requiredFields.includes(
                                                'description_body'
                                            )}
                                            showCopy={true}
                                            showToggle={false}
                                            enableToggle={false}
                                        />
                                    )}
                                </Grid>
                            )}
                            <Drawer
                                title={localization.event_and_competitor_image}
                                drawerId={`${tenant.tenantName}_event_and_competitor_images`}
                                open={tenant.isMax ? 0 : 1}
                            >
                                {' '}
                                <Grid item md={6} xs={12}>
                                    <UploadPreview
                                        label={`${localization.event_details_logo_light_background}:`}
                                        name='logo_light'
                                        bucket={leagueBucket}
                                        enableDelete={true}
                                        item='event_logo_light'
                                        type='image'
                                        config={config}
                                        fileName={
                                            override_logo_light || logo_light
                                        }
                                        handleChange={handleUploadChange}
                                        required={requiredFields.includes(
                                            'logo_light'
                                        )}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <UploadPreview
                                        label={`${localization.event_details_logo_dark_background}:`}
                                        name='logo_dark'
                                        bucket={leagueBucket}
                                        enableDelete={true}
                                        item='event_logo_dark'
                                        type='image'
                                        config={config}
                                        fileName={
                                            override_logo_dark || logo_dark
                                        }
                                        handleChange={handleUploadChange}
                                        required={requiredFields.includes(
                                            'logo_dark'
                                        )}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <CompetitorLogo
                                        competitorId={
                                            event?.let_override
                                                ?.home_competitor_id ||
                                            event.home_competitor_id
                                        }
                                        item='home_logo_light'
                                        logoName='logo_light'
                                        label={`${localization.event_details_home_logo_light}:`}
                                        bucket={leagueBucket}
                                        tenant={tenant.tenantName}
                                        config={config}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <CompetitorLogo
                                        competitorId={
                                            event?.let_override
                                                ?.home_competitor_id ||
                                            event.home_competitor_id
                                        }
                                        item='home_logo_dark'
                                        logoName='logo_dark'
                                        label={`${localization.event_details_home_logo_dark}:`}
                                        bucket={leagueBucket}
                                        tenant={tenant.tenantName}
                                        config={config}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <CompetitorLogo
                                        competitorId={
                                            event?.let_override
                                                ?.away_competitor_id ||
                                            event.away_competitor_id
                                        }
                                        logoName='logo_light'
                                        item='away_logo_light'
                                        label={`${localization.event_details_away_logo_light}:`}
                                        bucket={leagueBucket}
                                        tenant={tenant.tenantName}
                                        config={config}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <CompetitorLogo
                                        competitorId={
                                            event?.let_override
                                                ?.away_competitor_id ||
                                            event.away_competitor_id
                                        }
                                        logoName='logo_dark'
                                        item='away_logo_dark'
                                        label={`${localization.event_details_away_logo_dark}:`}
                                        bucket={leagueBucket}
                                        tenant={tenant.tenantName}
                                        config={config}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <UploadPreview
                                        label={`${localization.event_details_event_image}:`}
                                        name='image'
                                        bucket={leagueBucket}
                                        enableDelete={true}
                                        item='event_image'
                                        type='image'
                                        config={config}
                                        fileName={override_image || image}
                                        handleChange={handleUploadChange}
                                        required={requiredFields.includes(
                                            'image'
                                        )}
                                    />
                                </Grid>
                            </Drawer>

                            {tenant.isMaxFamily &&
                                !tenant.isMax &&
                                !tenant.isDomestic && (
                                    <HBOImages
                                        bucket={'source'}
                                        config={config}
                                        handleChange={handleChange}
                                        handleDeleteValues={handleDeleteValues}
                                        handleUploadChange={handleUploadChange}
                                        item={{ ...item }}
                                        localization={localization}
                                        requiredFields={requiredFields}
                                        tenant={tenant}
                                    />
                                )}
                            {(tenant?.isDomestic || tenant?.isMax) && (
                                <MaxImages
                                    bucket={'event'}
                                    config={config}
                                    handleChange={handleChange}
                                    handleDeleteValues={handleDeleteValues}
                                    handleUploadChange={handleUploadChange}
                                    item={{ ...item }}
                                    localization={localization}
                                    requiredFields={requiredFields}
                                    tenant={tenant}
                                />
                            )}

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <IconButton
                                        aria-label='delete'
                                        style={{ color: 'black' }}
                                        onClick={(e) =>
                                            handleForward(e, prevPage)
                                        }
                                    >
                                        <ArrowBackOutlined fontSize='large' />
                                        <RemoveOutlined
                                            fontSize='large'
                                            style={{ marginLeft: '-16px' }}
                                        />
                                    </IconButton>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={6}
                                    display='flex'
                                    alignItems='center'
                                    justifyContent='flex-end'
                                    sx={{ gap: 2, mb: 2 }}
                                >
                                    <Buttons
                                        type='submit'
                                        label={localization.save}
                                        showForWriteAccessUsers
                                    />
                                    {tenant.isMax ? (
                                        ''
                                    ) : (
                                        <IconButton
                                            aria-label='next-page'
                                            style={{ color: 'black' }}
                                            onClick={(e) =>
                                                handleForward(e, nextPage)
                                            }
                                        >
                                            <RemoveOutlined
                                                fontSize='large'
                                                style={{ marginRight: '-16px' }}
                                            />
                                            <ArrowForwardOutlined fontSize='large' />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            </LeagueContext.Provider>
            <Suspense fallback={<CircularProgress color='inherit' />}>
                <LanguageSelect setRefresh={setRefresh} />
            </Suspense>
        </div>
    );
};
