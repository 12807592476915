import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import productsService from '../../../services/productsService';
import store from '../../../redux/store';
import { ArrowBackOutlined, RemoveOutlined } from '@mui/icons-material';
import { Buttons } from '../../shared/Buttons';
import { CdnConfigGrid } from '../../shared/LiveAdminCdnConfig';
import { Checkbox, CircularProgress, Container, Grid } from '@mui/material';
import { Confirm } from '../../shared/Confirm';
import { Detail } from './Detail';
import { EventStatusOptions } from '../../../constants';
import { hasUserEditPermission } from '../../../reducer/userReducer';
import { Input } from '../../shared/Input';
import { isAdmin, isOperator } from '../../../reducer/userReducer';
import { Loader } from '../../shared/Loader';
import { SelectBox } from '../../shared/SelectBox';
import { useForm } from '../../hooks/UseForm';
import { useSelector } from 'react-redux';
import { useStyles, getTableHeaderStyles } from './LiveAdminStyles';
// Notifications:
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch } from 'react-redux';
import liveAdminHelper from '../../../services/liveAdminHelper';
import { selectTenant } from '../../../reducer/tenantSlice';

// selectors for encodeSlice state fields
import {
    getAthenaState,
    getButtonText,
    getCdnButtonDisabled,
    getCdnConfig,
    getCreateArchive,
    getDisableCdnConfig,
    getDvrWindow,
    getEncoderBusy,
    getSource
} from '../../../reducer/encoderSlice';

// Encoder Button customization:
import {
    ENCODER_BUTTON_ACTIONS,
    ENCODER_STATES,
    encoderStop,
    encoderStart,
    enableConfigSave,
    resetOnError,
    saveComplete,
    savingCdnConfig,
    setAthenaId,
    setEncoderConfig,
    setEncoderUnavailable,
    updateAsset,
    updateEncoderStatus
} from '../../../reducer/encoderSlice';
import { strings } from '../../../reducer/localizationSlice';

const LiveVideoManagement = lazy(() => import('./LiveVideoManagement'));
const ProductManagement = lazy(() => import('./ProductManagement'));

const LiveAdminBR = ({
    id,
    profile,
    cdn,
    event,
    setRefresh,
    eventSchema,
    config,
    path,
    handleForward
}) => {
    const localization = useSelector(strings);

    let prevPage = `/events/${id}/appearance`;
    const classes = useStyles();
    const profileId = profile;

    // ****************************************************************
    // Beginning of state declarations

    // Selectors that track state from the encoderSlice reducer for these fields
    //
    const athenaState = useSelector(getAthenaState);
    const buttonText = useSelector(getButtonText);
    const cdnButtonDisabled = useSelector(getCdnButtonDisabled);
    const cdnConfig = useSelector(getCdnConfig);
    const createArchive = useSelector(getCreateArchive);
    const disableCdnConfig = useSelector(getDisableCdnConfig);
    const dvrWindow = useSelector(getDvrWindow);
    const source = useSelector(getSource);

    // Encoder selection Lists
    const [cdnList, setCdnList] = useState([]);
    const [sourceList, setSourceList] = useState([]);
    const [createArchiveList, setCreateArchiveList] = useState([]);
    const [dvrWindowList, setDvrWindowList] = useState([]);

    // Permissions and tenant setup
    const isAdminUser = useSelector(isAdmin);
    const isOpUser = useSelector(isOperator);
    const selectedTenant = useSelector(selectTenant);
    const airAndEditDisabled = !(isAdminUser || isOpUser);
    const userCanEdit = useSelector(hasUserEditPermission);

    // For loading indicator, saving, and LiveVideoManagement
    const [loading, setLoading] = useState(false);
    const [currentEvent, setEvent] = useState(event);
    const [eventForSave, setEventForSave] = useState(null);

    // Live Media related state values
    const [liveSpan, setLiveSpan] = useState('');
    const [freeVideo, setFreeVideo] = useState(false);
    const [mediaIDError, setMediaIDError] = useState(null);
    const [mediaAssetIdLive, setMediaAssetIdLive] = useState(null);

    // Confirmation dialog related
    const [content, setContent] = useState('');
    const [openConfirm, setOpenConfirm] = useState(false);

    // Profile and Products subscription-related state values
    const [tableHeaders, setTableHeaders] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [productList, setProductList] = useState(null);
    const [activeProducts, setActiveProducts] = useState([]);
    const [foreignId, setForeignId] = useState('');
    const [entSelectionsChanged, setEntSelectionsChanged] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const [selectedBRProductName, setSelectedBRProductName] = useState('');
    // Related to product subscriptions as geo-perm is updated with the same button
    const [productPermissions, setProductPermissions] = useState({});
    const [permissionsChanged, setPermissionsChanged] = useState(false);

    // Media Asset ID:
    const [mediaAssetId, setMediaAssetId] = useState(null);
    // VOD flag:
    const [isVod, setIsVod] = useState(null);

    // End of state declarations
    // ****************************************************************

    const dispatch = useDispatch();

    // Used to poll the product service when starting/stopping the encoder:
    let encoderPollingInterval = 0;

    const {
        customFormSubmit,
        enableToggle,
        error,
        form,
        isInProgress,
        item,
        handleChange,
        handleToggleChange,
        showToggle
    } = useForm({
        service: 'events',
        initialState: currentEvent,
        id: id,
        requiredFields: eventSchema.required,
        setRefresh: setRefresh
    });

    if (event && !event.asset_id) {
        event.asset_id = event.let_override.asset_id;
    }

    const {
        event_status,
        is_live,
        is_medium,
        media_asset_id_live,
        media_asset_id_vod,
        scheduled_utc,
        scheduled_utc_end,
        let_override: {
            event_status: override_event_status,
            is_live: override_is_live,
            is_medium: override_is_medium,
            media_asset_id_live: override_media_asset_id_live,
            media_asset_id_vod: override_media_asset_id_vod,
            urls_asset_id_live: override_urls_asset_id_live,
            scheduled_utc: override_scheduled_utc,
            scheduled_utc_end: override_scheduled_utc_end
        } = {}
    } = item;

    const endDate = override_scheduled_utc_end || scheduled_utc_end;
    const startDate = override_scheduled_utc || scheduled_utc;
    const isLive = override_is_live || is_live;

    let isMedium;

    const loadingSpan = (
        <span className={classes.loading}>{`${localization.loading}...`}</span>
    );

    useEffect(() => {
        let athenaId = event.let_override.athena_id || event.athena_id;
        setMediaAssetIdLive(null);
        setLiveSpan(loadingSpan);
        dispatch(setAthenaId(athenaId));
        dispatch(
            enableConfigSave({
                cdnConfig: cdn,
                source: '',
                createArchive: '',
                dvrWindow: ''
            })
        );
        // Add callback to cancel any intervals;
        return () => {
            clearInterval(encoderPollingInterval);
        };
    }, []);

    useEffect(() => {
        setTableHeaders(
            getTableHeaderStyles(renderCheckboxContent, localization)
        );
    }, [
        subscriptions,
        productList,
        JSON.stringify(subscriptions),
        JSON.stringify(activeProducts)
    ]);

    useEffect(() => {
        productsService
            .getProfiles()
            .then((profileResponse) => {
                return setProfiles(profileResponse);
            })
            .catch((err) => handleCommError(err));
    }, []);

    useEffect(() => {
        const currentMediaValue =
            form.media_asset_id_live ||
            override_media_asset_id_live ||
            media_asset_id_live ||
            '';
        if (mediaAssetIdLive === null) {
            return;
        } else if (mediaAssetIdLive && mediaAssetIdLive !== currentMediaValue) {
            setLiveSpan(
                <span className={classes.small}>
                    <strong>From LETProducts:</strong>&nbsp;
                    {mediaAssetIdLive}
                </span>
            );
        } else {
            setLiveSpan('');
        }
    }, [mediaAssetIdLive]);

    useEffect(() => {
        let athenaId = store.getState().encoderStatus.athenaId;
        if (athenaId !== undefined) {
            setLoading(true);
            fetchProductData({ athenaId, profileId });
        }

        isMedium = override_is_medium || is_medium;

        // Gather the Live Media Asset ID:
        const liveMediaAssetId =
            override_media_asset_id_live || media_asset_id_live;
        // ... And the one for the VOD:
        const vodMediaAssetId =
            override_media_asset_id_vod || media_asset_id_vod;
        // And then prefer the VOD over the Live:
        setMediaAssetId(vodMediaAssetId || liveMediaAssetId);
        setIsVod(vodMediaAssetId ? true : false);
    }, [event]);

    useEffect(() => {
        if (mediaAssetId === null || isVod === null) return;
        console.info(
            `\u2384 Setting the Media Asset ID to ${mediaAssetId} (${
                isVod ? 'vod' : 'live'
            } asset) for the Top Player`
        );
    }, [mediaAssetId, isVod]);

    const renderCheckboxContent = (params) => (
        <p>
            {
                <>
                    <Checkbox
                        disabled={!userCanEdit}
                        checked={params.value.checked}
                        title={localization.add_to_event}
                        onChange={(event) =>
                            handleCheckboxClick(event, params.value)
                        }
                    />
                </>
            }
        </p>
    );

    const confirmSave = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (entSelectionsChanged || permissionsChanged) {
            let copy =
                entSelectionsChanged && permissionsChanged
                    ? `both the event's associated products and geo permissions`
                    : entSelectionsChanged
                    ? `the event's associated products`
                    : `the event's geo permissions`;
            setContent(
                <>
                    <p className={classes.small}>
                        You have unsaved changes to {copy}
                    </p>
                    <p className={classes.small}>
                        To save these changes you must click the{' '}
                        <strong>Update Products</strong> button prior to saving.
                    </p>
                    <p className={classes.small}>
                        Do you wish to continue without saving these changes?
                    </p>
                </>
            );
            setOpenConfirm(true);
            setEventForSave(event);
        } else {
            setEventForSave(event);
            customFormSubmit(event);
        }
    };

    const getMediaDetails = (mediaEvent) => {
        const mediaLiveAssetId =
            mediaEvent &&
            mediaEvent.let_override &&
            mediaEvent.let_override.media_asset_id_live !== undefined
                ? mediaEvent.let_override.media_asset_id_live
                : mediaEvent.asset_id_live;

        const mediaVodAssetId =
            mediaEvent &&
            mediaEvent.let_override &&
            mediaEvent.let_override.media_asset_id_vod !== undefined
                ? mediaEvent.let_override.media_asset_id_vod
                : mediaEvent.media_asset_id_vod || null;

        const media = {
            id: mediaEvent.id,
            mediaAssetId: mediaVodAssetId ? mediaVodAssetId : mediaLiveAssetId,
            mediaType: mediaVodAssetId ? 'vod' : 'live'
        };
        return media;
    };

    const processGeoPermissions = (registeredProductsData) => {
        const perms = registeredProductsData.permissions;
        setProductPermissions(perms);
    };

    const processProductRegistration = (productsData, subscribedProducts) => {
        setSelectedBRProductName('');
        productsData.products.map((element) => {
            element.id = element.universalProductIndicator;
            let SKU = '';
            if (
                element.storefrontProducts &&
                element.storefrontProducts.billing &&
                element.storefrontProducts.billing.length > 0 &&
                element.storefrontProducts.billing[0].sku
            ) {
                SKU = element.storefrontProducts.billing[0].sku;
            }
            let entitlement = '';
            if (
                element.entitlements[0] &&
                element.entitlements[0].entitlement
            ) {
                entitlement = element.entitlements[0].entitlement;
            }
            element.sku = SKU;
            element.product = {
                entitlement: entitlement,
                product: element.universalProductIndicator,
                checked: false
            };
            if (
                subscribedProducts.includes(element.universalProductIndicator)
            ) {
                setSelectedBRProductName(element.description);
                element.product.checked = true;
                updateSubscriptionList(element.product);
            }
        });

        const filteredProductsResponse = (productsData?.products || []).filter(
            function (item) {
                if (
                    item.entitlements === null ||
                    item.universalProductIndicator === null ||
                    item.universalProductIndicator === '...'
                ) {
                    return false; // skip if any of the bove is missing.
                }
                return true;
            }
        );

        // Update product list state from products response.
        setProductList(filteredProductsResponse);
    };

    const processAsset = (assetResponseData) => {
        setMediaAssetIdLive(assetResponseData?.media_asset_id_live || null);
        // To setFree Video
        if (
            assetResponseData.product !== undefined &&
            assetResponseData.product === 'free'
        ) {
            setFreeVideo(true);
        }

        dispatch(
            updateAsset({
                athenaId: assetResponseData?.id,
                encoderStatus: assetResponseData?.state,
                cdnConfig: assetResponseData?.cdn_config,
                createArchive: assetResponseData?.create_archive,
                dvrWindow: assetResponseData?.dvr_window,
                source: assetResponseData?.source,
                urls_asset_id_live: assetResponseData?.urls_asset_id_live
            })
        );
    };

    const processDescriptors = (allDescriptorsData) => {
        const formattedDescriptors =
            liveAdminHelper.formatDescriptorsChoices(allDescriptorsData);

        formattedDescriptors.cdn_config &&
            setCdnList(formattedDescriptors.cdn_config);

        formattedDescriptors.source &&
            setSourceList(
                formattedDescriptors.source.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                )
            );

        formattedDescriptors.create_archive &&
            setCreateArchiveList(formattedDescriptors.create_archive);

        formattedDescriptors.dvr_window &&
            setDvrWindowList(formattedDescriptors.dvr_window);
    };

    const fetchProductData = async (ids) => {
        const { athenaId, profileId } = ids;

        setMediaAssetIdLive(null);
        setLiveSpan(loadingSpan);

        const mediaDetails = getMediaDetails(event);
        const mediaId = mediaDetails.mediaAssetId;
        if (mediaId) {
            // Call for and await the data:
            try {
                const productData = await liveAdminHelper.getProductServiceData(
                    {
                        mediaId,
                        athenaId,
                        profileId
                    },
                    selectedTenant
                );
                const {
                    assetResponse,
                    allDescriptors,
                    productsResponse,
                    registeredProductsResponse
                } = productData;

                // Descriptors
                if (allDescriptors) {
                    if (allDescriptors.promiseStatus) {
                        processDescriptors(allDescriptors.data);
                    } else {
                        handleCommError(
                            `Unable to return any Descriptors on media/profiles/${profileId}`
                        );
                    }
                }

                //Asset/Profile
                if (assetResponse != null) {
                    if (assetResponse.promiseStatus && assetResponse.data.id) {
                        processAsset(assetResponse.data);
                    } else {
                        dispatch(setEncoderUnavailable({}));
                        handleCommError(
                            `${assetResponse?.data} for Athena ID ${athenaId}`
                        );
                    }
                }

                //RegisteredProducts
                let subscribedProducts = [];
                if (registeredProductsResponse) {
                    if (registeredProductsResponse.promiseStatus) {
                        subscribedProducts =
                            registeredProductsResponse.data.products;
                        setForeignId(registeredProductsResponse.data.foreignId);
                        setActiveProducts(subscribedProducts);

                        processGeoPermissions(registeredProductsResponse.data);
                    } else {
                        // Error returning active products, set to an empty array:
                        setActiveProducts([]);
                    }
                }

                //Product List/Product Registration
                if (productsResponse) {
                    if (productsResponse.promiseStatus) {
                        processProductRegistration(
                            productsResponse.data,
                            subscribedProducts
                        );
                    } else {
                        handleCommError(
                            `'Retreiving Product List, ' ${productsResponse.data}`
                        );
                    }
                }
                setLoading(false);
            } catch (err) {
                const errString = err.isAxiosError
                    ? `Error retrieving products for ${mediaId}.`
                    : err.message;

                handleCommError(errString);
                setLiveSpan('');
                const formValue =
                    form?.media_asset_id_live ||
                    override_media_asset_id_live ||
                    media_asset_id_live;
                if (formValue !== mediaId) {
                    // Populate the replace tag:
                    setMediaAssetIdLive(mediaId);
                }
                setLoading(false);
            }
        } else {
            // No media ID ... nothing to do.
            dispatch(setEncoderUnavailable({}));
            handleCommError(
                `No Live or VOD Media ID found on event ${event.id}`
            );
            setLoading(false);
        }
    };

    const handleCheckboxClick = (event, subscription) => {
        setEntSelectionsChanged(true);
        // Toggle checked flag
        subscription.checked = !subscription.checked;
        // Specific to BR Tenant.
        updateBRSubscriptionList(subscription);
    };

    const handleClose = () => {
        setOpenConfirm(false);
    };

    const handleCommError = (errorString) => {
        setProductList([]);
        setMediaIDError(errorString);
        dispatch(notifyError(`Error:  ${errorString}`));
    };

    const handleMediaChange = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (mediaAssetIdLive === null) {
            // First change; capture it.
            setMediaAssetIdLive(event.currentTarget.defaultValue);
        }
        setLiveSpan(
            <span className={classes.small}>
                <strong>From LETProducts:</strong>&nbsp;
                {mediaAssetIdLive}
            </span>
        );
        handleChange(event);
    };

    const handleMediaReset = (event) => {
        form.media_asset_id_live = mediaAssetIdLive;
        setLiveSpan('');
    };

    const handleYes = () => {
        setOpenConfirm(false);

        customFormSubmit(eventForSave, {
            urls_asset_id_live: override_urls_asset_id_live
        });
        setEntSelectionsChanged(false);
        setPermissionsChanged(false);
    };

    // BR tenant specific funtion to allow user to select only one product.
    const updateBRSubscriptionList = (selectedProduct) => {
        productList.forEach((row) => {
            if (row.id === selectedProduct.product) {
                if (selectedProduct.checked) {
                    row.product.checked = true;
                    setSelectedBRProductName(row.description);
                } else {
                    row.product.checked = false;
                    setSelectedBRProductName('');
                }
            } else {
                row.product.checked = false;
            }
        });
        if (selectedProduct.checked) {
            setSubscriptions([selectedProduct]);
        } else {
            setSubscriptions([]);
        }
    };

    const updateSubscriptionList = (subscription) => {
        let newProducts = subscriptions.concat([subscription]);
        setSubscriptions(newProducts);
    };

    let media_vod_id_form = media_asset_id_vod || '';

    if (override_media_asset_id_vod !== undefined) {
        media_vod_id_form = override_media_asset_id_vod;
    }

    if (form?.media_asset_id_vod !== undefined) {
        media_vod_id_form = form?.media_asset_id_vod;
    }

    return (
        <div className={classes.root}>
            <Confirm
                open={openConfirm}
                onClose={handleClose}
                onYes={handleYes}
                title='Confirmation'
                content={content}
            />
            <Container maxWidth='lg'>
                <Loader isInProgress={isInProgress || loading} />
                <form
                    noValidate
                    autoComplete='off'
                    onSubmit={(event) => {
                        confirmSave(event);
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {id !== 'new' && Object.keys(event).length > 0 && (
                                <Detail
                                    path={path}
                                    id={id}
                                    event={event}
                                    athenaState={athenaState}
                                    cdn={cdnConfig}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                label={`${localization.event_live_admin_live_media_id}:`}
                                name='media_asset_id_live'
                                handleChange={handleMediaChange}
                                showCopy={true}
                                value={
                                    form?.media_asset_id_live ||
                                    override_media_asset_id_live ||
                                    media_asset_id_live ||
                                    ''
                                }
                                autoTrim={true}
                                removeSpecialCharacters={true}
                            />
                            <Buttons
                                onClick={handleMediaReset}
                                label={localization.replace}
                            />
                            {liveSpan}
                        </Grid>
                        <Grid item sm={12}>
                            <Input
                                label={`${localization.event_live_admin_archive_media_id} (VOD):`}
                                name='media_asset_id_vod'
                                value={media_vod_id_form}
                                handleChange={handleChange}
                                showToggle={showToggle}
                                enableToggle={enableToggle}
                                originalValue={event?.media_asset_id_vod || ''}
                                handleToggleChange={handleToggleChange}
                                error={error.name}
                                showCopy={true}
                                autoTrim={true}
                                removeSpecialCharacters={true}
                            />
                        </Grid>

                        <CdnConfigGrid
                            classes={classes.cdn}
                            cdnOptions={cdnList}
                            cdnDefaultValue={cdnConfig || ''}
                            sourceOptions={sourceList}
                            sourceDefaultValue={source || ''}
                            createArchOptions={createArchiveList}
                            createArchDefaultValue={createArchive || ''}
                            dvrWindowOptions={dvrWindowList}
                            dvrWindowDefaultValue={dvrWindow || ''}
                            disabled={disableCdnConfig || airAndEditDisabled}
                            encoderButtonDisabled={
                                cdnButtonDisabled || airAndEditDisabled
                            }
                            encoderButtonText={buttonText}
                            currentEvent={currentEvent}
                            profileId={profileId}
                            tenant={selectedTenant}
                        />

                        <Suspense fallback={<CircularProgress />}>
                            <LiveVideoManagement
                                classes={classes}
                                eventId={id}
                                mediaId={mediaAssetId}
                                isMedium={isMedium}
                                isLive={isLive}
                                isVod={isVod}
                                profileId={profileId}
                                endDate={endDate}
                                startDate={startDate}
                                config={config}
                                isAdminUser={!airAndEditDisabled}
                                setEvent={setEvent}
                                freeVideo={freeVideo}
                            />
                        </Suspense>

                        <Grid item xs={12} sm={6}>
                            <SelectBox
                                label={localization.event_status}
                                name='event_status'
                                handleChange={handleChange}
                                options={EventStatusOptions || []}
                                defaultValue={
                                    override_event_status || event_status || ''
                                }
                                originalValue={
                                    override_event_status || event_status || ''
                                }
                                showToggle={false}
                                enableToggle={false}
                                disabled={airAndEditDisabled}
                                localization={localization}
                            />
                        </Grid>

                        <Suspense fallback={<CircularProgress />}>
                            <ProductManagement
                                eventId={id}
                                event={event}
                                isBR={true}
                                selectedBRProductName={selectedBRProductName}
                                mediaIDError={mediaIDError}
                                productList={productList}
                                tableHeaders={tableHeaders}
                                permissionsChanged={permissionsChanged}
                                setPermissionsChanged={setPermissionsChanged}
                                entSelectionsChanged={entSelectionsChanged}
                                setEntSelectionsChanged={
                                    setEntSelectionsChanged
                                }
                                userCanEdit={userCanEdit}
                                setLoading={setLoading}
                                subscriptions={subscriptions}
                                getMediaDetails={getMediaDetails}
                                foreignId={foreignId}
                                isFree={freeVideo}
                            />
                        </Suspense>

                        <Grid
                            container
                            item
                            xs={12}
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                        >
                            <Grid item xs={6}>
                                <IconButton
                                    aria-label='delete'
                                    style={{ color: 'black' }}
                                    onClick={(e) => handleForward(e, prevPage)}
                                >
                                    <ArrowBackOutlined fontSize='large' />
                                    <RemoveOutlined
                                        fontSize='large'
                                        style={{ marginLeft: '-16px' }}
                                    />
                                </IconButton>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid
                                    container
                                    display='flex'
                                    justifyContent='flex-end'
                                    spacing={3}
                                >
                                    <div className={classes.buttonright}>
                                        <Buttons
                                            type='submit'
                                            label={localization.save}
                                            showForWriteAccessUsers
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};

export default LiveAdminBR;
