import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = Object.freeze({
    cognitoUserName: null,
    email: null,
    firstName: null,
    isAdmin: null,
    isAuthenticated: false,
    isOperator: false,
    isReadyOnly: true,
    lastName: null,
    userTenants: [],
    userGroups: []
});

export const userSlice = createSlice({
    name: 'user',
    initialState: { ...INITIAL_STATE },
    reducers: {
        setUserIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        setUser: (state, action) => {
            state.cognitoUserName = action.payload['cognito:username'] || null;
            state.email = action.payload.email || null;
            state.firstName = action.payload.given_name || null;
            state.lastName = action.payload.family_name || null;
            const userGroups = action.payload['cognito:groups'] || [];
            state.userGroups = userGroups;
            state.isAdmin = userGroups.includes('let-administrators');
            state.isOperator = userGroups.includes('let-operators');
            const userTenants = userGroups
                .filter((group) => group.includes('tenant-'))
                .map((tenant) => tenant.replace('tenant-', ''));

            state.userTenants = userTenants || [];
        },
        setUserGroups: (state, action) => {
            const userGroups = action.payload;
            state.userGroups = userGroups;
            state.isAdmin = userGroups.includes('let-administrators');
            state.isOperator = userGroups.includes('let-operators');

            const cognitoTenant = userGroups.filter((group) =>
                group.includes('tenant-')
            );

            const userTenants = cognitoTenant.map((tenant) =>
                tenant.replace('tenant-', '')
            );

            state.userTenants = userTenants || [];
        }
    }
});

export const { setUser } = userSlice.actions;
export const { setUserGroups } = userSlice.actions;
export const { setUserIsAuthenticated } = userSlice.actions;

export const isAdmin = (state) => state.user.isAdmin;
export const isOperator = (state) => state.user.isOperator;
export const getUserData = (state) => state.user;
export const getUserFirstName = (state) => state.user.firstName;
export const getUserIsAuthenticated = (state) => state.user.isAuthenticated;
export const hasUserEditPermission = (state) =>
    state.user.isAdmin || state.user.isOperator;
export const userTenants = (state) => state.user.userTenants;

export default userSlice.reducer;
