import { createTheme } from '@mui/material/styles';
import ObjektivMk1 from './assets/fonts/ObjektivMk1_Lt.ttf';

const theme = createTheme({
    typography: {
        fontFamily: ObjektivMk1
    },
    button: {
        fontFamily: ObjektivMk1
    },
    overrides: {
        MuiDataGridPanelFooter: {
            button: {
                background: 'black',
                color: 'white'
            }
        }
    },
    components: {
        MuiTablePagination: {
            backgroundColor: 'red'
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: 'black',
                    borderRadius: 0,
                    border: 0,
                    color: 'white',
                    height: 45,
                    padding: '0 30px',
                    textTransform: 'none',
                    fontFamily: ObjektivMk1,
                    fontWeight: 700,
                    '&:hover': {
                        backgroundColor: 'black'
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    width: '100%'
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    marginBottom: '-4px'
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#000'
        },
        danger: {
            main: '#ec1300 !important'
        },
        info: {
            main: '#124377 !important'
        }
    },
    sectionHeading: {
        margin: '0 0 10px 0',
        padding: 0
    }
});

export default theme;
