import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Heading } from '../../shared/Heading';
import { CustomBreadcrumbs } from '../../shared/Breadcrumbs';
import { Info } from './Info';
import services from '../../../services/apiService';
import { Details } from '../../Details';
import { utils } from '../../../utils/utils';
import { Container } from '@mui/material';
import { getTerritories } from '../../../utils/TerritoriesUtils';
// Notifications:
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const JSONSchema = require('let-json-schemas/v1/input_db/series.json');

// Adds type to req'd per Angular LET.
const customRequiredFields = ['league_id', 'season'];
JSONSchema.required = [...JSONSchema.required, ...customRequiredFields];
/*
 TODO: Sort out why ^^^ this ^^^ was added on the LET-side,
 but not in the JSON schemas.
 */

const SeriesSteps = ({ slug, id, series, setRefresh }) => {
    switch (slug) {
        case 'series-info':
            return (
                <Info
                    id={id}
                    series={series}
                    setRefresh={setRefresh}
                    seriesSchema={JSONSchema}
                />
            );
        default:
            return <span />;
    }
};

export const CreateEditSeries = () => {
    const localization = useSelector(strings);

    const dispatch = useDispatch();
    const history = useHistory();

    let location = useLocation();
    let path = location.pathname.split('/').slice(-1)[0];
    let { id } = useParams();

    const [loadError, setLoadError] = useState();
    const [series, setSeries] = useState({});
    const [refresh, setRefresh] = useState(false);

    let steps = [
        {
            title: localization.series_info,
            slug: `series-info`
        }
    ];

    const [details, setDetails] = useState([]);

    const fetchSeries = useCallback(() => {
        id !== 'new' &&
            services
                .getItem('series', id, true)
                .then((res) => {
                    if (res && Object.keys(res).length === 0) {
                        setLoadError(
                            `Error: Series ${id} does not exist on this tenant`
                        );
                        history.push(`/series`);
                        return;
                    }
                    const { territories_available, let_override } =
                        getTerritories(res);

                    setSeries({
                        ...res,
                        territories_available,
                        let_override
                    });

                    const summary = utils.serializeSummary(
                        localization.series,
                        res,
                        localization
                    );

                    id !== 'new' && setDetails(summary);
                })
                .catch((err) => {
                    setLoadError(`${err.name}: ${err.message}`);
                });
    }, [refresh, id]);

    useEffect(() => {
        fetchSeries();
    }, [fetchSeries]);

    useEffect(() => {
        if (loadError) {
            dispatch(notifyError(loadError));
            setLoadError();
        }
    }, [loadError]);

    return (
        <Container>
            <Heading heading={localization.create_edit_series} />
            <CustomBreadcrumbs steps={steps} id={id} />
            {id !== 'new' && Object.keys(series).length > 0 && (
                <Details details={details} />
            )}
            <SeriesSteps
                slug={path}
                id={id}
                series={series}
                setRefresh={setRefresh}
            />
        </Container>
    );
};
