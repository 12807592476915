import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
    description: {
        color: 'gray',
        paddingLeft: '32px',
        fontSize: '14px'
    }
}));

export const CheckBox = (props) => {
    const {
        checked,
        disabled,
        name,
        label,
        handleChange,
        size = '',
        description = ''
    } = props;

    const classes = useStyles(props);

    return (
        <FormControl component='fieldset'>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        disabled={disabled}
                        name={name}
                        color='primary'
                        onChange={(e) => handleChange(e)}
                        style={{pointerEvents: "none"}}
                    />
                }
                label={
                    size === 'lg' ? (
                        <Typography sx={{ fontSize: 20 }}>{label}</Typography>
                    ) : (
                        label
                    )
                }
                labelPlacement='end'
            />
            {description && (
                <div className={classes.description}>{description}</div>
            )}
        </FormControl>
    );
};
