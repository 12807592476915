import React, { lazy, Suspense, useState, useEffect, useCallback } from 'react';
import FallbackUI from '../../shared/FallbackUI';
import productsService from '../../../services/productsService';
import services from '../../../services/apiService';
import Spinner from '../../shared/Spinner';
import { Appearance } from './Appearance';
import { Confirm } from '../../shared/Confirm';
import { CreateVideoAsset } from './CreateVideoAsset';
import { CustomBreadcrumbs } from '../../shared/Breadcrumbs';
import { EventInfo } from './Info';
import { getTerritories } from '../../../utils/TerritoriesUtils';
import { Heading } from '../../shared/Heading';
import { selectTenant } from '../../../reducer/tenantSlice';
import { useHistory, useLocation, useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LiveAdminBR from './LiveAdminBR';
// Notifications:
import { notifyError } from '../../../reducer/notificationReducer';
import { strings } from '../../../reducer/localizationSlice';
// ML Addition:
import { setEventData } from '../../../reducer/multilanguageSlice';

const LiveAdmin = lazy(() => import('./LiveAdmin'));

const LiveAdminHBOmax = lazy(() => import('./LiveAdminHBOmax'));

const JSONSchema = require('let-json-schemas/v1/input_db/event.json');
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    componentDidCatch(error) {
        this.setState({
            ...this.state,
            hasError: true,
            name: error.name,
            request: error.request
        });
    }
    render() {
        if (this.state.hasError) {
            return (
                <FallbackUI
                    componentName='Live Admin'
                    errorName={this.state.name}
                    errorRequest={this.state.request}
                />
            );
        }
        return this.props.children;
    }
}

const EventSteps = ({
    slug,
    id,
    event,
    setRefresh,
    config,
    handleUpdate,
    path,
    handleForward
}) => {
    if (id === 'new' && slug !== 'event-info') {
        return <Redirect to='/events/new/event-info' />;
    }

    const [cdn, setCdn] = useState('');
    const [createArchive, setCreateArchive] = useState('');
    const [state, setState] = useState('');

    const tenant = useSelector(selectTenant);

    const localization = useSelector(strings);

    useEffect(() => {
        const athenaId =
            event && event.let_override && event.let_override.athena_id;
        if (athenaId) {
            const assetResponse = productsService
                .getAsset(athenaId)
                .then((assetResponse) => {
                    let lCdn = assetResponse.cdn_config;
                    setCdn(lCdn);
                    setCreateArchive(assetResponse.create_archive);
                    setState(assetResponse.state);
                })
                .catch((error) => console.error(error));
        }
    }, [cdn, JSON.stringify(event)]);

    switch (slug) {
        case 'event-info':
            return (
                <EventInfo
                    id={id}
                    event={event}
                    setRefresh={setRefresh}
                    eventSchema={JSONSchema}
                    handleUpdate={handleUpdate}
                    path={path}
                    handleForward={handleForward}
                />
            );
        case 'appearance':
            return (
                <Appearance
                    id={id}
                    event={event}
                    setRefresh={setRefresh}
                    eventSchema={JSONSchema}
                    config={config}
                    handleUpdate={handleUpdate}
                    path={path}
                    handleForward={handleForward}
                />
            );
        case 'live-admin':
            if (tenant.isMax || tenant.isBR2){
                // Should not be here; there is no Live Admin for these tenants
                return (<Redirect to='./event-info' />);
            }
            if (event && event.let_override && event.let_override.athena_id) {
                const props = {
                    id,
                    profile: event.profile_id || event.let_override.profile_id,
                    cdn,
                    createArchive,
                    athenaState: state,
                    event,
                    setRefresh,
                    eventSchema: JSONSchema,
                    handleUpdate,
                    config,
                    path,
                    handleForward
                };

                if (tenant.isMax || tenant.isBR2) {
                    // No live admin.
                    return <div />;
                } else if (tenant.isHBO) {
                    return (
                        <Suspense fallback={<Spinner />}>
                            <ErrorBoundary>
                                <LiveAdminHBOmax {...props} />
                            </ErrorBoundary>
                        </Suspense>
                    );
                } else if (tenant.isBR) {
                    return <LiveAdminBR {...props} />;
                } else {
                    return (
                        <Suspense fallback={<Spinner />}>
                            <ErrorBoundary>
                                <LiveAdmin {...props} />
                            </ErrorBoundary>
                        </Suspense>
                    );
                }
            } else {
                return (
                    <CreateVideoAsset
                        id={id}
                        event={event}
                        setRefresh={setRefresh}
                        eventSchema={JSONSchema}
                        handleUpdate={handleUpdate}
                        config={config}
                        localization={localization}
                    />
                );
            }
        default:
            return <></>;
    }
};

export const CreateEditEvent = ({ config }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const tenant = useSelector(selectTenant);

    let location = useLocation();
    let path = location.pathname.split('/').slice(-1)[0];
    let { id } = useParams();

    const [event, setEvent] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [loadError, setLoadError] = useState();

    const handleUpdate = (updating) => {
        setIsUpdating(updating);
    };

    const localization = useSelector(strings);

    const EVENT_INFO = {
        title: localization.events_sub_event_info,
        slug: `event-info`
    };
    const EVENT_APPEARANCE = {
        title: localization.events_sub_appearance,
        slug: 'appearance'
    };
    const EVENT_LIVE_ADMIN = {
        title: localization.events_sub_live_admin,
        slug: 'live-admin'
    };

    const steps = [];
    tenant.eventSteps.forEach((stepString) => {
        steps.push(eval(stepString))
    });

    const [forwarding, setForwarding] = useState({});
    const [openConfirm, setOpenConfirm] = useState(false);
    const [link, setLink] = useState(path);

    const fetchEvents = useCallback(() => {
        id !== 'new' &&
            services.getItem('events', id, true).then((res) => {
                if (res && Object.keys(res).length === 0) {
                    // Returned an empty object. Likely it doesn't exist. Inform the User.
                    setLoadError(
                        `Error: Event ${id} does not exist on this tenant`
                    );
                    history.push(`/events`);
                    return;
                }

                // Clean up territories_available and its override:
                const { territories_available, let_override } =
                    getTerritories(res);
                // END clean up.

                setEvent({
                    ...res,
                    territories_available,
                    let_override
                });
            });
    }, [refresh, id]);

    useEffect(() => {
        dispatch(setEventData(event));
    }, [event]);

    useEffect(() => {
        fetchEvents();
    }, [fetchEvents]);

    useEffect(() => {
        if (loadError) {
            dispatch(notifyError(loadError));
            setLoadError();
        }
    }, [loadError]);

    const handleForward = (e, path) => {
        e.preventDefault();
        if (isUpdating) {
            setLink(path);
            setOpenConfirm(true);
            setForwarding({ e, path });
        } else {
            id !== 'new' && history.push(path);
        }
    };

    const handleNavigation = (e, id, slug) => {
        const path = `/events/${id}/${slug}`;
        handleForward(e, path);
    };

    const handleStay = () => {
        setOpenConfirm(false);
    };

    const handleOverride = () => {
        setIsUpdating(false);
        setOpenConfirm(false);

        history.push(forwarding.path);
    };

    return (
        <>
            <Heading heading={localization.event_info_create_edit} />
            <CustomBreadcrumbs
                steps={steps}
                id={id}
                onChange={handleNavigation}
            />
            <EventSteps
                slug={path}
                id={id}
                event={event}
                setRefresh={setRefresh}
                config={config}
                handleUpdate={handleUpdate}
                path={path}
                handleForward={handleForward}
            />
            <Confirm
                path={link}
                open={openConfirm}
                onYes={handleOverride}
                onNo={handleStay}
            />
        </>
    );
};
