import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { Grid } from '@mui/material';
import TopBar from './components/topbar';
import { BrowserRouter, Switch } from 'react-router-dom';
import Home from './components/home';
import GuardedRoute from './components/guardedRoute';
import Amplify, { Hub, Auth, Storage } from 'aws-amplify';
import getConfig from './components/Config';
import { CountryContext } from './components/context/CountryContext';
import { SportContext } from './components/context/SportContext';
import { LeagueContext } from './components/context/LeagueContext';
import { ConferenceContext } from './components/context/ConferenceContext';
import { TournamentContext } from './components/context/TournamentContext';
import { DivisionContext } from './components/context/DivisionContext';
import { TeamContext } from './components/context/TeamContext';
import { updateConfig, updateAPIKey } from './reducer/configSlice';
import {
    allTenants,
    changeTenant,
    selectTenant,
    setAllTenants
} from './reducer/tenantSlice';
import {
    getUserData,
    getUserIsAuthenticated,
    setUser,
    setUserIsAuthenticated
} from './reducer/userReducer';
import services from './services/apiService';
import productsService from './services/productsService';
import httpService from './services/httpService';
import NotisatckWrapper from './components/shared/NotisatckWrapper';
import { SessionExpired } from './components/SessionExpired';
import { getQueryParams } from './utils/utils';
import { InfoRedirect } from './components/shared/Redirects';
import Notifications from './components/Notifications';
import { setLanguage, strings } from './reducer/localizationSlice';
import localization from './l10n/localization';
import { notifyError } from './reducer/notificationReducer';
import { getTenantCheck, TENANT_CHECK } from './utils/tenantUtils';
import { SignalCellularNoSimOutlined } from '@mui/icons-material';

/*
 * App() is the implementation of <App/> tag and is the top level UI tag.
 */
function App() {
    // Add React to the global space:
    window.React = React;
    // (^^^ Addresses issue with craco build and losing the reference to React ^^^)

    const localizationScript = useSelector(strings);

    const [conferences, setConferences] = useState([]);
    const [countries, setCountries] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [gConfig, setgConfig] = useState({ amplify: { Auth: '' } });
    const [leagues, setLeagues] = useState([]);
    const [notifySessionExpiry, setNotifySessionExpiry] = useState(false);
    const [sports, setSports] = useState([]);
    const [redirect, setRedirect] = useState({});
    const [teams, setTeams] = useState([]);
    const [tournaments, setTournaments] = useState([]);

    const user = useSelector(getUserData);
    const tenants = useSelector(allTenants);

    const dispatch = useDispatch();

    const tenant = useSelector(selectTenant);

    const isAuthenticated = useSelector(getUserIsAuthenticated);

    const userData = useSelector(getUserData);

    const STARTUP_ERROR = 'startupError';

    const currentTenant = localStorage.getItem('tenant') || tenant.tenantName;

    const tenantName = useMemo(() => {
        if (tenants.length) {
            const queryObject = getQueryParams(window.location.search);
            const wouldBeTenant = queryObject?.tenant;

            if (wouldBeTenant) {
                const rootURL = window.location.href.split('?')[0];
                const updatedQuery = '';
                Object.keys(queryObject).forEach((key) => {
                    if (key && key !== 'tenant') {
                        updatedQuery += `${key}=${queryObject[key]}`;
                    }
                });
                const redirect =
                    updatedQuery === ''
                        ? rootURL
                        : `${rootURL}?${updatedQuery}`;
                if (wouldBeTenant !== currentTenant) {
                    setRedirect({
                        tenant: wouldBeTenant,
                        location: redirect
                    });
                }
            } else {
                const validTenant =
                    tenants.filter((t) => t.key === currentTenant).length !== 0;
                if (!validTenant) {
                    localStorage.removeItem('tenant');
                    localStorage.setItem(
                        STARTUP_ERROR,
                        localizationScript.invalid_tenant.replace(
                            /%%TENANT%%/,
                            currentTenant
                        )
                    );
                    window.location = window.location.origin;
                }
            }
        }

        return currentTenant;
    }, [currentTenant, tenants]);

    function login() {
        const config = gConfig.amplify.Auth;
        const { domain, redirectSignIn, responseType } = config.oauth;
        let href = `https://${domain}/oauth2/authorize?response_type=${responseType}&client_id=${config.userPoolWebClientId}&redirect_uri=${redirectSignIn}&scope=profile+openid+aws.cognito.signin.user.admin`;
        window.location.href = href;
    }

    function logout() {
        localStorage.removeItem('tenant');
        sessionStorage.clear();
        Auth.signOut()
            .then((res) => {                
                dispatch(setUserIsAuthenticated(false));
            })
            .catch((error) => {
                console.warn('error signing out: ', error);
            });
    }

    const onAuthChange = async () => {
        // console.log(`onAuthChange (${isAuthenticated})`);
        refreshServices();
    };

    const onInit = async () => {
        // console.log(`onInit`);
        // Add listener just this once:
        Hub.listen('auth', (data) => {
            const { payload } = data;
            if (payload.event === 'signIn') {
                const idToken =
                    payload?.data?.signInUserSession?.idToken?.payload;
                if (idToken) {
                    dispatch(setUser(idToken));
                }
                // Set API KEY from the generated auth token
                if (payload.data && payload.data.signInUserSession) {
                    const userSession = payload.data.signInUserSession;
                    if (
                        userSession.accessToken &&
                        userSession.accessToken.jwtToken
                    ) {
                        dispatch(
                            updateAPIKey(userSession.accessToken.jwtToken)
                        );
                    }
                }
                httpService.init();
                dispatch(setUserIsAuthenticated(true));
            }
            /*
            // Never called; commenting out.
            if (payload.event === 'signOut') {
                dispatch(setUserIsAuthenticated(false));
            }
            */
        });
        // Get the config:
        const config = await getConfig();
        setgConfig(config);
        dispatch(updateConfig(config));
        // Get all tenants:
        const allTenants = await productsService.getAllTenants();
        dispatch(setAllTenants(allTenants));
        // Set the current tenant:
        localStorage.setItem('tenant', tenantName);
        dispatch(changeTenant(tenantName));
        // Load the current language and set it in Redux:
        dispatch(setLanguage(localization.getCurrentLocalizedLanguage()));

        // Storage config:
        Storage.configure({
            bucket: config.amplify.Storage.bucket,
            region: config.amplify.Storage.region,
            identityPoolId: config.amplify.Auth.identityPoolId
        });

        const startupError = localStorage.getItem(STARTUP_ERROR);

        if (startupError) {
            localStorage.removeItem(STARTUP_ERROR);
            dispatch(notifyError(startupError));
        }

        // Configure Amplify Auth
        Amplify.configure(config.amplify);

        Auth.currentSession()
            .then((res) => {
                const idToken = res?.idToken?.payload;
                if (idToken) {
                    dispatch(setUser(idToken));
                }
                const jwtToken = res?.accessToken?.jwtToken;
                if (jwtToken) {
                    dispatch(updateAPIKey(res.accessToken.jwtToken));
                }
                httpService.init();
                dispatch(setUserIsAuthenticated(true));
            })
            .catch((error) => {
                dispatch(setUserIsAuthenticated(false));
                if (error !== 'No current user') {
                    setNotifySessionExpiry(true);
                }
            });
    };

    const onTenantChange = async () => {
        // We only need countries data for the estadios of the world.
        //
        // TODO: Change this to a configuration that only estadio-chile has.
        // This will allow new tenants to be configured for this feature or not.
        if (/^estadio-/i.test(currentTenant)) {
            const countriesUrl = 'https://geo.ngtv.io/countries';
            const alphaSort = (a, b) => (a.label > b.label ? 1 : -1);

            fetch(countriesUrl)
                .then((response) => response.text())
                .then((raw) => {
                    const keyValue = JSON.parse(raw);
                    let countries = [];
                    Object.keys(keyValue).forEach((key) => {
                        let label = keyValue[key];
                        let index = key;
                        countries.push({ id: index, value: key, label: label });
                    });
                    countries = countries.sort(alphaSort);
                    countries.unshift({
                        id: 'all',

                        value: 'all',

                        label: localizationScript.all
                    });
                    setCountries(countries);
                    return countries;
                });
        }

        refreshServices();
    };

    const refreshServices = () => {
        // Re-set sundry data:
        setSports([]);
        setLeagues([]);
        setConferences([]);
        setTournaments([]);
        setDivisions([]);
        setTeams([]);

        isAuthenticated &&
            services
                .getAttribute('sports', 'attribute', 'name')
                .then((allSports) => setSports(allSports))
                .catch((err) =>
                    console.warn('Error in fetching all sports :: ', err)
                );

        isAuthenticated &&
            services
                .getAttribute('leagues', 'attribute', 'name')
                .then((allLeagues) => {
                    setLeagues(allLeagues);
                })
                .catch((err) =>
                    console.warn('Error in fetching all leagues :: ', err)
                );
        isAuthenticated &&
            services
                .getAttribute('conferences', 'attribute', 'name')
                .then((allConferences) => {
                    setConferences(allConferences);
                })
                .catch((err) =>
                    console.warn('Error in fetching all conferences :: ', err)
                );
        isAuthenticated &&
            services
                .getAttribute('tournaments', 'attribute', 'name')
                .then((allTournaments) => setTournaments(allTournaments))
                .catch((err) =>
                    console.warn('Error in fetching all tournaments :: ', err)
                );
        isAuthenticated &&
            services
                .getAttribute('divisions', 'attribute', 'name')
                .then((allDivisions) => setDivisions(allDivisions))
                .catch((err) =>
                    console.warn('Error in fetching all divisions :: ', err)
                );
        isAuthenticated &&
            services
                .getAttribute('competitors', 'attribute', 'name')
                .then((allTeams) => setTeams(allTeams))
                .catch((err) =>
                    console.warn('Error in fetching all teams :: ', err)
                );

        notifySessionExpiry && setNotifySessionExpiry(false);
    };

    // Call only once:
    useEffect(onInit, []);
    // Call whenever auth changes:
    useEffect(onAuthChange, [isAuthenticated, notifySessionExpiry]);
    // Call whenever the tenant changes:
    useEffect(onTenantChange, [currentTenant, tenant]);

    useMemo(() => {
        const { tenant, location } = redirect;
        const { isAuthenticated, firstName, lastName } = user;

        if (!tenant || !location || !isAuthenticated) {
            return;
        }

        const tenantCheck = getTenantCheck(user, tenant, tenants, location);

        const userName = `${firstName} ${lastName}`;

        switch (tenantCheck) {
            case TENANT_CHECK.INVALID_TENANT:
                localStorage.setItem(
                    STARTUP_ERROR,
                    localizationScript.invalid_tenant.replace(
                        /%%TENANT%%/,
                        tenant
                    )
                );
                window.location = window.location.origin;
                break;
            case TENANT_CHECK.USER_HAS_NO_TENANTS:
                localStorage.setItem(
                    STARTUP_ERROR,
                    localizationScript.no_tenants_error.replace(
                        /%%USERNAME%%/,
                        userName
                    )
                );
                logout();
                break;
            case TENANT_CHECK.USER_NO_TENANT_ACCESS:
                const errorText = localizationScript.no_tenant_access
                    .replace(/%%TENANT%%/, tenant)
                    .replace(/%%USERNAME%%/, userName);
                localStorage.setItem(STARTUP_ERROR, errorText);
                window.location = window.location.origin;
                break;
            case TENANT_CHECK.OK:
                localStorage.setItem('tenant', tenant);
                dispatch(changeTenant(tenant));
                window.location = location;
                break;
            default:
            // Nothing.
            // console.info(`Unknown TENANT_CHECK response: ${tenantCheck}`);
        }
    }, [redirect, user, tenants]);

    return (
        <NotisatckWrapper>
            <SessionExpired
                message={localizationScript.session_expired}
                notify={notifySessionExpiry}
            ></SessionExpired>
            <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='flex-start'
            >
                <Grid item xs={12}>
                    <BrowserRouter>
                        <TopBar
                            login={login}
                            logout={logout}
                            userData={userData}
                        />
                        <Notifications />
                        {isAuthenticated && (
                            <CountryContext.Provider value={countries}>
                                <SportContext.Provider value={sports}>
                                    <TeamContext.Provider value={teams}>
                                        <LeagueContext.Provider value={leagues}>
                                            <TournamentContext.Provider
                                                value={tournaments}
                                            >
                                                <ConferenceContext.Provider
                                                    value={conferences}
                                                >
                                                    <DivisionContext.Provider
                                                        value={divisions}
                                                    >
                                                        <Switch>
                                                            <GuardedRoute
                                                                path='/competitors/:id/appearance'
                                                                component={Home}
                                                                config={gConfig}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='competitor-appearance'
                                                            />
                                                            <GuardedRoute
                                                                path='/competitors/:id/competitor-info'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='competitor-info'
                                                            />
                                                            <GuardedRoute
                                                                path='/competitors/:id/record-rank-management'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='competitor-record-rank-management'
                                                            />
                                                            <GuardedRoute
                                                                path='/competitors/:id'
                                                                component={
                                                                    InfoRedirect
                                                                }
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                            />

                                                            <GuardedRoute
                                                                path='/conferences/:id/conference-info'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='conference-info'
                                                            />
                                                            <GuardedRoute
                                                                path='/conferences/:id'
                                                                component={
                                                                    InfoRedirect
                                                                }
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                            />

                                                            <GuardedRoute
                                                                path='/divisions/:id/division-info'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='division-info'
                                                            />
                                                            <GuardedRoute
                                                                path='/divisions/:id'
                                                                component={
                                                                    InfoRedirect
                                                                }
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                            />

                                                            <GuardedRoute
                                                                path='/events/:id/event-info'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='event-info'
                                                            />
                                                            <GuardedRoute
                                                                path='/events/:id/appearance'
                                                                component={Home}
                                                                config={gConfig}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='event-appearance'
                                                            />
                                                            <GuardedRoute
                                                                path='/events/:id/live-admin'
                                                                component={Home}
                                                                config={gConfig}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='live-admin'
                                                            />
                                                            <GuardedRoute
                                                                path='/events/:id'
                                                                component={
                                                                    InfoRedirect
                                                                }
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                            />

                                                            <GuardedRoute
                                                                path='/leagues/:id/league-info'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='league-info'
                                                            />
                                                            <GuardedRoute
                                                                path='/leagues/:id/appearance'
                                                                component={Home}
                                                                config={gConfig}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='league-appearance'
                                                            />
                                                            <GuardedRoute
                                                                path='/leagues/:id/record-rank-management'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='league-record-rank-management'
                                                            />
                                                            <GuardedRoute
                                                                path='/leagues/:id'
                                                                component={
                                                                    InfoRedirect
                                                                }
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                            />

                                                            <GuardedRoute
                                                                path='/series/:id/series-info'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='series-info'
                                                            />
                                                            <GuardedRoute
                                                                path='/series/:id'
                                                                component={
                                                                    InfoRedirect
                                                                }
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                            />

                                                            <GuardedRoute
                                                                path='/sports/:id/sport-info'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='sport-info'
                                                            />
                                                            <GuardedRoute
                                                                path='/sports/:id/appearance'
                                                                component={Home}
                                                                config={gConfig}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='sport-appearance'
                                                            />
                                                            <GuardedRoute
                                                                path='/sports/:id'
                                                                component={
                                                                    InfoRedirect
                                                                }
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                            />

                                                            <GuardedRoute
                                                                path='/tournaments/:id/tournament-info'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='tournament-info'
                                                            />
                                                            <GuardedRoute
                                                                path='/tournaments/:id/appearance'
                                                                component={Home}
                                                                config={gConfig}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='tournament-appearance'
                                                            />
                                                            <GuardedRoute
                                                                path='/tournaments/:id'
                                                                component={
                                                                    InfoRedirect
                                                                }
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                            />

                                                            <GuardedRoute
                                                                path='/venues/:id/venue-info'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='venue-info'
                                                            />
                                                            <GuardedRoute
                                                                path='/venues/:id/appearance'
                                                                component={Home}
                                                                config={gConfig}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='venue-appearance'
                                                            />
                                                            <GuardedRoute
                                                                path='/venues/:id'
                                                                component={
                                                                    InfoRedirect
                                                                }
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                            />

                                                            <GuardedRoute
                                                                path='/competitors'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='competitors'
                                                            />
                                                            <GuardedRoute
                                                                path='/conferences'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='conferences'
                                                            />
                                                            <GuardedRoute
                                                                path='/divisions'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='divisions'
                                                            />
                                                            <GuardedRoute
                                                                path='/events'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='events'
                                                            />
                                                            <GuardedRoute
                                                                path='/leagues'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='leagues'
                                                            />
                                                            <GuardedRoute
                                                                path='/series'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='series'
                                                            />
                                                            <GuardedRoute
                                                                path='/sports'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='sports'
                                                            />
                                                            <GuardedRoute
                                                                path='/tournaments'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='tournaments'
                                                            />
                                                            <GuardedRoute
                                                                path='/venues'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                                resultType='venues'
                                                            />
                                                            <GuardedRoute
                                                                path='/calendar'
                                                                component={Home}
                                                                resultType='calendar'
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                            />
                                                            <GuardedRoute
                                                                path='/'
                                                                component={Home}
                                                                auth={
                                                                    isAuthenticated
                                                                }
                                                            />
                                                        </Switch>
                                                    </DivisionContext.Provider>
                                                </ConferenceContext.Provider>
                                            </TournamentContext.Provider>
                                        </LeagueContext.Provider>
                                    </TeamContext.Provider>
                                </SportContext.Provider>
                            </CountryContext.Provider>
                        )}
                    </BrowserRouter>
                </Grid>
            </Grid>
        </NotisatckWrapper>
    );
}

export default App;
