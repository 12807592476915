import React, { useEffect, useState, useMemo } from 'react';
import { Clear } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import _ from 'lodash';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { ToggleButton } from './ToggleButton';

const BootstrapInput = withStyles((theme) => ({
    root: (props) => ({
        'label + &': {
            marginTop: theme.spacing(3)
        },
        '& .MuiInputAdornment-root': {
            position: 'absolute',
            right: '10px'
        },
        '& .MuiInputBase-input': {
            color: props.toggle === 'true' ? 'black' : '#C0C0C0'
        }
    }),
    input: {
        borderRadius: '4px',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '9px 26px 9px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            boxShadow: 'none',
            borderRadius: '4px',
            borderColor: 'black'
        },
        '&:hover': {
            boxShadow: 'none'
        }
    }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
        width: '100%'
    },
    labelStyle: {
        fontWeight: 600,
        color: 'black',
        fontSize: 'large'
    },
    error: {
        borderRadius: 4,
        border: '1px solid red',
        '& .MuiSelect-select:focus': {
            border: 'none'
        },
        '& .MuiSelect-select:hover': {
            border: 'none'
        }
    }
}));

export const SelectBox = (props) => {
    const {
        options,
        label,
        name,
        handleChange,
        defaultValue,
        showToggle,
        enableToggle,
        originalValue,
        handleToggleChange,
        error,
        disabled,
        required,
        localization
    } = props;
    const classes = useStyles();
    const [values, setValues] = useState({
        input: ''
    });

    const [isValid, setIsValid] = useState(true);
    const isDisabled = disabled || !options || !options.length;

    useEffect(() => {
        if (
            options.length > 0 &&
            options.filter((o) => o.id === defaultValue).length > 0
        ) {
            setValues({ input: defaultValue });
            setIsValid(true);
        } else {
            setIsValid(false);
            setValues({ input: '' });
        }
    }, [options, defaultValue]);

    const handleClear = () => {
        setValues({ input: '' });
        handleChange({ target: { value: '', name } });
    };

    const handleMouseDown = (event) => {
        // event.preventDefault();
    };

    const endAdornment = () => {
        if (values.input) {
            return (
                <InputAdornment position='end'>
                    <IconButton
                        disabled={isDisabled}
                        onClick={handleClear}
                        onMouseDown={handleMouseDown}
                        data-testid='sel_btn_clear'
                    >
                        <Clear />
                    </IconButton>
                </InputAdornment>
            );
        }

        return '';
    };

    const defaultPlaceholder = useMemo(() => {
        if (defaultValue === '') return localization?.select;

        if (options.length === 0) return localization?.invalid_selection;

        const exist =
            options.length > 0 &&
            options.filter((o) => o.id === defaultValue).length > 0
                ? true
                : false;
        if (!exist) return localization?.select;

        return '';
    }, [defaultValue, options]);

    return (
        <>
            <Grid
                container
                justifyContent='space-between'
                alignItems='flex-end'
            >
                {showToggle && (
                    <>
                        <Grid item xs={9}>
                            <InputLabel
                                className={classes.labelStyle}
                                shrink
                                htmlFor={name}
                            >
                                {label}{' '}
                                {required && (
                                    <span className='text-danger'>*</span>
                                )}
                            </InputLabel>
                        </Grid>
                        <Grid item xs={3} style={{ flexBasis: '5%' }}>
                            <ToggleButton
                                showToggle={
                                    showToggle &&
                                    (defaultValue != null ||
                                        defaultValue !== undefined) &&
                                    !_.isEqual(defaultValue, originalValue)
                                }
                                name={name}
                                originalValue={originalValue}
                                enableToggle={enableToggle}
                                handleToggleChange={handleToggleChange}
                            />
                        </Grid>
                    </>
                )}
                {!showToggle && (
                    <>
                        <Grid item xs={12}>
                            <InputLabel
                                className={classes.labelStyle}
                                shrink
                                htmlFor={name}
                            >
                                {label}{' '}
                                {required && (
                                    <span className='text-danger'>*</span>
                                )}
                            </InputLabel>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item xs={12}>
                <Select
                    labelId={name}
                    id={name}
                    value={values.input}
                    onChange={handleChange}
                    displayEmpty
                    disabled={isDisabled}
                    input={
                        <BootstrapInput
                            endAdornment={endAdornment(defaultValue)}
                            toggle={
                                enableToggle && enableToggle[name]
                                    ? 'true'
                                    : 'false'
                            }
                        />
                    }
                    name={name}
                    style={{ width: '100%' }}
                    {...(!!error && { className: classes.error })}
                >
                    {isValid && <MenuItem value=''>Select</MenuItem>}
                    {!isValid && (
                        <MenuItem value=''>{defaultPlaceholder}</MenuItem>
                    )}
                    {!!options.length &&
                        options.map((o) => (
                            <MenuItem key={o.id} value={o.id}>
                                {o.name}
                            </MenuItem>
                        ))}
                </Select>
            </Grid>
        </>
    );
};
