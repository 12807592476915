import React from 'react';
import { Grid } from '@mui/material';
import { SelectBox } from '../../shared/SelectBox';

export const GROUP_OPTIONS = Object.freeze([
    {
        name: 'Default',
        id: '',
        key: 'go-00'
    },
    {
        name: 'Sports Talk',
        id: 'Sports Talk',
        key: 'go-01'
    }
]);

const Group = (props) => {
    const {
        groups,
        handleChange,
        localization,
        originalValue,
        selectTitle,
        value
    } = props;

    return (
        <Grid item container xs={6}>
            <SelectBox
                label={`${selectTitle}:`}
                name='group'
                options={groups}
                defaultValue={value || ''}
                handleChange={handleChange}
                showToggle={false}
                enableToggle={false}
                originalValue={originalValue}
                localization={localization}
                required={false}
            />
        </Grid>
    );
};

export default Group;
