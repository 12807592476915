import React from 'react';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    loadError: {
        border: '1px solid rgba(277,277,277, 0.5)',
        margin: '20px auto',
        padding: '20px',
        textAlign: 'center',
        width: '80%'
    }
}));

const FallbackUI = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.loadError}>
            <h3 data-name='fallback-title'>
                The {props.componentName} Component Has Failed to Load
            </h3>
            <p>
                This is <em>likely</em> a local development issue.
            </p>
            <p>
                Please click{' '}
                <a data-id='fallback-link' href={window.location.href}>
                    here
                </a>{' '}
                to reload this page.
            </p>
            <p>If this problem persists, please contact support.</p>
            {props.errorName && (
                <pre data-name='fallback-error-name'>{props.errorName}</pre>
            )}
            {props.errorRequest && (
                <pre data-name='fallback-error-request'>
                    {props.errorRequest}
                </pre>
            )}
        </div>
    );
};

export default FallbackUI;
