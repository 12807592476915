
export const exportService = {
  csvSeparator: ',',

  exportJSON: function (data, fileName) {
    return this.download(fileName, 'application/json', 'json', JSON.stringify(data));
  },

  validateCellData: function (cellData) {
    if (cellData === null) {
      cellData = '';
    } else if (typeof cellData === 'boolean') {
      cellData = cellData ? 'Yes' : 'No';
    }
    return cellData;
  },

  exportCSV: function (columns, rows, fileName) {
    let csv = '';
    const filteredCols = columns.filter(
      column => column.field
    );

    filteredCols.forEach(({ header }, i) => {
      csv += '"' + header + '"';

      if (i < filteredCols.length - 1) {
        csv += this.csvSeparator;
      }
    });

    rows.forEach(row => {
      csv += '\n';
      filteredCols.forEach((column, i) => {
        let cellData = row[column.field];
        cellData = this.validateCellData(cellData);

        if (typeof cellData !== 'undefined') {
          cellData = String(cellData).replace(/"/g, '""');
        } else {
          cellData = '';
        }

        csv += '"' + cellData + '"';

        if (i < filteredCols.length - 1) {
          csv += this.csvSeparator;
        }
      });
    });

    return this.download(fileName, 'text/csv', 'csv', csv);
  },

  download(fileName, contentType, extension, content) {
    const blob = new Blob([content], {
      type: `${contentType};charset=utf-8;`
    });

    return Promise.resolve(blob).then(data => {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(data, `${fileName}.${extension}`);
      } else {
        const link = document.createElement('a');

        link.style.display = 'none';
        link.setAttribute("id", "downloadLink");
        document.body.appendChild(link);
        if (link.download !== undefined) {
          link.setAttribute('href', URL.createObjectURL(data));
          link.setAttribute('download', `${fileName}.${extension}`);
          link.click();
        } else {
          window.open(encodeURI(`data:${contentType};charset=utf-8,${content}`));
        }

        document.body.removeChild(link);
      }
    });
  }
}