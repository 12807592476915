import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Heading } from '../../shared/Heading';
import { CustomBreadcrumbs } from '../../shared/Breadcrumbs';
import { Info } from './Info';
import services from '../../../services/apiService';
import { Details } from '../../Details';
const JSONSchema = require('let-json-schemas/v1/input_db/division.json');
import { utils } from '../../../utils/utils';
import { Container } from '@mui/material';
// Notifications:
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

// Override the JSON schema. Add alias:
const customRequiredFields = ['alias'];
JSONSchema.required = [...JSONSchema.required, ...customRequiredFields];
/*
TODO: Sort out why ^^^ this ^^^ was added on the LET-side,
but not in the JSON schemas.
*/

const DivisionSteps = ({ slug, id, division, setRefresh }) => {
    switch (slug) {
        case 'division-info':
            return (
                <Info
                    id={id}
                    division={division}
                    setRefresh={setRefresh}
                    divisionSchema={JSONSchema}
                />
            );
        default:
            return <span />;
    }
};

export const CreateEditDivisions = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    let location = useLocation();
    let path = location.pathname.split('/').slice(-1)[0];
    let { id } = useParams();

    const [division, setDivision] = useState({});
    const [loadError, setLoadError] = useState();
    const [refresh, setRefresh] = useState(false);

    const localization = useSelector(strings);

    let steps = [
        {
            title: localization.division_info,
            slug: `division-info`
        }
    ];

    const [details, setDetails] = useState([]);

    const fetchDivisions = useCallback(() => {
        id !== 'new' &&
            services
                .getItem('divisions', id, true)
                .then((res) => {
                    if (res && Object.keys(res).length === 0) {
                        setLoadError(
                            `Error: Division ${id} does not exist on this tenant`
                        );
                        history.push(`/divisions`);
                        return;
                    }

                    setDivision(res);

                    const summary = utils.serializeSummary(
                        localization.division_id,
                        res,
                        localization
                    );

                    id !== 'new' && setDetails(summary);
                })
                .catch((err) => {
                    setLoadError(`${err.name}:  ${err.message}`);
                });
    }, [refresh, id]);

    useEffect(() => {
        fetchDivisions();
    }, [fetchDivisions]);

    useEffect(() => {
        if (loadError) {
            dispatch(notifyError(loadError));
            setLoadError();
        }
    }, [loadError]);

    return (
        <Container>
            <Heading heading={localization.create_edit_division} />
            <CustomBreadcrumbs steps={steps} id={id} />
            {id !== 'new' && Object.keys(division).length > 0 && (
                <Details details={details} />
            )}
            <DivisionSteps
                slug={path}
                id={id}
                division={division}
                setRefresh={setRefresh}
            />
        </Container>
    );
};
