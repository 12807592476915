import React, { useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { ArrowBackOutlined, RemoveOutlined } from '@mui/icons-material';
import { Input } from '../../shared/Input';
import { Buttons } from '../../shared/Buttons';
import { useForm } from '../../hooks/UseForm';
import { Loader } from '../../shared/Loader';
import { useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiIconButton-root:hover': {
            background: 'none'
        }
    },
    buttons: {
        alignItems: 'center',
        marginTop: '1rem'
    }
}));

export const RecordRankManagement = ({
    id,
    competitor,
    setRefresh,
    competitorSchema,
    handleUpdate,
    handleForward
}) => {
    const classes = useStyles();
    let prevPage = `/competitors/${id}/appearance`;
    const requiredFields = competitorSchema.required;
    const {
        item,
        error,
        valueChanged,
        isInProgress,
        handleChange,
        handleNumberChange,
        onFormSubmit,
        form
    } = useForm({
        service: 'competitors',
        id: id,
        initialState: competitor,
        requiredFields,
        setRefresh
    });

    useEffect(() => {
        handleUpdate(valueChanged);
    }, [valueChanged]);

    const {
        rank,
        games_back,
        wins,
        losses,
        draws,

        let_override: {
            rank: override_rank,
            games_back: override_games_back,
            wins: override_wins,
            losses: override_losses,
            draws: override_draws
        } = {}
    } = item;

    const localization = useSelector(strings);

    return (
        <div className={classes.root}>
            <Container maxWidth='lg'>
                <Loader isInProgress={isInProgress} />
                <form noValidate autoComplete='off' onSubmit={onFormSubmit}>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.rank}:`}
                                name='rank'
                                value={override_rank || rank || form.rank || ''}
                                originalValue={competitor && competitor.rank}
                                handleChange={handleNumberChange}
                                type='number'
                                required={requiredFields.includes('rank')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.games_back}:`}
                                name='games_back'
                                value={
                                    override_games_back ||
                                    games_back ||
                                    form.games_back ||
                                    ''
                                }
                                originalValue={
                                    competitor && competitor.games_back
                                }
                                handleChange={handleNumberChange}
                                type='number'
                                required={requiredFields.includes('games_back')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.wins}:`}
                                name='wins'
                                value={override_wins || wins || form.wins || ''}
                                originalValue={competitor && competitor.wins}
                                handleChange={handleNumberChange}
                                type='number'
                                required={requiredFields.includes('wins')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.losses}:`}
                                name='losses'
                                value={
                                    override_losses ||
                                    losses ||
                                    form.losses ||
                                    ''
                                }
                                originalValue={competitor && competitor.losses}
                                handleChange={handleNumberChange}
                                type='number'
                                required={requiredFields.includes('losses')}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Input
                                label={`${localization.draws}:`}
                                name='draws'
                                value={
                                    override_draws || draws || form.draws || ''
                                }
                                originalValue={competitor && competitor.draws}
                                handleChange={handleNumberChange}
                                type='number'
                                required={requiredFields.includes('draws')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.buttons}>
                        <Grid item xs={6}>
                            <IconButton
                                aria-label='delete'
                                style={{ color: 'black' }}
                                onClick={(e) => handleForward(e, prevPage)}
                            >
                                <ArrowBackOutlined fontSize='large' />
                                <RemoveOutlined
                                    fontSize='large'
                                    style={{ marginLeft: '-16px' }}
                                />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align='right'>
                                <Buttons
                                    type='submit'
                                    label={localization.save}
                                    showForWriteAccessUsers
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};
