import React from 'react'
import { SnackbarProvider } from 'notistack';

const MAX_SNACK = 3;
const AUTO_HIDE_DURATION = 3000;
const POSITION = {
    vertical: 'top',
    horizontal: 'right'
};

const NotisatckWrapper = ({ children }) => {
    return (
        <SnackbarProvider
            maxSnack={MAX_SNACK}
            autoHideDuration={AUTO_HIDE_DURATION}
            hideIconVariant={true}
            anchorOrigin={POSITION}
            transitionDuration={{ appear: 750, enter: 500, exit: 500 }}
        >
            {children}
        </SnackbarProvider>
    );
};

export default NotisatckWrapper;
