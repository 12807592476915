import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { SelectBox } from '../../shared/SelectBox';

export const CustomSubFields = (props) => {
    const {
        customFields,
        currentFieldSelected,
        allFormattedDescriptors,
        formFields,
        handleChange,
        localization
    } = props;

    const [localFormFields, setLocalFormFields] = useState(formFields);
    const [isSubFieldChanged, setIsSubFieldChanged] = useState(false);

    useEffect(() => {
        setLocalFormFields(formFields);
        setIsSubFieldChanged(false);
    }, [formFields, localFormFields, isSubFieldChanged]);

    const onSubFieldChange = (event) => {
        const element = event.target;
        const { name, value } = element;

        let fields = localFormFields;
        fields[name] = value;
        setLocalFormFields(fields);
        setIsSubFieldChanged(true);
        handleChange(element);
    };

    const subFieldsOption = (field) => {
        let options = {};
        // If We need to pull the options from ATHENA.
        if (
            field.pullChoicesFromAthena &&
            Object.keys(allFormattedDescriptors).length != 0
        ) {
            const key = field['name'];
            options = allFormattedDescriptors[key].map((element) => {
                return { id: element.value, name: element.label };
            });
        } else {
            // Else pull the options from DB.
            options = field.choices.map((element) => {
                return { id: element.value, name: element.label };
            });
        }

        return options;
    };

    const getDefaultValue = (field) => {
        let fieldName = field.name;
        if (localFormFields && localFormFields[fieldName] != 'undefined') {
            return localFormFields[fieldName];
        }

        return '';
    };

    let subFieldsElements = '';
    if (
        customFields &&
        customFields.customs &&
        customFields.customs.length != 0
    ) {
        let customs = customFields.customs;
        subFieldsElements = customs.map((field, index) => {
            if (field.condition_on.find((ele) => ele == currentFieldSelected)) {
                let options = subFieldsOption(field);
                let defaultValue = getDefaultValue(field);
                return (
                    <Grid item xs={12} key={index}>
                        <SelectBox
                            key={index}
                            name={field.name}
                            label={field.label}
                            defaultValue={defaultValue}
                            options={options}
                            handleChange={onSubFieldChange}
                            localization={localization}
                        />
                    </Grid>
                );
            }
        });
    }
    return subFieldsElements;
};
