import React, { useState, useEffect, useCallback } from 'react';
import services from '../../../services/apiService';
import { Appearance } from '../../competitors/competitors-details/Appearance';
import { Confirm } from '../../shared/Confirm';
import { CustomBreadcrumbs } from '../../shared/Breadcrumbs';
import { Details } from '../../Details';
import { getTerritories } from '../../../utils/TerritoriesUtils';
import { Heading } from '../../shared/Heading';
import { Info } from './Info';
import { RecordRankManagement } from '../../competitors/competitors-details/RecordRankManagement';
import { useHistory, useLocation, useParams, Redirect } from 'react-router-dom';
import { utils } from '../../../utils/utils';
// Notifications:
import { notifyError } from '../../../reducer/notificationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../reducer/localizationSlice';

const JSONSchema = require('let-json-schemas/v1/input_db/competitor.json');

const CompetitorSteps = ({
    slug,
    id,
    competitor,
    setRefresh,
    config,
    handleUpdate,
    handleForward
}) => {
    if (id === 'new' && slug !== 'competitor-info') {
        return <Redirect to='/competitors/new/competitor-info' />;
    }

    switch (slug) {
        case 'competitor-info':
            return (
                <Info
                    id={id}
                    competitor={competitor}
                    setRefresh={setRefresh}
                    competitorSchema={JSONSchema}
                    handleUpdate={handleUpdate}
                    handleForward={handleForward}
                />
            );
        case 'appearance':
            return (
                <Appearance
                    id={id}
                    competitor={competitor}
                    setRefresh={setRefresh}
                    competitorSchema={JSONSchema}
                    config={config}
                    handleUpdate={handleUpdate}
                    handleForward={handleForward}
                />
            );
        case 'record-rank-management':
            return (
                <RecordRankManagement
                    id={id}
                    competitor={competitor}
                    setRefresh={setRefresh}
                    competitorSchema={JSONSchema}
                    handleForward={handleForward}
                    handleUpdate={handleUpdate}
                />
            );
        default:
            return <div></div>;
    }
};

export const CreateEditCompetitors = ({ config }) => {
    const history = useHistory();

    let location = useLocation();
    let path = location.pathname.split('/').slice(-1)[0];
    let { id } = useParams();

    const [competitor, setCompetitor] = useState({});
    const [isUpdating, setIsUpdating] = useState(false);
    const [link, setLink] = useState(path);
    const [loadError, setLoadError] = useState();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [forwarding, setForwarding] = useState({});
    const [refresh, setRefresh] = useState(false);

    const dispatch = useDispatch();

    const localization = useSelector(strings);

    const steps = [
        {
            title: localization.competitor_information,
            slug: 'competitor-info'
        },
        {
            title: localization.appearance,
            slug: 'appearance'
        },
        {
            title: localization.record_rank_management,
            slug: 'record-rank-management'
        }
    ];

    const handleUpdate = (event) => {
        setIsUpdating(event);
    };

    const [details, setDetails] = useState([]);

    const fetchCompetitors = useCallback(() => {
        id !== 'new' &&
            services
                .getItem('competitors', id, true)
                .then((res) => {
                    if (res && Object.keys(res).length === 0) {
                        setLoadError(
                            `Error: Competitor ${id} does not exist on this tenant`
                        );
                        history.push('/competitors');
                        return;
                    }

                    const { territories_available, let_override } =
                        getTerritories(res);

                    setCompetitor({
                        ...res,
                        territories_available,
                        let_override
                    });

                    const summary = utils.serializeSummary(
                        localization.competitor_id,
                        res,
                        localization
                    );

                    id !== 'new' && setDetails(summary);
                })
                .catch((err) => {
                    setLoadError(`Error: ${err.message}`);
                });
    }, [refresh, id]);

    useEffect(() => {
        fetchCompetitors();
    }, [fetchCompetitors]);

    useEffect(() => {
        if (loadError) {
            dispatch(notifyError(loadError));
            setLoadError();
        }
    }, [loadError]);

    const handleForward = (e, path) => {
        e.preventDefault();
        if (isUpdating) {
            setLink(path);
            setOpenConfirm(true);
            setForwarding({ e, path });
        } else {
            id !== 'new' && history.push(path);
        }
    };

    const handleStay = () => {
        setOpenConfirm(false);
    };

    const handleOverride = () => {
        setIsUpdating(false);
        setOpenConfirm(false);

        if (forwarding.path) {
            history.push(forwarding.path);
        }
    };

    const handleNavigation = (e, id, slug) => {
        const path = `/competitors/${id}/${slug}`;
        handleForward(e, path);
    };

    return (
        <>
            <Heading heading={localization.create_edit_competitor} />
            <CustomBreadcrumbs
                steps={steps}
                id={id}
                onChange={handleNavigation}
            />
            {id !== 'new' && Object.keys(competitor).length > 0 && (
                <Details details={details} />
            )}
            <CompetitorSteps
                slug={path}
                id={id}
                competitor={competitor}
                setRefresh={setRefresh}
                config={config}
                handleUpdate={handleUpdate}
                handleForward={handleForward}
            />
            <Confirm
                path={link}
                open={openConfirm}
                onYes={handleOverride}
                onNo={handleStay}
            />
        </>
    );
};
